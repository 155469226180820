import React, { FunctionComponent, useState, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { RowContainer, ColumnContainer } from '../basic/Flex';
import { BasicParagraph, H2, H3 } from '../basic/Text';
import { koti, changeHousePic } from '../../assets/index';
import { ApartmentChangeAd } from '../../context/appContext';
import Button, { LinkButton } from '../button/Button';
import Loader from '../basic/Loader';
import { activeElements, formatDate, formatMoney, removeAttributes, setAttributes } from '../../utils';
import { deleteApartmentChangeAd } from '../../apis';
import { useMessageGetter } from 'react-message-context';
import { DeviceSize } from '../basic/DeviceSize';
import Picture from '../basic/Picture';
import ReactGA from 'react-ga';
import RedirectModal from '../basic/RedirectModal';

const ApartmentChangeAdView: FunctionComponent<{ ad: ApartmentChangeAd }> = ({ ad }) => {
  const t = useMessageGetter('change.ad');
  const [status, setStatus] = useState(ad.status);
  const [isBusy, setIsBusy] = useState(false);
  const theme = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const modalText = useMessageGetter('modal');

  async function removeAd() {
    setIsBusy(true);
    await deleteApartmentChangeAd(ad.id!);
    setStatus('deleted');
    setIsBusy(false);
    window.location.reload();
  }

  const backgroundElements = document.querySelectorAll(activeElements);

  const openRedirectModal = (url: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    document.body.style.overflow = 'hidden';

    event.preventDefault();

    backgroundElements.forEach((element) => {
      setAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
    });

    setShowModal(true);
    setLinkUrl(url);
  };

  const cancelRedirection = () => {
    document.body.style.overflow = 'auto';

    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
    });

    setShowModal(false);
  };

  const continueToPage = () => {
    document.body.style.overflow = 'auto';

    window.open(linkUrl, '_blank');
    ReactGA.event({
      category: 'Apartment change',
      action: 'Click external link',
      label: linkUrl,
    });

    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
    });

    setShowModal(false);
  };

  return (
    <ApartmentChangeAdContainer
      key={ad.id}
      style={{
        backgroundColor: ad.status === 'published' ? theme.colors.peppermintVeryLight : theme.colors.shadow,
      }}
    >
      {showModal && (
        <RedirectModal cancelRedirect={cancelRedirection} continueRedirect={continueToPage} modalShown={showModal}>
          {modalText('redirect')}
        </RedirectModal>
      )}
      <AdHeader>
        <AdIcon src={koti} alt={t('icontext')} />
        <AdHeaderTextContent>
          <Address>
            {ad.apartment.address}, {ad.apartment.zip} {ad.apartment.city}
          </Address>
          <CreatedAt>{t(`status.${status}`, { published: formatDate(ad.createdAt) })}</CreatedAt>
        </AdHeaderTextContent>
      </AdHeader>
      <AdSection>
        <TitleRow>
          <H3>{t('buildingtitle')}</H3>
        </TitleRow>
        <StackOnMobileReverse>
          <ApartmentDetails>
            <AdvertisersInfo className="apartmentRent">
              <BasicParagraph>
                {t('rooms')}: <SemiboldText>{ad.apartment.rooms}</SemiboldText>
              </BasicParagraph>
              <BasicParagraph>
                {t('squares')}: <SemiboldText>{ad.apartment.squares}m2</SemiboldText>
              </BasicParagraph>
              <BasicParagraph>
                {t('lease')}: <SemiboldText>{formatMoney(ad.agreement && ad.agreement.lease, '-')} / kk</SemiboldText>
              </BasicParagraph>
              {ad.apartment.waterPrePayment && (
                <BasicParagraph>
                  {t('water')}: <SemiboldText>{formatMoney(ad.apartment.waterPrePayment, '-')}</SemiboldText>
                </BasicParagraph>
              )}
              {ad.apartment.waterDescription && <BasicParagraph>{ad.apartment.waterDescription}</BasicParagraph>}
            </AdvertisersInfo>
            <AdvertisersInfo className="apartmentRent">
              <BasicParagraph>
                {t('buildingtype')}: <SemiboldText>{ad.housing.buildingType}</SemiboldText>
              </BasicParagraph>
              <BasicParagraph>
                {t('year')}: <SemiboldText>{ad.housing.year}</SemiboldText>
              </BasicParagraph>
              <BasicParagraph>
                {t('apartments')}: <SemiboldText>{ad.housing.apartmentcounts}</SemiboldText>
              </BasicParagraph>
              <BasicParagraph>
                {t('floors')}: <SemiboldText>{ad.housing.floors}</SemiboldText>
              </BasicParagraph>
              <BasicParagraph>
                {t('elevator')}: <SemiboldText>{ad.housing.elevator ? t('yes') : t('no')}</SemiboldText>
              </BasicParagraph>
            </AdvertisersInfo>
          </ApartmentDetails>
          {/* TODO: Show housing image from ad.housing as new feature? */}
          <IllustrationContainer>
            <Picture aria-label="Kuvitus" src={changeHousePic} alt="Kuvitus" />
            {ad.exchangeUrl !== '' && <ApartmentButton
              href="#"
              onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                openRedirectModal(ad.exchangeUrl, event)
              }
            >
              {t('housinginfobtn')}
            </ApartmentButton>}
          </IllustrationContainer>
        </StackOnMobileReverse>
      </AdSection>
      <AdSection>
        <TitleRow>
          <H3>{t('current.title')}</H3>
        </TitleRow>
        <BasicParagraph>{ad.messages.current || '-'}</BasicParagraph>
        <TitleRow>
          <H3>{t('desired.title')}</H3>
        </TitleRow>
        <BasicParagraph>{ad.messages.desired || '-'}</BasicParagraph>
        <AdvertisersInfo>
          <BasicParagraph>
            {t('name')}:&nbsp;
            <SemiboldText>
              {ad.profile.givenName} {ad.profile.lastName}
            </SemiboldText>
          </BasicParagraph>
          <BasicParagraph>
            {t('phone')}: <SemiboldText>{ad.profile.phone}</SemiboldText>
          </BasicParagraph>
          <BasicParagraph>
            {t('email')}: <SemiboldText>{ad.profile.email}</SemiboldText>
          </BasicParagraph>
        </AdvertisersInfo>
        {ad.isMyAd &&
          (isBusy ? (
            <Loader />
          ) : (
            <DeleteButton onClick={removeAd} disabled={status === 'deleted'}>
              {t('removebtn')}
            </DeleteButton>
          ))}
      </AdSection>
    </ApartmentChangeAdContainer>
  );
};

const adCellStyle = css`
  margin: 15px;
`;

const ApartmentChangeAdContainer = styled.div`
  margin: 5px 0;
  border-radius: 24px;
  width: 100%;
`;

const AdHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  margin: 1px 0;

  @media ${DeviceSize.mobileL} {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
`;

const AdHeaderTextContent = styled.div`
  ${adCellStyle}
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  *:first-child {
    flex-grow: 1;
  }

  @media ${DeviceSize.mobileL} {
    flex-direction: column;
  }
`;

const IllustrationContainer = styled(RowContainer)`
  @media ${DeviceSize.mobileL} {
    margin-bottom: 10px;
  }
`;

const StackOnMobileReverse = styled.div`
  display: flex;
  flex-direction: row;
  @media ${DeviceSize.mobileL} {
    flex-direction: column-reverse;
  }
`;

const ApartmentDetails = styled.div`
  margin-right: 20px;
  flex: 1;

  .apartmentRent > p:first-child {
    margin-top: 0;
  }

  @media ${DeviceSize.mobileL} {
    margin: 0;
    
   .apartmentRent > p:first-child {
    margin-top: 15px;
  }
  }
`;

const SemiboldText = styled.span`
  margin: 0;
  display: inline-block;
  font-weight: bold;
`;

const AdvertisersInfo = styled.div`
  margin-top: 0;

  > p:not(:last-child) {
    margin-bottom: 6px;
  }

  > p:not(:first-child) {
    margin-top: 0;
  }
`;

const AdSection = styled.div`
  margin: 0 49px;
  padding: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.m2Black};

  > .apartmentdetails {
    margin-bottom: 19px;
  }

  @media ${DeviceSize.mobileL} {
    margin: 0;
    padding: 15px;
  }
`;

const AdIcon = styled.img`
  ${adCellStyle}
  display: flex;
  @media ${DeviceSize.mobileL} {
    display: none;
  }
`;

const Address = styled(H2)`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
const CreatedAt = styled(BasicParagraph)``;

const TitleRow = styled(ColumnContainer)`
  margin-bottom: 15px;

  @media ${DeviceSize.mobileL} {
  }
`;

const ApartmentButton = styled(LinkButton)`
  width: 100%;
  margin-top: 10px;
`;

const DeleteButton = styled(Button)`
  @media ${DeviceSize.mobileL} {
    width: 100%;
  }
`;

export default ApartmentChangeAdView;
