import React, { useEffect, useRef, useState } from 'react';
import { useMessageGetter } from 'react-message-context';
import { H1, H2, H3, CallOut, BasicParagraph, BoldAnchor } from '../components/basic/Text';
import styled, { css } from 'styled-components';
import PageContainer from './PageContainer';
import { RouteComponentProps } from 'react-router-dom';
import PaddedContent from '../components/housing/PaddedContent';
import { MarginContainer } from '../components/basic/Flex';
import { loadApartment, LOAD_STATES, loadProfile, showNotification } from '../context/actions';
import { useApp, ApartmentChangeAd } from '../context/appContext';
import { postApartmentChangeAd } from '../apis';
import Loader from '../components/basic/Loader';
import Button from '../components/button/Button';
import { Select, Textarea, ReadonlyTextField } from '../components/input/Input';
import { getApartmentChangeAds } from '../apis';
import ApartmentChangeAdView from '../components/apartment/ApartmentChangeAdView';
import { DeviceSize } from '../components/basic/DeviceSize';
import { activeElements, removeAttributes } from '../utils';
import ReactGA from 'react-ga';
import RedirectModal from '../components/basic/RedirectModal';
import {filledBox, notFilledBox, chevronUp} from '../assets';
import { housingExchangeUrls } from '../constants/housingExchangeUrls';

const ApartmentSwapPage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('change');
  const [{ apartment, profile }, dispatch] = useApp();
  const [ads, setAds] = useState<ApartmentChangeAd[]>([]);
  const [currentAptMessage, setCurrentAptMessage] = useState('');
  const [desiredAptMessage, setDesiredAptMessage] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [saveState, setSaveState] = useState(LOAD_STATES.READY);
  const [loadState, setLoadState] = useState(LOAD_STATES.READY);
  const [myAd, setMyAd] = useState(null);
  const [linkUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const modalText = useMessageGetter('modal');

  const phone = profile.phone || '';
  const email = profile.email || '';
  const address = apartment.info.address || '';

  const enlistRef = useRef<HTMLDivElement>(null);

  const [selectedCity, setSelectedCity] = useState('');

  async function getAds() {
    setLoadState(LOAD_STATES.BUSY);
    const ads = await getApartmentChangeAds();
    // Add link to housing page (if available) in m2kodit website
    ads.forEach((ad: any) => {
      ad.exchangeUrl = housingExchangeUrls.find((i) =>
        i.houseId === ad.housing.houseId)?.url || 'https://m2kodit.fi/vuokra-asunnot/';
    });

    ads.sort((a: ApartmentChangeAd, b: ApartmentChangeAd) => {
      if (a.isMyAd && !b.isMyAd) return -1;
      if (!a.isMyAd && b.isMyAd) return 1;
      return -a.createdAt.localeCompare(b.createdAt);
    });
    setAds(ads);
    setMyAd(ads.find((ad: ApartmentChangeAd) => ad.isMyAd));
    setLoadState(LOAD_STATES.READY);
  }

  useEffect(() => {
    if (!apartment.loadState) loadApartment(dispatch);
    if (!profile.loadState) loadProfile(dispatch);
  });

  useEffect(() => {
    getAds();
  }, []);

  const scrollToInstructions = () =>
    enlistRef.current && enlistRef.current.scrollIntoView({ behavior: 'smooth' });

  const sendApartmentChangeAd = async () => {
    setSaveState(LOAD_STATES.BUSY);
    try {
      await postApartmentChangeAd({ currentAptMessage, desiredAptMessage });
      ReactGA.event({
        category: 'Apartment change',
        action: 'Send change ad',
        label: currentAptMessage,
      });

      setCurrentAptMessage('');
      setDesiredAptMessage('');
      setSaveState(LOAD_STATES.READY);
      showNotification(dispatch, t('adsent'));
      await getAds();
      window.location.reload();
    } catch {
      setSaveState(LOAD_STATES.ERROR);
    }
  };

  const cities = Array.from(new Set(ads.map((ad) => ad.apartment.city))).sort();
  const filteredAds = ads.filter((ad) => !selectedCity || ad.apartment.city === selectedCity);

  const backgroundElements = document.querySelectorAll(activeElements);

  const cancelRedirection = () => {
    document.body.style.overflow = 'auto';

    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
    });

    setShowModal(false);
  };

  const continueToPage = () => {
    document.body.style.overflow = 'auto';

    window.open(linkUrl, '_blank');
    ReactGA.event({
      category: 'Apartment change',
      action: 'Click external link',
      label: linkUrl,
    });

    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
    });

    setShowModal(false);
  };

  return (
    <PageContainer history={history}>
      {showModal && (
        <RedirectModal cancelRedirect={cancelRedirection} continueRedirect={continueToPage} modalShown={showModal}>
          {modalText('redirect')}
        </RedirectModal>
      )}
      <Content>
        <TitleContent>
          <H1>{t('title')}</H1>
          <CallOut>{t('swap.title')}</CallOut>
          <BasicParagraph>{t('swap.description')}</BasicParagraph>
        </TitleContent>
        <ol>
          <li>{t('swap.step1')}</li>
          <li>{t('swap.step2')}</li>
          <li>{t('swap.step3')}</li>
          <li>{t('swap.step4')}</li>
        </ol>
        <p>{t('swap.notice')}</p>
        <p>
          {t('swap.instructionlink')}
          <Anchor onClick={scrollToInstructions}>
            {t('swap.link')}
          </Anchor>
        </p>
        <FormContainer>
          <ExchangeListContainer>
            <H1>{t('enlistedapartments')}</H1>
            <Select name="cityfilter" value={selectedCity} onChange={(e: any) => setSelectedCity(e.target.value)}>
              <option value="">{t('allcities')}</option>
              {cities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </Select>
            <ListContainer>
              {loadState === LOAD_STATES.BUSY && <Loader />}
              {loadState !== LOAD_STATES.BUSY && filteredAds.length === 0 && <EmptyList>{t('emptyadlist')}</EmptyList>}
              {filteredAds.map((ad) => (
                <ApartmentChangeAdView key={ad.id} ad={ad} />
              ))}
            </ListContainer>
          </ExchangeListContainer>

          { !myAd && <NewSwapContainer>
            <H2 ref={enlistRef} id="new-title">{t('ad.newtitle')}</H2>
            <BasicParagraph>
              {t('swap.newswapinfo1')}
            </BasicParagraph>
            <BasicParagraph>
              {t('swap.newswapinfo2')}
            </BasicParagraph>
            <BasicParagraph>
              {t('swap.newswapinfo3')}
            </BasicParagraph>
            <FormRow style={{display: 'none'}}>
              <ReadonlyTextField value={email} placeholder={t('ad.email')} />
              <ReadonlyTextField value={phone} placeholder={t('ad.phone')} />
              <ReadonlyTextField value={address} placeholder={t('ad.address')} />
            </FormRow>
            <FormRow style={{ 'flexDirection': 'column' }}>
              <Label htmlFor="currentAptMessage">{t('swap.currentapttitle')}</Label>
              <Textarea
                name="currentAptMessage"
                value={currentAptMessage}
                onChange={(e: any) => setCurrentAptMessage(e.target.value)}
                placeholder={t('swap.currentaptplaceholder')}
                disabled={saveState === LOAD_STATES.BUSY}
                noLabel
              />
            </FormRow>
            <FormRow style={{ 'flexDirection': 'column' }}>
              <Label htmlFor="desiredAptMessage">{t('swap.desiredapttitle')}</Label>
              <Textarea
                name="desiredAptMessage"
                value={desiredAptMessage}
                onChange={(e: any) => setDesiredAptMessage(e.target.value)}
                placeholder={t('swap.desiredaptplaceholder')}
                disabled={saveState === LOAD_STATES.BUSY}
                noLabel
              />
            </FormRow>
            <FormRow style={{ 'flexDirection': 'row' }}>
              <CheckBox
                type="image"
                src={termsAccepted ? filledBox : notFilledBox}
                alt={termsAccepted ? 'Empty box for accepting terms' : 'Filled box for not having terms accepted'}
                onClick={() => setTermsAccepted(!termsAccepted)}
              />
              <BasicParagraph>{t('swap.termscheckbox')}</BasicParagraph>
            </FormRow>
            <FormRow>
              {saveState === LOAD_STATES.BUSY ? (
                <div style={{ display: 'flex', margin: 'auto' }}>
                  <Loader />
                </div>
              ) : (
                <Button
                  disabled={!currentAptMessage || !desiredAptMessage || !termsAccepted}
                  onClick={sendApartmentChangeAd}
                >
                  {t('swap.send')}
                </Button>
              )}
            </FormRow>
            <FormRow>
              <ActionButton onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                <ButtonLabel>
                  <span>{t('swap.backtotop')}</span>
                </ButtonLabel>
                <img src={chevronUp} alt="print agreement" />
              </ActionButton>
            </FormRow>
          </NewSwapContainer> }
        </FormContainer>
      </Content>
    </PageContainer>
  );
};

const Content = styled(PaddedContent)`
  min-height: 600px;
`;
const TitleContent = styled.div`
  margin-bottom: 40px;
`;
const NewSwapContainer = styled.div`
  padding-top: 50px;
`;
const ExchangeListContainer = styled.div`
  padding-top: 20px;
`;
const ListContainer = styled(MarginContainer)`
  align-items: center;
`;
const FormContainer = styled.div`
  margin-bottom: 10px;
`;
const FormRow = styled.div`
  padding: 0 50px;
  margin-bottom: 20px;

  display: flex;
  > div {
    margin: 0 10px;
  }

  @media ${DeviceSize.mobileL} {
    flex-direction: column;
    padding: 0;
  }
`;
const Label = styled.label`
  margin-top: 40px;
  margin-bottom: 10px;
`;
const EmptyList = styled(H3)`
  text-align: center;
  padding: 60px 0;
  font-size: 32px;

  @media ${DeviceSize.mobileL} {
    padding: 20px 0;
    font-size: 24px;
  }
`;

const CheckBox = styled.input`
  cursor: pointer;
  margin-right: 20px;
`;

const anchorStyle = css`
  color: ${(props) => props.theme.colors.turquoise};
  text-decoration: underline;

  :visited,
  :hover {
    cursor: pointer;
  }

  > p {
    margin: 0;
    display: inline-block;
  }
`;

const Anchor = styled(BoldAnchor)`
  ${anchorStyle}
`;

const ActionButton = styled(Button)`
  background-color: ${props => props.theme.colors.m2Black};
  border: 2px solid black;
  margin-top: 50px;
  
  &:hover {
    background-color: ${props => props.theme.colors.coal};
    color: white;
  }

  @media ${DeviceSize.desktopS} {
    width: 100%;
  }
`;

const ButtonLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: ${props => props.theme.text.secondaryFont};
  font-size: 18px;
  
  + img {
    padding-left: 10px;
  }
`;

export default ApartmentSwapPage;
