import React, { FunctionComponent, ReactNodeArray } from 'react';
import styled from 'styled-components';
import { DeviceSize } from '../basic/DeviceSize';
import { MessageGetter } from 'react-message-context';
import { Bill, Payment } from 'isomorphic';

export type BillingPeriodProps = {
  t: MessageGetter;
  t2: MessageGetter;
  startDate: string;
  endDate: string;
  sum: number;
  filter: string;
  isCurrent?: boolean;
  children: ReactNodeArray;
};

function formatDate(date: string) {
  const parts = date.split('-');
  return [parts[2], parts[1], parts[0]].join('.');
}

export function formatEuros(amount: number) {
  let sign = '';

  if (amount < 0) {
    sign = '-';
    amount = -amount;
  }

  amount = Math.round(amount * 100);
  const cents = amount % 100;
  const euros = (amount - cents) / 100;

  return sign + euros + ',' + (cents + 100 + '').substr(1) + ' €';
}

export const BillingPeriod: FunctionComponent<BillingPeriodProps> = ({
  t,
  t2,
  startDate,
  endDate,
  sum,
  filter,
  isCurrent,
  children,
}) => {
  const sums = children.map(
    (rows) =>
      Array.isArray(rows) &&
      (rows.reduce(
        (sum: number, val) => sum + Math.round(((val as React.ReactElement).props as Bill | Payment).amountEuros * 100),
        0
      ) as number) / 100
  );

  let summary: string;

  if (sum < 0) summary = 'remaining';
  else if (sum > 0) summary = 'advance';
  else summary = 'uptodate';

  return (
    <Wrapper isCurrent={isCurrent}>
      <BillingTable isCurrent={isCurrent}>
        <colgroup>
          <col />
          <col width="80" />
          <col width="190" />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={3}>{t2('period') + ' ' + formatDate(startDate) + ' – ' + formatDate(endDate)}</th>
          </tr>
        </thead>
        <tbody>
          {filter === 'paid' ? null : (
            <tr>
              <td />
              <NumberCell><b>{t('billed')}</b></NumberCell>
              <td><b>{t('duedate')}</b></td>
            </tr>
          )}
          {filter === 'paid' ? null : children[0]}
          {filter === 'paid' ? null : (
            <tr>
              <SumHeader>{t('sum')}</SumHeader>
              <NumberHeader>{formatEuros(sums[0] || 0)}</NumberHeader>
              <td />
            </tr>
          )}
          {filter !== 'all' ? null : (
            <tr>
              <td colSpan={3}>&nbsp;</td>
            </tr>
          )}
          {filter === 'billed' ? null : (
            <tr>
              <td />
              <NumberCell><b>{t('paid')}</b></NumberCell>
              <td><b>{t('paiddate')}</b></td>
            </tr>
          )}
          {filter === 'billed' ? null : children[1]}
          {filter === 'billed' ? null : (
            <tr>
              <SumHeader>{t('sum')}</SumHeader>
              <NumberHeader>{formatEuros(sums[1] || 0)}</NumberHeader>
              <td />
            </tr>
          )}
          {filter !== 'all' ? null : (
            <tr>
              <td colSpan={3}>&nbsp;</td>
            </tr>
          )}
          {filter !== 'all' ? null : (
            <SummaryRow>
              <StatusHeader>
                {isCurrent ? null : formatDate(endDate).replace(/(^|\.)0/g, '$1')} {t2(summary)}
              </StatusHeader>
              <NumberHeader>{formatEuros(Math.abs(sum))}</NumberHeader>
              <td />
            </SummaryRow>
          )}
        </tbody>
      </BillingTable>
    </Wrapper>
  );
};

export const BillRow = ({ reasonName, amountEuros, dueDate }: Bill) => (
  <tr>
    <td>{reasonName}</td>
    <NumberCell>{formatEuros(amountEuros)}</NumberCell>
    <td>{dueDate && formatDate(dueDate)}</td>
  </tr>
);

export const PaymentRow = ({ payerName, amountEuros, paidDate }: Payment) => (
  <tr>
    <td>{payerName}</td>
    <NumberCell>{formatEuros(amountEuros)}</NumberCell>
    <td>{paidDate && formatDate(paidDate)}</td>
  </tr>
);

const Wrapper = styled.div<{ isCurrent?: boolean }>`
  padding: 30px;
  margin-bottom: 30px;
  background: ${(props) => props.theme.colors.white};

  @media ${DeviceSize.mobileL} {
    padding: 0px 0px 30px 0px;
  }

  @media print {
    padding: 15px;
    border-color: ${(props) => props.theme.colors.peppermint};
    border-width: 1px;
    background: none;
  }
`;

const BillingTable = styled.table<{ isCurrent?: boolean }>`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  color: ${(props) => props.theme.colors.coal};

  thead th {
    // color: ${(props) => (props.isCurrent ? props.theme.colors.black : 'initial')};
    font-family: ${(props) => props.theme.text.thirdFont};
    font-size: 32px;
    letter-spacing: 1.05px;

    @media print {
      color: ${(props) => props.theme.colors.coal};
      font-size: 12px;
      letter-spacing: 0.6px;
    }

    @media ${DeviceSize.desktopS} {
      font-size: 16px;
    }
  }

  tr:nth-child(even) {
    background: ${(props) => props.theme.colors.peppermintVeryLight};
    -webkit-print-color-adjust: exact !important;
  }

  tr:nth-child(odd) {
    background: ${(props) => props.theme.colors.white};
    
    @media print {
      background: none;
    }
  }

  td {
    padding: 10px 0px 10px 30px;
    font-family: ${(props) => props.theme.text.thirdFont};
    font-size: 16px;
    letter-spacing: 0.8px;

    @media ${DeviceSize.mobileL} {
      padding: 10px 5px;
      font-size: 12px;
    }

    @media print {
      padding: 5px 0px 5px 15px;
      font-size: 10px;
      letter-spacing: 0.5px;
    }
  }
`;

const SumHeader = styled.td`
  text-align: right;
  margin-right: 20px;
  font-weight: 700;
  // color: ${(props) => props.theme.colors.mint};
  font-family: ${(props) => props.theme.text.primaryFont} !important;
  
  @media print {
    font-family: ${(props) => props.theme.text.primaryFont} !important;
    font-weight: normal;
    font-size: 12px !important;
    letter-spacing: 0.6px !important;
    color: ${(props) => props.theme.colors.coal} !important;
  }
  
  @media ${DeviceSize.mobileL} {
    padding-right: 24px !important;
  }
`;

const StatusHeader = styled.td`
  text-align: left;
`;

const NumberHeader = styled.td`
  text-align: right;
  font-weight: 700;
  font-family: ${(props) => props.theme.text.secondaryFont};
  padding-right: 40px !important;
  white-space: nowrap;
  // color: ${(props) => props.theme.colors.mint};

  @media ${DeviceSize.mobileL} {
    padding-right: 12px !important;
  }

  @media print {
    font-family: ${(props) => props.theme.text.primaryFont} !important;
    font-weight: normal;
    font-size: 12px !important;
    letter-spacing: 0.6px !important;
    color: ${(props) => props.theme.colors.coal} !important;
  }
`;

const NumberCell = styled.td`
  text-align: right;
  padding-right: 40px !important;
  white-space: nowrap;

  @media ${DeviceSize.mobileL} {
    padding-right: 12px !important;
  }
`;

const SummaryRow = styled.tr`
  background: ${(props) => props.theme.colors.white} !important;
  
  @media print {
    background: none !important;
  }
`;
