import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useApp } from '../context/appContext';
import { loadHousing } from '../context/actions';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import HousingContact from '../components/housing/HousingContact';
import { H1 } from '../components/basic/Text';
import { LinkButton } from '../components/button/Button';
import { DeviceSize } from '../components/basic/DeviceSize';

const pdfs = [
  {
    name: 'pdf.powerofattorney',
    href: 'https://m2kodit.fi/wp-content/uploads/2021/05/Valtakirja-vuokralaisen-edustamiseen.pdf',
  },
  {
    name: 'pdf.keytransferagreement',
    href: 'https://m2kodit.fi/wp-content/uploads/2021/05/Avainten-luovutussopimus-M2.pdf',
  },
  {
    name: 'pdf.personaldataconsent',
    href: 'https://m2kodit.fi/wp-content/uploads/2021/05/Vuokralaisen-suostumus-henkilotietojen-luovuttamiseen-1.pdf',
  },
  {
    name: 'pdf.estateinstructions',
    href: 'https://m2kodit.fi/wp-content/uploads/2022/10/Ohjeita-kuolinpesalle.pdf',
  },
];

const ApartmentFormsPage = ({ history }: RouteComponentProps) => {
  const h = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const t = useMessageGetter('forms');
  const [{ housing }, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
  }, [dispatch]);

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <H1>{t('title')}</H1>
        <PdfWrapper>
          {
            pdfs.map((pdf) => (
              <PdfLinkButton href={pdf.href} target='_blank'>
                {t(pdf.name)}
              </PdfLinkButton>
            ))
          }
        </PdfWrapper>
      </PaddedContent>
      { housing && <HousingContact t={h} housingContacts={housing} c={c} /> }
    </PageContainer>
  );
};

const PdfWrapper = styled.div`
  display: flex; 
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0 0 0;
`;

const PdfLinkButton = styled(LinkButton)`
  background-color: ${(props) => props.theme.colors.strawberry};
  color: ${(props) => props.theme.colors.m2Black};
  white-space: break-spaces;
  align-items: center;
  height: unset;
  line-height: unset;
  text-align: center;
  flex-wrap: wrap;
  font-size: 16px;
  margin: 5px 0;
  width: 49%;
  padding: 16px 24px;
  border-radius: 36px;
  
  > a {
    margin: 0;
  }

  @media ${DeviceSize.desktopM} {
    margin: 10px 0;
    width: 100%;
  }
`;

export default ApartmentFormsPage;
