import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import { useApp } from '../../context/appContext';
import { loadHousing } from '../../context/actions';
import PageContainer from '../PageContainer';
import PaddedContent from '../../components/housing/PaddedContent';
import HousingContact from '../../components/housing/HousingContact';
import WordPressPage from '../../components/wordpress/wordpress';
// import Cards from '../../components/card/Cards';
// import { eventImage } from '../../assets';

// TODO: Fetch these from somewhere in the future?
// TODO: Use Card component once Lyyti integration is finished
/* const cards = [
  {
    title: 'La 25.2. Dinosauria',
    image: eventImage,
    body: `
      <p>Paikka: SuperPark Oulu</p>
      <p>Avoin Oulun alueen asukkaile</p>
      <p>
        <a href='https://m2kodit.fi/asukkaalle/asukastoiminta/asukastapahtumat/' target='_blank'>
            Avaa kutsu ja lue lisää
        </a>
      </p>
      <p>
        <a href='https://m2kodit.fi/asukkaalle/asukastoiminta/asukastapahtumat/' target='_blank'>
            Ilmotttautiminen käynnistyy 1.2.
        </a>
      </p>`
  },
  {
    title: 'Su 26.3 Hevisaurus - vuoden räyhäisin keikka!',
    image: eventImage,
    body: `
      <p>Paikka: Järvenpää-talo, Sibelius-sali</p>
      <p>Avoin Järvenpään, Keravan, Porvoon, Tuusulan sekä Hyvinkään alueen asukkaille.</p>
      <p>
        <a href='https://m2kodit.fi/asukkaalle/asukastoiminta/asukastapahtumat/' target='_blank'>
            Avaa kutsu ja lue lisää
        </a>
      </p>
      <p>
        <a href='https://m2kodit.fi/asukkaalle/asukastoiminta/asukastapahtumat/' target='_blank'>
            Ilmotttautiminen käynnistyy 13.2.
        </a>
      </p>`
  },
]; */

const LivingEvents = ({ history }: RouteComponentProps) => {
  const h = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  // const t = useMessageGetter('living.events');
  const [{ housing }, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
  }, [dispatch]);

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <WordPressPage slug='living-events' />
      </PaddedContent>
      { /* <PaddedContent>
        { cards && <Cards cards={cards} title={t('upcoming')} noExist={t('noupcoming')}/> }
      </PaddedContent> */}
      { housing && <HousingContact t={h} housingContacts={housing} c={c} /> }
    </PageContainer>
  );
};

export default LivingEvents;
