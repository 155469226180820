import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import { useMessageGetter } from 'react-message-context';
import { SubNavItem } from '../../context/appContext';
import styled from 'styled-components';
import {SubNavi} from '../basic/Text';
import { DeviceSize } from '../basic/DeviceSize';

const SubMenuItem = ({name, to, clickSubMenuItem, parentname}: SubNavItem) => {
  const t = useMessageGetter('navigation');

  return (
    <Fragment>
      <SubItem activeClassName={'active'}
        exact to={to}
        className="subitem" role='menuitem' aria-labelledby='sideMenu'>
        <SubNaviText onClick={() => clickSubMenuItem}>{t(`${parentname}.${name}`)}</SubNaviText>
      </SubItem>
    </Fragment>
  )
};

const SubItem = styled(NavLink)`
  width: 70%;
  
  :hover {
    background: ${props => props.theme.colors.black};

    @media ${DeviceSize.desktopM} {
      background: initial;
    }
  }

`;

const SubNaviText = styled(SubNavi)`
  font-family: ${props => props.theme.text.secondaryFont};
  color: white;

  @media ${DeviceSize.desktopM} {
    font-size: 15px;
    margin-bottom: 15px;
  }
`;

export default SubMenuItem;
