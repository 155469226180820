import React, { ReactNode } from 'react';
import { useApp } from '../../context/appContext';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useMessageGetter } from 'react-message-context';
import { ColumnContainer } from '../basic/Flex';
import Auth from '@aws-amplify/auth';
import { EVENTS, updateVisitedStatus } from '../../context/actions';
import { signout } from '../../assets';
import { DeviceSize } from '../basic/DeviceSize';

export interface LogoutProps {
  width?: number;
  height?: number;
  padding?: string;
  children?: ReactNode;
}

const Logout = ({ width, height, padding, children }: LogoutProps) => {
  const t = useMessageGetter('navigation');
  let history = useHistory();
  const [state, dispatch] = useApp();
  const agreementId = state.agreement.id;
  const apartmentId = state.apartment.info.apartmentId;
  const housingId = state.housingInfo.housingId;
  const userId = state.user.attributes.preferred_username;

  const userData = {
    agreementId,
    apartmentId,
    housingId,
    userId,
  }

  const signOut = async () => {
    try {
      updateVisitedStatus(dispatch, 'yes');
      await Auth.signOut();
      dispatch([EVENTS.LOGGEDOUT]);
      history.push('/feedback', userData);
    } catch (error) {
      console.log('Error signing out', error);
    }
  };

  return (
    <ItemContainer role="button" aria-label="Logout">
      { children ? ( 
        <ChildContainerButton
          type="button"
          aria-labelledby="logoutButton"
          onClick={signOut}
          className='logoutButton'
        >
          <Nav>{ children }</Nav>
        </ChildContainerButton>
      ) : (
        // Default layout if no children
        <DefaultButton
          type="button"
          aria-labelledby="logoutButton"
          onClick={signOut}
          width={width}
          height={height}
          padding={padding}
          className='logoutButton'
        >
          <TitleContainer aria-label="An icon of signing out">
            <Nav>{t('signout.title')}</Nav>
            <img src={signout} alt="Signout" />
          </TitleContainer>
        </DefaultButton>
      )}
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  cursor: pointer;
`;

const DefaultButton = styled.button<{ width?: number; height?: number; padding?: string }>`
  display: flex;
  background: ${props => props.theme.colors.m2Black};
  padding: ${props => (props.padding ? props.padding : '15px 30px')};
  margin: 3px;
  color: white;
  border-radius: 35px;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  font-family: ${props => props.theme.text.secondaryFont};
  font-size: 20px;
  letter-spacing: 1px;
  border: 0;
  text-align: center;

  :hover {
    cursor: pointer;
    background: ${props => props.theme.colors.coal};
    box-shadow: 4px 4px 4px ${props => props.theme.colors.shadow};
  }
  
  @media ${DeviceSize.mobileM} {
    padding: 10px 25px;
    font-size: 16px;
  }
`;

const ChildContainerButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
`

const TitleContainer = styled(ColumnContainer)`
  width: 100%;
`;

const Nav = styled.div`
  flex: 1 90%;
`;

export default Logout;
