import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import AppRouter from './AppRouter';
import { AppStateProvider } from './context/appContext';

const App: FunctionComponent = () => {

  return (
    <ThemeProvider theme={theme()}>
      <AppStateProvider>
        <AppRouter />
      </AppStateProvider>
    </ThemeProvider>

  );
};

export default App;
