import React, { FunctionComponent, useState, Fragment } from 'react';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { form2Style } from '../basic/Text';
import { searchIconBlack } from '../../assets';
import { DeviceSize } from '../basic/DeviceSize';

const Search: FunctionComponent<{ onSearch: (query: string) => void; initialValue?: string; isSearchPage?: boolean }> =
  ({ onSearch, initialValue, isSearchPage }) => {
    const [query, setQuery] = useState(initialValue || '');
    const t = useMessageGetter('search');

    const onKeyDown = (e: any) => {
      if (e.key === 'Enter' && query && query.length > 0) {
        onSearch(query);
      }
    };

    const changeText = (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
    };

    return (
      <Fragment>
        <SearchField
          name="query"
          id="queryfield"
          type="text"
          placeholder={t('queryplaceholder')}
          value={query}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeText(e)}
          onKeyDown={onKeyDown}
          aria-label={`${t('queryplaceholder')}`}
        />
        {query && query.length > 0 ? (
          <Image
            src={searchIconBlack}
            alt="Hae"
            type="image"
            onClick={() => onSearch(query)}
            isSearchPage={isSearchPage}
            aria-label="Hae"
            aria-disabled={false}
          />
        ) : (
          <Image
            src={searchIconBlack}
            alt="Disabled search icon"
            type="image"
            aria-label="Disabled search icon"
            aria-disabled={true}
            disabled
          />
        )}
      </Fragment>
    );
  };

const SearchField = styled.input`
  ${form2Style}
  border: 3px solid ${(props) => props.theme.colors.m2Black};
  background-color: ${(props) => props.theme.colors.m2White};
  border-radius: 35px;
  padding: 0 75px 0 25px;
  height: 75px;
  width: 70%;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;

  :-webkit-autofill,
  :-webkit-autofill:focus {
    outline: none;
    border: 3px solid ${(props) => props.theme.colors.black};
    -webkit-box-shadow: 0 0 0 100px ${(props) => props.theme.colors.m2White} inset;
  }

  @media ${DeviceSize.desktopS} {
    width: 100%;
    margin: 50px auto 25px auto;
  }
`;

const Image = styled.input<{ isSearchPage?: boolean }>`
  position: absolute;
  top: 20px;
  right: ${(props) => (props.isSearchPage ? '18%' : '20%')};

  :hover {
    box-shadow: 4px 4px 4px ${(props) => props.theme.colors.shadow};
  }

  @media ${DeviceSize.desktopS} {
    top: auto;
    right: 15%;
    bottom: 25%;
  }

  @media ${DeviceSize.tabletM} {
    right: 15%;
  }

  @media ${DeviceSize.mobileL} {
    right: 5%;
  }
`;
export default Search;
