// React
import React, {useEffect, useState} from 'react';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components';
import dayjs from 'dayjs';

// Constants
import { MoveInAgreements } from '../../constants/moveInAgreements';

// Components
import { H2 } from '../basic/Text';
import PaddedContent from '../housing/PaddedContent';
import { DeviceSize } from '../basic/DeviceSize';

import { blackWarningIcon } from './../../assets';

interface NoticeProps {
  housingId?: number,
  startDate?: string,
}

const MoveInNotice: React.FunctionComponent<NoticeProps> = ({housingId, startDate}) => {
  const t = useMessageGetter('moveinnotice');
  const [showMoveInNotice, setShowMoveInNotice] = useState(false);
  
  useEffect(() => {
    const checkMoveInNoticeVisibility = () => {
      const today = dayjs();
      const uudisAgreement = MoveInAgreements.find(agreement =>
        agreement.housingId === housingId?.toString());
      // Override test building for now
      const isTestBuilding = housingId?.toString() === '120881';

      if (uudisAgreement) {
        const beforeContractStart = today.isBefore(dayjs(startDate));
        setShowMoveInNotice((beforeContractStart && uudisAgreement.showMoveInNotice) || isTestBuilding);
      }
    };
    
    checkMoveInNoticeVisibility();
  }, [startDate, housingId]);


  return (
    <NoticeContainer hidden={!showMoveInNotice}>
      <NoticeIcon src={ blackWarningIcon }/>
      <NoticeText>
        {
          t('description', {startDate: dayjs(startDate).format('DD.MM.YYYY')}) 
        }
      </NoticeText>
    </NoticeContainer>
  )
};

const NoticeContainer = styled(PaddedContent)<{ hidden: boolean }>`
  ${props => props.hidden && `
    display: none;
  `}
  ${props => !props.hidden && `
    display: flex;
  `}
  align-items: center;
  margin-bottom: 40px;
  border-radius: 20px;
  padding: 20px;
  background-color: ${props => props.theme.colors.lemon};
  color: ${props => props.theme.colors.black};
`;

const NoticeIcon = styled.img`
  margin-right: 20px;

  @media ${DeviceSize.mobileL} {
    margin-right: 10px;
  }
`;

const NoticeText = styled(H2)`
  font-size: 24px;
  margin-left: 20px;

  @media ${DeviceSize.mobileL} {
    font-size: 16px;
  }
`;

export default MoveInNotice;
