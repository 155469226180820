import { EVENTS, ACTIONS, LOAD_STATES } from './actions';
import { AppState, initialState } from './appContext';
import { BillingMonth } from 'isomorphic';

export function appReducer(state: AppState, [type, payload]: [EVENTS | ACTIONS, any?]): AppState {
  switch (type) {
    case ACTIONS.LOGIN:
      return initialState;
    case EVENTS.LOGGEDIN:
      const otherValidAgreements = JSON.parse(payload.attributes['custom:agreements'] || '[]');

      return {
        ...state,
        user: {
          isLoggedIn: true,
          isLoaded: true,
          attributes: {
            preferred_username: payload.attributes.preferred_username,
            phone_number: payload.attributes.phone_number,
            email: payload.attributes.email,
            given_name: payload.attributes.given_name,
            family_name: payload.attributes.family_name,
            created_at: payload.attributes['custom:created_at'],
            housing_id: payload.attributes['custom:housing_id'],
            apartment_id: payload.attributes['custom:apartment_id'],
            agreement_id: payload.attributes['custom:agreement_id'],
            visited: payload.attributes['custom:visited'],
            expiring_agreement: payload.attributes['custom:expiring_agreement'],
            agreements: otherValidAgreements.length > 0 ? otherValidAgreements.map((item: any) => ({
              id: item.SopimusId,
              targetType: item.KohdeTyyppiMetanimi,
              targetName: item.KohdeNimi
            })) : []
          }
        }
      };
    case ACTIONS.LOGOUT:
      return {
        ...state,
        user: { ...state.user, isLoaded: false }
      };
    case EVENTS.LOGGEDOUT:
      return {
        ...state,
        user: { attributes: initialState.user.attributes, isLoggedIn: false, isLoaded: true },
        housing: undefined,
        debts: { loadState: false },
        notifications: initialState.notifications,
        apartment: initialState.apartment,
        debtInfo: initialState.debtInfo,
        agreement: initialState.agreement,
        messages: {
          apartmentMessages: initialState.messages.apartmentMessages,
          housingMessages: initialState.messages.housingMessages
        }
      };
    case ACTIONS.LOAD_HOUSING:
      return {
        ...state,
        loadingHousing: true
      };
    case EVENTS.LOADED_HOUSING:
      return {
        ...state,
        housing: payload,
        loadingHousing: false
      };

    case ACTIONS.LOAD_APARTMENT:
      return {
        ...state,
        apartment: { ...state.apartment, loadState: LOAD_STATES.BUSY }
      };
    case EVENTS.LOAD_APARTMENT_SUCCESS:
      return {
        ...state,
        apartment: { ...state.apartment, ...payload, loadState: LOAD_STATES.READY }
      };
    case EVENTS.LOAD_APARTMENT_FAILURE:
      return {
        ...state,
        apartment: { ...state.apartment, loadState: LOAD_STATES.ERROR }
      };

    case ACTIONS.LOAD_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          loadState: LOAD_STATES.BUSY
        }
      };
    case EVENTS.LOAD_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload,
          loadState: LOAD_STATES.READY
        },
        user: {
          ...state.user,
          attributes: {
            ...state.user.attributes,
            email: payload.email,
            phone_number: payload.phone,
          }
        }
      };

    case ACTIONS.UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          updateState: LOAD_STATES.BUSY
        }
      };
    case EVENTS.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          updateState: LOAD_STATES.READY
        }
      };

    case ACTIONS.LOAD_STRONG_AUTH_SETTINGS:
      return {
        ...state,
        strongAuthUrl: void 0
      };
    case EVENTS.LOAD_STRONG_AUTH_SETTINGS_SUCCESS:
      return {
        ...state,
        strongAuthUrl: payload.url
      };

    case ACTIONS.LOAD_MESSAGES:
      return {
        ...state,
        messages: { ...state.messages, loadState: LOAD_STATES.BUSY }
      };
    case EVENTS.LOAD_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          apartmentMessages: payload.apartmentMessages,
          housingMessages: payload.housingMessages,
          loadState: LOAD_STATES.READY
        }
      };

    case ACTIONS.LOAD_BILLING_HISTORY:
      return {
        ...state,
        billing: { ...state.billing, loadState: LOAD_STATES.BUSY }
      };
    case EVENTS.LOAD_BILLING_HISTORY_SUCCESS:
      payload.sort(
        (a: BillingMonth, b: BillingMonth) => +(a.startDate < b.startDate) - +(a.startDate > b.startDate)
      );

      const num = payload.length && payload[0].year * 12 + payload[0].month;

      return {
        ...state,
        billing: {
          ...state.billing,
          history: payload,
          rangeEnd: num,
          rangeStart: num - 2,
          loadState: LOAD_STATES.READY
        }
      };

    case ACTIONS.LOAD_DEBTS:
      return {
        ...state,
        debts: { ...state.debts, loadState: LOAD_STATES.BUSY }
      };
    case EVENTS.LOAD_DEBTS_SUCCESS:
      return {
        ...state,
        debts: {
          ...state.debts,
          amountEuros: payload.amountEuros,
          inProceedings: payload.inProceedings,
          inDistraint: payload.inDistraint,
          billed: payload.billed,
          itemized: payload.itemized,
          loadState: LOAD_STATES.READY
        }
      };

    case ACTIONS.HIDE_DEBT_WARNING:
      return {
        ...state,
        debts: {
          ...state.debts,
          hideWarning: true
        }
      };

    case ACTIONS.SET_BILLING_FILTER:
      return {
        ...state,
        billing: { ...state.billing, filter: payload }
      };

    case ACTIONS.SET_BILLING_RANGE_START:
      return {
        ...state,
        billing: { ...state.billing, rangeStart: payload }
      };

    case ACTIONS.SET_BILLING_RANGE_END:
      return {
        ...state,
        billing: { ...state.billing, rangeEnd: payload }
      };

    case ACTIONS.SHOW_NOTIFICATION:
      return {
        ...state,
        notifications: [payload, ...state.notifications]
      };
    case ACTIONS.HIDE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(msg => msg !== payload)
      };
    case ACTIONS.LOAD_HOUSING_COOP:
      return {
        ...state,
        housingInfo: {
          ...state.housingInfo,
          loadState: LOAD_STATES.BUSY
        }
      };
    case EVENTS.LOADED_HOUSING_COOP:
      return {
        ...state,
        housingInfo: {
          ...state.housingInfo,
          ...payload,
          laundry: payload.features.some((f: any) => f.name === 'laundryroom' && f.isExist),
          loadState: LOAD_STATES.READY
        }
      };
    case ACTIONS.LOAD_DOCUMENTS:
      return {
        ...state,
        documents: {
          loadState: LOAD_STATES.BUSY
        }
      };
    case EVENTS.LOADED_DOCUMENTS:
      return {
        ...state,
        housingInfo: {
          ...state.housingInfo,
          folders: payload.manuals.length > 0
        },
        documents: {
          ...state.documents,
          ...payload,
          loadState: LOAD_STATES.READY
        }
      };
    case ACTIONS.LOAD_AGREEMENT_INFO:
      return {
        ...state,
        agreement: {
          loadState: LOAD_STATES.BUSY
        }
      };
    case EVENTS.LOAD_AGREEMENT_INFO_SUCCESS:
      return {
        ...state,
        agreement: {
          ...state.agreement,
          ...payload,
          parties: payload.parties,
          deposit: payload.deposit,
          houseowner: payload.houseowner && {
            name: payload.houseowner.name,
            address: payload.houseowner.address,
            postalcode: payload.houseowner.postalcode,
            city: payload.houseowner.city
          },
          attachment: payload.attachment && Object.entries(payload.attachment).length > 0 && {
            id: payload.attachment.id,
            filename: payload.attachment.filename,
            length: payload.attachment.length,
            contenttype: payload.attachment.contenttype,
            createdAt: payload.attachment.createdAt
          },
          loadState: LOAD_STATES.READY
        }
      };
    case ACTIONS.UPDATE_VISITED:
      return {
        ...state,
        user: {
          ...state.user
        }
      };
    case EVENTS.UPDATE_VISITED_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          attributes: {
            ...state.user.attributes,
            visited: payload
          }
        }
      };
    case ACTIONS.LOAD_RECENT_PAYMENTS:
      return {
        ...state,
        recentPayments: {
          loadState: LOAD_STATES.BUSY
        }
      };
    case EVENTS.LOAD_RECENT_PAYMENTS_SUCCESS:
      return {
        ...state,
        recentPayments: {
          ...state.recentPayments,
          ...payload,
          loadState: LOAD_STATES.READY
        }
      };
    case ACTIONS.LOAD_TENANT_MODULES:
      return {
        ...state,
        modules: {
          ...state.modules,
          loadState: LOAD_STATES.BUSY
        }
      };
    case ACTIONS.LOAD_TENANT_BOOKINGS:
      return {
        ...state,
        bookings: {
          loadState: LOAD_STATES.BUSY
        }
      };
    case EVENTS.LOAD_TENANT_MODULES_SUCCESS:
      return {
        ...state,
        modules: {
          ...state.modules,
          ...payload,
          loadState: LOAD_STATES.READY
        }
      };
    case EVENTS.LOAD_TENANT_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: { ...state.bookings, ...payload, loadState: LOAD_STATES.READY }
      };
    case ACTIONS.LOAD_TENANT_LANGUAGE:
      return {...state, tenantLanguage: { loadState: LOAD_STATES.BUSY }};
    case ACTIONS.LOAD_TENANT_PIN:
      return {...state, tenantPin: { loadState: LOAD_STATES.BUSY }};
    case EVENTS.LOAD_TENANT_LANGUAGE_SUCCESS:
      return {
        ...state,
        tenantLanguage: {...payload, loadState: LOAD_STATES.READY }
      };
    case EVENTS.LOAD_TENANT_PIN_SUCCESS:
      return {
        ...state,
        tenantPin: { ...payload, loadState: LOAD_STATES.READY }
      };
    default:
      console.log('Unknown event', type);
      return state;
  }
}
