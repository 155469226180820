import React, { Fragment, useEffect, useState } from 'react';
import { useApp } from '../context/appContext';
import { useMessageGetter } from 'react-message-context';
import Auth from '@aws-amplify/auth';
import { RouteComponentProps } from 'react-router-dom';
import { signup } from '../apis';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import { baseFontStyle, BasicParagraph, H1, H2, H3 } from '../components/basic/Text';
import Loader from '../components/basic/Loader';
import { Input } from '../components/input/Input';
import Button from '../components/button/Button';
import HomeLogo from '../components/header/HomeLogo';
import backgroundLogo from '../assets/mint-logo-large.svg';
import pageElement from '../components/basic/PageElement';
import PaddedContent from '../components/housing/PaddedContent';
import { ColumnContainer } from '../components/basic/Flex';
import { isSignupComplete, validatePhone, checkPhone, validateEmailRegex, changeLanguage } from '../utils';
import { utils } from 'isomorphic';
import { EVENTS, loadStrongAuthSettings, loadProfile, updateProfile, LOAD_STATES } from '../context/actions';
import { DeviceSize } from '../components/basic/DeviceSize';
import HousingFooter from '../components/footer/HousingFooter';
import { notFilledBox, filledBox } from '../assets';
import ReactGA from 'react-ga';

const SignInPage = ({ history, location }: RouteComponentProps) => {
  const INPUT_PROPS = { maxWidth: '400px', margin: '0 auto' };
  const t = useMessageGetter('signin');
  const n = useMessageGetter('navigation');
  const [state, dispatch] = useApp();
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [step, setStep] = useState(0);
  const [failedStep, setFailedStep] = useState(-1);
  const [cognitoUser, setCognitoUser] = useState();
  const [isBusy, setIsBusy] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const user = state.user;
  const profileForm = useForm();
  const profileFormSetValue = profileForm.setValue;
  const [noEmail, setNoEmail] = useState(false);

  useEffect(() => {
    if (step === 2) loadStrongAuthSettings(dispatch);
    if (step === 3) loadProfile(dispatch);
  }, [dispatch, step]);

  useEffect(() => {
    const phoneInput = document.getElementById(t('step1.placeholder'));
    const codeInput = document.getElementById(t('step2.placeholder'));
    if (codeInput) {
      codeInput.focus();
    }
    if (phoneInput) {
      phoneInput.focus();
    }
  });

  useEffect(() => {
    if (
      state.profile.loadState === LOAD_STATES.READY &&
      user.attributes &&
      user.attributes.phone_number &&
      !state.profile.updateState
    ) {
      profileFormSetValue('phone', user.attributes.phone_number);
      profileFormSetValue('email', state.profile.email || '');
    }
  }, [state.profile, user.attributes, profileFormSetValue]);

  useEffect(() => {
    if (noEmail) {
      profileFormSetValue('email', '');
    } else {
      profileFormSetValue('email', state.profile.email);
    }
  }, [state.profile, profileFormSetValue, noEmail]);

  const readTermsOfUse = () => {
    window.open('https://m2kodit.fi/kayttoehdot-ja-henkilotietosuoja/');
  };

  const showStep = (step: number) => {
    setStep(step);
  };

  const navigateToSite = () => {
    const { from }: any = location.state || { from: { pathname: '/' } };
    history.push(from);
  };

  const refreshUser = async () => {
    const loggedInUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    dispatch([EVENTS.LOGGEDIN, loggedInUser]);
    return loggedInUser;
  };

  // user is logged in and strongly authenticated, redirect to where they were going to (or home)
  useEffect(() => {
    if (isSignupComplete(user)) {
      navigateToSite();
    }
  });

  const changeSelectedLanguage = async (languageCode: string) => {
    changeLanguage(languageCode);
  };

  const determineNextStep = (user: any, isLoggedIn: boolean) => {
    //console.log('Determine next step', user);
    // logged in but no strong authentication, just go straight to strong authentication step
    if (isLoggedIn && !user.attributes.preferred_username) {
      showStep(2);
    }
    // first/last names not set in profile step
    else if (isLoggedIn && user.attributes.preferred_username && !user.attributes.given_name) {
      showStep(3);
    }
  };

  if (!step) {
    determineNextStep(user, user.isLoggedIn);
  }

  const renderPhoneStep = () => {
    const onSubmit = async () => {
      try {
        setIsBusy(true);
        
        // Check if number is valid before signup
        if (!checkPhone(utils.sanitizePhone(phone))) {
          setFailedStep(step);
          setIsBusy(false);
          return;
        }
        
        await signup({ phoneNumber: phone });
        const cognitoUser = await Auth.signIn(utils.sanitizePhone(phone));
        setCognitoUser(cognitoUser);
        //console.log('user created and auth challenge sent', cognitoUser);
        showStep(1);

        // updating time of the last activity before signing in, so the user wouldn't be logged out immediately
        const now = Date.now();
        window.localStorage.setItem('lastActivity', now.toString());
        setIsBusy(false);
      } catch (err) {
        setFailedStep(step);
        setIsBusy(false);
      }
    };

    const match = window.location.search.match(/autherr=([0-9])/);

    return (
      <Fragment>
        <InputRow>
          {match && match[1] === '2' ? <ErrorMessage>{t(`step${step + 1}.forbidden`)}</ErrorMessage> : ''}
          <Label>{t('step1.label')}</Label>
          <Input
            name="phone"
            value={phone}
            labelHidden={true}
            errors={failedStep === step ? { message: t(`step${step + 1}.error`) } : null}
            onChange={(e: any) => setPhone(e.target.value)}
            placeholder={t('step1.placeholder')}
            onSubmit={onSubmit}
            {...INPUT_PROPS}
          />
          <SubmitButton id="submit" disabled={phone.length === 0 || isBusy} onClick={onSubmit}>
            {t('step1.continue')}
          </SubmitButton>
        </InputRow>
      </Fragment>
    );
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      dispatch([EVENTS.LOGGEDOUT]);
      history.push('/');
    } catch (error) {
      console.log('Error signing out', error);
    }
  };

  const renderCodeStep = () => {
    const onSubmit = async () => {
      try {
        setIsBusy(true);
        await Auth.sendCustomChallengeAnswer(cognitoUser, code);

        const loggedInUser = await refreshUser();

        if (isSignupComplete(loggedInUser)) {
          navigateToSite();
        } else {
          setIsBusy(false);
          determineNextStep(loggedInUser, true);
        }
      } catch (err) {
        setFailedStep(step);
        setIsBusy(false);
      }
    };

    return (
      <Fragment>
        <InputRowStep2>
          <Input
            name="code"
            value={code}
            labelHidden={true}
            errors={failedStep === step ? { message: t(`step${step + 1}.error`) } : null}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
            placeholder={t('step2.placeholder')}
            onSubmit={onSubmit}
            {...INPUT_PROPS}
          />
          <ButtonContainer>
            <FlexButton id="submit" disabled={code.length === 0 || isBusy} onClick={onSubmit}>
              {t('step2.doSignIn')}
            </FlexButton>
            <FlexButton onClick={signOut}>{t('cancel')}</FlexButton>
          </ButtonContainer>
        </InputRowStep2>
      </Fragment>
    );
  };

  const renderStrongAuthStep = () => {
    const navigateToStrongAuth = () => {
      if (state.strongAuthUrl) {
        window.location.href = state.strongAuthUrl;
      }
    };

    const match = window.location.search.match(/autherr=([0-9])/);
    const msg = match && match[1] === '2' ? 'forbidden' : 'error';

    if (failedStep !== step && match) {
      setFailedStep(step);
    }

    return (
      <Fragment>
        {failedStep === step ? <ErrorMessage>{t(`step${step + 1}.${msg}`)}</ErrorMessage> : ''}
        <TermsInput className="termuse">
          <CheckBox
            type="image"
            src={acceptTerms ? filledBox : notFilledBox}
            alt={noEmail ? 'Empty box for not accepting terms' : 'Filled box for accepting terms'}
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => setAcceptTerms(!acceptTerms)}
          />
          <LabelCheckBox>
            {t('step2.acceptservice')}{' '}
            <UseOfTermsLink onClick={readTermsOfUse}>
              <TermsBoldLink>{t('step2.termsofuse')}</TermsBoldLink>
            </UseOfTermsLink>
          </LabelCheckBox>
        </TermsInput>
        <ButtonContainer>
          <FlexButton disabled={acceptTerms === false} onClick={navigateToStrongAuth}>
            {t('step3.continue')}
          </FlexButton>
          <FlexButton onClick={signOut}>{t('cancel')}</FlexButton>
        </ButtonContainer>
      </Fragment>
    );
  };

  const renderProfileStep = () => {
    const onSubmit = async () => {
      try {
        setIsBusy(true);
        await updateProfile(dispatch, profileForm.getValues());
        await refreshUser();
        setIsBusy(false);

        if (noEmail) {
          ReactGA.event({
            category: 'Sign in page',
            action: 'Update profile without email',
            label: '/signin',
          });
        } else {
          ReactGA.event({
            category: 'Sign in page',
            action: 'Update profile with email',
            label: '/signin',
          });
        }

        navigateToSite();
      } catch (err) {
        console.log('Update profile failed');
        setIsBusy(false);
      }
    };
    return state.profile.loadState !== LOAD_STATES.READY ? (
      <Loader />
    ) : (
      <Fragment>
        <Input
          name="phone"
          placeholder={t('step4.phoneplaceholder')}
          onSubmit={profileForm.handleSubmit(onSubmit)}
          validations={{
            required: t('step4.phonerequired'),
            validate: (x: string) => validatePhone(x, t('step4.phoneinvalid')),
          }}
          form={profileForm}
          {...INPUT_PROPS}
        />
        <Input
          name="email"
          type="email"
          placeholder={t('step4.emailplaceholder')}
          onSubmit={profileForm.handleSubmit(onSubmit)}
          validations={{
            pattern: {
              value: validateEmailRegex,
            },
          }}
          form={profileForm}
          disabled={noEmail}
          {...INPUT_PROPS}
        />
        <TermsInput className="noemail">
          <CheckBox
            type="image"
            src={noEmail ? filledBox : notFilledBox}
            alt={noEmail ? 'Empty box for having email' : 'Filled box for not having email'}
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => setNoEmail(!noEmail)}
          />
          <LabelCheckBox>{t('step4.noemail')}</LabelCheckBox>
        </TermsInput>
        <SubmitButton
          disabled={state.profile.updateState === LOAD_STATES.BUSY}
          onClick={profileForm.handleSubmit(onSubmit)}
        >
          {t('step4.save')}
        </SubmitButton>
      </Fragment>
    );
  };

  const getContentWithLineBreaks = (content: string) => {
    if (content) {
      return (
        <>
          {content.split('\n').map((paragraph, i) => (
            <SignInPageBasicParagraph key={i}>{paragraph}</SignInPageBasicParagraph>
          ))}
        </>
      );
    } else {
      return (<></>);
    }
  };

  const getBulletListContent = (content: string) => {
    if (content) {
      return (
        <SignInPageList>
          {content.split('\n').map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </SignInPageList>
      );
    } else {
      return (<></>);
    }
  };

  const getSignInDescription = () => {
    if (step !== 0) {
      return getContentWithLineBreaks(t(`step${step + 1}.description`) || t('description'));
    }

    return (
      <>
        <SignInPageDescriptionContainer>
          <SignInPageDescriptionItem>
            {getContentWithLineBreaks(t(`step1.description`))}
            {getBulletListContent(t(`step1.servicedescriptions`))}
          </SignInPageDescriptionItem>
          <SignInPageDescriptionItem>
            {getContentWithLineBreaks(t(`step1.authdescription`))}
            {getBulletListContent(t(`step1.authoptions`))}
          </SignInPageDescriptionItem>
        </SignInPageDescriptionContainer>
      </>
    );
  };

  return (
    <Fragment>
      <Header>
        <IconLinks>
          <HomeLogo margin={'0px'} isHomePage={false}/>
        </IconLinks>
        <Localizations id="mainlocalizations">
          {sessionStorage.getItem('currentLanguage') === 'en' ?
            <Language onClick={() => changeSelectedLanguage('fi')}>
              <H3 className={sessionStorage.getItem('currentLanguage') === 'fi' ? 'activeLang' : ''}>{n('finnish')}</H3>
            </Language>
            :
            <Language onClick={() => changeSelectedLanguage('en')}>
              <H3 className={sessionStorage.getItem('currentLanguage') === 'en' ? 'activeLang' : ''}>{n('english')}</H3>
            </Language>
          }
        </Localizations>
        <Image src={backgroundLogo} />
      </Header>
      <Container>
        <SignupContainer>
          <H1>{t(`step${step + 1}.title`) || t('title')}</H1>
          {isBusy ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <SignupBox>
              {getSignInDescription()}
              <SignupForm>
                {step === 0 && renderPhoneStep()}
                {step === 1 && renderCodeStep()}
                {step === 2 && renderStrongAuthStep()}
                {step === 3 && renderProfileStep()}
              </SignupForm>
            </SignupBox>
          )}
        </SignupContainer>
        <HousingFooter history={history} />
      </Container>
    </Fragment>
  );
};

const Header = styled.div`
  ${pageElement};
  display: flex;
  align-contents: space-between;
  
  @media ${DeviceSize.desktopM} {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lightYellow};
`;

const SignupContainer = styled(PaddedContent)`
  padding-bottom: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;

  > h1 {
    margin-bottom: 30px;
    z-index: 1;
  }
   > h3 {
    margin-bottom: 40px;
    z-index: 1;
  }

  @media ${DeviceSize.desktopM} {
    padding-bottom: 60px;
  }
`;

const SignupBox = styled.div`
  background-color: ${(props) => props.theme.colors.apila};
  z-index: 2;
  border-radius: 24px;
  padding: 40px 20px 0;
  margin-top: 20px;
`

const SignupForm = styled.div`
  z-index: 1;
  margin-bottom: 30px;
  padding: 5px 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border-radius: 24px;

  @media ${DeviceSize.desktopM} {
    padding: 20px 0;
    flex-direction: column;
  }
`;

const InputRow = styled.div`
  z-index: 1;
  display: flex;
  width: 100%;
  padding: 0 3em;
  flex-direction: row;
  align-items: center;
  align-content: space-evenly;
  
  > * {
    padding: 1em;
  }
   
  @media ${DeviceSize.desktopM} {
    padding: 0.5em;
  }
  
  @media ${DeviceSize.tabletL} {
    flex-direction: column;
    > * {
      width: 100%;
    }
  }
  
  @media ${DeviceSize.mobileL} {
    > * {
      padding: 0;
    }
  }

  div {
    margin: 0;
    min-width: 200px;
  }
`;

const InputRowStep2 = styled.div`
  z-index: 1;
  display: flex;
  width: 100%;
  padding: 0 3em;
  flex-direction: column;
  align-items: center;
  align-content: space-evenly;
  
  > * {
    padding: 1em;
  }
  
  @media ${DeviceSize.desktopM} {
    flex-direction: column;
    > * {
      width: 100%;
    }
  }

  @media ${DeviceSize.mobileL} {
    padding: 0;
  }

  div {
    margin: 0;
    min-width: 200px;
  }
`;

const Label = styled(H2)`
  font-size: 32px;
  font-weight: bold;
  z-index: 1;
  
    @media ${DeviceSize.desktopM} {
      font-size: 16px;
      padding: 0 0.5em;
   }
`;

const ButtonContainer = styled(ColumnContainer)`
  align-items: space-evenly;
  justify-content: center;

  @media ${DeviceSize.mobileL} {
    flex-direction: column;

    > div {
      margin-top: 10px
    }
  }
`;

const SignInPageDescriptionContainer = styled.div`
  display: flex;
  flex-basis: auto;
  z-index: 1;
  padding: 0 2em;
  
  @media ${DeviceSize.desktopS} {
    display: block;
  }

  @media ${DeviceSize.mobileL} {
    padding: 0 1em;
  }
`;

const SignInPageDescriptionItem = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
    
  @media ${DeviceSize.desktopS} {
    width: 100%;
    padding-bottom: 20px;
  }
`;

const SignInPageBasicParagraph = styled.p`
  z-index: 1;
  ${baseFontStyle};
  display: block;
  margin: 0 1em;

  @media print {
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 1;
  }
`;

const SignInPageList = styled.ul`
  ${baseFontStyle};
  display: block;
  margin: 10px;
`;

const FlexButton = styled(Button)`
  margin: 0px 5px;

  @media ${DeviceSize.mobileL} {
    margin: 10px 5px;
  }
`;

const SubmitButton = styled(Button)`
  width: 30%;
  min-width: 150px;
  
  @media ${DeviceSize.desktopM} {
      width: 20%;
  }
`;

const LoaderContainer = styled.div`
  padding: 50px;
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.error};
`;

const TermsInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: auto;
`;

const TermsBoldLink = styled.span`
  font-family: ${(props) => props.theme.text.fourthFont};
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: ${(props) => (props.color ? props.theme.colors.coal : props.theme.colors.turquoise)};
`;

const CheckBox = styled.input`
  cursor: pointer;
  margin-right: 20px;
`;

const LabelCheckBox = styled(BasicParagraph)``;

const UseOfTermsLink = styled.a`
  color: ${(props) => props.theme.colors.turquoise};
  text-decoration: underline;

  :hover {
    cursor: pointer;
  }
`;

const IconLinks = styled.div`
  display: flex;
  flex: 1;
`;

const Image = styled.img`
  max-width: 50vw;
  width: 100%;
  width: 712px;
  top: -100px;
  left: -30px;
  z-index: 0;
  position: absolute;
  display: block;

  @media ${DeviceSize.desktopM} {
    display: none;
  }
`;

const Localizations = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 3;
`;
const Language = styled.button`
  color: ${(props) => props.theme.colors.black};
  background: none !important;
  border: none;
  padding: 0 7px !important;
  
  h3 {
    font-family: 'OpenSansRegular';
    background-color: ${(props) => props.theme.colors.almostWhite};
    border-radius: 35px;
    padding: 7px 15px;
    font-size: 12px;
  }

  :hover {
    > h3 {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  > h3.activeLang {
    color: ${(props) => props.theme.colors.mint};
  }
`;

export default SignInPage;
