import Auth from '@aws-amplify/auth';
import {
  getHousingInfo,
  getStrongAuthSettings,
  getProfile,
  putProfile,
  getHousing,
  getDocuments,
  getMessages,
  getBillingHistory,
  getDebts,
  getApartment,
  getAgreementInfo,
  updateVisited,
  getRecentPayments,
  getOne4allModules,
  getOne4allBookings,
  getOne4allLanguage,
  getOne4allPin
} from '../apis';
import { getOne4allLanguageKey } from '../utils';
import { Profile, AppState } from './appContext';

export enum ACTIONS {
  LOAD_HOUSING = 1000,
  LOAD_APARTMENT,
  LOAD_PROFILE,
  UPDATE_PROFILE,
  LOAD_STRONG_AUTH_SETTINGS,
  LOAD_MESSAGES,
  LOAD_BILLING_HISTORY,
  LOAD_DEBTS,
  HIDE_DEBT_WARNING,
  SET_BILLING_FILTER,
  SET_BILLING_RANGE_START,
  SET_BILLING_RANGE_END,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  LOAD_HOUSING_COOP,
  LOAD_DOCUMENTS,
  LOAD_AGREEMENT_INFO,
  LOAD_RECENT_PAYMENTS,
  LOAD_TENANT_MODULES,
  LOAD_TENANT_BOOKINGS,
  LOAD_TENANT_LANGUAGE,
  LOAD_TENANT_PIN,
  UPDATE_VISITED,
  LOGOUT,
  LOGIN
}

export enum EVENTS {
  LOADED_HOUSING = 2000,
  LOGGEDIN,
  LOGGEDOUT,
  LOAD_PROFILE_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  LOAD_STRONG_AUTH_SETTINGS_SUCCESS,
  LOADED_HOUSING_COOP,
  LOADED_DOCUMENTS,
  LOAD_APARTMENT_SUCCESS,
  LOAD_APARTMENT_FAILURE,
  LOAD_MESSAGES_SUCCESS,
  LOAD_BILLING_HISTORY_SUCCESS,
  LOAD_DEBTS_SUCCESS,
  LOAD_AGREEMENT_INFO_SUCCESS,
  LOAD_RECENT_PAYMENTS_SUCCESS,
  LOAD_TENANT_MODULES_SUCCESS,
  LOAD_TENANT_BOOKINGS_SUCCESS,
  LOAD_TENANT_LANGUAGE_SUCCESS,
  LOAD_TENANT_PIN_SUCCESS,
  UPDATE_VISITED_SUCCESS
}

export enum LOAD_STATES {
  READY = 1,
  BUSY,
  ERROR
}

export type Dispatcher = React.Dispatch<[EVENTS | ACTIONS, any?]>;

export async function loadUser(dispatch: Dispatcher) {
  try {
    dispatch([ACTIONS.LOGIN]);
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true
    });

    dispatch([EVENTS.LOGGEDIN, user]);
  } catch (err) {
    dispatch([EVENTS.LOGGEDOUT]);
  }
}

export async function logout(dispatch: Dispatcher) {
  try {
    dispatch([ACTIONS.LOGOUT]);
    await Auth.signOut();
    dispatch([EVENTS.LOGGEDOUT]);
  } catch (err) {
    console.log('Logout failed:', err);
  }
}

export async function loadHousing(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_HOUSING]);

  const housing = await getHousingInfo();

  dispatch([EVENTS.LOADED_HOUSING, housing]);
}

export async function loadApartment(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_APARTMENT]);
  try {
    const apartment = await getApartment();
    dispatch([EVENTS.LOAD_APARTMENT_SUCCESS, apartment]);
  } catch {
    dispatch([EVENTS.LOAD_APARTMENT_FAILURE]);
  }
}

export async function loadStrongAuthSettings(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_STRONG_AUTH_SETTINGS]);
  const settings = await getStrongAuthSettings();
  dispatch([EVENTS.LOAD_STRONG_AUTH_SETTINGS_SUCCESS, settings]);
}

export async function loadProfile(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_PROFILE]);
  const profile = await getProfile();
  dispatch([EVENTS.LOAD_PROFILE_SUCCESS, profile]);
}

export async function updateProfile(dispatch: Dispatcher, profile: Profile) {
  dispatch([ACTIONS.UPDATE_PROFILE]);
  await putProfile(profile);
  dispatch([EVENTS.UPDATE_PROFILE_SUCCESS]);
}

export async function loadMessages(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_MESSAGES]);
  try {
    const messages = await getMessages();
    dispatch([EVENTS.LOAD_MESSAGES_SUCCESS, messages]);
  } catch {
    dispatch([EVENTS.LOAD_MESSAGES_SUCCESS, []]);
  }
}

export async function loadBillingHistory(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_BILLING_HISTORY]);
  const billingHistory = await getBillingHistory();
  dispatch([EVENTS.LOAD_BILLING_HISTORY_SUCCESS, billingHistory]);
}

export async function loadDebts(dispatch: Dispatcher, state?: AppState) {
  let debts = state && state.debts;

  if (!debts || debts.loadState !== LOAD_STATES.READY) {
    dispatch([ACTIONS.LOAD_DEBTS]);
    debts = await getDebts();
  }

  dispatch([EVENTS.LOAD_DEBTS_SUCCESS, debts]);
}

export async function showNotification(dispatch: Dispatcher, message: string) {
  dispatch([ACTIONS.SHOW_NOTIFICATION, message]);
  setTimeout(() => dispatch([ACTIONS.HIDE_NOTIFICATION, message]), 10000);
}

export async function loadHousingCoop(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_HOUSING_COOP]);
  const housingCoop = await getHousing();
  dispatch([EVENTS.LOADED_HOUSING_COOP, housingCoop]);
}

export async function getDocumentsList(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_DOCUMENTS]);
  const list = await getDocuments();
  dispatch([EVENTS.LOADED_DOCUMENTS, list]);
}

export async function getAgreement(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_AGREEMENT_INFO]);
  const agreementInfo = await getAgreementInfo();
  dispatch([EVENTS.LOAD_AGREEMENT_INFO_SUCCESS, agreementInfo]);
}

export async function updateVisitedStatus(dispatch: Dispatcher, visited: string) {
  dispatch([ACTIONS.UPDATE_VISITED]);
  await updateVisited(visited);
  dispatch([EVENTS.UPDATE_VISITED_SUCCESS, visited]);
}

export async function loadRecentPayments(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_RECENT_PAYMENTS]);
  const recentPayments = await getRecentPayments();
  dispatch([EVENTS.LOAD_RECENT_PAYMENTS_SUCCESS, recentPayments]);
}

export async function getTenantModules(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_TENANT_MODULES]);
  try {
    const language = getOne4allLanguageKey();
    const tenantModules = await getOne4allModules(language);
    dispatch([EVENTS.LOAD_TENANT_MODULES_SUCCESS, tenantModules]);
  } catch {
    dispatch([EVENTS.LOAD_TENANT_MODULES_SUCCESS, {}]);
  }
}

export async function getTenantBookings(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_TENANT_BOOKINGS]);
  try {
    const language = getOne4allLanguageKey();
    const tenantBookings = await getOne4allBookings(language);
    dispatch([EVENTS.LOAD_TENANT_BOOKINGS_SUCCESS, tenantBookings]);
  } catch {
    dispatch([EVENTS.LOAD_TENANT_BOOKINGS_SUCCESS, {}]);
  }
}

export async function getTenantLanguage(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_TENANT_LANGUAGE]);
  try {
    const language = getOne4allLanguageKey();
    const tenantLanguage = await getOne4allLanguage(language);
    dispatch([EVENTS.LOAD_TENANT_LANGUAGE_SUCCESS, tenantLanguage]);
  } catch {
    dispatch([EVENTS.LOAD_TENANT_LANGUAGE_SUCCESS, {}]);
  }
}

export async function getTenantPin(dispatch: Dispatcher) {
  dispatch([ACTIONS.LOAD_TENANT_PIN]);
  try {
    const language = getOne4allLanguageKey();
    const tenantPin = await getOne4allPin(language);
    dispatch([EVENTS.LOAD_TENANT_PIN_SUCCESS, tenantPin]);
  } catch {
    dispatch([EVENTS.LOAD_TENANT_PIN_SUCCESS, {}]);
  }
}

