import React, { useState } from 'react';
import styled from 'styled-components';
import ColorIcon from '../basic/ColorIcon';
import { H2 } from '../basic/Text';
import { ReactComponent as IconUp } from '../../assets/chevron-up-black.svg';
import { ReactComponent as IconDown } from '../../assets/chevron-down-black.svg';
import { Node } from 'interweave';

interface WPAccordionProps {
  children?: Node[]; 
  title: string; 
}

// TODO: Use the already existing Accordion.tsx component. It needs some refactoring to work here though.
export const WPAccordion = ({ children, title }: WPAccordionProps) => {
  const [open, setOpen] = useState(false);

  return (
    <WPAccordionContainer>
      <Title onClick={() => setOpen(!open)}>
        <H2>{title}</H2>
        <ColorIcon IconComponent={open ? IconDown : IconUp } width={12} />
      </Title>
      <Content>
        { open ? children?.map(c => c) : null }
      </Content>
    </WPAccordionContainer>
  );
}

const WPAccordionContainer = styled.div`
  width: 100%;
  margin: 0 0 32px 0;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const Content = styled.div`

`;

export const BackgroundBox = styled.div`
  background: ${props => props.theme.colors.lemon};
`;


interface WPLinkButtonProps {
  children?: Node[];
  external?: boolean;
}

export const WPLinkButton = ({ children }: WPLinkButtonProps) => {

  return (
    <>{children}</>
  );
}
