import React from 'react';
import { useMessageGetter } from 'react-message-context';
import { H1, CallOut, H3, BasicParagraph } from '../components/basic/Text';
import styled from 'styled-components';
import PageContainer from './PageContainer';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import PaddedContent from '../components/housing/PaddedContent';
import { DeviceSize } from '../components/basic/DeviceSize';

const ApartmentChangePage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('change');
  return (
    <PageContainer history={history}>
      <Content>
        <TitleContent>
          <H1>{t('title')}</H1>
          <CallOut>{t('description')}</CallOut>
        </TitleContent>
        <ChoiceContainer>
          <ChoiceLink exact to={'/asuntoni/vaihda/asunnonvaihto'}>
            <ChoiceTitle>{t('alternative1')}</ChoiceTitle>
            <ChoiceBody>{t('alt1description')}</ChoiceBody>
          </ChoiceLink>
          <ChoiceLink exact to={'/asuntoni/vaihda/uusi-hakemus'}>
            <ChoiceTitle>{t('alternative2')}</ChoiceTitle>
            <ChoiceBody>{t('alt2description')}</ChoiceBody>
          </ChoiceLink>
        </ChoiceContainer>
      </Content>
    </PageContainer>
  );
};

const Content = styled(PaddedContent)`
  min-height: 600px;

  > h1:first-child {
    margin-bottom: 26px;
  }
`;
const TitleContent = styled.div`
  margin-bottom: 40px;
`;
const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  
  @media ${DeviceSize.desktopS} {
      flex-direction: column;
  }
`;
const ChoiceLink = styled(NavLink)`
  background-color: ${(props) => props.theme.colors.apila};
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-height: 100px;
  width: 49%;
  margin: 1rem 0;
  border-radius: 24px;
  transition: 0.2s;

  :hover {
    cursor: pointer;
    transform: scaleX(1.02) scaleY(1.02) translate(0, -0.25rem);
    box-shadow: 0 25px 50px -12px rgba(0,0,0,0.25);
  }
  
  @media ${DeviceSize.desktopS} {
    margin: 0;
    width: 100%;
  }
`;

const ChoiceTitle = styled(H3)`
  color: ${(props) => props.theme.colors.black};
  font-size: 20px;
  margin-bottom: 24px;
`;

const ChoiceBody = styled(BasicParagraph)`
  margin: 0;
`;

export default ApartmentChangePage;
