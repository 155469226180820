import React, { Fragment } from 'react';
import styled from 'styled-components';
import { H2, H3 } from '../basic/Text';
import FeatureItem from './FeatureItem';
import PaddedContent from './PaddedContent';
import { MarginContainer } from '../basic/Flex';
import Loader from '../basic/Loader';
import { LOAD_STATES } from '../../context/actions';
import { Housing } from '../../context/appContext';

type FeatureListProps = {
  t: (...args: any[]) => any,
  housing: Housing,
  className?: string,
};
const FeatureList: React.FunctionComponent<FeatureListProps> = ({ t, housing, className }) => {
  const ListContents = () => {
    const features = housing.features && housing.features.filter(v => v.isExist) 
    return (
      <Fragment>
        {features && features.length > 0 ? (
          features.map((feature, i) => (
            <Fragment key={i}>
              <FeatureItem key={i} feature={feature} t={t} />
            </Fragment> 
          )
          )) : (
          <NoFeatures>{t('housefeat.none')}</NoFeatures>
        )}
      </Fragment>
    );
  };
  return (
    <Wrapper className={className}>
      <Padding>
        <Content>
          <H2>{t('housefeat.title')}</H2>
          {housing.loadState === LOAD_STATES.BUSY && !housing.features ? (
            <Loader />
          ) : (
            <Fragment>
              <DesktopContainer>
                <ListContents />
              </DesktopContainer>
            </Fragment>
          )}
        </Content>
      </Padding>
    </Wrapper>
  );
};
const Wrapper = styled.div`
`;
const Padding = styled(PaddedContent)`
`;
const Content = styled.div`
  background: ${props => props.theme.colors.lemon};
  border-radius: 24px;
  padding: 20px 40px;
  margin: auto;
`;
const NoFeatures = styled(H3)`
  padding-bottom: 40px; 
`;
const DesktopContainer = styled(MarginContainer)`
  margin-top: 20px;
`;
export default FeatureList;
