import React from 'react';
import { DeviceSize } from '../basic/DeviceSize';
import styled from 'styled-components';
import { blackLogo } from '../../assets';
import { NavLink } from 'react-router-dom';

interface HomeLogoProps {
  margin?: string
  isHomePage?: boolean // It should not be shown on the home page on mobile.
}

const HomeLogoImage = styled(NavLink) <{ margin?: string, isHomePage?: boolean }>`
  //position: absolute;
  //top: 20px;
  //left: 30px;
  width: 35%;
  z-index: 1;

  img {
    width: 150px;
    margin-top: 3rem;
    margin-right: 7rem;

    @media print {
      height: 50px;
    }

    @media ${DeviceSize.desktopM} {
      width: 70px;
      margin-top: 0;
      margin-right: 0;
    }

    @media ${DeviceSize.mobileS} {
      padding-right: 20px;
    }
  }

  @media ${DeviceSize.desktopM} {
    display: flex;
    // margin: ${props => props.margin ? props.margin : 'auto'};
    margin: 0;
    position: unset;
    top: 0;
    left: 0;
    order: 0;
  }

  @media ${DeviceSize.desktopS} {
    width: 20%;
  }

  @media ${DeviceSize.mobileL} {
    display: ${props => props.isHomePage ? 'none' : 'flex'};
  }

  @media print {
    position: absolute;
    margin: 0 0 0 0;
    left: unset;
    right: 0;
  }
`;

const HomeLogo = ({ margin, isHomePage = false }: HomeLogoProps) =>
  <HomeLogoImage activeClassName="active" exact to="/" margin={margin} id='homelogo' isHomePage={isHomePage}>
    <img src={blackLogo} alt="M2 logo" aria-label="Siirry kotisivulle" />
  </HomeLogoImage>
;

export default HomeLogo;
