export const housingExchangeUrls = [
  {
    'houseId': '20026',
    'url': 'https://m2kodit.fi/asunnot/espoo/friisinmaenaukio-7/'
  },
  {
    'houseId': '10134',
    'url': 'https://m2kodit.fi/asunnot/espoo/huvilinnanmaki-7/'
  },
  {
    'houseId': '10125',
    'url': 'https://m2kodit.fi/asunnot/espoo/kalastajanmaki-3/'
  },
  {
    'houseId': '10202',
    'url': '',
  },
  {
    'houseId': '10058',
    'url': 'https://m2kodit.fi/asunnot/espoo/kaskimaa-4/'
  },
  {
    'houseId': '10206',
    'url': 'https://m2kodit.fi/asunnot/espoo/keelkorvenkuja-3/'
  },
  {
    'houseId': '11011',
    'url': 'https://m2kodit.fi/asunnot/espoo/asunto-oy-espoon-klariksentie-2/'
  },
  {
    'houseId': '10192',
    'url': 'https://m2kodit.fi/asunnot/espoo/kompassikatu-7/'
  },
  {
    'houseId': '11021',
    'url': 'https://m2kodit.fi/asunnot/espoo/kukkaromaki-8/'
  },
  {
    'houseId': '10161',
    'url': 'https://m2kodit.fi/asunnot/espoo/leppaviita-1-ja-5/'
  },
  {
    'houseId': '11022',
    'url': 'https://m2kodit.fi/asunnot/espoo/lyhtykuja-4/'
  },
  {
    'houseId': '11007',
    'url': 'https://m2kodit.fi/asunnot/espoo/piilipuuntie-11/'
  },
  {
    'houseId': '11009',
    'url': 'https://m2kodit.fi/asunnot/espoo/piilipuuntie-7/'
  },
  {
    'houseId': '11006',
    'url': 'https://m2kodit.fi/asunnot/espoo/piilipuuntie-9/'
  },
  {
    'houseId': '11025',
    'url': 'https://m2kodit.fi/asunnot/espoo/runoratsunkatu-9/'
  },
  {
    'houseId': '20053',
    'url': 'https://m2kodit.fi/asunnot/espoo/ullantorppa-2/'
  },
  {
    'houseId': '10104',
    'url': 'https://m2kodit.fi/asunnot/espoo/viittakorpi-2/'
  },
  {
    'houseId': '10193',
    'url': 'https://m2kodit.fi/asunnot/helsinki/agronominkatu-1/'
  },
  {
    'houseId': '10142',
    'url': 'https://m2kodit.fi/asunnot/helsinki/aleksis-kiven-katu-45/'
  },
  {
    'houseId': '10160',
    'url': 'https://m2kodit.fi/asunnot/helsinki/gunnel-nymanin-katu-4/'
  },
  {
    'houseId': '10170',
    'url': 'https://m2kodit.fi/asunnot/helsinki/gunnel-nymanin-katu-8/'
  },
  {
    'houseId': '10050',
    'url': 'https://m2kodit.fi/asunnot/helsinki/haapalahdenkatu-1/'
  },
  {
    'houseId': '10051',
    'url': 'https://m2kodit.fi/asunnot/helsinki/haapalahdenkatu-13/'
  },
  {
    'houseId': '10092',
    'url': 'https://m2kodit.fi/asunnot/helsinki/untamalankuja-1/'
  },
  {
    'houseId': '10196',
    'url': 'https://m2kodit.fi/asunnot/helsinki/juutinraumankatu-10/'
  },
  {
    'houseId': '10102',
    'url': 'https://m2kodit.fi/asunnot/helsinki/laivalahdenkaari-18/'
  },
  {
    'houseId': '10057',
    'url': 'https://m2kodit.fi/asunnot/helsinki/kellaripellonkuja-126/'
  },
  {
    'houseId': '10100',
    'url': 'https://m2kodit.fi/asunnot/helsinki/kellosaarenkatu-4/'
  },
  {
    'houseId': '10007',
    'url': 'https://m2kodit.fi/asunnot/heikinlaakso/kirsitie-2/'
  },
  {
    'houseId': '10048',
    'url': 'https://m2kodit.fi/asunnot/helsinki/koskustie-4/'
  },
  {
    'houseId': '10088',
    'url': 'https://m2kodit.fi/asunnot/helsinki/kotinummentie-47/'
  },
  {
    'houseId': '10087',
    'url': 'https://m2kodit.fi/asunnot/helsinki/liusketie-20/'
  },
  {
    'houseId': '10213',
    'url': 'https://m2kodit.fi/asunnot/kuopio/vanhankallionkatu-5/'
  },
  {
    'houseId': '11019',
    'url': 'https://m2kodit.fi/asunnot/helsinki/kiinteisto-oy-helsingin-jallukka/'
  },
  {
    'houseId': '10066',
    'url': 'https://m2kodit.fi/asunnot/helsinki/mesenaatinkuja-4/'
  },
  {
    'houseId': '10065',
    'url': 'https://m2kodit.fi/asunnot/helsinki/mesenaatintie-8-12/'
  },
  {
    'houseId': '10056',
    'url': 'https://m2kodit.fi/asunnot/helsinki/marssykuja-1/'
  },
  {
    'houseId': '10085',
    'url': 'https://m2kodit.fi/asunnot/helsinki/naavapolku-14/'
  },
  {
    'houseId': '10121',
    'url': 'https://m2kodit.fi/asunnot/helsinki/norkkokuja-34/'
  },
  {
    'houseId': '10205',
    'url': 'https://m2kodit.fi/asunnot/helsinki/otonkuja-4/'
  },
  {
    'houseId': '10103',
    'url': 'https://m2kodit.fi/asunnot/helsinki/otto-brandtin-tie-17/'
  },
  {
    'houseId': '10046',
    'url': 'https://m2kodit.fi/asunnot/helsinki/pasuunakuja-1/'
  },
  {
    'houseId': '10008',
    'url': 'https://m2kodit.fi/asunnot/hakuninmaa/perhekunnantie-24-26/'
  },
  {
    'houseId': '10208',
    'url': 'https://m2kodit.fi/asunnot/helsinki/postiljooninkatu-1/'
  },
  {
    'houseId': '11010',
    'url': 'https://m2kodit.fi/asunnot/helsinki/asunto-oy-paivolantie-25/'
  },
  {
    'houseId': '10086',
    'url': 'https://m2kodit.fi/asunnot/helsinki/rapakiventie-12/'
  },
  {
    'houseId': '10068',
    'url': 'https://m2kodit.fi/asunnot/helsinki/rankitie-1-3/'
  },
  {
    'houseId': '10004',
    'url': 'https://m2kodit.fi/asunnot/helsinki/saniaistie-10/'
  },
  {
    'houseId': '10076',
    'url': 'https://m2kodit.fi/asunnot/helsinki/selkamerenkatu-12/'
  },
  {
    'houseId': '11003',
    'url': 'https://m2kodit.fi/asunnot/helsinki/soittajantie-4b/'
  },
  {
    'houseId': '10062',
    'url': 'https://m2kodit.fi/asunnot/helsinki/taiteentekijantie-7/'
  },
  {
    'houseId': '10003',
    'url': 'https://m2kodit.fi/asunnot/helsinki/tiirismaantie-11/'
  },
  {
    'houseId': '10070',
    'url': 'https://m2kodit.fi/asunnot/helsinki/tulisuontie-5/'
  },
  {
    'houseId': '10179',
    'url': 'https://m2kodit.fi/asunnot/helsinki/von-daehnin-katu-11/'
  },
  {
    'houseId': '11000',
    'url': 'https://m2kodit.fi/asunnot/helsinki/koy-helsingin-muurahaisenpolku-6/'
  },
  {
    'houseId': '20061',
    'url': 'https://m2kodit.fi/asunnot/hollola/oravapiha-2/'
  },
  {
    'houseId': '10077',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/kapulantie-16/'
  },
  {
    'houseId': '10093',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/kapulantie-5/'
  },
  {
    'houseId': '10061',
    'url': 'https://m2kodit.fi/asunnot/hakala/kurkihirrentie-2/'
  },
  {
    'houseId': '10019',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/mannikkotie-3/'
  },
  {
    'houseId': '10020',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/mannikkotie-5/'
  },
  {
    'houseId': '10153',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/puskintie-4/'
  },
  {
    'houseId': '10146',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/tienhaarankuja-10/'
  },
  {
    'houseId': '10147',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/tienhaarankuja-12/'
  },
  {
    'houseId': '10144',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/tienhaarankuja-6/'
  },
  {
    'houseId': '10145',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/tienhaarankuja-8/'
  },
  {
    'houseId': '10152',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/vesitorninkatu-10/'
  },
  {
    'houseId': '10150',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/vesitorninkatu-15/'
  },
  {
    'houseId': '10148',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/vesitorninkatu-5/'
  },
  {
    'houseId': '10149',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/vesitorninkatu-7/'
  },
  {
    'houseId': '10151',
    'url': 'https://m2kodit.fi/asunnot/hyvinkaa/vesitorninkatu-8/'
  },
  {
    'houseId': '10114',
    'url': 'https://m2kodit.fi/asunnot/hameenlinna/karhitie-3-haratie-6/'
  },
  {
    'houseId': '10096',
    'url': 'https://m2kodit.fi/asunnot/hameenlinna/kammekantie-1/'
  },
  {
    'houseId': '10191',
    'url': 'https://m2kodit.fi/asunnot/ahvenisto/rakuunankatu-3/'
  },
  {
    'houseId': '10117',
    'url': 'https://m2kodit.fi/asunnot/iisalmi/saarnikatu-4/'
  },
  {
    'houseId': '10038',
    'url': '',
  },
  {
    'houseId': '20056',
    'url': 'https://m2kodit.fi/asunnot/jokela/puistotie-4/'
  },
  {
    'houseId': '10016',
    'url': 'https://m2kodit.fi/asunnot/jyvaskyla/aarontie-4/'
  },
  {
    'houseId': '10060',
    'url': 'https://m2kodit.fi/asunnot/jyvaskyla/aarontie-7/'
  },
  {
    'houseId': '11028',
    'url': 'https://m2kodit.fi/asunnot/huhtasuo-kangasrinne/huhta-9a/'
  },
  {
    'houseId': '10175',
    'url': 'https://m2kodit.fi/asunnot/jyvaskyla/kekkolantie-22/'
  },
  {
    'houseId': '10073',
    'url': 'https://m2kodit.fi/asunnot/jyvaskyla/keskisentie-5/'
  },
  {
    'houseId': '10022',
    'url': 'https://m2kodit.fi/asunnot/ainola/lehtorannantie-5/'
  },
  {
    'houseId': '10021',
    'url': 'https://m2kodit.fi/asunnot/jyvaskyla/suuruspaantie-3/'
  },
  {
    'houseId': '10009',
    'url': 'https://m2kodit.fi/asunnot/jyvaskyla/taitoniekantie-4/'
  },
  {
    'houseId': '10135',
    'url': 'https://m2kodit.fi/asunnot/jyvaskyla/vaihdekuja-6/'
  },
  {
    'houseId': '10044',
    'url': 'https://m2kodit.fi/asunnot/jamppa/haltianpolku-1/'
  },
  {
    'houseId': '10047',
    'url': 'https://m2kodit.fi/asunnot/jamppa/haltianpolku-3/'
  },
  {
    'houseId': '10042',
    'url': 'https://m2kodit.fi/asunnot/jamppa/haltianpolku-9/'
  },
  {
    'houseId': '11012',
    'url': 'https://m2kodit.fi/asunnot/jamppa/as-oy-jarvenpaan-jampanpaju-2/'
  },
  {
    'houseId': '10033',
    'url': 'https://m2kodit.fi/asunnot/jamppa/kaarnapolku-2/'
  },
  {
    'houseId': '10113',
    'url': 'https://m2kodit.fi/asunnot/jamppa/kaarnapolku-4/'
  },
  {
    'houseId': '10054',
    'url': 'https://m2kodit.fi/asunnot/jarvenpaa/maljakatu-7/'
  },
  {
    'houseId': '10055',
    'url': 'https://m2kodit.fi/asunnot/jarvenpaa/maljakatu-8/'
  },
  {
    'houseId': '20059',
    'url': 'https://m2kodit.fi/asunnot/jarvenpaa/metsolantie-50/'
  },
  {
    'houseId': '10029',
    'url': 'https://m2kodit.fi/asunnot/jamppa/wartsilankatu-47-naavatie-8/'
  },
  {
    'houseId': '10074',
    'url': 'https://m2kodit.fi/asunnot/jarvenpaa/peltolantie-30-32/'
  },
  {
    'houseId': '10128',
    'url': 'https://m2kodit.fi/asunnot/jarvenpaa/poytaalhonkuja-2/'
  },
  {
    'houseId': '20058',
    'url': 'https://m2kodit.fi/asunnot/jarvenpaa/poytaalhontie-74/'
  },
  {
    'houseId': '10107',
    'url': 'https://m2kodit.fi/asunnot/jarvenpaa/sauvakatu-3/'
  },
  {
    'houseId': '10089',
    'url': 'https://m2kodit.fi/asunnot/jarvenpaa/teuvankatu-1-3/'
  },
  {
    'houseId': '20025',
    'url': 'https://m2kodit.fi/asunnot/jarvenpaa/wartsilankatu-10/'
  },
  {
    'houseId': '20017',
    'url': 'https://m2kodit.fi/asunnot/kangasala/isolukontie-5/'
  },
  {
    'houseId': '10005',
    'url': 'https://m2kodit.fi/asunnot/kellokoski/mantyrinteentie-1/'
  },
  {
    'houseId': '10006',
    'url': 'https://m2kodit.fi/asunnot/kellokoski/mantyrinteentie-3/'
  },
  {
    'houseId': '11001',
    'url': 'https://m2kodit.fi/asunnot/kerava/as-oy-satotaival/'
  },
  {
    'houseId': '10098',
    'url': 'https://m2kodit.fi/asunnot/kerava/kuparisepankatu-2/'
  },
  {
    'houseId': '20060',
    'url': 'https://m2kodit.fi/asunnot/jaakkola/lintulammenkatu-11/'
  },
  {
    'houseId': '10017',
    'url': 'https://m2kodit.fi/asunnot/jaakkola/lintulammenkatu-5-b/'
  },
  {
    'houseId': '10034',
    'url': 'https://m2kodit.fi/asunnot/kerava/neulaskuja-5/'
  },
  {
    'houseId': '10018',
    'url': 'https://m2kodit.fi/asunnot/jaakkola/palokorvenkatu-8/'
  },
  {
    'houseId': '10132',
    'url': 'https://m2kodit.fi/asunnot/kerava/visatie-6/'
  },
  {
    'houseId': '10030',
    'url': 'https://m2kodit.fi/asunnot/gesterby/gesterborgintie-1-hopeahaka-2/'
  },
  {
    'houseId': '10131',
    'url': 'https://m2kodit.fi/asunnot/keskusta/piippolanrinne-2/'
  },
  {
    'houseId': '10012',
    'url': 'https://m2kodit.fi/asunnot/keskusta/virkatie-4-wiikintie-1/'
  },
  {
    'houseId': '20050',
    'url': 'https://m2kodit.fi/asunnot/klaukkala/isoseppala-10/'
  },
  {
    'houseId': '10122',
    'url': 'https://m2kodit.fi/asunnot/klaukkala/kiilakiventie-2/'
  },
  {
    'houseId': '20210',
    'url': 'https://m2kodit.fi/asunnot/klaukkala/myllarintie-2/'
  },
  {
    'houseId': '10126',
    'url': 'https://m2kodit.fi/asunnot/kotka/gutzeitintie-13/'
  },
  {
    'houseId': '10124',
    'url': 'https://m2kodit.fi/asunnot/kotka/gutzeitintie-3/'
  },
  {
    'houseId': '10118',
    'url': 'https://m2kodit.fi/asunnot/kotka/gutzeitintie-5/'
  },
  {
    'houseId': '10199',
    'url': 'https://m2kodit.fi/asunnot/kotka/koulukatu-23/'
  },
  {
    'houseId': '10198',
    'url': 'https://m2kodit.fi/asunnot/kotka/koulukatu-25-b/'
  },
  {
    'houseId': '10040',
    'url': 'https://m2kodit.fi/asunnot/kotka/tasaajankatu-1/'
  },
  {
    'houseId': '10154',
    'url': 'https://m2kodit.fi/asunnot/kangas/hallituskatu-6/'
  },
  {
    'houseId': '10155',
    'url': 'https://m2kodit.fi/asunnot/kangas/hallituskatu-6-c/'
  },
  {
    'houseId': '10027',
    'url': 'https://m2kodit.fi/asunnot/kouvola/jukolantie-7/'
  },
  {
    'houseId': '10031',
    'url': 'https://m2kodit.fi/asunnot/kouvola/viertolantie-18/'
  },
  {
    'houseId': '10045',
    'url': 'https://m2kodit.fi/asunnot/kelloniemi/ankkuritie-4/'
  },
  {
    'houseId': '10075',
    'url': 'https://m2kodit.fi/asunnot/kuopio/heinakatu-1/'
  },
  {
    'houseId': '10091',
    'url': 'https://m2kodit.fi/asunnot/kuopio/kyparakatu-3/'
  },
  {
    'houseId': '11008',
    'url': 'https://m2kodit.fi/asunnot/kuopio/papinkuja-3/'
  },
  {
    'houseId': '11005',
    'url': 'https://m2kodit.fi/asunnot/kuopio/papinkuja-7/'
  },
  {
    'houseId': '10059',
    'url': 'https://m2kodit.fi/asunnot/kuopio/pyoronkaari-2/'
  },
  {
    'houseId': '10095',
    'url': 'https://m2kodit.fi/asunnot/kuopio/pyoronkaari-8/'
  },
  {
    'houseId': '10209',
    'url': 'https://m2kodit.fi/asunnot/kuopio/vanhankallionkatu-5/'
  },
  {
    'houseId': '10036',
    'url': 'https://m2kodit.fi/asunnot/kouvola/puistokuja-4/'
  },
  {
    'houseId': '10043',
    'url': 'https://m2kodit.fi/asunnot/kerinkallio/jyrkankatu-10/'
  },
  {
    'houseId': '10112',
    'url': 'https://m2kodit.fi/asunnot/kerinkallio/jyrkankatu-14/'
  },
  {
    'houseId': '10035',
    'url': 'https://m2kodit.fi/asunnot/kerinkallio/jyrkankatu-2/'
  },
  {
    'houseId': '10041',
    'url': 'https://m2kodit.fi/asunnot/kerinkallio/jyrkankatu-6/'
  },
  {
    'houseId': '20062',
    'url': 'https://m2kodit.fi/asunnot/asemantausta/kaarikatu-9/'
  },
  {
    'houseId': '10014',
    'url': 'https://m2kodit.fi/asunnot/keskusta-lahti-1076/kauppakatu-29/'
  },
  {
    'houseId': '10015',
    'url': 'https://m2kodit.fi/asunnot/keskusta-lahti-1077/kauppakatu-34/'
  },
  {
    'houseId': '10116',
    'url': 'https://m2kodit.fi/asunnot/kilpiainen/kevatkatu-1/'
  },
  {
    'houseId': '10024',
    'url': 'https://m2kodit.fi/asunnot/lahti/korkeakatu-4/'
  },
  {
    'houseId': '10026',
    'url': 'https://m2kodit.fi/asunnot/kerinkallio/luhtikatu-2/'
  },
  {
    'houseId': '10169',
    'url': 'https://m2kodit.fi/asunnot/keski-lahti/mallaskatu-10/'
  },
  {
    'houseId': '10174',
    'url': 'https://m2kodit.fi/asunnot/keski-lahti/mallaskatu-8/'
  },
  {
    'houseId': '10109',
    'url': 'https://m2kodit.fi/asunnot/kartano/hakatornit/'
  },
  {
    'houseId': '10120',
    'url': 'https://m2kodit.fi/asunnot/asemantausta/nuolikatu-10/'
  },
  {
    'houseId': '10078',
    'url': 'https://m2kodit.fi/asunnot/kiverio/pohjanakanpolku-30/'
  },
  {
    'houseId': '10025',
    'url': 'https://m2kodit.fi/asunnot/ahtiala/uustorpankatu-2/'
  },
  {
    'houseId': '11002',
    'url': 'https://m2kodit.fi/asunnot/asemantausta/as-oy-marolinnankoti/'
  },
  {
    'houseId': '11017',
    'url': 'https://m2kodit.fi/asunnot/keskusta-lappeenranta-93/kiinteisto-oy-lappeenrannan-koulukatu-1/'
  },
  {
    'houseId': '20064',
    'url': 'https://m2kodit.fi/asunnot/karhuvuori/oraankatu-1-2/'
  },
  {
    'houseId': '10214',
    'url': 'https://m2kodit.fi/asunnot/keskusta-lempaala-107/manttaalitie-10/'
  },
  {
    'houseId': '10013',
    'url': 'https://m2kodit.fi/asunnot/kirkkonummi/sepankylantie-12/'
  },
  {
    'houseId': '10023',
    'url': 'https://m2kodit.fi/asunnot/lahti/niinitie-9-11-15-17/'
  },
  {
    'houseId': '12001',
    'url': 'https://m2kodit.fi/asunnot/keskusta-nokia-131/kauppakatu-5/'
  },
  {
    'houseId': '11023',
    'url': 'https://m2kodit.fi/asunnot/nokia/as-oy-nokian-rantahelmi-1/'
  },
  {
    'houseId': '20052',
    'url': 'https://m2kodit.fi/asunnot/nummela/papulintie-2/'
  },
  {
    'houseId': '10067',
    'url': 'https://m2kodit.fi/asunnot/nummela/vanhan-sepan-tie-6-8/'
  },
  {
    'houseId': '20051',
    'url': 'https://m2kodit.fi/asunnot/nurmijarven-kk/sorvankaari-10/'
  },
  {
    'houseId': '20063',
    'url': 'https://m2kodit.fi/asunnot/orimattila/palojoentie-2/'
  },
  {
    'houseId': '10156',
    'url': 'https://m2kodit.fi/asunnot/myllytulli/heikinkatu-27/'
  },
  {
    'houseId': '10157',
    'url': 'https://m2kodit.fi/asunnot/myllytulli/heikinkatu-31/'
  },
  {
    'houseId': '10083',
    'url': 'https://m2kodit.fi/asunnot/oulu/jarvitie-20/'
  },
  {
    'houseId': '10158',
    'url': 'https://m2kodit.fi/asunnot/myllytulli/koulukatu-3/'
  },
  {
    'houseId': '10139',
    'url': 'https://m2kodit.fi/asunnot/kaakkuri/pesatie-4/'
  },
  {
    'houseId': '10123',
    'url': 'https://m2kodit.fi/asunnot/oulu/pinkelikatu-2/'
  },
  {
    'houseId': '10105',
    'url': 'https://m2kodit.fi/asunnot/oulu/pinkelikatu-6/'
  },
  {
    'houseId': '10079',
    'url': 'https://m2kodit.fi/asunnot/mantyla/postipojankuja-2/'
  },
  {
    'houseId': '10101',
    'url': 'https://m2kodit.fi/asunnot/oulu/sellukatu-15/'
  },
  {
    'houseId': '10167',
    'url': 'https://m2kodit.fi/asunnot/kaakkuri/sulkakuja-3-7/'
  },
  {
    'houseId': '10162',
    'url': 'https://m2kodit.fi/asunnot/intio/veteraanikatu-4/'
  },
  {
    'houseId': '10177',
    'url': '',
  },
  {
    'houseId': '10207',
    'url': 'https://m2kodit.fi/asunnot/jyvaskyla/mannisenrinne-14/'
  },
  {
    'houseId': '10185',
    'url': 'https://m2kodit.fi/asunnot/pakkalankulma/kaartotie-3-mustimaentie-2/'
  },
  {
    'houseId': '10186',
    'url': 'https://m2kodit.fi/asunnot/pirkkala/kaskenpolttajantie-3/'
  },
  {
    'houseId': '10183',
    'url': 'https://m2kodit.fi/asunnot/niemenmaa/kattilaistentie-24/'
  },
  {
    'houseId': '10184',
    'url': 'https://m2kodit.fi/asunnot/niemenmaa/kattilaistentie-26/'
  },
  {
    'houseId': '10187',
    'url': 'https://m2kodit.fi/asunnot/kurikka/kullaantie-2/'
  },
  {
    'houseId': '10188',
    'url': 'https://m2kodit.fi/asunnot/kurikka/kullaantie-7-9/'
  },
  {
    'houseId': '12003',
    'url': 'https://m2kodit.fi/asunnot/loukonlahti/lapikaytavankuja-4/'
  },
  {
    'houseId': '10182',
    'url': 'https://m2kodit.fi/asunnot/niemenmaa/niemenmaantie-17/'
  },
  {
    'houseId': '20015',
    'url': 'https://m2kodit.fi/asunnot/kyosti/perkiontie-55/'
  },
  {
    'houseId': '10181',
    'url': 'https://m2kodit.fi/asunnot/pirkkala/takamaantie-6/'
  },
  {
    'houseId': '10115',
    'url': 'https://m2kodit.fi/asunnot/gammelbacka/peipontie-6-8/'
  },
  {
    'houseId': '10164',
    'url': 'https://m2kodit.fi/asunnot/gammelbacka/suolaketie-1/'
  },
  {
    'houseId': '10037',
    'url': 'https://m2kodit.fi/asunnot/gammelbacka/tornipolku-5-viertotie-2/'
  },
  {
    'houseId': '10032',
    'url': 'https://m2kodit.fi/asunnot/gammelbacka/tornipolku-6/'
  },
  {
    'houseId': '10069',
    'url': 'https://m2kodit.fi/asunnot/gammelbacka/verajapolku-3/'
  },
  {
    'houseId': '10099',
    'url': 'https://m2kodit.fi/asunnot/gammelbacka/verajatie-4/'
  },
  {
    'houseId': '10163',
    'url': 'https://m2kodit.fi/asunnot/gammelbacka/viertotie-12/'
  },
  {
    'houseId': '10028',
    'url': 'https://m2kodit.fi/asunnot/gammelbacka/viertotie-14/'
  },
  {
    'houseId': '10090',
    'url': 'https://m2kodit.fi/asunnot/gammelbacka/viertotie-4-8/'
  },
  {
    'houseId': '10072',
    'url': 'https://m2kodit.fi/asunnot/pyynpaa/sorkantie-12/'
  },
  {
    'houseId': '10084',
    'url': 'https://m2kodit.fi/asunnot/riihimaki/sompiontie-1/'
  },
  {
    'houseId': '10166',
    'url': 'https://m2kodit.fi/asunnot/kolmas-kaupunginosa/asemieskatu-10/'
  },
  {
    'houseId': '10159',
    'url': 'https://m2kodit.fi/asunnot/kolmas-kaupunginosa/asemieskatu-3/'
  },
  {
    'houseId': '10080',
    'url': 'https://m2kodit.fi/asunnot/ounasrinne/saaruantie-5/'
  },
  {
    'houseId': '10071',
    'url': 'https://m2kodit.fi/asunnot/ounasrinne/saaruantie-7/'
  },
  {
    'houseId': '10039',
    'url': 'https://m2kodit.fi/asunnot/ollikkala/hameenojankatu-2/'
  },
  {
    'houseId': '10111',
    'url': 'https://m2kodit.fi/asunnot/ollikkala/isotuvankatu-1/'
  },
  {
    'houseId': '10110',
    'url': 'https://m2kodit.fi/asunnot/ollikkala/isotuvankatu-5/'
  },
  {
    'houseId': '10081',
    'url': 'https://m2kodit.fi/asunnot/hervanta/insinoorinkatu-8690/'
  },
  {
    'houseId': '10189',
    'url': 'https://m2kodit.fi/asunnot/kalkku/kalkunvuorenkatu-28/'
  },
  {
    'houseId': '10180',
    'url': 'https://m2kodit.fi/asunnot/rantaperkio/lahdenperankatu-6/'
  },
  {
    'houseId': '10140',
    'url': 'https://m2kodit.fi/asunnot/kaukajarvi/mummunkuja-6/'
  },
  {
    'houseId': '10200',
    'url': 'https://m2kodit.fi/asunnot/linnainmaa/piettasenristi-8/'
  },
  {
    'houseId': '10176',
    'url': 'https://m2kodit.fi/asunnot/kalkku/poteronkatu-2/'
  },
  {
    'houseId': '11024',
    'url': 'https://m2kodit.fi/asunnot/kaleva/koy-tampereen-jallukka/'
  },
  {
    'houseId': '10173',
    'url': 'https://m2kodit.fi/asunnot/hervanta/tamppikuja-4/'
  },
  {
    'houseId': '10195',
    'url': 'https://m2kodit.fi/asunnot/hervanta/tieteenkatu-8-ja-10/'
  },
  {
    'houseId': '10204',
    'url': 'https://m2kodit.fi/asunnot/harmala/tuiskunkatu-1/'
  },
  {
    'houseId': '10190',
    'url': 'https://m2kodit.fi/asunnot/turku/hippoksentie-31-b/'
  },
  {
    'houseId': '10127',
    'url': 'https://m2kodit.fi/asunnot/moikoinen/omenatie-5/'
  },
  {
    'houseId': '10197',
    'url': 'https://m2kodit.fi/asunnot/kaerla/signalistinkatu-4/'
  },
  {
    'houseId': '10106',
    'url': 'https://m2kodit.fi/asunnot/turku/vahaheikkilantie-53/'
  },
  {
    'houseId': '10133',
    'url': 'https://m2kodit.fi/asunnot/hyokkala/hyokkalantie-3/'
  },
  {
    'houseId': '20055',
    'url': 'https://m2kodit.fi/asunnot/hyryla/hyokkalantie-9/'
  },
  {
    'houseId': '12002',
    'url': 'https://m2kodit.fi/asunnot/hyryla/kauppatie-2/'
  },
  {
    'houseId': '20209',
    'url': 'https://m2kodit.fi/asunnot/hyryla/as-oy-tuusulan-korkintie-1/'
  },
  {
    'houseId': '10119',
    'url': 'https://m2kodit.fi/asunnot/lahela/kulleronkuja-10/'
  },
  {
    'houseId': '10130',
    'url': 'https://m2kodit.fi/asunnot/paijala/naavankierto-6/'
  },
  {
    'houseId': '20057',
    'url': 'https://m2kodit.fi/asunnot/hyryla/pahkinamaentie-255/'
  },
  {
    'houseId': '20054',
    'url': 'https://m2kodit.fi/asunnot/pekkola/swingipolku-2/'
  },
  {
    'houseId': '30005',
    'url': 'https://m2kodit.fi/asunnot/ketunkallio/kiinteisto-oy-uudenkaupungin-lukkosepankatu-1/'
  },
  {
    'houseId': '10171',
    'url': 'https://m2kodit.fi/asunnot/hakunila/hiirakkokuja-8/'
  },
  {
    'houseId': '10010',
    'url': 'https://m2kodit.fi/asunnot/simonkyla/kallioimarteentie-14-a/'
  },
  {
    'houseId': '10136',
    'url': 'https://m2kodit.fi/asunnot/pakkala/keikarinkuja-1/'
  },
  {
    'houseId': '10137',
    'url': 'https://m2kodit.fi/asunnot/pakkala/keikarinkuja-4/'
  },
  {
    'houseId': '11016',
    'url': 'https://m2kodit.fi/asunnot/myyrmaki/kilterinaukio-1/'
  },
  {
    'houseId': '11004',
    'url': 'https://m2kodit.fi/asunnot/myyrmaki/kilterinaukio-5-a-b/'
  },
  {
    'houseId': '11015',
    'url': 'https://m2kodit.fi/asunnot/myyrmaki/kilterinrinne-6/'
  },
  {
    'houseId': '11013',
    'url': 'https://m2kodit.fi/asunnot/myyrmaki/kilterinrinne-8-a/'
  },
  {
    'houseId': '11014',
    'url': 'https://m2kodit.fi/asunnot/myyrmaki/kilterinrinne-8-b/'
  },
  {
    'houseId': '10201',
    'url': 'https://m2kodit.fi/asunnot/martinlaakso/laajaniitynkuja-5-r-s-ja-t/'
  },
  {
    'houseId': '10011',
    'url': 'https://m2kodit.fi/asunnot/metsola/metsolantie-13/'
  },
  {
    'houseId': '10194',
    'url': 'https://m2kodit.fi/asunnot/leinela/puunhaltijankuja-1-ja-3/'
  },
  {
    'houseId': '10001',
    'url': 'https://m2kodit.fi/asunnot/martinlaakso/raappavuorenrinne-2/'
  },
  {
    'houseId': '10141',
    'url': 'https://m2kodit.fi/asunnot/pakkala/ruotsinpiha-2/'
  },
  {
    'houseId': '10097',
    'url': 'https://m2kodit.fi/asunnot/hameenkyla/ryytimaantie-2/'
  },
  {
    'houseId': '10053',
    'url': 'https://m2kodit.fi/asunnot/hakunila/saagatie-1-2/'
  },
  {
    'houseId': '10094',
    'url': 'https://m2kodit.fi/asunnot/hakunila/saagatie-12/'
  },
  {
    'houseId': '10052',
    'url': 'https://m2kodit.fi/asunnot/hakunila/saagatie-5/'
  },
  {
    'houseId': '10049',
    'url': 'https://m2kodit.fi/asunnot/hakunila/saagatie-6/'
  },
  {
    'houseId': '10082',
    'url': 'https://m2kodit.fi/asunnot/simonkyla/sorakuja-5/'
  },
  {
    'houseId': '10138',
    'url': 'https://m2kodit.fi/asunnot/hakunila/suitsikuja-6/'
  },
  {
    'houseId': '10172',
    'url': 'https://m2kodit.fi/asunnot/tammisto/tammistonkatu-11/'
  },
  {
    'houseId': '10178',
    'url': 'https://m2kodit.fi/asunnot/tammisto/tammistonkatu-21/'
  },
  {
    'houseId': '10168',
    'url': 'https://m2kodit.fi/asunnot/tammisto/tammistonkatu-31/'
  },
  {
    'houseId': '10064',
    'url': 'https://m2kodit.fi/asunnot/ilola/tanssijantie-1/'
  },
  {
    'houseId': '10063',
    'url': 'https://m2kodit.fi/asunnot/jokiniemi/valtuustokatu-4/'
  },
  {
    'houseId': '10129',
    'url': 'https://m2kodit.fi/asunnot/asola/viidakkopolku-8/'
  },
  {
    'houseId': '10165',
    'url': 'https://m2kodit.fi/asunnot/jokiniemi/viljo-sohkasen-katu-4/'
  },
  {
    'houseId': '10203',
    'url': 'https://m2kodit.fi/asunnot/keijarvi/niemitie-7/'
  }
];
