// React
import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

// Context
import { useApp } from '../context/appContext';
import { loadHousing, getTenantModules, getTenantPin, LOAD_STATES } from '../context/actions';

// Components
import PageContainer from './PageContainer';
import HousingContact from '../components/housing/HousingContact';
import Loader from '../components/basic/Loader';
import PaddedContent from '../components/housing/PaddedContent';
import { DeviceSize } from '../components/basic/DeviceSize';
import { BasicParagraph } from '../components/basic/Text';
import Button from '../components/button/Button';
import WordPressPage from '../components/wordpress/wordpress';

const HousingBookingsCalendar = ({history}: RouteComponentProps) => {
  const b = useMessageGetter('bookings.calendar')
  const t = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{housing, modules, tenantPin}, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
    getTenantModules(dispatch);
    getTenantPin(dispatch);
  }, [dispatch]);

  // Load scripts to page
  useEffect(() => {
    const script = document.createElement('script');
    const jQueryScript = document.createElement('script');

    // Load PNTCalendar script
    script.src = 'https://js.pnt.fi/modules/calendar/calendar.js';
    script.async = true;
    document.head.appendChild(script);

    // Load jQuery script required by PNTCalendar 
    jQueryScript.src = 'https://code.jquery.com/jquery-3.6.3.min.js';
    jQueryScript.integrity = 'sha256-pvPw+upLPUjgMXY0G+8O0xUf+/Im1MZjXxxgOcBQBXU=';
    jQueryScript.crossOrigin = 'anonymous';
    jQueryScript.async = true;
    document.head.appendChild(jQueryScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(jQueryScript);
    }
  }, []);

  useEffect(() => {
    // Skip creating calendar without both available
    if (!modules.token || !modules.modules?.length) {
      return;
    }
    
    try {
      //@ts-ignore - Ignore error as the class is fetched from script in head
      new PNTCalendar({
        module: modules.modules[0].uuid,
        container: 'pntcalendar',
        token: modules.token,
        style: {
          background: '#ffe64b', // Changes background color
          maincolor: '#71CFCB', // Changes add booking button color and date button color
          infobuttoncolor: '#0f0f0f', // Changes info icon color
          maintextcolor: '#0f0f0f', // Changes add booking text color
        }
      });
    } catch (e) {
      // Ignore, just catch error if we try to instantiate PNTCalendar before it is available
    }
  }, [modules.token, modules.modules]);
  
  const openInstructions = () => {
    window.open('https://sites.google.com/one4all.fi/usermanuals/home');
  };

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <WordPressPage slug="reservations"/>
        {(modules.loadState === LOAD_STATES.BUSY) ?
          <Loader/> :
          <Container>
            { tenantPin.pin &&
              <PinContainer>
                <BasicParagraph>{b('pincode')} <b>{tenantPin.pin}</b></BasicParagraph>
              </PinContainer>
            }
            { modules.modules && modules.modules.length ?
              <CalendarContainer>
                <div id="pntcalendar"></div>
                <UserManualButton onClick={openInstructions}>
                  {b('userManual')}
                </UserManualButton>
              </CalendarContainer> :
              <BasicParagraph>{b('noexist')}</BasicParagraph>
            }
          </Container>
        }
      </PaddedContent>
      {housing && <HousingContact t={t} housingContacts={housing} c={c}/>}
    </PageContainer>
  );
}

const Container = styled.div`
  margin: 40px 0 0 0;
`;

const PinContainer = styled.div`
  background-color: ${props => props.theme.colors.lemon};
  border-radius: 24px;
  padding: 10px 20px;
  margin-bottom: 20px;

  @media ${DeviceSize.desktopS} {
    padding: 5px 20px;
  }
`;

const CalendarContainer = styled.div`
  #pntcalendar {
    max-width: unset;
    border-radius: 24px;
    div:not(.fa), p {
      font-size: 16px;
      font-family: ${props => props.theme.text.primaryFont}, 'maven_proregular';
    }
  }
`;

const UserManualButton = styled(Button)`
  margin: 20px auto;

  @media ${DeviceSize.desktopS} {
    margin: 40px auto;
  }
`;



export default HousingBookingsCalendar;
