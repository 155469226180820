import React, { Fragment } from 'react';
import { ColumnContainer, RowContainer, MarginContainer } from '../basic/Flex';
import styled from 'styled-components';
import { H2, H3, BasicText } from '../basic/Text';
import PaddedContent from './PaddedContent';
import { DeviceSize } from '../basic/DeviceSize';
import { Contacts } from '../../context/appContext';

type HousingContactProps = {
  t: (...args: any[]) => any;
  c: (...args: any[]) => any;
  housingContacts: Contacts;
  expiringContract?: boolean;
  className?: string;
};

const HousingContact: React.FunctionComponent<HousingContactProps> = (
  {
    t,
    c,
    housingContacts,
    expiringContract = false,
    className 
  }
) => {
  const Contacts = () => {
    return (
      <ContactWrap>
        <TitleMobile>{t('contacts.title')}</TitleMobile>
        {!expiringContract && <Contact>
          <SectionHeader>{t('contacts.maintenance')}</SectionHeader>
          <InfoContent>
            <Item>
              <BasicText>{housingContacts.maintenance.companyName}</BasicText>
            </Item>
            <Item>
              {housingContacts.maintenance.companyPhone && (
                <a href={`tel: ${housingContacts.maintenance.companyPhone}`} aria-labelledby="maintenance">{`${t(
                  'contacts.customerservice'
                )}: ${t('contacts.phonenumber')} ${housingContacts.maintenance.companyPhone}`}</a>
              )}
              {housingContacts.maintenance.emergencyPhone && (
                <a href={`tel: ${housingContacts.maintenance.emergencyPhone}`} aria-labelledby="maintenance">{`${t(
                  'contacts.emergency'
                )}: ${t('contacts.phonenumber')} ${housingContacts.maintenance.emergencyPhone}`}</a>
              )}
            </Item>
          </InfoContent>
        </Contact> }
        <Contact>
          <SectionHeader id="customerservice">{t('contacts.customerservice')}</SectionHeader>
          <InfoContent>
            <Item>
              <BasicText>{t('contacts.customerservicename')}</BasicText>
            </Item>
            <Item>
              <BasicText>{c('address')}</BasicText>
              <a href={`mailto: ${c('email')}`}
                aria-labelledby="customerservice"
                target='_blank'
                rel='noopener noreferrer'
              >
                {c('email')}
              </a>
              <a href={`tel: ${c('phone')}`}
                aria-labelledby="customerservice"
              >
                {`${t('contacts.customerservice')}: ${t('contacts.phonenumber')} 
                ${c('phone')}`}
              </a>
            </Item>
          </InfoContent>
        </Contact>
        {!expiringContract && <Contact>
          <SectionHeader id="manager">{t('contacts.manager')}</SectionHeader>
          <InfoContent>
            <Item>
              <BasicTextLargeScreen>{t('contacts.descriptionLeft')}</BasicTextLargeScreen>
              <BasicTextSmallScreen>{t('contacts.descriptionAbove')}</BasicTextSmallScreen>
            </Item>
          </InfoContent>
        </Contact>}
      </ContactWrap>
    );
  };
  return (
    <Fragment>
      {housingContacts && (
        <Padding paddingTop='40px' className={className} id="maintenance">
          <Title>{t('contacts.title')}</Title>
          <DesktopContainer>
            <Contacts />
          </DesktopContainer>
        </Padding>
      )}
    </Fragment>
  );
};

const ContactWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  @media ${DeviceSize.desktopM} {
    background: ${props => props.theme.colors.apila};
    border-radius: 24px;
    padding: 24px;
  }
`;

const Contact = styled.div`
  background: ${props => props.theme.colors.apila};
  border-radius: 24px;
  padding: 24px;
  width: 32%;
  margin: 1rem 0;

  @media ${DeviceSize.desktopM} {
    width: 100%;
    margin: 0;
    padding: 10px 0;
    border-radius: 0;
  }
`;

const SectionHeader = styled(H3)`
  color: ${(props) => props.theme.colors.black};
  font-size: 32px;
  margin-bottom: 24px;

  @media ${DeviceSize.desktopM} {
    font-size: 18px;
    margin-bottom: 0;
  }

  @media ${DeviceSize.desktopS} {
    font-size: 18px;
    margin-bottom: 0;
  }
`;

const Title = styled(H2)`
  @media ${DeviceSize.desktopM} {
    display: none;
  }
`;

const TitleMobile = styled(H2)`
  display: none;
  margin-bottom: 10px;
  
  @media ${DeviceSize.desktopM} {
    font-size: 28px;
    display: block;
  }

  @media ${DeviceSize.mobileL} {
    font-size: 24px;
  }
`;

const InfoContent = styled(ColumnContainer)`
  margin-top: 9px;
  margin-bottom: 9px;
  align-items: left;
  flex-direction: column;

  > div:first-child {
    margin-right: 9px;
  }

  @media ${DeviceSize.desktopS} {


    > div:first-child {
      margin-right: 0;
      width: 100%;
    }
  }
`;

const Item = styled(RowContainer)`
  flex: 1;
  justify-content: center;
  font-weight: 400;

  > p {
    margin: 0;
  }

  > a {
    color: ${(props) => props.theme.colors.black};
    letter-spacing: 0.8px;
  }

  > a:hover {
    text-decoration: underline;
  }

  @media ${DeviceSize.desktopS} {
    :nth-child(2n) {
      width: 100%;
    }
  }
`;

const DesktopContainer = styled(MarginContainer)`
  margin-top: 0;
`;

const BasicTextLargeScreen = styled(BasicText)`
  display: block;
  @media ${DeviceSize.desktopM} {
    display: none;
  }
`;

const BasicTextSmallScreen = styled(BasicText)`
  display: none;
  @media ${DeviceSize.desktopM} {
    display: block;
  }
`;
const Padding = styled(PaddedContent)`
  padding-bottom: 0;

  @media ${DeviceSize.desktopS} {
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;
export { HousingContact, Contact }
export default HousingContact

