import React, { FunctionComponent, ReactNode, Fragment } from 'react';
import { MessageGetter } from 'react-message-context';
import styled from 'styled-components';
import { DeviceSize } from '../basic/DeviceSize';
import pageElement from '../basic/PageElement';
import { BasicParagraph, H2 } from '../basic/Text';
import { ColumnContainer } from '../basic/Flex';
import { useApp } from '../../context/appContext';
import { ACTIONS, LOAD_STATES } from '../../context/actions';
import { formatEuros } from './BillingPeriod';
import { blackCross } from '../../assets';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

export type WarningPanelProps = {
  t: MessageGetter;
  children: ReactNode;
  className?: string;
};

export const WarningPanel: FunctionComponent<WarningPanelProps> = ({ t, children, className }) => {
  const [state, dispatch] = useApp();
  const debts = state.debts;

  const closeWarning = () => {
    dispatch([ACTIONS.HIDE_DEBT_WARNING, true]);

    ReactGA.event({
      category: 'Billing',
      action: 'Close the warning banner',
      label: 'Warning banner closed',
    });
  };

  let msg: string;

  if (debts.inDistraint) {
    msg = 'distraint';
  } else if (debts.inProceedings) {
    msg = 'proceedings';
  } else {
    msg = 'conditional';
  }

  return (
    <div
      className={className}
      style={{
        display:
          debts.loadState === LOAD_STATES.BUSY || !debts.amountEuros || debts.amountEuros < 0 || debts.hideWarning
            ? 'none'
            : 'block',
      }}
    >



      <PanelContainer>
        <WrapContainer>
          <WarningText>
            {t('debt')}
            <br />
            <WarningSum>{debts.inDistraint ? <Fragment /> : formatEuros(debts.amountEuros || 0)}</WarningSum>
            <BasicParagraph>{t(msg)}</BasicParagraph>
            {sessionStorage.getItem('currentLanguage') === 'fi' && <UurasText>
              {t('uuras.text')}{' '}
              <InlineLink to='/toita/toita-asukkaille'>{t('uuras.linktext')}</InlineLink>
            </UurasText>}
          </WarningText>
          <div>
            <CloseButton onClick={closeWarning}>
              {t('close')}
              <CloseImg src={blackCross} alt={t('close')}/>
            </CloseButton>
            {children}
          </div>
        </WrapContainer>
      </PanelContainer>
    </div>
  );
};

const PanelContainer = styled.div`
  ${pageElement}
`;

const CloseButton = styled.button`
  display: flex; 
  align-items: center;
  background: none;
  border: none;
  font-size: 16px;
  width: 100%;
  justify-content: flex-end;

  @media ${DeviceSize.mobileL} {
    width: auto;
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

const CloseImg = styled.img`
  width: 14px;
  height: 14px;
  margin-left: .5rem;
`;


const WrapContainer = styled(ColumnContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${DeviceSize.mobileL} {
    flex-direction: column;
  }
`;

const WarningText = styled.div`
  font-size: 24px;
  letter-spacing: 1.05px;
  font-weight: bold;
  margin-top: 48px;
  margin-bottom: 32px;
  
  @media ${DeviceSize.mobileL} {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const WarningSum = styled(H2)`
  font-size: 28px;
  font-family: ${props => props.theme.text.secondaryFont};
  letter-spacing: 1.8px;
  padding-top: 2rem;
  display: block;
  margin-bottom: -1rem;
  font-weight: 400;

  @media ${DeviceSize.mobileL} {
    padding-top: 20px;
    margin-bottom: 5px;
    font-size: 20px;
  }
`;

const UurasText = styled(BasicParagraph)`
  font-size: 18px;
  letter-spacing: 1.05px;
  font-weight: bold;
  margin: 0;

  @media ${DeviceSize.mobileL} {
    font-size: 16px;
  }
`;

const InlineLink = styled(Link)`
    color: inherit;
    font-family: inherit;
    text-decoration: underline;
    line-height: 1.5;
    letter-spacing: 0.8px;
`;
