import React, {useEffect, useState, Fragment} from 'react';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components';
import dayjs from 'dayjs';
import PageContainer from './PageContainer';
import { RouteComponentProps } from 'react-router-dom';
import { BasicParagraph, H1, H3 } from '../components/basic/Text';
import PaddedContent from '../components/housing/PaddedContent';
import QuickLinksHome from '../components/quicklinks/QuickLinksHome';
import { HousingContact } from '../components/housing/HousingContact';
import NewMessage from '../components/contact/NewMessage';
import MessageModal from '../components/basic/MessageModal';
import { useApp } from '../context/appContext';
import {
  loadHousingCoop,
  loadHousing,
  loadDebts,
  getAgreement,
  loadApartment,
  getDocumentsList
} from '../context/actions';
import Newsletter from '../components/newsletter/Newsletter';
import DocumentList from '../components/housing/DocumentList';
import Loader from '../components/basic/Loader';
import FaultFormBulletin from '../components/bulletin/FaultFormBulletin';
import { MoveInAgreements } from '../constants/moveInAgreements';
import { hasExpiringAgreement } from '../utils';
import WordPressPage from '../components/wordpress/wordpress';
import BookingsList from '../components/booking/BookingsList';
import M2News from '../components/wordpress/M2News';

const HomePage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('');
  const c = useMessageGetter('footer.housing.customerservice');
  const msgHousing = useMessageGetter('housing');
  const [state, dispatch] = useApp();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showFaultForm, setShowFaultForm] = useState(false);

  useEffect(() => {
    loadApartment(dispatch);
    getAgreement(dispatch);
    loadHousingCoop(dispatch);
    loadDebts(dispatch, state);
    getDocumentsList(dispatch);
    loadHousing(dispatch);
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.location.hash === '#newmessage') {
      const element = document.getElementById('newmessage');
      element && element.scrollIntoView(true);
      toggleModal(true);
    }
    if (window.location.hash === '#maintenance') {
      const element = document.getElementById('maintenance');
      element && element.scrollIntoView(true);
      // TODO: Open contact menu automatically on mobile
      // TODO: This solution is also rather janky overall. Pls fix.
    }
    if (window.location.hash === '#news') {
      const element = document.getElementById('news');
      element && element.scrollIntoView(true);
    }
    checkFaultFormVisibility();
  });
  
  const checkFaultFormVisibility = () => {
    const today = dayjs();
    const uudisAgreement = MoveInAgreements.find(agreement =>
      agreement.housingId === state.housingInfo.housingId?.toString());
    
    if (uudisAgreement) {
      const agreementLessThanTwoWeeksOld = today.isBetween(
        dayjs(uudisAgreement.moveInDate),
        dayjs(state.agreement.startDate).add(2, 'weeks')
      );
      
      setShowFaultForm(agreementLessThanTwoWeeksOld);
    }
  };

  const toggleModal = (state: boolean) => {
    window.location.hash = '';
    setShowMessageModal(state);

    if(state) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }

  
  const fetchResidentPricing = (manuals: any) => {
    const residentPricing = manuals
      .filter((manual: any) => manual.folder === '1.6.1 Oma talo')
      .map((folder: any, i: number) => {
        return folder.items.filter((item: any) => item.name === 'Asukaslaskutushinnasto_M2_2022');
      });
    if (residentPricing) return residentPricing[0];
    else return null;
  };

  return (
    <Fragment>
      { hasExpiringAgreement(state.user) ? (
        <PageContainer history={history}>
          <PaddedContent>
            <WordPressPage slug='etusivu-poismuuttava' />
            { state.documents.manuals &&
              <ResidentPricingContainer>
                <DocumentList
                  t={msgHousing}
                  header={t('home.residentpricelist')}
                  type='residentpricelist'
                  limit={6}
                  columns={3}
                  prefix='• '
                  contents={fetchResidentPricing(state.documents.manuals)}
                />
              </ResidentPricingContainer>
            }
            { state.housing && (
              <HousingContact housingContacts={state.housing} t={msgHousing} c={c} expiringContract={true} />
            )}
            <Newsletter
              givenName={state.user.attributes.given_name}
              lastName={state.user.attributes.family_name}
              email={state.user.attributes.email}
            />
          </PaddedContent>
        </PageContainer>
      ) : (
        <PageContainer history={history}>
          <PaddedContent paddingBottom='0px' mobilePaddingBottom='0px'>
            <Header>{t('home.title')}</Header>
          </PaddedContent>
          { showFaultForm &&
            <FaultFormBulletinContainer>
              <FaultFormBulletin/>
            </FaultFormBulletinContainer> }
          { state.apartment.info.address && 
            <PaddedContent paddingTop='0px' mobilePaddingBottom='10px' mobilePaddingTop='10px'>
              <AddressContainer>
                <AddressTitle>{t('home.address.title')}</AddressTitle>
                <AddressText>
                  {state.apartment.info.address},
                </AddressText>
                <AddressText>
                  {state.apartment.info.zip} {state.apartment.info.city}
                </AddressText>
              </AddressContainer>
            </PaddedContent>
          }
          { state.modules.modules && state.modules.modules.length > 0 && <BookingsList /> }
          <DocumentListContainer id="news">
            { state.documents && state.housingInfo ? (
              <DocumentList
                t={msgHousing}
                header={`${msgHousing('latestnews.title')}`}
                type={'briefings'}
                contents={state.documents.briefings}
                limit={3}
              />
            ) : (
              <Loader />
            )}
          </DocumentListContainer>
          <DocumentListContainer>
            <M2News /> 
          </DocumentListContainer>
          <QuickLinksHome />
          <Newsletter
            givenName={state.user.attributes.given_name}
            lastName={state.user.attributes.family_name}
            email={state.user.attributes.email}
          />
          { state.housing && (
            <HousingContact housingContacts={state.housing} t={msgHousing} c={c} />
          )}
          { showMessageModal && (
            <MessageModal openModal={(s) => toggleModal(s)} modalShown={showMessageModal}>
              <NewMessage toggleModal={(s) => toggleModal(s)} />
            </MessageModal>
          ) }
        </PageContainer>
      )}
    </Fragment>
  );
};

const AddressContainer = styled.div`
`;

const AddressTitle = styled(H3)`
  font-size: 20px;
  margin-bottom: 5px;
`;

const AddressText = styled(BasicParagraph)`
  font-size: 16px;
  margin: 0;
`;

const Header = styled(H1)`
  margin-bottom: 20px;
`

const FaultFormBulletinContainer = styled(PaddedContent)`
  padding-top: 20px;
`;

const ResidentPricingContainer = styled.div`
  white-space: nowrap;
  border-radius: 24px;
  padding: 48px;
  padding-bottom: 24px;
  margin: 36px 0;
  background: ${props => props.theme.colors.lemon};
`;

const DocumentListContainer = styled(PaddedContent)`
  padding-top: 20px;
`;

export default HomePage;
