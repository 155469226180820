import React, { useState, useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import axios from 'axios';
import styled from 'styled-components';
import './wordpress.scss';
import Loader from '../basic/Loader';
import { getWpLanguageSuffix } from '../../utils';
import { H1 } from '../basic/Text';
import { Interweave, Node } from 'interweave';
import { WPAccordion, BackgroundBox } from './WPComponents';
import { EmergencyPlanItem } from './../housing/EmergencyPlanItem';

export interface WPPageProps {
  slug?: string;
  postType?: string;
  data?: Record<string, any>;
}

const WordPressPage = ({ slug, postType = 'pages', data }: WPPageProps) => {
  const t = useMessageGetter('wordpress');
  const [ post, setPost ] = useState('');
  const [ title, setTitle ] = useState('');
  const [ isLoading, setIsLoading ] = useState(true);

  // Transforms input tags into some other format, such as a React Component.
  // If there are no matches, the element will be returned as-is.
  const wpContentTransformer = (node: HTMLElement, children: Node[]) => {
    if (node.classList.contains('wp-block-pb-accordion-item')) {
      const title = node.firstChild?.textContent || '';
      const content = children.slice(1)
      return <WPAccordion title={title}>{content}</WPAccordion>
    }

    if (node.classList.contains('wp-block-button')) { // Built-in button(s) element
      if (node.classList.contains('emergency-plan-link')) { // Emergency plan link button
        if(!data?.emergencyPlanUrl) return <Loader />
        return (
          <EmergencyPlanItem 
            url={data?.emergencyPlanUrl} 
          />
        );
      }
    }

    if (node.classList.contains('bg-lemon')) {
      return (
        <BackgroundBox>{children}</BackgroundBox>
      );
    }
    
    // Add more rules here
    /* e.g.
     * if (node.tagName === 'A') {
     *   return <Link href={node.getAttribute('href')>{children}</Link>
     * }
     */
  }

  useEffect(() => {
    setIsLoading(true);
    // Ignore fetch and keep loading when we don't have a slug
    if (!slug) {
      return;
    }
    // Fetch page and if it is not found after 30 seconds throw an error page
    axios.get(
      `${process.env.REACT_APP_WORDPRESS_REST_URL}/${postType}?slug=${slug}${getWpLanguageSuffix()}`,
      {timeout: 30000}
    )
      .then(res => {
        setPost(res.data[0].content.rendered)
        setTitle(res.data[0].title.rendered)
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setTitle(t('errorpage.title'));
        setPost(`<p>${t('errorpage.post')}</p>`);
        setIsLoading(false);
      })
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrap className={'wp_content'}>
      { title && post && !isLoading ? (
        <div>
          <H1>{title!}</H1>
          <BodyWrapper>
            <Interweave
              transform={wpContentTransformer}
              content={post} 
            />
          </BodyWrapper>
        </div>
      ) : (
        <Loader />
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
`;

const BodyWrapper = styled.div`
  margin-top: 40px;
`;

export default WordPressPage;
