import React, { useEffect, useState, Fragment } from 'react';
import { useMessageGetter } from 'react-message-context';
import { search } from '../apis';
import Search from '../components/search/Search';
import { RouteComponentProps, useParams } from 'react-router';
import Loader from '../components/basic/Loader';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import { BasicParagraph, H1, H3 } from '../components/basic/Text';
import styled from 'styled-components';
import { extLinkIcon } from '../assets';
import { NavLink } from 'react-router-dom';
import { DeviceSize } from '../components/basic/DeviceSize';
import RedirectModal from '../components/basic/RedirectModal';
import { activeElements, removeAttributes, setAttributes } from '../utils';
import { searchPages } from '../constants/searchPages';

const SearchPage = ({ history }: RouteComponentProps) => {
  const { query: q } = useParams();
  const [results, setResults] = useState<{ url: string; title: string; description: string }[] | null>(null);
  const [isBusy, setIsBusy] = useState(false);
  const t = useMessageGetter('search');
  const modalText = useMessageGetter('modal');
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('');
  const backgroundElements = document.querySelectorAll(activeElements);

  // TODO: Improve the logic of the search feature as a whole
  const searchFromNavi = (query: string) => {
    let results: any[] = [];
    const queryList = query.toLowerCase().split(' ');

    queryList.forEach((word) => {
      searchPages
        .filter((i) => (i.keywords).toLowerCase().indexOf(word) !== -1)
        .map((i) =>
          results.push({
            url: i.route,
            title: t(`${i.name}.title`) as string,
            description: t(`${i.name}.description`) as string,
          })
        );
    });

    // Filter out duplicate objects
    results = results.filter((item, index, object) =>
      object.findIndex((item2) => (JSON.stringify(item2) === JSON.stringify(item))) === index)

    return results;
  };

  const startSearch = (query: string) => {
    setResults(null);
    history.push(`/haku/${encodeURIComponent(query)}`);
  };

  const executeSearch = async () => {
    if (q && q.length > 2 && !isBusy && !results) {
      setIsBusy(true);
      const newResults = searchFromNavi(q);
      setResults(newResults);
      try {
        const wpResults = await search(q);
        setResults(newResults.concat(wpResults));
      } finally {
        setIsBusy(false);
      }
    }
  };

  useEffect(() => {
    executeSearch();
  });

  const cancelRedirection = () => {
    document.body.style.overflow = 'auto';
    setShowModal(false);

    // activating inputs, buttons in the background etc. when modal is hidden
    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'false' });
    });
  };

  const continueToPage = (url: string) => {
    document.body.style.overflow = 'auto';

    window.open(url, '_blank');
    setShowModal(false);

    // activating inputs, buttons in the background etc. when modal is hidden
    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'false' });
    });
  };

  const openRedirectModal = (url: string) => {
    document.body.style.overflow = 'hidden';

    // activating inputs, buttons in the background etc. when modal is hidden
    backgroundElements.forEach((element) => {
      setAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
    });

    setShowModal(true);
    setUrl(url);
  };

  // redirects straight to the page without opening new window/tab
  const openLink = (url: string) => {
    setUrl(url);
    history.push(url);
  };

  // opens new tab/window
  const openNewTab = (url: string) => {
    setUrl(url);
    window.open(url, '_blank');
  };

  return (
    <PageContainer history={history}>
      {showModal && (
        <RedirectModal
          cancelRedirect={cancelRedirection}
          continueRedirect={() => continueToPage(url)}
          modalShown={showModal}
        >
          {modalText('redirect')}
        </RedirectModal>
      )}
      <Content>
        <NavLink to="/">
          <H1>{t('back')}</H1>
        </NavLink>
        <SearchBoxMargin>
          <Search initialValue={q} onSearch={(q) => startSearch(q)} isSearchPage={true} />
        </SearchBoxMargin>

        <BasicParagraph>
          {!isBusy && t('resultsummary', { query: q, resultCount: results ? results.length : 0 })}
        </BasicParagraph>
        <SearchResultsContainer>
          {results &&
            results.map((r, i) => (
              <Fragment key={i}>
                {r.url.startsWith('http') ? (
                  <SearchResultContainer
                    id="searchresult"
                    key={i}
                    onClick={() => openRedirectModal(r.url)}
                    onKeyPress={() => openNewTab(r.url)}
                    tabIndex={0}
                  >
                    <ResultTitle>{r.title}</ResultTitle>
                    {r.description && r.description !== '' && <ResultBody>{r.description}</ResultBody>}
                    <ExtLinkIcon src={extLinkIcon} alt="Redirect to new page" />
                  </SearchResultContainer>
                ) : (
                  <SearchResultContainer
                    id="searchresult"
                    key={i}
                    onClick={() => openLink(r.url)}
                    onKeyPress={() => openLink(r.url)}
                    tabIndex={0}
                  >
                    <ResultTitle>{r.title}</ResultTitle>
                    {r.description && r.description !== '' && <ResultBody>{r.description}</ResultBody>}
                  </SearchResultContainer>
                )}
              </Fragment>
            ))}
          {isBusy && <Loader />}
        </SearchResultsContainer>
      </Content>
    </PageContainer>
  );
};

const Content = styled(PaddedContent)`
  display: flex;
  flex-direction: column;

  > a:hover {
    text-decoration: underline ${(props) => props.theme.colors.coal};
  }

  > a:active {
    text-decoration: underline ${(props) => props.theme.colors.coal};
  }
`;

const SearchBoxMargin = styled.div`
  margin: 70px 0 50px 0;
  display: flex;
  position: relative;
  @media ${DeviceSize.mobileL} {
    margin: 0;
  }
`;

const ResultTitle = styled(H3)`
  color: ${(props) => props.theme.colors.black};
  font-size: 32px;
  margin-bottom: 24px;
`;

const ResultBody = styled(BasicParagraph)`
  margin: 0;
`;

const SearchResultsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SearchResultContainer = styled.div`
  background-color: ${(props) => props.theme.colors.apila};
  display: flex;
  flex-direction: column;
  width: 32%;
  padding: 24px;
  margin: 10px 0;
  border-radius: 24px;
  transition: 0.2s;

  :hover {
    cursor: pointer;
    transform: scaleX(1.02) scaleY(1.02) translate(0, -0.25rem);
    box-shadow: 0 25px 50px -12px rgba(0,0,0,0.25);
  }
  
  @media ${DeviceSize.desktopM} {
    width: 100%;
  }
`;

const ExtLinkIcon = styled.img`
  flex-grow: 0;
`;

export default SearchPage;
