import React, { useState, useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import axios from 'axios';
import Loader from '../basic/Loader';
import DocumentList from '../housing/DocumentList';

const WordPressPage = () => {
  const t = useMessageGetter('housing');
  const [ posts, setPosts ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const m2koditWpUrl = 'https://m2kodit.fi/wp-json/wp/v2';
  
  const htmlDecode = (title: string) =>
    (new DOMParser().parseFromString(title, 'text/html').documentElement.textContent);


  useEffect(() => {
    setIsLoading(true);
    axios.get(
      `${m2koditWpUrl}/posts?per_page=3`, // limit = 3 posts
      {timeout: 30000}
    )
      .then(res => {
        setPosts(
          res.data.map((post: any) => ({
            date: post.date,
            title: htmlDecode(post.title.rendered),
            url: post.link
          }))
        );

        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    isLoading ? (
      <Loader />
    ) : (
      <DocumentList 
        t={t} 
        header={t('externalnews.title')}
        type='external'
        limit={3}
        columns={1}
        contents={posts}
      />
    )
  )
}

export default WordPressPage;
