import styled from 'styled-components';

export const ColumnContainer = styled.div < { spread? : boolean, width? : string, padding? : string } > `
  display: flex;
  flex-direction: row;
  justify-content: ${ props => props.spread ? 'space-between' : 'initial' };
  width: ${ props => props.width ? props.width : 'initial' } };
  padding: ${ props => props.padding ? props.padding : 'initial' } };
`;

export const RowContainer = styled.div < { width? : string, padding? : string } > `
  display: flex;
  flex-direction: column;
  width: ${ props => props.width ? props.width : 'initial' } };
  padding: ${ props => props.padding ? props.padding : 'initial' } };
`;

export const MarginContainer = styled.div < { marginTop? : string, direction? : string } > `
  display: flex;
  margin-top: ${props => props.marginTop ? props.marginTop : '30px'};
  flex-wrap: wrap;
  flex-direction: ${props => props.direction ? 'row' : 'column'};
`;