import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { H2, SemiboldParagraph, BasicParagraph } from './Text';
import { useApp } from '../../context/appContext';
import { utils } from 'isomorphic';
import { DeviceSize } from './DeviceSize';
import { formatDate, formatMoney } from '../../utils';

const AgreementInfo = () => {
  const t = useMessageGetter('agreement');
  const [state] = useApp();
  const apartment = state.apartment;
  const agreement = state.agreement;

  return (
    <RentContainer>
      <TenantInfo>
        <SecondaryHeader>{t('tenants.title2')}</SecondaryHeader>
        {agreement.parties &&
          agreement.parties.contractParties.length > 0 &&
          agreement.parties.contractParties.map(
            (p, index) =>
              `${p.id}` === state.user.attributes.preferred_username && (
                <SemiboldParagraph key={index}>
                  {p.name} ({p.socialNumber})
                </SemiboldParagraph>
              )
          )}
        <SemiboldParagraph>
          {apartment.info.address}, {apartment.info.zip} {apartment.info.city}
        </SemiboldParagraph>
      </TenantInfo>
      <ApartmentRentInfo>
        <div>
          <SecondaryHeader>{t('apartment.title')}</SecondaryHeader>
          <BasicParagraph>
            {t('apartment.type')}: <b>{apartment.info.rooms}</b>
          </BasicParagraph>
          <BasicParagraph>
            {t('apartment.sqfoot')}: <b>{apartment.info.squares}m2</b>
          </BasicParagraph>
          <BasicParagraph>
            {t('apartment.houseowner')}: <b>{agreement.houseowner ? agreement.houseowner.name : '-'}</b>
          </BasicParagraph>
        </div>
        <div>
          <SecondaryHeader>{t('lease.title2')}</SecondaryHeader>
          {agreement.lease && (
            <BasicParagraph>
              {t('lease.price')}:{' '}
              <b>
                {formatMoney(agreement.lease)}
                {t('permonth')}
              </b>
            </BasicParagraph>
          )}
          {agreement.waterPrePayment && (
            <BasicParagraph>
              {t('lease.waterPrePayment')}:{' '}
              <b>
                {formatMoney(agreement.waterPrePayment)}
                {t('personmonth')}
              </b>
            </BasicParagraph>
          )}
          {agreement.waterPerPerson && (
            <BasicParagraph>
              {t('lease.waterPerPerson')}:{' '}
              <b>
                {formatMoney(agreement.waterPerPerson)}
                {t('personmonth')}
              </b>
            </BasicParagraph>
          )}
          {agreement.waterPerConsumption && (
            <BasicParagraph>
              {t('lease.waterPerConsumption')}:{' '}
              <b>
                {formatMoney(agreement.waterPerConsumption)}
                {t('permonth')}
              </b>
            </BasicParagraph>
          )}
          {apartment.info.waterDescription && <BasicParagraph>{apartment.info.waterDescription}</BasicParagraph>}
          <BasicParagraph>
            {t('lease.duedate')}:{' '}
            <b>
              {t('month')} {agreement.dueDate === '99' ? t('last') : `${agreement.dueDate}.`} {t('day')}
            </b>
          </BasicParagraph>
        </div>
        <div>
          <SecondaryHeader>{t('period.title')}</SecondaryHeader>
          <BasicParagraph>
            {t('period.type')}: <b>{agreement.isTemporary ? t('istemporary') : t('ongoing')}</b>
          </BasicParagraph>
          <BasicParagraph>
            {t('period.startdate')}: <b>{formatDate(agreement.startDate)}</b>
          </BasicParagraph>
          <BasicParagraph>
            {t('period.enddate')}: <b>{formatDate(agreement.endDate, '-')}</b>
          </BasicParagraph>
          {agreement.deposit &&
            agreement.deposit.length > 0 &&
            agreement.deposit.map((d, index) => (
              <Fragment key={index}>
                {utils.depositCategories.map(
                  (category: any, i: number) =>
                    category === d.depositId && (
                      <BasicParagraph key={i}>
                        {t(`deposit.paymenttype.${d.depositId}`)}: <b>{formatMoney(d.depositSum)}</b>{' '}
                        {d.refundDate !== '' &&
                          `(${t('deposit.refunded')}: ${formatDate(d.refundDate)}, ${t(
                            'deposit.refundedsum'
                          )}: ${formatMoney(Math.abs(d.refundSum))})`}
                      </BasicParagraph>
                    )
                )}
              </Fragment>
            ))}
        </div>
      </ApartmentRentInfo>
    </RentContainer>
  );
};

const RentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  height: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.m2Black};

  @media ${DeviceSize.desktopS} {
    width: 100%;
  }
`;

const TenantInfo = styled.div`
  flex: 1 1 0;
  margin-right: 20px;

  > p {
    margin-top: 8px;
    margin-bottom: 8px;

    :last-child {
      margin-bottom: 28px;
    }
  }
`;

const ApartmentRentInfo = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
  }

  > div > p {
    margin-top: 8px;
    margin-bottom: 8px;

    :last-child {
      margin-bottom: 28px;
    }
  }
`;

const SecondaryHeader = styled(H2)`
  padding-bottom: 14px;
`;

export default AgreementInfo;
