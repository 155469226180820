import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './web.config';
import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import awsApiConfig from './awsApiConfig';
import { awsAuth } from 'isomorphic';
import GaConfig from './GaConfig';

// Globally setup dayjs
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

// Tell the compiler and IDE that REACT_APP_ENV environment variable
// must match an existing key in awsApiConfig.
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_ENV: keyof typeof awsApiConfig;
    }
  }
}

function showOutlineOnKey(e: KeyboardEvent) {
  if (e.keyCode !== 9) return;

  document.body.classList.remove('hide-focus');

  window.removeEventListener('keydown', showOutlineOnKey);
  window.addEventListener('mousedown', hideOutlineOnMouse);
}

function hideOutlineOnMouse() {
  document.body.classList.add('hide-focus');

  window.addEventListener('keydown', showOutlineOnKey);
  window.removeEventListener('mousedown', hideOutlineOnMouse);
}

document.body.classList.add('hide-focus');
window.addEventListener('keydown', showOutlineOnKey);

// Configure Amplify modules
const environment = process.env.REACT_APP_ENV;

API.configure(awsApiConfig[environment].apiGateway);
Auth.configure(awsAuth.config[environment]);
ReactDOM.render(<App />, document.getElementById('root'));

// Configure Google analytics
ReactGA.initialize(GaConfig[environment].key);
