/**
 * AWS Amplify config for local, dev, staging and production environments (based on REACT_APP_ENV)
 */
export const config = {
  local: {
    identityPoolId: 'eu-central-1:03a3b5ca-6e36-45a7-81bb-c13276494313',
    region: 'eu-central-1',
    identityPoolRegion: 'eu-central-1',
    userPoolId: 'eu-central-1_8kokuVNDP',
    userPoolWebClientId: '1e2h7ogje48uce0bra2k4hh2bg',
    mandatorySignIn: true,
  },
  dev: {
    identityPoolId: 'eu-central-1:03a3b5ca-6e36-45a7-81bb-c13276494313',
    region: 'eu-central-1',
    identityPoolRegion: 'eu-central-1',
    userPoolId: 'eu-central-1_8kokuVNDP',
    userPoolWebClientId: '1e2h7ogje48uce0bra2k4hh2bg',
    mandatorySignIn: true,
  },
  staging: {
    identityPoolId: 'eu-central-1:d5640a1d-2358-461c-97da-cfebce08c4f5',
    region: 'eu-central-1',
    identityPoolRegion: 'eu-central-1',
    userPoolId: 'eu-central-1_t2pfGtehl',
    userPoolWebClientId: '4iqtuqrg3vvgeed98jlfqe0mt8',
    mandatorySignIn: true,
  },
  production: {
    identityPoolId: 'eu-central-1:939a05d4-3da7-4e7d-8e55-5d064864dc7d',
    region: 'eu-central-1',
    identityPoolRegion: 'eu-central-1',
    userPoolId: 'eu-central-1_Zq11CFJAP',
    userPoolWebClientId: '70626jf1hkulm7p6pn5m8jls9o',
    mandatorySignIn: true,
  },
}
