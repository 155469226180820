import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import { BasicParagraph, H1 } from '../components/basic/Text';
import { useApp } from '../context/appContext';
import HousingContact from '../components/housing/HousingContact';
import DocumentList from '../components/housing/DocumentList';
import { getDocumentsList, loadHousing } from '../context/actions';
import { DeviceSize } from '../components/basic/DeviceSize';

const HousingNews = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ housing, documents }, dispatch] = useApp();

  useEffect(() => {
    getDocumentsList(dispatch);
    loadHousing(dispatch);
  }, [dispatch]);

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <H1>{t('news.title')}</H1>
        <DocumentsContainer>
          <BasicParagraph>{t('documentsdescription')}</BasicParagraph>
          <DocumentList
            t={t}
            header={t('news.title')}
            type={'briefings'}
            contents={documents.briefings}
          />
        </DocumentsContainer>
      </PaddedContent>
      { housing && <HousingContact t={t} housingContacts={housing} c={c} /> }
    </PageContainer>
  );
};

const DocumentsContainer = styled(PaddedContent)`
  background: ${props => props.theme.colors.lemon};
  padding: 24px;
  border-radius: 24px;
  margin: 40px 0;
  
  @media ${DeviceSize.mobileL} {
    padding: 24px;
    margin: 40px 0 40px;
  }
`;
export default HousingNews;
