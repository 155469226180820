import React, {Fragment, useEffect, useState} from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import HomeLogo from '../components/header/HomeLogo';
import backgroundLogo from '../assets/mint-logo-large.svg';
import pageElement from '../components/basic/PageElement';
import PaddedContent from '../components/housing/PaddedContent';
import { DeviceSize } from '../components/basic/DeviceSize';
import HousingFooter from '../components/footer/HousingFooter';

const FeedbackPage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('feedback');
  // eslint-disable-next-line max-len
  const [surveyUrl, setSurveyUrl] = useState('https://link.webropolsurveys.com/Participation/Public/5a79af70-b480-45df-8a9c-c3af3f436c03?displayId=Fin2592026&surveyLocale=fi');
  const location = useLocation();
  const userData = location.state as CustomizedState;

  interface CustomizedState {
    agreementId: number,
    apartmentId: string,
    housingId: string,
    userId: string,
  }

  // Encode url params
  function encodeQueryData(data: any) {
    const parameters = [];
    for (let d in data) {
      // Skip adding parameter if it doesn't exist
      if (data[d]) {
        parameters.push(encodeURIComponent(d) + '%3D' + encodeURIComponent(data[d]));
      }
    }
    return parameters.join('%26');
  }

  useEffect(() => {
    const createSurveyUrl = () => {
      let prefilledQuestions = '';
      if (userData) {
        prefilledQuestions = encodeQueryData(userData);
        setSurveyUrl(surveyUrl + `&questionsAnsweredByUrlParam=${prefilledQuestions}`);
      }
    };
    createSurveyUrl();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Header>
        <HomeLogo isHomePage={false}/>
        <BackLink href="https://www.m2kodit.fi">{t('redirectToM2')}</BackLink>
        <BackgroundLogo src={backgroundLogo} />
      </Header>
      <Container>
        <FeedbackContainer>
          <IFrameContainer frameBorder='0' title='OmaM2 Survey' src={surveyUrl} />
        </FeedbackContainer>
      </Container>
      <HousingFooter history={history} />
    </Fragment>
  );
};

const Header = styled.div`
  ${pageElement}
  display: flex;
  margin: auto;
  height: ${props => props.theme.layout.headerHeight}px;
`;

const Container = styled(PaddedContent)`
  
`;

const FeedbackContainer = styled(PaddedContent)`
  min-height: calc(100vh - 532px);
  height: 100%;
  display: flex;
  flex-direction: column;

  > h1 {
    margin-bottom: 15px;
  }
`;

const BackLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: ${props => props.theme.colors.mint};
  @media ${DeviceSize.mobileL} {
    display: none;
  }
`;

const BackgroundLogo = styled.img`
  max-width: 50vw;
  width: 100%;
  width: 712px;
  top: -100px;
  left: 30px;
  z-index: -1;
  position: absolute;
  display: block;

  @media ${DeviceSize.desktopM} {
    display: none;
  }
`;

const IFrameContainer = styled.iframe`
  height: 900px;
  padding: 20px;
  border-radius: 24px;
  background: rgba(245, 245, 245, 1.00);
`;

export default FeedbackPage;
