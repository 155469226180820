import React, { FunctionComponent } from 'react';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components';
import PaddedContent from '../housing/PaddedContent';
import { DeviceSize } from '../basic/DeviceSize';
import { LinkButton } from '../button/Button';

const QuickLinksHome: FunctionComponent = () => {
  const t = useMessageGetter('linkshome');

  return (
    <QuickLinksContainer>
      <Wrapper>
        <ContactLinkButton href="https://m2kodit.fi/asukkaalle/tee-vikailmoitus" target="_blank">
          {t('urgent.linktext')}
        </ContactLinkButton>
        <ContactLinkButton href="asuntoni/maksaminen">
          {t('billing.linktext')}
        </ContactLinkButton>
        <ContactLinkButton href="/asuntoni/vaihda">
          {t('change.linktext')}
        </ContactLinkButton>
        <ContactLinkButton href="#newmessage">
          {t('contact.linktext')}
        </ContactLinkButton>
        
      </Wrapper>
    </QuickLinksContainer>
  );
}

const QuickLinksContainer = styled(PaddedContent)`
  padding-top: 20px;
  @media ${DeviceSize.desktopS} {
    padding-top: 12px;
  }
`;

const Wrapper = styled.div`
    display: flex; 
    flex-wrap: wrap;
    justify-content: space-between;
`;

const ContactLinkButton = styled(LinkButton)`
  background-color: ${(props) => props.theme.colors.lemon};
  color: ${(props) => props.theme.colors.m2Black};
  font-size: 16px;
  margin: 5px 0;
  width: 49%;
  
  @media ${DeviceSize.mobileL} {
    width: 100%;
  }
`;

export default QuickLinksHome;
