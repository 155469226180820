import React, { Fragment, useEffect, useState } from 'react';
import { useApp } from '../context/appContext';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components';
import { H1, BasicParagraphs, H2, H3, form1Style, CallOut } from '../components/basic/Text';
import Button from '../components/button/Button';
import { Input } from '../components/input/Input';
import Loader from '../components/basic/Loader';
import PageContainer from './PageContainer';
import { updateProfile, loadProfile, LOAD_STATES, showNotification } from '../context/actions';
import { RouteComponentProps } from 'react-router-dom';
import useForm from 'react-hook-form';
import { validatePhone, validateEmailRegex, validatePhoneRegex, checkPhone } from '../utils';
import PaddedContent from '../components/housing/PaddedContent';
import { DeviceSize } from '../components/basic/DeviceSize';
import ReactGA from 'react-ga';

const ProfilePage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('profile');
  const [state, dispatch] = useApp();
  const profileForm = useForm();
  const profileFormSetValue = profileForm.setValue;
  const { protectionOrder } = state.profile;
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    loadProfile(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (state.profile.loadState === LOAD_STATES.READY) {
      profileFormSetValue('phone', state.profile.phone || '');
      profileFormSetValue('email', state.profile.email || '');
    }
  }, [state.profile, profileFormSetValue]);

  const onSubmit = async () => {
    await updateProfile(dispatch, profileForm.getValues());
    ReactGA.event({
      category: 'Profile',
      action: 'Save changes',
      label: '/omat-tiedot'
    });
    await loadProfile(dispatch);
    showNotification(dispatch, t('savesuccess'));
  };

  const disableSubmit = (value: string, type: string) => {
    if (type === 'phone' && checkPhone(value) === false) return setDisableBtn(true);

    if (type === 'email' && !validateEmailRegex.test(value) && value !== '') return setDisableBtn(true);

    return setDisableBtn(false);
  };

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <H1>{t('title')}</H1>
        <H3>{t('description')}</H3>
      </PaddedContent>
      {state.profile.loadState !== LOAD_STATES.READY || state.profile.updateState === LOAD_STATES.BUSY ? (
        <Loader />
      ) : (
        <Fragment>
          <PaddedContent style={{ paddingTop: 0 }}>
            <CallOut>{t('contactinfoheader')}</CallOut>
            <Content>
              <FormContent>
                <FormPhone>
                  <Input
                    name="phone"
                    placeholder={t('phoneplaceholder')}
                    onSubmit={profileForm.handleSubmit(onSubmit)}
                    validations={{
                      required: t('phonerequired'),
                      validate: (x: string) => validatePhone(x, t('phoneinvalid')),
                      pattern: {
                        value: validatePhoneRegex,
                        message: t('phoneinvalid')
                      }
                    }}
                    form={profileForm}
                    disableSubmit={disableSubmit}
                  />
                </FormPhone>
                <FormEmail>
                  <Input
                    name="email"
                    type="email"
                    placeholder={t('emailplaceholder')}
                    onSubmit={profileForm.handleSubmit(onSubmit)}
                    validations={{
                      pattern: {
                        value: validateEmailRegex,
                        message: t('emailinvalid')
                      }
                    }}
                    form={profileForm}
                    disableSubmit={disableSubmit}
                  />
                </FormEmail>
              </FormContent>
              <SubmitButton disabled={disableBtn} onClick={profileForm.handleSubmit(onSubmit)}>
                {t('save')}
              </SubmitButton>
            </Content>
          </PaddedContent>
          {protectionOrder && (
            <PaddedContent>
              <H2>{t('protectionOrder')}</H2>
              <Content>
                <BasicParagraphs>{t('protectionOrderText')}</BasicParagraphs>
              </Content>
            </PaddedContent>
          )}
        </Fragment>
      )}
    </PageContainer>
  );
};

const Content = styled.div`
  margin: 20px 0;
  
  > div > label {
    ${form1Style}
  }
  
  @media ${DeviceSize.mobileL} {
    margin: 20px 0px;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${DeviceSize.mobileL} {
    flex-direction: column;
  }
`;

const FormPhone = styled.div`
  flex-grow: 1;
  margin-right: 20px;
  
  @media ${DeviceSize.mobileL} {
    margin-right: 0;
  }
`;

const FormEmail = styled.div`
  flex-grow: 3;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
`;

export default ProfilePage;
