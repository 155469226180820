import styled from 'styled-components';
import {DeviceSize} from './DeviceSize';

const Picture = styled.img<{flex?: string}>`
  border-radius: 13%;
  max-width: ${props => props.width ? props.width : '400px'};
  max-height: ${props => props.height ? props.height : '266.3px'};
  width: 100%;
  height: 100%;
  flex: 1;
  
  @media ${DeviceSize.desktopS} {
    max-width: 100%;
    max-height: 100%;
    flex:  ${props => props.flex ? props.flex : '1 1 100%'};
  }
`;

export default Picture;