import PageContainer from './PageContainer';
import { RouteComponentProps } from 'react-router';
import { useMessageGetter } from 'react-message-context';
import { H1, H2, H3, SemiboldParagraph, BasicParagraph } from '../components/basic/Text';
import PaddedContent from '../components/housing/PaddedContent';
import styled from 'styled-components';
import Button from '../components/button/Button';
import { whitePrintIcon } from '../assets';
import { DeviceSize } from '../components/basic/DeviceSize';
import React, { useEffect, Fragment, useState } from 'react';
import { loadApartment, getAgreement, loadDebts, loadHousing, getDocumentsList, LOAD_STATES } from '../context/actions';
import { useApp } from '../context/appContext';
import Loader from '../components/basic/Loader';
import RedirectModal from '../components/basic/RedirectModal';
import {
  activeElements,
  removeAttributes,
  setAttributes,
  formatMoney,
  formatDate,
  hasExpiringAgreement
} from '../utils';
import { utils } from 'isomorphic';
import ReactGA from 'react-ga';
import HousingContact from '../components/housing/HousingContact';

const AgreementPage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('agreement');
  const c = useMessageGetter('footer.housing.customerservice');
  const msgHousing = useMessageGetter('housing');
  const modalText = useMessageGetter('modal');
  const [{ apartment, agreement, housing, user }, dispatch] = useApp();
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('');

  const backgroundElements = document.querySelectorAll(activeElements);

  useEffect(() => {
    loadApartment(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getAgreement(dispatch);
  }, [dispatch]);

  useEffect(() => {
    loadDebts(dispatch);
  }, [dispatch]);

  useEffect(() => {
    loadHousing(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getDocumentsList(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (window.location.hash === '#terminate') {
      const element = document.getElementById('terminate');
      element && element.scrollIntoView(true);
    }
  });

  const printAgreementInfo = () => {
    ReactGA.event({
      category: 'Apartment',
      action: 'Print information',
      label: '/asuntoni',
    });

    window.print();
  };

  const cancelRedirection = () => {
    document.body.style.overflow = 'auto';
    setShowModal(false);

    // activating inputs, buttons in the background etc. when modal is hidden
    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'false' });
    });
  };

  const continueToPage = (url: string) => {
    document.body.style.overflow = 'auto';
    ReactGA.event({
      category: 'Apartment',
      action: 'Click external link',
      label: url,
    });
    window.open(url, '_blank');
    setShowModal(false);

    // activating inputs, buttons in the background etc. when modal is hidden
    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'false' });
    });
  };

  const openRedirectModal = (url: string) => {
    document.body.style.overflow = 'hidden';

    // activating inputs, buttons in the background etc. when modal is hidden
    backgroundElements.forEach((element) => {
      setAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
    });

    setShowModal(true);
    setUrl(url);
  };

  return (
    <PageContainer history={history}>
      {showModal && (
        <RedirectModal
          cancelRedirect={cancelRedirection}
          continueRedirect={() => continueToPage(url)}
          modalShown={showModal}
        >
          {modalText('redirect')}
        </RedirectModal>
      )}
      <Content>
        {agreement.loadState === LOAD_STATES.BUSY || agreement.loadState !== LOAD_STATES.READY ? (
          <Loader />
        ) : (
          <Fragment>
            <AgreementInfo>
              <MainHeader>{t('title')}</MainHeader>
              <RentContainer>
                <div>
                  <SecondaryHeader>{t('apartment.title')}</SecondaryHeader>
                  <RentBox>
                    <BasicParagraph>
                      <b>{t('apartment.address')}: </b>
                      {apartment.info.address}, {apartment.info.zip} {apartment.info.city}
                    </BasicParagraph>
                    <BasicParagraph>
                      <b>{t('apartment.type')}:</b> {apartment.info.rooms}
                    </BasicParagraph>
                    <BasicParagraph>
                      <b>{t('apartment.sqfoot')}:</b> {apartment.info.squares}m2
                    </BasicParagraph>
                    <BasicParagraph>
                      <b>{t('apartment.houseowner')}:</b> {agreement.houseowner ? agreement.houseowner.name : '-'}
                    </BasicParagraph>
                  </RentBox>
                </div>
                <div>
                  <SecondaryHeader>{t('tenants.title')}</SecondaryHeader>
                  <RentBox>
                    <Fragment>
                      <H3>{t('tenants.contract')}</H3>
                      {agreement.parties &&
                        agreement.parties.contractParties &&
                        agreement.parties.contractParties.length > 0 &&
                        agreement.parties.contractParties.map((p, index) => (
                          <SemiboldParagraph key={index}>
                            {p.name} ({p.socialNumber})
                          </SemiboldParagraph>
                        ))}
                      {agreement.parties &&
                        agreement.parties.otherParties &&
                        agreement.parties.otherParties.length > 0 &&
                        <H3>{t('tenants.other')}</H3>
                      }
                      {agreement.parties &&
                        agreement.parties.otherParties &&
                        agreement.parties.otherParties.length > 0 &&
                        agreement.parties.otherParties.map((p, index) => (
                          <SemiboldParagraph key={index}>
                            {p.name} {p.socialNumber ? (p.socialNumber) : null}
                          </SemiboldParagraph>
                        ))}
                    </Fragment>
                  </RentBox>
                </div>
                <div>
                  <SecondaryHeader>{t('lease.title')}</SecondaryHeader>
                  <RentBox>
                    {agreement.lease && (
                      <BasicParagraph>
                        <b>{t('lease.price')}:</b>{' '}
                        {formatMoney(agreement.lease)}
                        {t('permonth')}
                      </BasicParagraph>
                    )}
                    <BasicParagraph>
                      <b>{t('lease.duedate')}:</b>{' '}
                      {t('month')} {agreement.dueDate === '99' ? t('last') : `${agreement.dueDate}.`} {t('day')}
                    </BasicParagraph>
                    {agreement.waterPrePayment && (
                      <BasicParagraph>
                        <b>{t('lease.waterPrePayment')}:</b>{' '}
                        {formatMoney(agreement.waterPrePayment)}
                        {t('personmonth')}
                      </BasicParagraph>
                    )}
                    {agreement.waterPerPerson && (
                      <BasicParagraph>
                        <b>{t('lease.waterPerPerson')}:</b>{' '}
                        {formatMoney(agreement.waterPerPerson)}
                        {t('personmonth')}
                      </BasicParagraph>
                    )}
                    {agreement.waterPerConsumption && (
                      <BasicParagraph>
                        <b>{t('lease.waterPerConsumption')}:</b>{' '}
                        {formatMoney(agreement.waterPerConsumption)}
                        {t('permonth')}
                      </BasicParagraph>
                    )}
                    {apartment.info.waterDescription && (
                      <BasicParagraph>{apartment.info.waterDescription}</BasicParagraph>
                    )}
                  </RentBox>
                </div>
                <div>
                  <SecondaryHeader>{t('period.title')}</SecondaryHeader>
                  <RentBox>
                    <BasicParagraph>
                      <b>{t('period.type')}:</b> {agreement.isTemporary ? t('istemporary') : t('ongoing')}
                    </BasicParagraph>
                    <BasicParagraph>
                      <b>{t('period.startdate')}:</b> {formatDate(agreement.startDate)}
                    </BasicParagraph>
                    <BasicParagraph>
                      <b>{t('period.enddate')}:</b> {formatDate(agreement.endDate, '-')}
                    </BasicParagraph>
                  </RentBox>
                </div>
                {agreement.deposit && agreement.deposit.length > 0 && (
                  <div>
                    <SecondaryHeader>{t('deposit.title')}</SecondaryHeader>
                    <RentBox>
                      {agreement.deposit.map((d, index) => (
                        <Fragment key={index}>
                          {utils.depositCategories.map(
                            (category: any, i: number) =>
                              category === d.depositId && (
                                <BasicParagraph key={i}>
                                  <b>{t(`deposit.paymenttype.${d.depositId}`)}:</b> {formatMoney(d.depositSum)}{' '}
                                  {d.refundDate !== '' &&
                                    `(${t('deposit.refunded')}: ${formatDate(d.refundDate)}, ${t(
                                      'deposit.refundedsum'
                                    )}: ${formatMoney(Math.abs(d.refundSum))})`}
                                </BasicParagraph>
                              )
                          )}
                          <BasicParagraph>
                            {t('deposit.info')}
                          </BasicParagraph>
                        </Fragment>
                      ))}
                    </RentBox>
                  </div>
                )}
              </RentContainer>
            </AgreementInfo>
            <Buttons id="terminate">
              <TerminateAgreementButton
                onClick={() => openRedirectModal('https://portaali.tampuuri.fi/ysaatio/vuokrasopimuksenirtisanominen')}
              >
                <Label>{t('terminate')}</Label>
              </TerminateAgreementButton>
              <ActionButton onClick={printAgreementInfo}>
                <Label>
                  <span>{t('print')}</span>
                </Label>
                <img src={whitePrintIcon} alt="print agreement" />
              </ActionButton>
            </Buttons>

          </Fragment>
        )}
      </Content>

      { housing &&
        <HousingContact
          t={msgHousing}
          c={c}
          housingContacts={housing}
          expiringContract={ hasExpiringAgreement(user)}
        />
      }

    </PageContainer>
  );
};

const Content = styled(PaddedContent)`
`;

const MainHeader = styled(H1)`
  margin-bottom: 40px;
    
  @media ${DeviceSize.desktopM} {
    font-size: 24px;
  }
`;

const AgreementInfo = styled.div``;

const RentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    padding: 0 20px 20px 0;
    width: calc((100% / 2) - 5px);
    margin-bottom: 20px;

    @media ${DeviceSize.desktopM} {
      width: 100%;
      margin-bottom: 0;
    }

    @media print {
      margin: 10px;
      padding: 0;
    }
  }

  h2 {
    font-family: ${props => props.theme.text.secondaryFont};
    font-weight: 300;
    margin-bottom: 20px;
    
    @media ${DeviceSize.desktopM} {
      font-family: ${props => props.theme.text.primaryFont};
      font-weight: 700;
    }

    @media print {
      margin-bottom: 10px;
    }
  }
  
  h3 {
    @media print {
      padding-top: 10px;
    }
  }

  p {
    margin: 0 0 5px 0;
    padding: 0;
    
    @media print {
      padding: 5px 0px;
    }

`;

const RentBox = styled.div`
  @media ${DeviceSize.desktopM} {
    width: 100%;
    background: none;
    padding: 0;
    margin-bottom: 30px;
    min-height: auto;
  }

  @media print {
    min-height: 0;
    padding: 10px 0;
    border-radius: 0;
    background: none;
    border-bottom: 1px solid ${props => props.theme.colors.m2Black};
  }
`

const SecondaryHeader = styled(H2)`
  white-space: nowrap;

  @media ${DeviceSize.desktopS} {
    white-space: initial;
    padding-bottom: 0;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media print {
    display: none;
  } 
  
  @media ${DeviceSize.desktopS} {
    flex-direction: column-reverse;
  }
`;

const ActionButton = styled(Button)`
  justify-content: space-between;
  background-color: ${props => props.theme.colors.m2Black};
  border: 2px solid black;
  margin: 0;
  
  &:hover {
    background-color: ${props => props.theme.colors.coal};
    color: white;
  }
  &:first-of-type {
    margin-right: 1rem;
  }
  &:nth-of-type(2) {
    margin-left: 1rem;
  }  
  @media ${DeviceSize.desktopS} {
    width: 100%;
    margin: 20px 0 0 0 !important;
  }
`;

const TerminateAgreementButton = styled(ActionButton)`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.m2Black};
  
  @media ${DeviceSize.desktopS} {
    background-color: ${props => props.theme.colors.coal};
    color: ${props => props.theme.colors.white};
  }

  &:hover {
    background-color: ${props => props.theme.colors.coal};
    color: ${props => props.theme.colors.m2White};
    border: 2px solid ${props => props.theme.colors.coal};

    @media ${DeviceSize.desktopS} {
      background-color: ${props => props.theme.colors.m2White};
      color: ${props => props.theme.colors.coal};
    }
  }
`
const Label = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: ${props => props.theme.text.secondaryFont};
  font-size: 18px;
  
  + img {
    padding-left: 10px;
  }
`;

export default AgreementPage;
