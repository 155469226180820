import React, { Fragment, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import PaddedContent from '../housing/PaddedContent';
import { DeviceSize } from './DeviceSize';

type MessageModalProps = {
  openModal: (isOpen: boolean) => void;
  modalShown: boolean;
};

const MessageModal: FunctionComponent<MessageModalProps> = ({ openModal, modalShown, children }) => {
  useEffect(() => {
    document.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        openModal(false);
      }
    });
  });

  return (
    <Fragment>
      <Overlay />
      <Container role="dialog" aria-modal="true" aria-labelledby="modal-text" onClick={() => openModal(false)}>
        <Content onClick={(e) => e.stopPropagation()}>
          {children}
        </Content>
      </Container>
    </Fragment>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  width: 75vw;

  @media ${DeviceSize.mobileL} {
    width: 100%;
    top: 0;
    height: 100vh;
  }
`;

const Content = styled(PaddedContent)`
  background: ${(props) => props.theme.colors.white};
  border-radius: 20px;
  padding: 0 24px;
  overflow-y: scroll;
  scrollbar-width: thin;
  max-height: calc(100vh - 80px);
  
  @media ${DeviceSize.mobileL} {
    width: 100%;
    top: 0;
    margin: 0;
    padding: 12px;
    max-height: calc(100vh - 60px);
  }
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.mint};
`;

export default MessageModal;
