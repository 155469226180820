import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ColumnContainer } from '../basic/Flex';
import { chevronDownBlack, chevronUpBlack } from '../../assets';
import { useMessageGetter } from 'react-message-context';
import SubMenuItem from './SubMenuItem';
import { MainNavItem } from '../../context/appContext';
import { MainNavi } from '../basic/Text';

const MainMenuItem = ({ name, to, children, clickMenuItem, clickSubMenuItem, isOpen }: MainNavItem) => {
  const t = useMessageGetter('navigation');
  let location = useLocation();

  let childClicked = children.find((child) => child.to === location.pathname);

  return (
    <Fragment key={name}>
      <MenuItemContainer
        className={to === location.pathname || childClicked ? 'active' : ''}
        role="menuitem"
        aria-haspopup="true"
        onClick={clickMenuItem}
      >
        <MainNaviText onClick={() => clickSubMenuItem}>{t(name + '.title')}</MainNaviText>
        <input
          id="chevronmenu"
          aria-label={`${isOpen ? 'Close' : 'Open'} the submenu`}
          type="image"
          src={isOpen ? chevronUpBlack : chevronDownBlack}
          alt="Close the submenu"
        />
      </MenuItemContainer>
      <SubMenuContainer isOpen={isOpen} subItems={children.length} className={isOpen ? 'open' : ''}>
        {children.map((child) => (
          <SubMenuItem
            name={child.name}
            to={child.to}
            clickSubMenuItem={clickSubMenuItem}
            key={child.name}
            parentname={name}
          />
        ))}
      </SubMenuContainer>
    </Fragment>
  );
};

const MainNaviText = styled(MainNavi)`
  width: auto;
  margin-right: 5px;
`;

const MenuItemContainer = styled(ColumnContainer)`
  input { // Bad?
    position: relative;
    top: -8px;
    width: 12px;
  }
`;

const SubMenuContainer = styled.div<{isOpen?: boolean, subItems: number}>`
  transition: max-height 0.5s ease;
  max-height: 0;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.open {
    max-height: ${props => `${props.subItems * 35}px`}; // Sets the approx submenu height based on the number of items.
  }
`;

export default MainMenuItem;
