import React  from 'react';
import { useMessageGetter } from 'react-message-context';
import {H1, CallOut, BasicParagraph} from '../components/basic/Text';
import styled from 'styled-components';
import PageContainer from './PageContainer';
import { RouteComponentProps } from 'react-router-dom';
import PaddedContent from '../components/housing/PaddedContent';

const ApartmentChangePage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('change');

  const linkToApartments = () => {
    return sessionStorage.getItem('currentLanguage') === 'en' ?
      'https://m2kodit.fi/en/rental-apartments/' :
      'https://m2kodit.fi/vuokra-asunnot/';
  }

  return (
    <PageContainer history={history}>
      <Content>
        <TitleContent>
          <H1>{t('title')}</H1>
          <CallOut>{t('newapplication.title')}</CallOut>
          <BasicParagraph>{t('newapplication.description')}</BasicParagraph>
        </TitleContent>
        <ol>
          <li>
            {t('newapplication.step1')}
            <a href={linkToApartments()} target="_blank" rel="noreferrer">
              {linkToApartments()}
            </a>
          </li>
          <li>{t('newapplication.step2')}</li>
          <li>{t('newapplication.step3')}</li>
          <li>{t('newapplication.step4')}</li>
        </ol>
        <p>{t('newapplication.notice')}</p>
      </Content>
    </PageContainer>
  );
};

const Content = styled(PaddedContent)`
  min-height: 600px;

  > h1:first-child {
    margin-bottom: 26px;
  }
`;
const TitleContent = styled.div`
  margin-bottom: 40px;
`;

export default ApartmentChangePage;
