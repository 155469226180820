import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import { BasicParagraph } from '../components/basic/Text';
import WordPressPage from '../components/wordpress/wordpress';
import { useApp } from '../context/appContext';
import { loadApartment, loadHousing } from '../context/actions';
import HousingContact from '../components/housing/HousingContact';
import '../App.css';

const ApartmentWaterMeter = ({ history }: RouteComponentProps) => {

  const t = useMessageGetter('watermeter');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ housing, apartment }, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
    loadApartment(dispatch)
  }, [dispatch]);

  const getWaterMeterSlug = (waterMeter: string | undefined) => {
    // Catch undefined to wait until data is fetched
    if (waterMeter === undefined) return;
    if (waterMeter === 'Huoneistokohtainen vesimittari') return 'water-meter';
    return 'no-water-meter';
  }

  const slug = getWaterMeterSlug(apartment.info.waterMeter)

  return (
    <PageContainer history={history}>
      <PaddedContent>
        { slug === 'no-water-meter' ? (
          <BasicParagraph>{ t('noexist.description') }</BasicParagraph>
        ) : (
          <WordPressPage slug={slug} />
        ) }
      </PaddedContent>
      {housing && <HousingContact t={t} housingContacts={housing} c={c} />}
    </PageContainer>
  );
};

export default ApartmentWaterMeter;
