import React, { Fragment } from 'react';
import styled from 'styled-components';
import { SemiboldParagraph } from '../basic/Text';
import * as icons from '../../assets/index';
import { DeviceSize } from '../basic/DeviceSize';
import { Feature, useApp } from '../../context/appContext';
import { LinkButton } from '../button/Button';
import { Routes } from '../../constants/routes';

const bookingTypes = ['clubroom', 'laundryroom', 'dryingroom'];

type FeatureItemProps = {
  feature: Feature,
  t: (...args: any[]) => any
};

type IconsModuleType = typeof icons;

const FeatureItem: React.FunctionComponent<FeatureItemProps> = ({ feature, t }) => {
  const [{ modules }] = useApp();

  function featIcon<K extends keyof IconsModuleType>(feature: string): IconsModuleType[K] {
    return icons[feature as K];
  }

  return (
    <Fragment>
      {feature.isExist && (
        <Item>
          <FeatureName>
            <Icon
              src={featIcon(feature.name)}
              style={{ height: '27px', width: '24px' }}
              alt={'Ikoni: ' + t(`housefeat.${feature.name.toLowerCase()}`)}
              aria-label={
                t(`housefeat.${feature.name.toLowerCase()}`) + ' on olemassa'
              }
            />
            {feature && (
              <SemiboldParagraph>
                {t(`housefeat.${feature.name.toLowerCase()}`)}
              </SemiboldParagraph>
            )}
            { bookingTypes.includes(feature.name.toLowerCase()) &&
              modules.modules && modules.modules.length > 0 &&
              <BookingButton href={Routes.Apartment.BOOKINGS_CALENDAR}>
                {t('housefeat.book')}
              </BookingButton>
            }
          </FeatureName>
        </Item>
      )}
    </Fragment>
  );
};
const Item = styled.div`
  display: flex;
  border-radius: 40px;
  max-height: 60px;
  max-width: 600px;
  justify-content: space-between;
  flex: 1 1 50%;
  margin: 3px;

  @media ${DeviceSize.desktopS} {
    max-width: 100%;
  }
`;
const FeatureName = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const Icon = styled.img`
  margin-right: 25px;
`;

const BookingButton = styled(LinkButton)`
  font-size: 16px;
  min-width: unset;
  margin: 0 0 0 auto;
`;
export default FeatureItem;
