import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import { useApp } from '../../context/appContext';
import { loadHousing } from '../../context/actions';
import PageContainer from '../PageContainer';
import PaddedContent from '../../components/housing/PaddedContent';
import HousingContact from '../../components/housing/HousingContact';
import WordPressPage from '../../components/wordpress/wordpress';

const LivingHome = ({ history }: RouteComponentProps) => {
  const h = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ housing }, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
  }, [dispatch]);

  return (
    <>
      <PageContainer history={history}>
        <PaddedContent>
          <WordPressPage slug='living-home' />
        </PaddedContent>
        { housing && <HousingContact t={h} housingContacts={housing} c={c} /> }
      </PageContainer>
    </>
  );
};

export default LivingHome;
