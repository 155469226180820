import React, { Fragment, Props } from 'react';
import styled, { css } from 'styled-components';
import { DeviceSize } from './DeviceSize';

export const baseFontStyle = css`
  font-family: ${props => props.theme.text.primaryFont};
  color: ${props => props.theme.text.fontColor};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-feature-settings: "ss01";
`;

export const H1 = styled.h1`
  ${baseFontStyle};
  margin: 0;
  font-size: 42px;
  letter-spacing: 2.1px;
  line-height: 1.1;
  font-family: ${props => props.theme.text.secondaryFont};
  @media ${DeviceSize.tabletL} {
    font-size: 36px;
  }
  @media ${DeviceSize.mobileL} {
    font-size: 24px;
  }
  @media ${DeviceSize.desktopM} {
    letter-spacing: initial;
  }

  @media print {
    font-size: 16px;
    letter-spacing: 0.8px;
  }
`;

export const H2 = styled.h2`
  ${baseFontStyle};
  margin: 0;
  font-family: ${props => props.theme.text.secondaryFont};
  font-size: 32px;
  letter-spacing: 1.05px;
  font-weight: 400;
  
  @media ${DeviceSize.desktopM} {
    font-size: 26px;
  }

  @media ${DeviceSize.tabletL} {
    font-size: 24px;
    font-weight: 700;
    font-family${props => props.theme.text.primaryFont};
    letter-spacing: initial;
  }
  
  @media ${DeviceSize.mobileL} {
    font-size: 20px;
  }

  @media print {
    font-size: 14px;
    letter-spacing: 0.6px;
  }
`;

export const H3 = styled.h3`
  ${baseFontStyle};
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.8px;
  
  @media ${DeviceSize.desktopM} {
    letter-spacing: initial;
  }

  @media print {
    font-size: 12px;
    letter-spacing: 0.6px;
  }
`;

export const BasicText = styled.p`
  ${baseFontStyle};
  font-family: ${props => props.theme.text.primaryFont};
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1.5;
  display: block;
`;

export const BasicParagraph = styled.p`
  ${baseFontStyle};
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1.5;
  display: block;

  @media print {
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 1;
  }
`;

export const SemiboldParagraph = styled.p`
  ${baseFontStyle};
  font-family: ${props => props.theme.text.thirdFont};
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1.5;
  display: block;

  @media print {
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 1;
  }
`;

export const BoldAnchor = styled.a`
  ${baseFontStyle};
  font-family: ${props => props.theme.text.fourthFont};
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: ${props => props.color ? props.theme.colors.coal : props.theme.colors.mint};
`;

export const BasicParagraphs = (props: Props<{}>) => {
  if (typeof props.children !== 'string') {
    console.log('Warning: content of <BasicParagraphs /> must be string');
    return <Fragment />;
  }
  return (
    <Fragment>
      {props.children.split('\n').map((paragraph, i) => (
        <BasicParagraph key={i}>{paragraph}</BasicParagraph>
      ))}
    </Fragment>
  );
};

export const SmallText = styled.p`
  ${baseFontStyle};
  font-size: 12px;
  letter-spacing: 0.6px;
  line-height: 1.5;
  display: block;
  margin: auto;
`;

export const CallOut = styled.p`
  ${baseFontStyle};
  font-family: ${props => props.theme.text.secondaryFont};
  color: ${props => props.theme.colors.black};
  font-size: 20px;
  letter-spacing: 0.7px;
  line-height: 1.43;
  display: block;
  
  @media ${DeviceSize.desktopM} {
    font-family: ${props => props.theme.text.primaryFont};
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 14px;
    letter-spacing: initial;
  }
`;

export const MainNavi = styled.p`
  ${baseFontStyle};
  font-family: ${props => props.theme.text.secondaryFont};
  font-size: 21px;
  letter-spacing: 1.05px;
  margin: auto 0;
  
  @media ${DeviceSize.desktopM} {
    color: ${props => props.theme.colors.black};
    font-family: ${props => props.theme.text.primaryFont};
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 14px;
  }
`;

export const SubNavi = styled.p`
  ${baseFontStyle};
  font-size: 17px;
  letter-spacing: 1.05px;
  margin: auto 0;
  
  @media ${DeviceSize.desktopM} {
    color: ${props => props.theme.colors.black} !important; // hmm
    font-family: ${props => props.theme.text.primaryFont} !important;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const QuickLinkText = styled.p`
  ${baseFontStyle};
  font-size: 20px;
  letter-spacing: 1px;
`;

export const NameText = styled.p`
  ${baseFontStyle};
  font-size: 14px;
  //font-family: ${props => props.theme.text.secondaryFont};
  color: ${props => props.theme.colors.coal};
  line-height: 1.5;
  letter-spacing: 0.7px;
`;

export const form1Style = css`
  ${baseFontStyle};
  //font-family: ${props => props.theme.text.secondaryFont};  
  font-size: 16px;
  letter-spacing: 0.8px;
  color: ${props => props.theme.colors.coal};
  opacity: 0.7;
`;

export const form2Style = css`
  ${form1Style};
  opacity: 1.0;
`;

export const LogInStyle = styled.p`
  ${baseFontStyle};
  font-family: ${props => props.theme.text.secondaryFont};
  color: ${props => props.theme.colors.white};
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.8px;
  margin: auto;
`;

export const TableHeaderText = styled.th`
  ${baseFontStyle};
  font-family: ${props => props.theme.text.primaryFont};
  font-size: 16px;
  letter-spacing: 0.8px;
  color: ${props => props.theme.colors.coal};
  line-height: 1.3;
`;

export const TableRowText = styled.p`
  ${baseFontStyle};
  font-family: ${props => props.theme.text.fifthFont};
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: 0.7px;
  color: ${props => props.theme.colors.coal};
`;
