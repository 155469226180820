import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BasicParagraph, H1 } from '../components/basic/Text';
import { useMessageGetter } from 'react-message-context';
import PaddedContent from '../components/housing/PaddedContent';
import { Button } from '../components/button/Button';
import { printIcon } from '../assets';
import Table from '../components/debt/Table';
import { useApp } from '../context/appContext';
import { DeviceSize } from '../components/basic/DeviceSize';
import { loadDebts, LOAD_STATES } from '../context/actions';
import ReactGA from 'react-ga';
import { signAwsUrl } from '../utils/awsRequestSigner';
import { AWSEndpoints } from '../constants/AWSEndpoints';
import PageContainer from './PageContainer';
import { RouteComponentProps } from 'react-router-dom';
import Loader from '../components/basic/Loader';

const DebtPage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('debt');
  const [state, dispatch] = useApp();

  const isLoading = () => {
    return state.debts?.loadState !== LOAD_STATES.READY;
  };

  useEffect(() => {
    loadDebts(dispatch, state);
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const printInvoice = () => {
    signAwsUrl(AWSEndpoints.BILLING_DEBT_INVOICE, (signedUrl) => {
      window.open(signedUrl);
      ReactGA.event({
        category: 'Debt page',
        action: 'Print debt invoice',
        label: '/asuntoni/maksut/velkaerittely',
      });
    });

    return false;
  };

  const DebtContent = () => {
    return (
      <>
        <DebtBackground>
          <PaddedContent>
            <TitleHeader className="titleBox">
              <button onClick={history.goBack}>
                <H1>{`< ${t('title')}`}</H1>
              </button>
              <BasicParagraph>{t('description')}</BasicParagraph>
            </TitleHeader>
            <PayTypeContainer className="table">
              {state.debts.inDistraint ? null : <DebtTable debts={state.debts} />}
              {!state.debts || state.debts.inProceedings || state.debts.inDistraint ? (
                null
              ) : (
                <ButtonContainer>
                  <PrintButton onClick={printInvoice} aria-labelledby="printLabel">
                    <Label id="printLabel">{t('print')}</Label>
                    <img src={printIcon} alt="printer" />
                  </PrintButton>
                </ButtonContainer>
              )}
            </PayTypeContainer>
            <BasicParagraph>{t('contact.description')}</BasicParagraph>
            <BasicParagraph>{t('contact.description2')}</BasicParagraph>
          </PaddedContent>
        </DebtBackground>
      </>
    );
  };

  return (
    <>
      <PageContainer history={history}>{isLoading() ? <Loader /> : <DebtContent />}</PageContainer>
    </>
  );
};

export default DebtPage;

const DebtBackground = styled.div`
`;

const TitleHeader = styled.div`
  margin-top: 26px;
  margin-bottom: 45px;

  > button {
    border: none;
    cursor: pointer;
    background: none;
  }

  > button:hover {
    text-decoration: underline;
  }
`;

const PayTypeContainer = styled.div`
  margin-bottom: 78px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > button {
    margin: auto 0;
  }
`;

const PrintButton = styled(Button)`
  margin: auto 0;
  width: 100%;
  max-width: 292px;
  justify-content: space-between;

  @media ${DeviceSize.desktopS} {
    max-width: 100%;
  }
`;

const Label = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const DebtTable = styled(Table)`
  background: none;
`;
