// React
import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

// Context
import { useApp } from '../context/appContext';
import { getTenantModules, loadHousing, loadHousingCoop } from '../context/actions';

// Components
import PageContainer from './PageContainer';
import WordPressPage from '../components/wordpress/wordpress';
import HousingContact from '../components/housing/HousingContact';
import PaddedContent from '../components/housing/PaddedContent';
import { DeviceSize } from '../components/basic/DeviceSize';
import { LinkButton } from '../components/button/Button';
import { Routes } from '../constants/routes';

const HousingSauna = ({ history }: RouteComponentProps) => {
  const b = useMessageGetter('bookings.calendar');
  const t = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ housing, housingInfo, modules }, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
    loadHousingCoop(dispatch);
    if (!modules.modules) {
      getTenantModules(dispatch);
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSaunaSlug = (sauna: boolean | undefined, hasCalendar: boolean | undefined) => {
    // Catch undefined to wait until data is fetched
    if (sauna === undefined || hasCalendar === undefined) return;
    if (sauna && hasCalendar) return 'one4all-sauna';
    if (sauna) return 'sauna';
    return 'ei-sauna';
  }

  const hasSauna = housingInfo?.features && housingInfo?.features
    .filter(v => v.isExist)
    .some((item) => item.name === 'buildingSauna');

  const slug = getSaunaSlug(hasSauna, (modules.modules && modules.modules.length > 0));

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <WordPressPage slug={slug} />
        { modules.modules && modules.modules.length > 0 && hasSauna &&
          <ButtonContainer>
            <BookingsButton href={Routes.Apartment.BOOKINGS_CALENDAR}>
              <ButtonLabel>
                <span>{b('navigate')}</span>
              </ButtonLabel>
            </BookingsButton>
          </ButtonContainer>
        }
      </PaddedContent>
      {housing && <HousingContact t={t} housingContacts={housing} c={c} />}
    </PageContainer>
  );
};

const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
`;

const BookingsButton = styled(LinkButton)`
  display: inline-flex;
`;

const ButtonLabel = styled.div`
  display: flex;
  justify-content: center;

  + img {
    padding-left: 10px;
  }

  @media ${DeviceSize.mobileL} {
    font-size: 17px;
  }
`;

export default HousingSauna;
