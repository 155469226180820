import React, { useState } from 'react';
import styled from 'styled-components';
import {
  bulletinAlert,
  bulletinNotice,
  bulletinNoticeBlack,
  whiteCross,
  blackCross,
  chevronDown,
  chevronDownBlack
} from './../../assets';
import { H2, BasicParagraph } from '../basic/Text';
import { DeviceSize } from '../basic/DeviceSize';

interface BulletinProps {
  title: string,
  body: string,
  theme: string
}

const Bulletin: React.FunctionComponent<BulletinProps> = ({title, body, theme}) => {
  const [ open, setOpen ] = useState(false);
  const bulletinType = (theme || 'notice');
  
  let bulletinIcon;
  let chevronIcon;
  let crossIcon;
  switch(bulletinType) {
    case 'alert':
      bulletinIcon = bulletinAlert;
      chevronIcon = chevronDown;
      crossIcon = whiteCross;
      break;
    case 'notice':
      bulletinIcon = bulletinNotice;
      chevronIcon = chevronDown;
      crossIcon = whiteCross;
      break;
    case 'uuras-info':
      bulletinIcon = bulletinNoticeBlack;
      chevronIcon = chevronDownBlack;
      crossIcon = blackCross;
      break;
    default:
      bulletinIcon = bulletinAlert;
      chevronIcon = chevronDown;
      crossIcon = whiteCross;
  }

  return (
    <BulletinContainer bulletinType={bulletinType}>
      <BulletinHeader onClick={() => setOpen(!open)}>
        <BulletinIcon alt="Ilmoitus" src={ bulletinIcon } />
        <BulletinTitle>{ title }</BulletinTitle>
        <BulletinToggle 
          onClick={() => setOpen(!open)} 
          alt="Piilota/näytä ilmoitus" 
          src={ open ? crossIcon : chevronIcon } 
        />
      </BulletinHeader>
      { open && (
        <BulletinBody dangerouslySetInnerHTML={{ __html:body! }}></BulletinBody>
      )}
    </BulletinContainer>
  )
};

const BulletinContainer = styled.div<{ bulletinType: string }>`
position: relative;
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 20px;


  ${props => props.bulletinType === 'notice' && `
    background-color: ${props.theme.colors.apila};
    
    h2 {
      color: ${props.theme.colors.m2White};
      text-shadow: 1px 1px 2px ${props.theme.colors.shadow};
    }
    
    p {
      color: ${props.theme.colors.m2Black};
    }
  `}
  
  ${props => props.bulletinType === 'alert' && `
    background-color: ${props.theme.colors.carrot};
    color: ${props.theme.colors.m2White};
  `}
  
  ${props => props.bulletinType === 'uuras-info' && `
    border: 1px solid white;
    background-color: ${props.theme.colors.lemon};
    color: ${props.theme.colors.coal};
    border: 2px solid ${props.theme.colors.white};
    
    h2, p {
      color: ${props.theme.colors.coal};
    }
  `}
`;

const BulletinHeader = styled.div`
  display: flex;
`;

const BulletinIcon = styled.img`
  position: relative;  
  margin-right: 20px;
  top: 2px;  
`;

const BulletinToggle = styled.img`
  cursor: pointer;
  margin-left: auto;
`;

const BulletinTitle = styled(H2)`
  display: inline;
  margin: 0 10px;
  font-size: 24px;
  color: ${props => props.theme.colors.m2White};

  @media ${DeviceSize.mobileL} {
    font-size: 16px;
  }
`;

const BulletinBody = styled(BasicParagraph)`
  color: ${props => props.theme.colors.m2White};

  .wp-block-media-text__media {
    img {
      width: 100%;
      max-width: 320px;
      height: auto;
      border-radius: 12px;

      @media ${DeviceSize.desktopS} {
        margin: 0 auto;
        display: block;
      }
    }
  }

  .wp-block-media-text__content {
    padding: 0 4%;
  }

  h2.wp-block-post-title {
    display: none; // "temporary" workaround to hide duplicate post titles
  }
`;

export default Bulletin;
