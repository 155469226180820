import React, { useEffect } from 'react';
import { useApp } from '../context/appContext';
import { useMessageGetter } from 'react-message-context';
import PageContainer from './PageContainer';
import { EmergencyPlanItem } from '../components/housing/EmergencyPlanItem';
import Description from '../components/housing/Description';
import HousingContact from '../components/housing/HousingContact';
import FeatureList from '../components/housing/FeatureList';
import { BasicParagraph, H1 } from '../components/basic/Text';
import PaddedContent from '../components/housing/PaddedContent';
import { DeviceSize } from '../components/basic/DeviceSize';
import { RouteComponentProps } from 'react-router';
import { loadHousingCoop, getDocumentsList, loadHousing, loadApartment } from '../context/actions';
import styled from 'styled-components';

const Housing = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ housingInfo, documents, housing, apartment }, dispatch] = useApp();

  useEffect(() => {
    loadHousingCoop(dispatch);
    getDocumentsList(dispatch);
    loadHousing(dispatch);
    loadApartment(dispatch);
  }, [dispatch]);

  // Scroll user to the contact details section of the page
  useEffect(() => {
    if (window.location.hash === '#contactinformation') {
      const element = document.getElementById('maintenance');
      element && element.scrollIntoView(true);
    }
  });
  return (
    <PageContainer history={history}>
      <PaddedContent>
        <H1>{t('title')}</H1>
      </PaddedContent>
      { apartment.info.address &&
        <AddressContainer>
          <AddressText>{apartment.info.address},</AddressText>
          <AddressText>{apartment.info.zip} {apartment.info.city}</AddressText>
        </AddressContainer>
      }
      <Description t={t} housingInfo={housingInfo} documents={documents} />
      <FeatureListContainer t={t} housing={housingInfo} />
      <Documents className="documents">
        <EmergencyPlanItem
          url={housingInfo?.emergencyplanurl}
        />
      </Documents>
      {housing && <HousingContact t={t} c={c} housingContacts={housing} />}
    </PageContainer>
  );
};

const FeatureListContainer = styled(FeatureList)`
  margin-bottom: 40px;
  
  @media ${DeviceSize.mobileL} {
    margin-bottom: 20px;
  }
`;

const Documents = styled(PaddedContent)`
  padding-top: 40px;
  padding-bottom: 20px;
`;

const AddressContainer = styled(PaddedContent)`
  padding-top: 0;
  padding-bottom: 0;
`;

const AddressText = styled(BasicParagraph)`
  margin: 0;
`;

export default Housing;
