import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { H2, TableHeaderText, TableRowText } from '../basic/Text';
import { Debts } from '../../context/appContext';
import dayjs from 'dayjs';
import { DeviceSize } from '../basic/DeviceSize';

export type DebtTableProps = {
  debts: Debts;
};

export const Table: FunctionComponent<DebtTableProps> = ({ debts }) => {
  const t = useMessageGetter('debt');
  const [sum, setSum] = useState(0);

  useEffect(() => {
    let euros = 0;
    if (debts.itemized && debts.itemized.length > 0) {
      debts.itemized.map((value) => (euros = euros + Math.round(value.euros * 100)));
    }
    setSum(euros / 100);
  }, [debts.itemized]);

  return (
    <Wrapper>
      <SubTitle className="paytypeTitle">
        <H2>{t('paytype')}</H2>
      </SubTitle>
      <TableContainer>
        <HeaderRow>
          <tr>
            <TableHeaderText>&nbsp;</TableHeaderText>
            <TableHeaderText>{t('debtamount')}</TableHeaderText>
            <TableHeaderText>{t('duedate')}</TableHeaderText>
          </tr>
        </HeaderRow>
        <tbody>
          {debts.itemized &&
            debts.itemized.length > 0 &&
            debts.itemized.map((d, index) => (
              <DataRow key={index}>
                <td className="debttype">
                  <TableRowText>{d.kind}</TableRowText>
                </td>
                <td className="debtamount">
                  <TableRowText>
                    {Number(d.euros).toLocaleString('fi-FI', { minimumFractionDigits: 2 })}&nbsp;€
                  </TableRowText>
                </td>
                <td className="duedate">
                  <TableRowText>{dayjs(d.due).format('DD.MM.YYYY')}</TableRowText>
                </td>
              </DataRow>
            ))}
          <SumRow>
            <td>{t('sum')}</td>
            <td>{sum}&nbsp;€</td>
            <td>&nbsp;</td>
          </SumRow>
        </tbody>
      </TableContainer>
    </Wrapper>
  );
};

export default Table;

const Wrapper = styled.div`
  padding: 26px 53px;
  margin-bottom: 15px;
  // background: ${(props) => props.theme.colors.white};

  @media ${DeviceSize.desktopS} {
    padding: 0;
  }
`;

const SubTitle = styled.div`
  margin-bottom: 26px;

  > h2 {
    margin-left: 25px;
  }
`;

const HeaderRow = styled.thead`
  > tr > th {
    text-align: right;
    padding-bottom: 12px;
  }

  > tr > th:nth-child(2) {
    padding-right: 17px;
  }

  > tr > th:last-child {
    padding-right: 40px;
    width: 25%;
  }

  @media ${DeviceSize.desktopS} {
    > tr > th:nth-child(2) {
      padding-right: 35px;
    }

    > tr > th:last-child {
      padding-right: 35px;
    }
  }
`;

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;

  > tbody > tr:nth-child(odd) {
    background: ${(props) => props.theme.colors.peppermintVeryLight};
  }

  > tbody > tr:nth-child(even) {
    background: ${(props) => props.theme.colors.white};
  }
`;

const DataRow = styled.tr`
  width: 880px;
  height: 40px;

  > .debttype {
    padding-left: 27px;
  }

  > .debtamount,
  > .duedate {
    text-align: right;
  }

  > .duedate {
    padding-right: 27px;
  }

  @media ${DeviceSize.desktopS} {
    .debtamount,
    .debttype,
    .duedate {
      padding-right: 20px;
      font-size: 14px;
    }
  }

  @media ${DeviceSize.mobileM} {
    padding-right: 25px;
  }
`;

const SumRow = styled.tr`
  > td {
    padding-top: 10px;
    letter-spacing: 0.8px;
    padding-bottom: 10px;
  }

  > td:first-child {
    padding-left: 25%;
    text-align: right;
  }

  > td:nth-child(2) {
    text-align: right;
  }

  @media ${DeviceSize.mobileL} {
    > td {
      font-size: 14px;
    }

    > td:first-child {
      padding-left: 15%;
      text-align: left;
    }

    > td:nth-child(2) {
      padding-right: 15px;
    }
  }
`;
