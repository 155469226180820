import React from 'react';
import { useAppState } from './context/appContext';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { isSignupComplete, hasExpiringAgreement } from './utils';
import { Routes } from './constants/routes';

// Disable these routes on expiring agreements and redirect to front page
const disabledRoutesWithExpiringAgreement = [
  Routes.Apartment.WATER_METER,
  Routes.Apartment.CHANGE,
  Routes.Apartment.SWAP,
  Routes.Apartment.NEW,
  Routes.Apartment.MESSAGES,
  Routes.Housing.HOME,
  Routes.Housing.MANUALS,
  Routes.WORK,
];

const AuthenticatedRoute = ({
  component: Component,
  ...rest
}: RouteProps & { component: (props: RouteComponentProps) => JSX.Element }) => {
  const { user } = useAppState();

  if (!user.isLoaded) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isSignupComplete(user) ? (
          hasExpiringAgreement(user) &&
            // Check substring to target child routes as well
            disabledRoutesWithExpiringAgreement.some((path) => rest.path?.includes(path)) ? (
              <Redirect
                to={{
                  pathname: '/',
                  search: window.location.search,
                  state: { from: props.location },
                }}
              />
            ) : (
              <Component {...props} />
            )
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              search: window.location.search,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
