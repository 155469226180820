import React from 'react';
import styled from 'styled-components';
import { CarouselItem } from '../../context/appContext';
import { DeviceSize } from '../basic/DeviceSize';
import { CallOut, H1 } from '../basic/Text';

const SliderItem = ({id, title, text, image}: CarouselItem) => {
  return (
    <ItemContainer className={`slider${id}`}>
      <Content>
        <TextContainer>
          <H1>{title}</H1>
          <CallOut>{text}</CallOut>
        </TextContainer>
        <ImgContainer>
          <img src={image} alt='Illustration'/>
        </ImgContainer>
      </Content>
    </ItemContainer>
  )
};

const ItemContainer = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
`;

const Content = styled.div`
  display: flex;
  height: inherit;
  width: inherit;
  padding: 0 164px 0 164px;
  justify-content: center;
  
  @media ${DeviceSize.desktopS} {
    flex-direction: column;
    padding: 0 75px 0 75px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20px;
  flex: 1;
  max-width: 467px;
  white-space: normal;
  margin-right: 80px;
  
  @media ${DeviceSize.desktopS} {
    margin-right: 0;
    width: 100%;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 1;
  max-width: 394px;
  max-height: 407px;
  flex-flow: nowrap;
  
  @media ${DeviceSize.desktopS} {
  
    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

export default SliderItem;