import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CarouselContent } from '../../context/appContext';
import SliderItem from './SliderItem';
import {LeftArrowButton, RightArrowButton, LeftArrowDisabled, RightArrowDisabled} from '../button/ArrowButton';
import { BasicText } from '../basic/Text';
import { blackCross } from '../../assets';

const Slider = ({content, hideCarousel}: CarouselContent) => {
  const [translateValue, setTranslateValue] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLeftDisabled, setLeftDisabled] = useState(true);
  const [isRightDisabled, setRightDisabled] = useState(false);
  const slider = document.body.querySelector('.slider');

  const maxId = content && content.reduce((prev: any, next: any) => {
    return prev.id > next.id ? prev : next;
  });

  useEffect(() => {
    if(slider) {
      slider.setAttribute('style', `transform: translateX(${translateValue}px); transition: transform 0.45s ease-out`);
    }
  });

  useEffect(() => {
    if(currentIndex === 0) {
      setLeftDisabled(true);
      setRightDisabled(false);
    } else if (maxId && currentIndex === maxId.id) {
      setRightDisabled(true);
      setLeftDisabled(false);
    } else {
      setRightDisabled(false);
      setLeftDisabled(false);
    }
  }, [currentIndex, maxId]);

  const navigateToSlide = (chosenIndex: number) => {
    if(currentIndex === chosenIndex) return;

    if(slider) {
      setTranslateValue(-(slider.clientWidth * chosenIndex));
    }
    setCurrentIndex(chosenIndex);
  };

  const navigateToPrev = () => {
    if(slider) {
      if(currentIndex === 0) return;

      setRightDisabled(false);
      setTranslateValue(translateValue + slider.clientWidth);
      setCurrentIndex(currentIndex - 1);
    }
  };

  const navigateToNext = () => {
    if(slider) {
      if(maxId && currentIndex === maxId.id) return;

      setLeftDisabled(false);
      setTranslateValue(translateValue - slider.clientWidth);
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <SliderContainer>
      <CancelInput>
        <input type='image' src={blackCross} alt='Cancel' onClick={hideCarousel}/>
      </CancelInput>
      <Wrapper className='slider'>
        {content && content.map((element, i) => (
          <SliderItem key={i}
            id={element.id}
            title={element.title}
            text={element.text}
            image={element.image}
            index={element.index}
          />
        ))}
      </Wrapper>
      {isLeftDisabled ?
        <LeftArrowDisabled aria-hidden={true}/>
        :
        <LeftArrowButton navigateToPrev={navigateToPrev}/>
      }
      {isRightDisabled ?
        <RightArrowDisabled aria-hidden={true}/>
        :
        <RightArrowButton navigateToNext={navigateToNext}/>
      }
      <IndicatorNumbers>
        {content && content.map((element, i) => (
          <Indicator key={i} onClick={() => navigateToSlide(element.id)}
            className={currentIndex === element.id ? `slide${element.id} active` : `slide${element.id}`}>
            <BasicText>{element.index}</BasicText>
          </Indicator>
        ))}
      </IndicatorNumbers>
    </SliderContainer>
  )
};

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  background: ${props => props.theme.colors.peppermintLight};

  @media print {
    display: none;
  }
`;

const CancelInput = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 30px 0 0;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const IndicatorNumbers = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 16px;
  justify-content: center;
  width: 100%;
  
  > button:not(:last-child) {
    margin-right: 9px;
  }
`;

const Indicator = styled.button`
  border-radius: 50%;
  border: none;
  background: ${props => props.theme.colors.white};
  width: 34px;
  height: 34px;
  
  > p {
    margin: auto;
  }
  
  &.active {
    background: ${props => props.theme.colors.mint};
    
    > p {
      color: ${props => props.theme.colors.white};
    }
  }
  
  :hover {
    background: ${props => props.theme.colors.mint};
  }
  
  :hover > p {
    color: ${props => props.theme.colors.white};
  }
`;

export default Slider;