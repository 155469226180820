// React
import React, { useEffect, useState } from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

// Context
import { useApp } from '../context/appContext';
import {
  getDocumentsList,
  loadHousing,
  getAgreement,
  loadHousingCoop,
  LOAD_STATES
} from '../context/actions';

// Constants
import { FOLDER_TYPES } from '../constants/folderTypes';

// Components
import PaddedContent from '../components/housing/PaddedContent';
import { H1, BasicParagraph, BoldAnchor } from '../components/basic/Text';
import { DeviceSize } from '../components/basic/DeviceSize';
import PageContainer from './PageContainer';
import HousingContact from '../components/housing/HousingContact';
import DocumentList from '../components/housing/DocumentList';
import MessageModal from '../components/basic/MessageModal';
import NewMessage from '../components/contact/NewMessage';
import MoveInNotice from '../components/bulletin/MoveInNotice';
import Loader from '../components/basic/Loader';

// Assets
import { chevronDownBlack } from '../assets';

const HousingManualsPage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ documents, housing, housingInfo, agreement }, dispatch] = useApp();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [openList, setOpenList] = useState<string>('');

  useEffect(() => {
    loadHousingCoop(dispatch);
    getAgreement(dispatch);
    getDocumentsList(dispatch);
    loadHousing(dispatch);
  }, [dispatch]);

  const toggleModal = (state: boolean) => {
    setShowMessageModal(state);
  };

  const toggleOpenList = (listName: string | undefined) => {
    if (listName) {
      // Close list if it is already open
      if (listName === openList) {
        setOpenList('');
      } else {
        setOpenList(listName);
      }
    }
  };

  // TODO: Sort after mapping
  const renderManuals = (manuals: any, include: string[]) => {
    const docs = manuals.sort((a: any, b: any) => {
      if(a.folder > b.folder) return 1;
      if(a.folder < b.folder) return -1;
      return 0;
    }).map((folder: any, i: number) => {
      const { name, index } = getFolderType(folder.folder)
      if(!includedFolderTypes.includes(index)) return null;

      return (
        <DocumentContainer key={i} onClick={() => toggleOpenList(name)}>
          <DocumentList
            t={t}
            header={t(`folders.${name || 'generic'}`)}
            contents={folder.items}
            type='manuals'
            limit={99}
            columns={3}
            prefix='• '
            open={openList === name}
            backgroundColor={'#ffe64b'}
            chevronIcon={chevronDownBlack}
            accordion={true}
          />
        </DocumentContainer>
      )
    })

    if (docs.length > 0) {
      return docs
    } else {
      return (
        <DocumentContainer>
          <BasicParagraph>
            {t('noexist.instructions')}{' '}
            <InlineAnchor onClick={() => toggleModal(true)}>
              {t('noexist.instructionsLink')}
            </InlineAnchor>
          </BasicParagraph>
        </DocumentContainer>
      );
    }
  };

  // Returns the folder's internal name based on its sorting index, e.g. 1.6.4 -> 'appliances'
  // TODO: This is a mess and a half, clean it up maybe
  // TODO: Reiterating the above. Jeez
  const getFolderType = (folder: string) => {
    // Show test folder for Testikiinteistö
    if(folder === '999996 Testikiinteistö') return {
      name: 'generic',
      index: '999996'
    };

    const regex = /^([\d|\d.]*)(.*)$/
    let folderMatch = folder.match(regex);

    if(folderMatch) {
      let folderNumber = folderMatch[1]
      // Remove trailing periods from index
      while(folderNumber?.slice(-1) === '.') {
        folderNumber = folderNumber.slice(0, -1);
      }

      // [0] == name, [1] == index
      const folderName = Object.entries(FOLDER_TYPES).find(folderType => {
        return folderNumber === folderType[1]
      });

      if(folderName) return {
        name: folderName[0],
        index: folderNumber
      };
    }

    return {
      return: 'generic',
      index: '999'
    }
  };

  const includedFolderTypes = [
    FOLDER_TYPES.ownhouse,
    FOLDER_TYPES.documents,
    // FOLDER_TYPES.communication,
    FOLDER_TYPES.appliances,
    FOLDER_TYPES.service,
    FOLDER_TYPES.hvac,
    FOLDER_TYPES.safety,
    (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'local' ? '999996' : '')
  ];

  return (
    <PageContainer history={history}>
      <PaddedContent>
        {documents.loadState === LOAD_STATES.BUSY ? (
          <Loader />
        ) : (
          <>
            <MoveInNotice startDate={agreement.startDate} housingId={housingInfo.housingId}/>
            <H1>{t('folders.title')}</H1>
            <DescriptionParagraph>{t('folders.description')}</DescriptionParagraph>
            <ManualsContainer>
              { documents.manuals && renderManuals(documents.manuals, includedFolderTypes) }
            </ManualsContainer>
          </>
        )}
      </PaddedContent>
      {housing && <HousingContact t={t} housingContacts={housing} c={c} />}
      {showMessageModal && (
        <MessageModal openModal={(s) => toggleModal(s)} modalShown={showMessageModal}>
          <NewMessage toggleModal={(s) => toggleModal(s)}/>
        </MessageModal>
      )}
    </PageContainer>
  )
};

const DescriptionParagraph = styled(BasicParagraph)`
  padding: 16px 0;
`;
const DocumentContainer = styled(PaddedContent)`
  border-radius: 36px;
  padding: 24px 48px;
  margin: 36px 0;
  background: ${props => props.theme.colors.lemon};
  border: 2px solid ${props => props.theme.colors.white};

  @media ${DeviceSize.desktopS} {
    padding: 8px 12px;
    margin: 24px 0;
  }
`;

const ManualsContainer = styled.div`
    
`;

const InlineAnchor = styled(BoldAnchor)`
    font-family: inherit;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
`;

export default HousingManualsPage;
