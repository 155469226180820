import React, { useEffect, Fragment, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useMessageGetter } from 'react-message-context';
import PageContainer from './PageContainer';
import { H1, BasicParagraph, form2Style, form1Style, H2, H3 } from '../components/basic/Text';
import styled from 'styled-components';
import { useApp } from '../context/appContext';
import { loadMessages, LOAD_STATES } from '../context/actions';
import { messageTypeProblem, houseProblemIcon } from '../assets';
import Loader from '../components/basic/Loader';
import PaddedContent from '../components/housing/PaddedContent';
import { DeviceSize } from '../components/basic/DeviceSize';
import { formatDate } from '../utils';
import Button from '../components/button/Button';
import { RowContainer } from '../components/basic/Flex';
import { LinkButton } from '../components/button/Button';

const MessagesPage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('messages');
  const [state, dispatch] = useApp();
  const { apartmentMessages, housingMessages, loadState } = state.messages;
  const [showState, setShowState] = useState(false);
  const [showMoreHousing, setShowMoreHousing] = useState(false);
  const [row, setRow] = useState('none');
  const [housingRow, setHousingRow] = useState('none');

  useEffect(() => {
    loadMessages(dispatch);
  }, [dispatch]);

  const getTypeIconProps = (category: string) =>
    category === '472'
      ? { alt: getTypeLabel(category), src: messageTypeProblem }
      : { alt: getTypeLabel(category), src: houseProblemIcon };

  const getTypeLabel = (category: string) => t(`new.form.categories.${category}`);

  const showAll = () => {
    if (!showState) {
      setShowState(true);
      setRow('flex');
    }
  };

  const showAllHousing = () => {
    if (!showMoreHousing) {
      setShowMoreHousing(true);
      setHousingRow('flex');
    }
  };

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <TitleSection>
          <H1>{t('title')}</H1>
          <MainDescription>{t('maindescription')}</MainDescription>
        </TitleSection>
        <NewMessageButton href="https://m2kodit.fi/asukkaalle-3/tee-vikailmoitus/" target="_blank">
          {t('newmessage')}
        </NewMessageButton>
        <H2>{t('description')}</H2>
        <BasicParagraph>{t('disclaimer1')}</BasicParagraph>
        {loadState === LOAD_STATES.BUSY && !apartmentMessages && !housingMessages ? (
          <Loader />
        ) : (
          <Fragment>
            <Messages>
              {(Array.isArray(apartmentMessages) &&
                apartmentMessages.length > 0 &&
                apartmentMessages.map(message => (
                  <Message key={message.id} display={row}>
                    <TypeIcon {...getTypeIconProps(message.messageCategory)} />
                    <TypeDescription>{getTypeLabel(message.messageCategory)}</TypeDescription>
                    <SentDate>{formatDate(message.date)}</SentDate>
                    <Status>{t(`messageStatuses.${message.status}`)}</Status>
                    <Title>
                      {t('subject')}: "{message.subject}"
                    </Title>
                  </Message>
                ))) || <EmptyList>{t('emptylist')}</EmptyList>}
            </Messages>
            {((apartmentMessages && apartmentMessages.length > 3) && !showState) &&
            <ButtonContainer>
              <ShowButton style={{ height: '30px' }} onClick={showAll}>
                {t('showmore')}
              </ShowButton>
            </ButtonContainer>
            }
            <HousingMessages>
              <H2>{t('deschousing')}</H2>
              <BasicParagraph>{t('disclaimer2')}</BasicParagraph>
              <BasicParagraph>{t('disclaimer3')}</BasicParagraph>
              <Messages>
                {(Array.isArray(housingMessages) && housingMessages.length > 0 &&
                  housingMessages.map(message => (
                    <Message key={message.id} display={housingRow}>
                      <TypeIcon {...getTypeIconProps(message.messageCategory)} />
                      <TypeDescription>{getTypeLabel(message.messageCategory)}</TypeDescription>
                      <SentDate>{formatDate(message.date)}</SentDate>
                      <Status>{t(`messageStatuses.${message.status}`)}</Status>
                      <Title>
                        {t('subject')}: "{message.subject}"
                      </Title>
                    </Message>
                  ))
                ) || <EmptyList>{t('emptylist')}</EmptyList>}
              </Messages>
            </HousingMessages>
            {((housingMessages && housingMessages.length > 3) && !showMoreHousing) &&
              <ButtonContainer>
                <ShowButton style={{ height: '30px' }} onClick={showAllHousing}>
                  <H3>{t('showmore')}</H3>
                </ShowButton>
              </ButtonContainer>
            }
          </Fragment>
        )}
      </PaddedContent>
    </PageContainer>
  );
};

const Messages = styled(PaddedContent)`
  background-color: ${props => props.theme.colors.apila};
  border-radius: 24px;
  padding: 24px;
  margin-top: 24px;

  @media ${DeviceSize.mobileL} {
    margin-top: 12px;
  }
`;

const NewMessageButton = styled(LinkButton)`
  background: ${props => props.theme.colors.m2Black};
  color: white;
  font-family: ${props => props.theme.text.secondaryFont};
  width: 254px;
  margin: 0 0 80px;

  :hover {
    background-color: ${(props) => props.theme.colors.coal};
  }

  @media ${DeviceSize.mobileL} {
    margin: 0 0 40px;
  }
`

const HousingMessages = styled.div`
  margin-top: 76px;

  @media ${DeviceSize.mobileL} {
    margin-top: 36px;

    > ${BasicParagraph} {
      margin: 4px 0 6px;
    }
  }
`;

const TitleSection = styled.div`
  margin-bottom: 40px;
`;

const MainDescription = styled(H2)`
  margin-top: 24px;
`;

const Message = styled.div<{display?: string}>`
  display: ${props => props.display ? props.display : 'none'};
  flex-wrap: wrap;
  justify-items: stretch;
  padding: 10px;
  margin: 5px 0;
  background-color: ${props => props.theme.colors.peppermintLight};
  
  :nth-child(-n+3) {
    display: flex;
  }
`;

const EmptyList = styled.div`
  ${form1Style};
  text-align: center;
  padding: 24px 0;
`;
const TypeIcon = styled.img`
  padding: 10px;
`;
const TypeDescription = styled.div`
  padding: 10px;
  flex-grow: 1;
`;
const SentDate = styled.div`
  ${form1Style};
  padding: 10px;
  min-width: 120px;
  text-align: right;
`;
const Status = styled.div`
  ${form1Style};
  padding: 10px;
  min-width: 120px;
  text-align: right;
  @media ${DeviceSize.mobileL} {
    width: 100%;
  }
`;
const Title = styled.div`
  ${form2Style};
  padding: 20px 10px 10px 10px;
  border-top: 1px solid white;
  width: 100%;
`;

const ButtonContainer = styled(RowContainer)`
  align-items: flex-end;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 100%;
  left: 30px;
  top: 10px;
  
  @media ${DeviceSize.desktopS} {
    top: 0;
    margin-top: 20px;
  }
`;

const ShowButton = styled(Button)`
  color:  ${props => props.theme.colors.m2White};
  position: absolute;
  right: 30px;
  padding: 25px 0;
`;

export default MessagesPage;
