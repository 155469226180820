import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import WordPressPage from '../components/wordpress/wordpress';
import { useApp } from '../context/appContext';
import { loadHousing } from '../context/actions';
import HousingContact from '../components/housing/HousingContact';
import { DeviceSize } from '../components/basic/DeviceSize';

const HousingStorage = ({ history }: RouteComponentProps) => {

  const t = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ housing }, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
  }, [dispatch]);

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <BodyContainer>
          <WordPressPage slug='varastointi' />
        </BodyContainer>
      </PaddedContent>
      {housing && <HousingContact t={t} housingContacts={housing} c={c} />}
    </PageContainer>
  );
};

const BodyContainer = styled.div`
  margin-top: 12px;
  
  @media ${DeviceSize.desktopS} {
    margin-bottom: 40px;
  }
`;

export default HousingStorage;
