import React, { FunctionComponent, useState, useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import { Link } from 'react-router-dom';
import Button from '../button/Button';
import useForm from 'react-hook-form';
import styled from 'styled-components';
import {H2, BasicParagraph, BasicText, baseFontStyle, BoldAnchor} from '../basic/Text';
import { Input, Select, Textarea } from '../input/Input';
import { utils } from 'isomorphic';
import PaddedContent from '../housing/PaddedContent';
import { postMessage } from '../../apis';
import { showNotification } from '../../context/actions';
import { useApp, ContactCategory } from '../../context/appContext';
import { DeviceSize } from '../basic/DeviceSize';
import { validatePhone } from '../../utils';
import ReactGA from 'react-ga';
import {blackCross, bulletinAlert} from '../../assets';

const NewMessage: FunctionComponent<{
  afterSubmit?: () => void,
  toggleModal: (open: boolean) => void
}> = ({ afterSubmit, toggleModal }) => {
  const t = useMessageGetter('messages.new');
  const messageForm = useForm();
  const messageFormSetValue = messageForm.setValue;
  const [isBusy, setIsBusy] = useState(true);
  const [formProps, setFormProps] = useState({});
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [state, dispatch] = useApp();
  const elisaActionUrl =
    'https://form-api-y-saatio.eee.do/Dd3jRaanbrrnQZ9WrTdJpUugNf6DwPzxVeVTuTDHdLjuZbqE6vNKMbc/formhandler/';

  useEffect(() => {
    if (state.user && state.user.isLoggedIn && state.user.isLoaded) {
      messageFormSetValue('email', state.user.attributes.email || '');
      messageFormSetValue('phone', state.user.attributes.phone_number || '');
    }
  }, [state.user, messageFormSetValue]);

  const onSubmit = async (e: React.FormEvent) => {
    setIsBusy(true);
    const values = messageForm.getValues();
    // Add language to post method
    values.language = sessionStorage.getItem('currentLanguage');
    if (!values.category) {
      values.category = utils.messageCategories[0];
    }
    // Prevent submit and let function handle form when not housing counselling or rent issues,
    // otherwise submit form to Elisa after function.
    if (values.category === '859' || (values.category === '473' && values.subcategory !== 'billing.latepayment')) {
      e.preventDefault();
    }
    try {
      // Handle e-mail messaging through AWS
      await postMessage(values);
      ReactGA.event({
        category: `New message ${t(`form.categories.${values.category}`)}`,
        action: `Submit subject: ${values.subject}`,
        label: values.content,
      })

      // Handle success message normally
      await showNotification(dispatch, t('form.messagesent'));

      messageForm.reset({
        email: state.user.attributes.email || '',
        phone: state.user.attributes.phone_number || '',
      });
      if (afterSubmit) afterSubmit();
    } catch (e) {
      console.error(e);
      await showNotification(dispatch, t('form.messagefailure'));
    }
    setIsBusy(false);
  };

  // Set form to send message to Elisa when 859 or 473 + latepayment is not selected.
  const updateFormProps = () => {
    if (messageForm.getValues()['category'] === '859' || 
       (messageForm.getValues()['category'] === '473' && 
        messageForm.getValues()['subcategory'] !== 'billing.latepayment')
    ) {
      setFormProps({
        onSubmit: onSubmit
      });
    } else {
      setFormProps({
        method: 'POST',
        action: elisaActionUrl,
        onSubmit: onSubmit
      });
    }
    disableSubmit();
  };

  const disableSubmit = () => {
    if (!messageForm.getValues()['category'] ||
        !messageForm.getValues()['subcategory'] ||
        !messageForm.getValues()['subject'].length ||
        !messageForm.getValues()['content'].length ||
        !messageForm.getValues()['email'].length)
      return setIsBusy(true);

    // Don't allow sending for these two options
    if (messageForm.getValues()['subcategory'] === 'customerservice.faultreports' ||
      messageForm.getValues()['subcategory'] === 'customerservice.sauna_laundry_parking')
      return setIsBusy(true);

    return setIsBusy(false);
  };

  const disableTextFields = () => {
    return messageForm.getValues()['subcategory'] === 'customerservice.faultreports' ||
      messageForm.getValues()['subcategory'] === 'customerservice.sauna_laundry_parking';
  };

  // Override links to always return user to contact form
  const setCurrentUrlNewMessage = () => {
    window.history.replaceState(null, 'New Message', '/#newmessage');
    toggleModal(false);
  }

  const handleCategoryChange = (newCategory: string) => {
    // Only called when a change in category is made. We reset subcategory and clear its value in the form.
    setCategory(newCategory);
    setSubcategory('');
    messageFormSetValue('subcategory', '');
  }

  const categories: ContactCategory[] = [
    {
      id: '471',
      name: 'customerservice',
      subcategories:  [
        { name: 'customerservice.apartmentapplications' },
        { name: 'customerservice.sauna_laundry_parking' },
        { name: 'customerservice.contracttermination' },
        { name: 'customerservice.propertymanagement' },
        { name: 'customerservice.faultreports' },
        { name: 'customerservice.other' }
      ]
    }, {
      id: '473',
      name: 'billing',
      subcategories: [
        { name: 'billing.latepayment' },
        { name: 'billing.paymentissues' },
        { name: 'billing.debtrepayment' }
      ]
    }, {
      id: '859',
      name: 'housing',
      subcategories: [
        { name: 'billing.paymentissues' }, 
        { name: 'billing.debtrepayment' },
        { name: 'housing.debtassistance' },
        { name: 'housing.benefitapplication' },
        { name: 'housing.tooexpensive' },
        { name: 'housing.neighbourdispute' },
        { name: 'housing.evictionassistance' },
        { name: 'housing.dailyerrandsassistance' },
        { name: 'housing.mentalhealthandsubstanceabuse' }
      ]
    }
  ]
  return (
    <Wrapper>
      <CloseButton onClick={() => toggleModal(false)}>
        <img src={blackCross} alt="Close the message form" />
      </CloseButton>
      <H2 id="newmessage">{t('title')}</H2>
      <BasicParagraph>{t('desc')}</BasicParagraph>
      <BasicParagraph>
        <InlineAnchor href='https://m2kodit.fi/asukkaalle/tee-vikailmoitus/' target='_blank'>
          {t('faultreportstart')}
        </InlineAnchor>{' '}
        {t('faultreport')}{' '}
        <InlineLink to='/taloni#contactinformation' onClick={setCurrentUrlNewMessage}>
          {t('faultreportlink')}
        </InlineLink>
      </BasicParagraph>
      <BasicParagraph>{t('replysoon')}</BasicParagraph>
      {!state.user.attributes.email &&
        <BasicText>
          {t('copymessage')}
          <InlineLink to='/omat-tiedot' onClick={setCurrentUrlNewMessage}>{t('profile')}</InlineLink>
        </BasicText> }

      <form {...formProps}>
        <FormRow>
          <Input
            name="name"
            placeholder={t('form.name')}
            value={`${state.user.attributes.given_name} ${state.user.attributes.family_name}`}
            maxWidth="initial"
            disabled={false}
            readonly={true}
          />
          <Input
            name="email"
            type="email"
            placeholder={t('form.email')}
            validations={{
              required: t('form.emailrequired'),
            }}
            value={`${state.user.attributes.email}`}
            form={messageForm}
            maxWidth="initial"
            disabled={false}
            readonly={true}
            disableSubmit={disableSubmit}
          />
          <Input
            name="phone"
            placeholder={t('form.phone')}
            validations={{
              validate: (x: string) => validatePhone(x, t('form.phoneinvalid')),
            }}
            form={messageForm}
            maxWidth="initial"
            disabled={false}
            readonly={true}
          />
        </FormRow>

        <FormRow>
          <Select
            name="category"
            placeholder={t('form.category')}
            form={messageForm}
            onChange={(e) => {
              handleCategoryChange(e.target.value)
              updateFormProps()
            }}
            validations={{ required: t('form.categoryrequired') }}
            disabled={!state.user.attributes.email}
          >
            {utils.messageCategories.map((mt: any) => (
              <option key={mt} value={mt}>
                {t(`form.categories.${mt}`)}
              </option>
            ))}
          </Select>
        </FormRow>

        { category && (
          <FormRow>
            <Select
              name="subcategory"
              placeholder={t('form.subcategory')}
              form={messageForm}
              onChange={(e) => {
                setSubcategory(e.target.value)
                updateFormProps()
              }}
              value={subcategory}
              validations={{ required: t('form.subcategoryrequired') }}
            >
              { categories?.find((cat: ContactCategory) => cat.id === category)?.subcategories?.map((subCat) => (
                <option key={subCat.name} value={subCat.name}>
                  {t(subCat.name)}
                </option>
              )) }
            </Select>
          </FormRow>
        )}

        {/* Warning dialogues */}
        { subcategory === 'billing.latepayment' && (
          <WarningBox>
            <img src={bulletinAlert} alt='warning-icon'/>
            <BasicText>
              {t('instruction.latepayment')}
            </BasicText>
          </WarningBox>
        )}

        { subcategory === 'customerservice.propertymanagement' && (
          <WarningBox>
            <img src={bulletinAlert} alt='warning-icon'/>
            <BasicText>
              {t('instruction.propertymanagement')}
            </BasicText>
          </WarningBox>
        )}

        { subcategory === 'customerservice.faultreports' && (
          <WarningBox>
            <img src={bulletinAlert} alt='warning-icon'/>
            <BasicText>
              {t('instruction.faultreports')}
              <InlineLink to='/taloni#contactinformation' onClick={setCurrentUrlNewMessage}>
                {t('instruction.linktomaintenance')}
              </InlineLink>
              <br />
              <br />
              {t('instruction.faultreportsother')}
              <InlineAnchor href='https://m2kodit.fi/asukkaalle/tee-vikailmoitus/' target='_blank'>
                {t('instruction.linktofaultreport')}
              </InlineAnchor>
            </BasicText>
          </WarningBox>
        )}
        { subcategory === 'customerservice.sauna_laundry_parking' && (
          <WarningBox>
            <img src={bulletinAlert} alt='warning-icon'/>
            <BasicText>
              {t('instruction.sauna_laundry_parking')}
              <InlineLink to='/taloni#contactinformation' onClick={setCurrentUrlNewMessage}>
                {t('instruction.linktomaintenance')}
              </InlineLink>
            </BasicText>
          </WarningBox>
        )}

        { subcategory !== 'housing.faultreports' && (
          <>
            <FormRow>
              <Input
                name="subject"
                placeholder={t('form.subject')}
                validations={{
                  required: t('form.subjectrequired'),
                }}
                form={messageForm}
                maxWidth="initial"
                disableSubmit={disableSubmit}
                disabled={!state.user.attributes.email || disableTextFields()}
              />
            </FormRow>

            <FormRow>
              <Textarea
                name="content"
                validations={{ required: t('form.contentrequired') }}
                placeholder={t('form.content')}
                form={messageForm}
                disableSubmit={disableSubmit}
                disabled={!state.user.attributes.email || disableTextFields()}
              />
            </FormRow>

            <div>
              <SendButton id="submitMsg" disabled={isBusy}>
                {t('form.button')}
              </SendButton>
            </div>
          </>
        )}
      </form>
    </Wrapper>
  );
};

const FormRow = styled.div`
  display: flex;
  flex: 1;

  > * {
    padding: 0 10px 0 0;
  }

  > .Puhelinnumero,
  > .Telephone {
    display: none;
  }

  @media ${DeviceSize.mobileL} {
    flex-direction: column;
  }
`;

const Wrapper = styled(PaddedContent)`
  padding-top: 20px;
  padding-bottom: 30px;
`;

const InlineLink = styled(Link)`
    ${baseFontStyle}
    font-family: inherit;
    text-decoration: underline;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.8px;
    color: ${props => props.color ? props.theme.colors.coal : props.theme.colors.mint};
`;

const InlineAnchor = styled(BoldAnchor)`
    font-family: inherit;
    text-decoration: underline;
    font-size: 16px;
`;

const WarningBox = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 5px 10px 5px 0;
  padding: 10px;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.carrot};

  p, a {
    color: ${props => props.theme.colors.m2White};
  }
`;

const SendButton = styled(Button)`
  margin-top: 20px;
`;

const CloseButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
export default NewMessage;
