import React, { useEffect, useState } from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import WordPressPage from '../components/wordpress/wordpress';
import { useApp } from '../context/appContext';
import { loadHousing, getDocumentsList } from '../context/actions';
import { Document } from '../context/appContext';
import HousingContact from '../components/housing/HousingContact';
import { DocumentItem } from '../components/housing/DocumentItem';
import { whiteDownloadIcon } from '../assets';

const HousingParking = ({ history }: RouteComponentProps) => {

  const t = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ housing, documents }, dispatch] = useApp();
  const [parkingDocument, setParkingDocument] = useState<Document | undefined>(undefined);

  useEffect(() => {
    loadHousing(dispatch);
    getDocumentsList(dispatch);
  }, [dispatch]);
  
  useEffect(() => {
    if(documents.documents) {
      setParkingDocument(documents.documents.find((d: Document) => d.categoryType === 'Autopaikkakartta'));
    }
  }, [documents]);
  
  return (
    <PageContainer history={history}>
      <PaddedContent>
        <WordPressPage slug='asukaspysakointi' />
        { parkingDocument && (
          <DocumentItem
            type=''
            item={parkingDocument}
            icon={whiteDownloadIcon}
          />
        )}
      </PaddedContent>
      {housing && <HousingContact t={t} housingContacts={housing} c={c} />}
    </PageContainer>
  );
};

export default HousingParking;
