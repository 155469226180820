import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router';
import { RowContainer, ColumnContainer } from '../components/basic/Flex';
import Button from '../components/button/Button';
import ApartmentChangeAdView from '../components/apartment/ApartmentChangeAdView';
import { getApartmentChangeAd, approveApartmentChangeAd, deleteApartmentChangeAdApproval } from '../apis';
import Loader from '../components/basic/Loader';
import { ApartmentChangeAd } from '../context/appContext';
import PaddedContent from '../components/housing/PaddedContent';

const ApartmentChangeApprovalPage = ({ history }: RouteComponentProps) => {
  const { id, secret } = useParams();
  const [ad, setAd] = useState<ApartmentChangeAd | null>(null);

  useEffect(() => {
    async function fetch() {
      console.log('Getting change ad...');
      id && secret && setAd(await getApartmentChangeAd(id, secret));
    }
    fetch();
  }, [id, secret]);

  async function publish() {
    setAd(null);
    await approveApartmentChangeAd(id!, secret!);
    setAd(await getApartmentChangeAd(id!, secret!));
  }

  async function remove() {
    setAd(null);
    await deleteApartmentChangeAdApproval(id!, secret!);
    setAd(await getApartmentChangeAd(id!, secret!));
  }

  return !ad ? (
    <Loader />
  ) : (
    <PaddedContent>
      <RowContainer>
        <h1>Uusi asunnonvaihtoilmoitus</h1>
        <div style={{ margin: '0 0 40px 0' }}>
          <ApartmentChangeAdView ad={ad} />
        </div>
        <ColumnContainer>
          <Button onClick={publish} disabled={ad.status === 'published'}>
            Hyväksy
          </Button>
          <Button onClick={remove} disabled={ad.status === 'deleted'}>
            Hylkää
          </Button>
        </ColumnContainer>
      </RowContainer>
    </PaddedContent>
  );
};

export default ApartmentChangeApprovalPage;
