import React, { FunctionComponent, useState, Fragment } from 'react';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { form2Style } from '../basic/Text';
import { searchIconBlack } from '../../assets';
import { DeviceSize } from '../basic/DeviceSize';

const HeaderSearch: FunctionComponent<{
    onSearch: (query: string) => void; 
    initialValue?: string; 
    isSearchPage?: boolean, 
    isHomePage?: boolean, // In mobile view, it should only be shown on the home page 
    className?: string 
  }> = ({ onSearch, initialValue, isSearchPage, isHomePage, className }) => {
    const [query, setQuery] = useState(initialValue || '');
    const t = useMessageGetter('search');

    const onKeyDown = (e: any) => {
      if (e.key === 'Enter' && query && query.length > 0) {
        onSearch(query);
      }
    };

    const changeText = (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
    };

    return (
      <Fragment>
        <SearchField
          name="query"
          id="queryfield"
          type="text"
          placeholder={t('queryplaceholder')}
          value={query}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeText(e)}
          onKeyDown={onKeyDown}
          aria-label={`${t('queryplaceholder')}`}
          className={className}
          isHomePage={isHomePage}
        />
        {query && query.length > 0 ? (
          <Image
            src={searchIconBlack}
            alt="Hae"
            type="image"
            onClick={() => onSearch(query)}
            isSearchPage={isSearchPage}
            aria-label="Hae"
            aria-disabled={false}
          />
        ) : (
          <Image
            src={searchIconBlack}
            alt="Disabled search icon"
            type="image"
            aria-label="Disabled search icon"
            aria-disabled={true}
            disabled
          />
        )}
      </Fragment>
    );
  };

const SearchField = styled.input<{ isHomePage?: boolean }>`
  ${form2Style};
  border: none;
  background-color: ${(props) => props.theme.colors.lightYellow};
  border-radius: 35px;
  padding: 0 95px 0 25px;
  height: 48px;
  min-width: 100px;
  
  :-webkit-autofill,
  :-webkit-autofill:focus {
    outline: none;
    border: none;
    -webkit-box-shadow: 0 0 0 100px ${(props) => props.theme.colors.m2White} inset;
  }

  ::placeholder { 
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;
  }

  @media ${DeviceSize.mobileL} {
    display: ${props => (props.isHomePage ? 'initial' : 'none')};
    border: 1px solid black;
    padding: 0 12px;
    margin-right: 15px;
    width: 100%;
    min-width: 0px;
  }
`;

const Image = styled.input<{ isSearchPage?: boolean }>`
  position: absolute;
  top: 13px;
  width: 20px;
  right: ${(props) => (props.isSearchPage ? '18%' : '10%')};

  :hover {
    box-shadow: 4px 4px 4px ${(props) => props.theme.colors.shadow};
  }

  @media ${DeviceSize.mobileL} {
    display: none;
  }
`;
export default HeaderSearch;
