const { utils } = require('isomorphic');

module.exports.isSignupComplete = (user) => {
  return user && user.isLoggedIn && user.isLoaded && user.attributes.preferred_username && user.attributes.given_name;
};

module.exports.hasExpiringAgreement = (user) => {
  return user && user.attributes.expiring_agreement && user.attributes.expiring_agreement === true;
};

module.exports.validatePhone = (phone, message) => {
  try {
    if (!/^\+?[0-9\s]+$/.test(phone)) return message;
    utils.sanitizePhone(phone);
    return;
  } catch {
    return message;
  }
};

module.exports.checkPhone = (phone) => {
  if (!/^\+?[0-9\s]+$/.test(phone)) return false;
  return !(phone.length < 7 || phone.length > 14);
};

module.exports.validatePhoneRegex = /^\+?[0-9\s]+$/;

module.exports.validateEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

module.exports.isTrue = (value) => {
  return value === 'yes';
};

// eslint-disable-next-line max-len
module.exports.activeElements =
  'input:not(.modal-input), button:not(.modal-input), a:not(.modal-link), textarea, select, #mainlocalizations';

module.exports.modalFocusableElements = '.modal-input';

module.exports.setAttributes = (element, attrs) => {
  for (const key in attrs) {
    element.setAttribute(key, attrs[key]);
  }
};

module.exports.removeAttributes = (element, attrs) => {
  for (const key in attrs) {
    element.removeAttribute(key);
  }
};

module.exports.saveAsFile = (blob, contentdisposition) => {
  const filename = contentdisposition.split(';')[1].trim().split('=')[1];

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob);
  }
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.style.display = 'none';
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

module.exports.formatMoney = (num, nullString) => {
  if (num) return parseFloat(num).toLocaleString('fi-FI', { style: 'currency', currency: 'EUR' });
  else return nullString;
};

module.exports.formatDate = (dateText, nullString) => {
  if (dateText) {
    return new Date(Date.parse(dateText.toString())).toLocaleDateString('fi-FI');
  } else {
    return nullString;
  }
};

module.exports.formatLocaleDateWithTime = (dateText) => {
  const language = sessionStorage.getItem('currentLanguage');
  const date = new Date(Date.parse(dateText.toString()));
  const time = date.toLocaleTimeString('fi-FI', {minute: '2-digit', hour: '2-digit'});
  const dayMonth = date.toLocaleDateString('fi-FI', {month: '2-digit', day: '2-digit'});
  const dayName = date.toLocaleString(language === 'fi' ? 'fi-FI' : 'en-GB', {weekday: 'short'});
  return `${dayName} ${dayMonth} ${time}`;
};

module.exports.formatTime = (dateText) => {
  const date = new Date(Date.parse(dateText.toString()));
  const time = date.toLocaleTimeString('fi-FI', {minute: '2-digit', hour: '2-digit'});
  return `${time}`;
};

module.exports.changeLanguage = (languageCode) => {
  sessionStorage.setItem('currentLanguage', languageCode);
  window.location.reload();
};

module.exports.isSupportedLanguageCode = (languageCode) => {
  return languageCode === 'fi' || languageCode === 'en';
};

// WP pages in the non-default language are appended with an appropriate language code, i.e. '-eng' for english.
module.exports.getWpLanguageSuffix = () => {
  const lang = sessionStorage.getItem('currentLanguage');
  switch(lang) {
    case 'en': return '-eng';
    default:   return ''; // finnish
  }
};

module.exports.getOne4allLanguageKey = () => {
  const lang = sessionStorage.getItem('currentLanguage');
  switch(lang) {
    case 'en': return 'en_EN';
    default:   return 'fi_FI'; // finnish
  }
};

module.exports.scrollToTop = () => {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
};

