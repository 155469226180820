import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import WordPressPage from '../components/wordpress/wordpress';
import { useApp } from '../context/appContext';
import HousingContact from '../components/housing/HousingContact';
import { loadHousing, loadHousingCoop } from '../context/actions';

const HousingInternet = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ housing, housingInfo }, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
    loadHousingCoop(dispatch);
  }, [dispatch]);

  const getIspSlug = (isp: string | undefined, broadbandType: string | undefined) => {
    // Catch undefined to wait until data is fetched
    if (isp === undefined || broadbandType === undefined) return;
    if (isp === 'DNA') {
      if (broadbandType === 'Ethernet') return 'internet-dna-ethernet';
      if (broadbandType === 'Kaapelimodeemi') return 'internet-dna-cablemodem';
      return 'internet-dna';
    }
    else if (isp === 'Elisa') return 'internet-elisa';
    return 'no-internet';
  }

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <WordPressPage slug={getIspSlug(housingInfo?.isp, housingInfo?.broadbandType)} />
      </PaddedContent>
      {housing && <HousingContact t={t} housingContacts={housing} c={c} />}
    </PageContainer>
  );
};

export default HousingInternet;
