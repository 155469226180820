const PaytrailConfig = {
  local: {
    paytrail: {
      urlSuccess: 'http://localhost:3000/asuntoni/maksut/onnistui',
      urlCancel: 'http://localhost:3000/asuntoni/maksut/virhe',
      urlConfirmPayment: 'http://example.com',
    }
  },
  dev: {
    paytrail: {
      urlSuccess: 'https://dev-assi.m2kodit.fi.s3-website.eu-central-1.amazonaws.com/asuntoni/maksut/onnistui',
      urlCancel: 'https://dev-assi.m2kodit.fi.s3-website.eu-central-1.amazonaws.com/asuntoni/maksut/virhe',
      urlConfirmPayment: 'https://jm79919vyd.execute-api.eu-central-1.amazonaws.com/dev/confirmpayment',
    }
  },
  staging: {
    paytrail: {
      urlSuccess: 'http://staging-assi.m2kodit.fi.s3-website.eu-central-1.amazonaws.com/asuntoni/maksut/onnistui',
      urlCancel: 'http://staging-assi.m2kodit.fi.s3-website.eu-central-1.amazonaws.com/asuntoni/maksut/virhe',
      urlConfirmPayment: 'https://usbfm9oni5.execute-api.eu-central-1.amazonaws.com/staging/confirmpayment',
    }
  },
  production: {
    paytrail: {
      urlSuccess: 'https://omam2.m2kodit.fi/asuntoni/maksut/onnistui',
      urlCancel: 'https://omam2.m2kodit.fi/asuntoni/maksut/virhe',
      urlConfirmPayment: 'https://oma-api.m2kodit.fi/confirmpayment',
    }
  },
}

export default PaytrailConfig;
