import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import { ColumnContainer, RowContainer } from '../basic/Flex';
import { BasicParagraph } from '../basic/Text';
import PaddedContent from './PaddedContent';
import { DeviceSize } from '../basic/DeviceSize';
import { LOAD_STATES } from '../../context/actions';
import Loader from '../../components/basic/Loader';
import { myHouse } from '../../assets';
import { Documents, Housing } from '../../context/appContext';
import dayjs from 'dayjs';
import { downloadDocument } from '../../apis';
import ReactGA from 'react-ga';

type DescriptionProps = {
  t: (...args: any[]) => any;
  housingInfo: Housing;
  documents: Documents;
};

const Description: React.FunctionComponent<DescriptionProps> = ({ t, housingInfo, documents }) => {
  const [downloading, setDownloading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const downloadFile = async () => {
      setDownloading(true);

      if (housingInfo.houseImage && housingInfo.houseImage.fileId) {
        const documentDate = dayjs(housingInfo.houseImage.date).toISOString();
        await downloadDocument(housingInfo.houseImage.fileId.toString(), documentDate).then((response) => {
          ReactGA.event({
            category: 'Housing',
            action: 'Click external link',
            label: `https://oma-api.m2kodit.fi/documents/${housingInfo.houseImage?.fileId}/${documentDate}`,
          });
          setDownloading(false);
          return setImageUrl(response.url);
        }).catch(() => {
          setDownloading(false);
          return setImageUrl(myHouse);
        });
      }
      else {
        setDownloading(false);
        return setImageUrl(myHouse);
      }
    };

    downloadFile();
  }, [housingInfo.houseImage]);

  return (
    <Container>
      {housingInfo.loadState === (LOAD_STATES.BUSY || false) &&
      !housingInfo &&
      documents.loadState === (LOAD_STATES.BUSY || false) &&
      !documents ? (
          <Loader />
        ) : (
          <Fragment>
            <InfoContainer>
              <ImageContainer>
                { downloading ? (
                  <Loader/>
                ) : ( imageUrl === myHouse ? (
                  <ImageDefault aria-label="Kuvitus" src={myHouse} alt="Kuvitus" />
                ) : (
                  <HouseImage aria-label="Talon kuva" src={imageUrl} alt="Talon kuva" />
                ))}
              </ImageContainer>
              <BasicInfo>
                <InfoRow>
                  <InfoRowTitle>{t('basicinfo.buildingtype')}:</InfoRowTitle>
                  <InfoRowText>{housingInfo.buildingType}</InfoRowText>
                </InfoRow>
                <InfoRow>
                  <InfoRowTitle>{t('basicinfo.year')}:</InfoRowTitle>
                  <InfoRowText>{housingInfo.year}</InfoRowText>
                </InfoRow>
                <InfoRow>
                  <InfoRowTitle>{t('basicinfo.apartmentcounts')}:</InfoRowTitle>
                  <InfoRowText>{housingInfo.apartmentcounts}</InfoRowText>
                </InfoRow>
                <InfoRow>
                  <InfoRowTitle>{t('basicinfo.floors')}:</InfoRowTitle>
                  <InfoRowText>{housingInfo.floors}</InfoRowText>
                </InfoRow>
                <InfoRow>
                  <InfoRowTitle>{t('basicinfo.elevator')}:</InfoRowTitle>
                  <InfoRowText>{housingInfo.elevator ? t('yes') : t('no')}</InfoRowText>
                </InfoRow>
              </BasicInfo>
            </InfoContainer>
          </Fragment>
        )}
    </Container>
  );
};

const Container = styled(PaddedContent)`
  padding-top: 20px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 10px;
  
  @media ${DeviceSize.desktopS} {
    justify-content: flex-start;
    margin-left: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  @media ${DeviceSize.mobileL} {
    justify-content: center;
  }
`;

const HouseImage = styled.img`
  width: auto;
  height: 400px;
  max-height: 400px;
  border-radius: 10px;
  align-self: center;
  aspect-ratio: 3/2;
  background-color: ${(props) => props.theme.colors.shadow};

  @media ${DeviceSize.desktopM} {
    height: 300px;
  }
    
  @media ${DeviceSize.mobileL} {
    height: 100%;
    width: 100%;
  }
`;

const ImageDefault = styled.img`
  width: auto;
  height: 400px;
  max-height: 400px;
  border-radius: 330px;
  align-self: center;
  background-color: yellow;

  @media ${DeviceSize.desktopS} {
    height: 300px;
  }
`;

const InfoContainer = styled(ColumnContainer)`
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;

  @media ${DeviceSize.desktopS} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    margin-top: 16px;
  }
`;

const BasicInfo = styled(RowContainer)`
  margin-left: 40px;

  @media ${DeviceSize.desktopS} {
    margin: 0;
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid  ${(props) => props.theme.colors.shadow};

  @media ${DeviceSize.desktopS} {
    border-bottom: unset;
  }
`;

const InfoRowTitle = styled(BasicParagraph)`
  font-family: ${props => props.theme.text.secondaryFont};

  @media ${DeviceSize.desktopS} {
    margin: 0;
  }
`;

const InfoRowText = styled(BasicParagraph)`
  margin-left: auto;
  padding-left: 20px;

  @media ${DeviceSize.desktopS} {
    margin: 0;
    padding-left: 5px;
  }
`;

export default Description;
