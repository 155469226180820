import React, { FunctionComponent } from 'react';
import styled, { css, StyledComponent } from 'styled-components';
import { form2Style } from '../basic/Text';

type InputProps = {
  name: string;
  labelHidden?: boolean,
  placeholder: string;
  type?: string;
  value?: string;
  required?: boolean;
  inputmode?: string;
  errors?: any; // should have more precise type for this property
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (...args: any[]) => void;
  disabled?: boolean;
  readonly?: boolean;
  form?: any; // should have more precise type for this property
  validations?: any; // should have more precise type for this property
  maxWidth?: string;
  margin?: string;
  disableSubmit?: (...args: any[]) => any;
  errorText?: string;
};

const Input: React.FunctionComponent<InputProps> = ({
  name,
  labelHidden,
  type = 'text',
  value,
  errors,
  placeholder,
  onChange,
  onSubmit,
  disabled,
  readonly,
  form,
  validations,
  maxWidth,
  margin,
  disableSubmit,
}) => {
  // submit on enter
  const onKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' && onSubmit) {
      onSubmit();
    }
  };

  const update = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(e);
    form && form.setValue(name, e.target.value);

    if (typeof disableSubmit === 'function') {
      disableSubmit(e.target.value, name);
    }
  };

  const formattedPlaceholder = validations && validations.required ? `${placeholder} *` : placeholder;
  const val = value || (form && form.getValues()[name]);
  errors = errors || (form && form.errors && form.errors[name]);

  return (
    <Container maxWidth={maxWidth} margin={margin} className={formattedPlaceholder}>
      <Label 
        style={{ visibility: labelHidden ? 'hidden' : 'visible', display: labelHidden ? 'none' : 'block'}} 
        htmlFor={formattedPlaceholder}
      >
        {val && formattedPlaceholder}
      </Label>

      {form ? (
        <InputField
          id={formattedPlaceholder}
          name={name}
          type={type}
          onChange={update}
          placeholder={formattedPlaceholder}
          ref={form.register(validations)}
          onKeyDown={onKeyDown}
          disabled={!disabled ? false : disabled}
          readOnly={!readonly ? false : readonly}
          isError={errors}
        />
      ) : (
        <InputField
          id={formattedPlaceholder}
          name={name}
          type={type}
          value={value}
          onChange={update}
          placeholder={formattedPlaceholder}
          onKeyDown={onKeyDown}
          disabled={!disabled ? false : disabled}
          readOnly={!readonly ? false : readonly}
          isError={errors}
        />
      )}
      { errors && <ValidationErrorMessage>{errors.message}</ValidationErrorMessage> }
    </Container>
  );
};

type ReadOnlyTextFieldProps = {
  placeholder: string;
  value?: string;
  margin?: string;
  maxWidth?: string;
};

const ReadonlyTextField: React.FunctionComponent<ReadOnlyTextFieldProps> = ({
  value,
  placeholder,
  margin,
  maxWidth,
}) => {
  return (
    <Container maxWidth={maxWidth} margin={margin}>
      <Label>{placeholder}</Label>
      <ReadonlyTextFieldValue>{value}</ReadonlyTextFieldValue>
      <ValidationErrorMessage />
    </Container>
  );
};

type SelectProps = {
  name: string;
  type?: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
  maxWidth?: string;
  margin?: string;
  onChange?: (...args: any[]) => any;
  onSubmit?: (...args: any[]) => any;
  disabled?: boolean;
  errorText?: string;
  validations?: any;
  form?: any;
};

const Select: React.FunctionComponent<SelectProps> = ({
  name,
  value,
  placeholder,
  onChange,
  onSubmit,
  disabled,
  form,
  validations,
  maxWidth,
  margin,
  children,
}) => {
  const update = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    form && form.setValue(name, e.target.value);
  };

  const formattedPlaceholder = validations && validations.required ? `${placeholder} *` : placeholder;
  const val = value || (form && form.getValues()[name]);
  const errors = form && form.errors && form.errors[name];

  return (
    <Container className={formattedPlaceholder} maxWidth={maxWidth} margin={margin}>
      <Label htmlFor={formattedPlaceholder}>{val && formattedPlaceholder}</Label>
      <SelectField
        id={!formattedPlaceholder ? '' : formattedPlaceholder}
        name={name}
        onChange={update}
        placeholder={!formattedPlaceholder ? '' : formattedPlaceholder}
        ref={form && form.register(validations)}
        disabled={!disabled ? false : disabled}
        isError={errors}
        defaultValue=""
      >
        {formattedPlaceholder && (
          <option disabled value="">
            {formattedPlaceholder}
          </option>
        )}
        {children}
      </SelectField>

      {errors && <ValidationErrorMessage>{errors.message}</ValidationErrorMessage>}
    </Container>
  );
};

type SimpleSelectProps = {
  name: string;
  value?: string;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const SimpleSelect: FunctionComponent<SimpleSelectProps> = ({ name, value, onChange, disabled, children }) => {
  return (
    <SelectField name={name} onChange={onChange} disabled={disabled} defaultValue={value}>
      {children}
    </SelectField>
  );
};

type TextAreaProps = {
  name: string;
  placeholder: string;
  type?: string;
  required?: boolean;
  value?: string;
  maxWidth?: string;
  margin?: string;
  onChange?: (...args: any[]) => any;
  onSubmit?: (...args: any[]) => any;
  disabled?: boolean;
  errorText?: string;
  validations?: any;
  refObj?: any;
  form?: any;
  disableSubmit?: (...args: any[]) => any;
  noLabel?: boolean;
};

const Textarea: React.FunctionComponent<TextAreaProps> = ({
  name,
  value,
  placeholder,
  onChange,
  disabled,
  form,
  validations,
  maxWidth,
  margin,
  refObj,
  disableSubmit,
  noLabel
}) => {
  const update = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(e);
    form && form.setValue(name, e.target.value);

    if (typeof disableSubmit === 'function') {
      disableSubmit();
    }
  };

  const formattedPlaceholder = validations && validations.required ? `${placeholder} *` : placeholder;
  const val = value || (form && form.getValues()[name]);
  const errors = form && form.errors && form.errors[name];

  return (
    <Container maxWidth={maxWidth} margin={margin}>
      { !noLabel && <Label htmlFor={formattedPlaceholder}>{val && formattedPlaceholder}</Label> }
      <TextareaField
        id={formattedPlaceholder}
        name={name}
        onChange={update}
        placeholder={formattedPlaceholder}
        ref={refObj || (form && form.register(validations))}
        disabled={disabled}
        isError={errors}
        value={value}
        required
      />
      { errors && <ValidationErrorMessage>{errors.message}</ValidationErrorMessage> }
    </Container>
  );
};

const Container = styled.div<{ maxWidth?: string; margin?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  min-height: 80px;
  margin: ${(props) => props.margin};
`;

const Label = styled.label`
  font-family: ${(props) => props.theme.text.secondaryFont};
  min-height: 22px;
  font-size: 0.8em;
  letter-spacing: 0.8px;
  padding: 3px 22px 3p 0x;
  display: block;
`;

const inputStyle = css<{ isError?: boolean; disabled?: boolean }>`
  ${form2Style}
  border: 2px solid ${(props) => (props.isError ? props.theme.colors.error : props.theme.colors.black)};
  background-color: ${(props) => (props.disabled ? props.theme.colors.almostWhite : props.theme.colors.white)};
  border-radius: 26px;
  width: 100%;
  padding: 0 18px;
  .hide-focus &:focus {
    outline: none;
  }
  :hover {
    border-color: ${(props) =>
    props.disabled ? null : props.isError ? props.theme.colors.error : props.theme.colors.black};
  }
`;

const InputField: StyledComponent<any, any> = styled.input`
  ${inputStyle}
  height: 50px;

  :disabled {
    border: none;
    border-color: transparent;
  }
`;

const SelectField: StyledComponent<any, any> = styled.select`
  ${inputStyle}
  height: 50px;
  background-image: url("data:image/svg+xml;utf8,
    <svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
  appearance: none;
`;

const TextareaField: StyledComponent<any, any> = styled.textarea`
  ${inputStyle}
  height: 200px;
  padding-top: 18px;
`;

const ValidationErrorMessage = styled.div`
  // min-height: 22px;
  font-size: 0.8em;
  padding: 3px 22px 3px 22px;
  color: ${(props) => props.theme.colors.error};
`;

const ReadonlyTextFieldValue = styled.p`
  ${form2Style}
  font-weight: bold;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export { Input, Select, SimpleSelect, Textarea, ReadonlyTextField };
