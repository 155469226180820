import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { RowContainer } from '../basic/Flex';
import { H2, NameText } from '../basic/Text';
import { MarginContainer } from '../basic/Flex';
import { DeviceSize } from '../basic/DeviceSize';
import Accordion from '../accordion/Accordion';
import { chevronDown, arrowForward } from '../../assets/index';
import { DocumentItem } from './DocumentItem';

type DocumentListProps = {
  contents?: object[],
  header: string,
  t: (...args: any[]) => any,
  type: string,
  url?: string,
  addedtime?: string,
  limit?: number,
  className?: string,
  prefix?: string,
  columns?: number,
  backgroundColor?: string,
  chevronIcon?: string,
  accordion?: boolean,
  open?: boolean,
};

const DocumentList: React.FunctionComponent<DocumentListProps> = ({
  className,
  contents,
  header,
  t,
  type,
  url,
  addedtime,
  limit = 5,
  prefix, // Appears before each document item, e.g. list bullet point
  columns = 2,
  backgroundColor,
  chevronIcon,
  accordion = false,
  open = false,
}) => {
  const [showState, setShowState] = useState(false);
  const showAll = () => {
    if (!showState) {
      setShowState(true);
    }
  };
  const DocumentItems = () => {
    return (
      <Fragment>
        {((!contents || contents.length === 0)) && (
          <NameText>{t(`noexist.${type}`)}</NameText>
        )}

        <DocumentsContainer className={className} columns={columns}>
          {contents &&
            contents.map((item, index) => (
              <DocumentItem
                key={index}
                type={type}
                item={item}
                icon={arrowForward}
                display={(index < limit || showState ? 'flex' : 'none')}
                prefix={prefix}
              />
            ))
          }
          {contents && contents.length > limit && !showState && type !== 'external' && (
            <ButtonContainer>
              <ShowButton onClick={showAll}> {t('showall.placeholder')} </ShowButton>
            </ButtonContainer>
          )}
        </DocumentsContainer>
      </Fragment>
    );
  };
  return (
    <Wrapper>
      { accordion ? (
        <Wrapper>
          { header && <AccordionMainHeader>{header}</AccordionMainHeader> }
          <Accordion
            color={backgroundColor}
            icon={chevronIcon ? chevronIcon : chevronDown}
            title={header || t('documents.title') }
            open={open}
          >
            <DocumentItems />
          </Accordion>
          <DesktopContainer>
            <DocumentItems />
          </DesktopContainer>
        </Wrapper>
      ) : (
        <Wrapper>
          { header && <MainHeader>{header}</MainHeader> }
          <DocumentItems />
        </Wrapper>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
`;

const DocumentsContainer = styled.div<{columns: number}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  
  @media ${DeviceSize.desktopM} {
    padding:0;
    justify-content: end;
  }

  > div {
    width: calc(${ props => (100 / props.columns) }% - 20px);
    max-width: calc(${ props => (100 / props.columns) }% - 20px);
    word-break: break-word;
  }
`;

const MainHeader = styled(H2)`
    padding-bottom: 20px;
`;

const AccordionMainHeader = styled(H2)`
  @media ${DeviceSize.desktopM} {
    padding-top: 0;
  }
  @media ${DeviceSize.desktopS} {
    display: none;
  }
`;

const ButtonContainer = styled(RowContainer)`
  flex-direction: row;
  padding: 24px;

  @media ${DeviceSize.desktopM} {
    justify-content: end;
    width: unset !important;
    max-width: unset !important;
  }
`;

const ShowButton = styled(H2)`
  font-family: ${props => props.theme.text.primaryFont};
  letter-spacing: initial;
  font-weight: 700;
  font-size: 20px;
  text-align: right;
  
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
  
  @media ${DeviceSize.desktopS} {
    font-size: 16px;
  }
`;

const DesktopContainer = styled(MarginContainer)`
  margin-top: 20px;
  
  @media ${DeviceSize.desktopS} {
    display: none;
  }
`;

export default DocumentList;
