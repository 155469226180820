import React, { useEffect, useState } from 'react';
import { useApp, PaymentRequest, PaymentCustomer, PaymentAddress } from '../context/appContext';
import { useMessageGetter } from 'react-message-context';
import {H1, H2, H3, BasicParagraph, BasicText} from '../components/basic/Text';
import { Button, LinkButton } from '../components/button/Button';
import Loader from '../components/basic/Loader';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import { Input } from '../components/input/Input';
import { RouteComponentProps } from 'react-router';
import { loadDebts, getAgreement, loadApartment, LOAD_STATES } from '../context/actions';
import { createPayment } from '../apis';
import styled from 'styled-components';
import { formatEuros } from '../components/billing/BillingPeriod';
import { v4 as uuidv4 } from 'uuid';

import PaytrailConfig from '../PaytrailConfig';
import { Routes } from '../constants/routes';

const environment = process.env.REACT_APP_ENV;
const paytrailConfig = PaytrailConfig[environment].paytrail;

const getPaytrailFields = async (
  customer: PaymentCustomer, 
  invoicingAddress: PaymentAddress,
  amount: number, 
  referenceNumber: string, 
): Promise<object> => {
  const trimmedRefNum = referenceNumber.replace(/\s/g, '');
  
  const paymentRequestBody: PaymentRequest = {
    stamp: uuidv4(), // "Merchant unique ID" according to docs.
    reference: referenceNumber, 
    amount: Math.floor(amount * 100),
    currency: 'EUR',  // only EUR supported
    language: sessionStorage.getItem('currentLanguage')?.toUpperCase() || 'FI', 
    orderId: trimmedRefNum.slice(trimmedRefNum.length - 4) + '_' + String(Date.now()),
    items: [
      {
        unitPrice: Math.floor(amount * 100), // Currency minor units i.e. cents
        units: 1,
        vatPercentage: 0, 
        productCode: '1',
        stamp: uuidv4(), 
        reference: referenceNumber, 
        merchant: '' // Submerchant ID; Added in the backend.
      }
    ],
    customer,
    invoicingAddress,
    redirectUrls: { 
      success: paytrailConfig.urlSuccess,
      cancel: paytrailConfig.urlCancel
    },
    callbackUrls: { // Payment gateway pings this URL after succes/fail 
      success: paytrailConfig.urlConfirmPayment,
      cancel: paytrailConfig.urlConfirmPayment
    }
  };

  return paymentRequestBody
};

const Payment = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('billing.payment');
  const [{ user, debts, agreement, apartment }, dispatch] = useApp();
  const [fetchingProviders, setFetchingProviders] = useState<boolean>(false);
  const minAmount = 0;
  const debtAmount = debts.amountEuros || 0;
  const [amountToPayInputValue, setAmountToPayInputValue] = useState<string>(String(minAmount));
  const [amountToPay, setAmountToPay] = useState<number>(minAmount);
  const [paymentLink,  setPaymentLink] = useState<string | null>(null);
  const [paymentProviders,  setPaymentProviders] = useState<Array<any>>([]);
  const [paytrailError,  setPaytrailError] = useState<string>('');

  useEffect(() => {
    loadDebts(dispatch);
    getAgreement(dispatch);
    loadApartment(dispatch);
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const amount = debtAmount && debtAmount > 0 ? debtAmount : minAmount;
    setAmountToPay(amount);
    setAmountToPayInputValue(amount.toFixed(2));
  }, [debtAmount]);

  // Creates a payment with the Paytrail API, returns list of 
  // available payment providers AND a link to external payment portal.
  const initPayment = async () => {
    if (agreement.referenceNumber && !Number.isNaN(amountToPay) && amountToPay >= 1) {
      setFetchingProviders(true);

      const paymentCustomer: PaymentCustomer = {
        email: '', // Left empty to prevent Paytrail from sending its own confirmation emails 
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        phone: user.attributes.phone_number,
      }

      const paymentAddress: PaymentAddress = {
        streetAddress: apartment.info.address,
        postalCode: apartment.info.zip,
        city: apartment.info.city || 'unknown',
        country: 'FI'
      }

      const paytrailBody: any = await getPaytrailFields(
        paymentCustomer,
        paymentAddress,
        amountToPay, 
        agreement.referenceNumber,
      );
      
      const response = await createPayment(paytrailBody);
      console.log(response)

      if(!response.error) {
        setPaymentLink(response.data.href);
        setPaymentProviders(response.data.providers);
      } else {
        console.error(response.error);
        setPaytrailError(response.error);
      }

      setFetchingProviders(false);
    }
  };

  const onChangePaymentAmount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAmountToPayInputValue(e.target.value);
    const amount = parseFloat(e.target.value.replace(',', '.'));
    setAmountToPay(Number.isNaN(amount) || amount < minAmount ? minAmount : amount);
  };

  const parameterToInput = (param: any) => <input type='hidden' name={param.name} value={param.value} />;

  if (
    agreement.loadState === LOAD_STATES.ERROR ||
    (agreement.loadState === LOAD_STATES.READY && !agreement.referenceNumber)
  ) {
    return (
      <PageContainer history={history}>
        <PaddedContent>
          <H1>{t('title')}</H1>
          <BasicParagraph>{t('errorhelptext')}</BasicParagraph>
        </PaddedContent>
      </PageContainer>
    );
  } else {
    return (
      <PageContainer history={history}>
        <PaddedContent>
          {agreement.loadState === LOAD_STATES.BUSY || debts.loadState === LOAD_STATES.BUSY ? (
            <Loader />
          ) : (
            <>
              <H1>{t('title')}</H1>

              { paymentLink === null ? ( 
                <>
                  <PaymentSection>
                    <BasicText>{t('description')}</BasicText>
                    <BasicParagraph dangerouslySetInnerHTML={{ __html: t('description2')}} />
                    {debtAmount > 0 && (
                      <Actions>
                        <LinkButton href={Routes.Apartment.BILLING_DEBT_DETAILS}>{t('showbilling')}</LinkButton>
                      </Actions>
                    )}
                  </PaymentSection>

                  <PaymentContainer>
                    { fetchingProviders ? (
                      <Loader />
                    ) : (
                      <>
                        <H2>{t('subtitle1')}</H2>

                        { paytrailError.length > 0 && (
                          <ErrorContainer>Error: { paytrailError }</ErrorContainer>
                        )}
         
                        <PaymentSection>
                          {!!(debtAmount && debtAmount > 0) && (
                            <DebtAmountInfo>
                              <H3>{t('description1')}</H3>
                              <H2>{formatEuros(debtAmount)}</H2>
                            </DebtAmountInfo>
                          )}

                          <PaymentAmountContainer>
                            <Input
                              type="text"
                              inputmode="decimal"
                              name="amount"
                              placeholder={t('tobepaid')}
                              maxWidth="30rem"
                              onChange={onChangePaymentAmount}
                              value={amountToPayInputValue}
                            />
                            {amountToPay < 1 && <span>{t('amounttoolow')}</span>}
                          </PaymentAmountContainer>
                        </PaymentSection>

                        <PaymentSection>
                          <i>{t('paytrailinfo')}</i>

                          <Button onClick={() => initPayment()}>
                            {t('continue')}
                          </Button>
                        </PaymentSection>
                      </>
                    )}
                  </PaymentContainer>
                </>
              ) : ( 
                <>
                  <BasicParagraph>
                    <BasicText>{t('description')}</BasicText>
                    <BasicParagraph><div dangerouslySetInnerHTML={{ __html: t('description2')}} /></BasicParagraph>

                    <H2>{t('subtitle2')}</H2>
                    <PaymentMethodContainer>
                      { paymentProviders.map(p => (
                        <form method='POST' action={p.url}>
                          { p.parameters.map(parameterToInput) }
                          <PaymentMethodLink type="submit"><img src={p.svg} alt={p.name} /></PaymentMethodLink>
                        </form>
                      )) }
                    </PaymentMethodContainer>
                  </BasicParagraph>
                </>
              )}
            </>
          )}
        </PaddedContent>
      </PageContainer>
    );
  }
};

const Actions = styled.div`
  margin-bottom: 2rem;

  button {
    margin: 0;
  }
`;

const PaymentSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PaymentContainer = styled.div`
  background: ${props => props.theme.colors.apila};
  border-radius: 24px;
  padding: 24px 32px;
`

const DebtAmountInfo = styled.div`
  margin-top: 1rem;
`;

const PaymentAmountContainer = styled.div`
  margin: 1.5rem 0;
`;

const PaymentMethodContainer = styled.div`
  padding-top: 30px;
  --gap: 8px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
`;

const PaymentMethodLink = styled(Button)`
  display: flex;
  background-color: ${(props) => props.theme.colors.almostWhite};
  border-radius: 24px;
  margin: var(--gap) 0 0 var(--gap);
  padding: 1rem;
  width: 10rem;
  height: 8rem;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
  }

  img {
    object-fit: contain;
    width: 8rem;
  }
`;

const ErrorContainer = styled.div`
  padding: 12px 0;
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.error};
`

export default Payment;
