import React, { useState } from 'react';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components';
import { blackWarningIcon, blackCross, chevronDownBlack } from './../../assets';
import { BasicParagraph, H2 } from '../basic/Text';
import { DeviceSize } from '../basic/DeviceSize';
const defectFormUrl = 'https://app.formup.fi/fillautolinkreport/8Ck0NbETFWDpK84iyVNi';

const FaultFormBulletin: React.FunctionComponent = () => {
  const t = useMessageGetter('faultformbulletin');
  const [ open, setOpen ] = useState(false);

  return (
    <BulletinContainer>
      <BulletinHeader onClick={() => setOpen(!open)}>
        <BulletinIcon alt="Ilmoitus" src={ blackWarningIcon } />
        <BulletinTitleContainer>
          <BulletinTitle>
            {t('title')}
          </BulletinTitle>
          <BasicParagraph>
            {t('description')}<br/>
            {t('description2')}
          </BasicParagraph>
          <LinkText onClick={() => setOpen(!open)}>
            { open ? t('close') : t('open') }
          </LinkText>
        </BulletinTitleContainer>
        <BulletinToggle
          onClick={() => setOpen(!open)}
          alt="Piilota/näytä lomake"
          src={ open ? blackCross : chevronDownBlack }
        />
      </BulletinHeader>
      <BulletinBody open={open}>
        <IFrameContainer title='Vika/puute lomake' src={defectFormUrl} />
        <LinkText onClick={() => setOpen(!open)} >
          { t('close') }
        </LinkText>
      </BulletinBody>
      
    </BulletinContainer>
  )
};

const BulletinContainer = styled.div`
  transition: 1s;
  position: relative;
  margin-bottom: 40px;
  border-radius: 20px;
  padding: 20px;
  background-color: ${props => props.theme.colors.lemon};
  color: ${props => props.theme.colors.black};
`;

const BulletinHeader = styled.div`
  display: flex;
  align-items: center;
`;

const BulletinIcon = styled.img`
  margin-right: 20px;
`;

const BulletinToggle = styled.img`
  cursor: pointer;
  margin-left: 20px;
`;

const BulletinTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BulletinTitle = styled(H2)`
  display: inline;
  font-size: 24px;
  color: ${props => props.theme.colors.black};

  @media ${DeviceSize.mobileL} {
    font-size: 16px;
  }
`;

const BulletinBody = styled.div<{ open: boolean }>`
  padding: 20px;
  color: ${props => props.theme.colors.black};

  // Handle opening with display to fix screen jumping
  ${props => props.open && `
    display: block;
  `}
  ${props => !props.open && `
   display: none;
  `}

  @media ${DeviceSize.mobileL} {
    padding: 20px 0;
  }
`;

const IFrameContainer = styled.iframe`
  border: none;
  height: 70vh;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 24px;
  background: #f9f9f9;
`;

const LinkText = styled(BasicParagraph)`
  font-size: 20px;
  margin: 0;
  text-align: center;
  text-decoration: underline;

  :hover {
    cursor: pointer;
  }

  @media ${DeviceSize.mobileL} {
    font-size: 16px;
  }
`;

export default FaultFormBulletin;
