// AWS
import API from '@aws-amplify/api';
import { Credentials, Signer } from '@aws-amplify/core';

/**
 * Sign AWS request for execution.
 *
 * @param endpointSuffix    url endpoint (suffix)
 * @param executeAfterFunc  function to execute after AWS request signer has been called.
 *                          The function is called with the signed url.
 */
export const signAwsUrl = (endpointSuffix: string, executeAfterFunc: (signedUrl: any) => void) => {
  Promise.all([API.endpoint('apigateway'), Credentials.get()]).then(([endpointPrefix, credentials]) => {
    const url = Signer.signUrl(
      endpointPrefix + endpointSuffix,
      {
        access_key: credentials.accessKeyId,
        secret_key: credentials.secretAccessKey,
        session_token: credentials.sessionToken,
      },
      {
        region: 'eu-central-1',
        service: 'execute-api',
      }
    );

    executeAfterFunc(url);
  });
};
