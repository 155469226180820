import { DeviceSize } from './DeviceSize';
import { css } from 'styled-components';

const pageElement = css`
  max-width: ${props => props.theme.layout.maxPageWidth}px;
  padding-left: ${props => props.theme.layout.pagePadding}px;
  padding-right: ${props => props.theme.layout.pagePadding}px;
  margin: auto;
  
  @media ${DeviceSize.desktopM} {
    padding-right: 10%;
    padding-left: 10%;
  }
  
  @media ${DeviceSize.tabletM} {
    padding-right: 10%;
    padding-left: 10%;
  }
  
  @media ${DeviceSize.mobileL} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export default pageElement;
