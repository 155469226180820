import React, { useState } from 'react';
import {
  disabledLeft,
  disabledRight,
  largeArrowRight,
  largeArrowLeft,
  leftArrowHover,
  rightArrowHover
} from '../../assets/index';
import { CarouselContent } from '../../context/appContext';
import styled, { css } from 'styled-components';

export const LeftArrowDisabled = () => {
  return <BackArrow type='image' src={disabledLeft} alt='Disabled left arrow' disabled/>;
};

export const LeftArrowButton = ({navigateToPrev}: CarouselContent) => {
  const [isHovered, setHover] = useState(false);

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {isHovered ?
        <BackArrow type='image' src={leftArrowHover} alt='Left arrow' onClick={navigateToPrev}/>
        :
        <BackArrow type='image' src={largeArrowLeft} alt='Left arrow' onClick={navigateToPrev}/>
      }
    </div>
  );
};

export const RightArrowDisabled = () => {
  return <NextArrow type='image' src={disabledRight} alt='Disabled right arrow' disabled/>
};

export const RightArrowButton = ({navigateToNext}: CarouselContent) => {
  const [isHovered, setHover] = useState(false);

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {isHovered ?
        <NextArrow type='image' src={rightArrowHover} alt='Right arrow' onClick={navigateToNext}/>
        :
        <NextArrow type='image' src={largeArrowRight} alt='Right arrow' onClick={navigateToNext}/>
      }
    </div>
  )
};


const ArrowStyle = css`
  position: absolute;
  top: 50%;
  z-index: 999;
`;

const NextArrow = styled.input`
  ${ArrowStyle}
  right: 25px;
`;

const BackArrow = styled.input`
  ${ArrowStyle}
  left: 25px;
`;