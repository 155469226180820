import { SwitchProps, useHistory, useLocation } from 'react-router-dom';
import { changeLanguage, isSupportedLanguageCode } from '../../utils';

/**
 * Component for handling lang-query parameter for any route.
 *
 * NOTE: There are probably loads of better ways to do this, but because of the previously chosen
 * way for handling language selection, this approach is taken for now to avoid massive refactorings.
 */
const LanguageQueryParamHandler = ({ children }: SwitchProps): any => {
  const LANG_PARAM = 'lang';

  const location = useLocation();
  const history = useHistory();

  const urlParams = new URLSearchParams(location.search);

  if (urlParams.has(LANG_PARAM)) {
    const langCode = urlParams.get(LANG_PARAM);

    const selected = sessionStorage.getItem('currentLanguage');
    const requiresLanguageChange =
      langCode && isSupportedLanguageCode(langCode) && (!selected || selected !== langCode);

    if (requiresLanguageChange) {
      changeLanguage(langCode);
      
      return null;
    } else {
      urlParams.delete(LANG_PARAM);

      history.replace({
        search: urlParams.toString(),
      });
    }
  }

  return children;
};

export default LanguageQueryParamHandler;
