import styled, { ThemedStyledProps } from 'styled-components';
import { baseFontStyle } from '../basic/Text';

const Button = styled.button`
  ${baseFontStyle};
  font-family: ${props => props.theme.text.secondaryFont};
  color: ${props => props.theme.colors.m2White};
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1.05px;
  border-radius: 25px;
  padding: 0 25px 0 25px;
  height: 50px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  border: 0;
  min-width: 200px;
  align-items: center;

  background-color: ${props => props.theme.colors.m2Black};

  .hide-focus &:focus {
    outline: none;
  }

  :hover {
    background-color: ${props => !props.disabled && props.theme.colors.coal};
    color: ${props => !props.disabled && props.theme.colors.m2White};
    cursor: ${props => !props.disabled && 'pointer'};
    box-shadow: 4px 4px 4px ${props => props.theme.colors.shadow};
  }

  :active {
    background-color: ${props => !props.disabled && props.theme.colors.blueGray};
  }
  
  :disabled {
    background-color: ${props => props.theme.colors.lightGray};
    cursor: default;
    box-shadow: none;
  }
`;

const getButtonColor = (props: ThemedStyledProps<{ disabled?: boolean }, any>) => {
  return props.disabled ? props.theme.colors.gray : props.theme.colors.m2Black;
};

const LinkButton = styled.a`
  ${baseFontStyle};
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.text.secondaryFont};
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1.05px;
  border-radius: 25px;
  padding: 0 25px 0 25px;
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  border: 0;
  min-width: 200px;

  background-color: ${props => getButtonColor(props)};

  .hide-focus &:focus {
    outline: none;
  }

  :hover {
    background-color: ${props => props.theme.colors.coal};
    color: ${props => props.theme.colors.white};
    cursor: pointer;
    box-shadow: 4px 4px 4px ${props => props.theme.colors.shadow};
  }

  :active {
    background-color: ${props => props.theme.colors.black};
  }

  line-height: 50px;
`;

export { Button, LinkButton };
export default Button;
