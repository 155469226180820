import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import PageContainer from '../PageContainer';
import PaddedContent from '../../components/housing/PaddedContent';
import WordPressPage from '../../components/wordpress/wordpress';
import { DeviceSize } from '../../components/basic/DeviceSize';
import { useMessageGetter } from 'react-message-context';
import HousingContact from '../../components/housing/HousingContact';
import { useApp } from '../../context/appContext';
import { loadHousing } from '../../context/actions';
import Button from '../../components/button/Button';

const workUrl = 'https://ysaatio.fi/asukkaalle/y-saation-tarjoamia-tyollistymismahdollisuuksia';
const uurasUrl = 'https://ysaatio.fi/asunnot/uuras-tyollistymisohjelma/';

const WorkPage = ({ history }: RouteComponentProps) => {
  const h = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const t = useMessageGetter('work');
  const [{ housing }, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
  }, [dispatch]);
  
  const openUrl = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <WordPressPage slug={'work'}/>
        <ButtonContainer>
          <LinkButton onClick={() => openUrl(uurasUrl)}>
            {t('more')}
          </LinkButton>
          <LinkButton onClick={() => openUrl(workUrl)}>
            {t('work')}
          </LinkButton>
        </ButtonContainer>
      </PaddedContent>
      { housing && <HousingContact t={h} housingContacts={housing} c={c} /> }
    </PageContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DeviceSize.mobileL} {
    margin-top: 40px;
  }
`;

const LinkButton = styled(Button)`
  width: 400px;
  margin: 10px 0 0 0;

  @media ${DeviceSize.mobileL} {
    margin: 5px 0;
    width: 100%;
  }
`;


export default WorkPage;
