import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import HousingFooter from '../components/footer/HousingFooter';
import { History } from 'history';
import Header from '../components/header/Header';
import { useApp } from '../context/appContext';
import Notifications from '../components/header/Notifications';
import { illustration1, illustration2, illustration3, illustration4 } from '../assets/index';
import { useMessageGetter } from 'react-message-context';
import { isTrue } from '../utils';
import { updateVisitedStatus } from '../context/actions';
import Slider from '../components/slider/Slider';
import { DeviceSize } from '../components/basic/DeviceSize';
import Bulletins from '../components/bulletin/Bulletins';
import { hasExpiringAgreement } from '../utils';
import { WarningPanel } from '../components/billing/WarningPanel';
import { LinkButton } from '../components/button/Button';
import { Routes } from '../constants/routes';

const PageContainer: FunctionComponent<{ history: History }> = ({ history, children }) => {
  const [{ notifications, user }, dispatch] = useApp();
  const [showOnboard, setShowOnboard] = useState(false);
  const t = useMessageGetter('onboarding');
  const msgWarning = useMessageGetter('warning');

  const carouselContent = [
    {
      id: 0,
      title: `${t('welcome')} ${user.attributes.given_name}!`,
      text: `${t('info1')}`,
      index: 1,
      image: `${illustration1}`,
    },
    { id: 1, title: `${t('uptodate')}`, text: `${t('info2')}`, index: 2, image: `${illustration2}` },
    { id: 2, title: `${t('rental')}`, text: `${t('info3')}`, index: 3, image: `${illustration3}` },
    { id: 3, title: `${t('developing')}`, text: `${t('info4')}`, index: 4, image: `${illustration4}` },
  ];

  useEffect(() => {
    const result = isTrue(user.attributes.visited);
    if (!result) {
      setShowOnboard(true);
    }
  }, [dispatch, user.attributes.visited]);

  const closeElement = () => {
    setShowOnboard(false);
    updateVisitedStatus(dispatch, 'yes');
  };

  const showWarning = [ // Only show the billing warning on these pages
    Routes.Home.HOME,
    Routes.Apartment.BILLING,
    Routes.Apartment.HOME,
  ].includes(window.location.pathname);

  return (
    <Container>
      <Header history={history} />
      {showOnboard && <Slider content={carouselContent} hideCarousel={closeElement} />}
      <div className="content">
        { !hasExpiringAgreement(user) && showWarning && (
          <Warning t={msgWarning}>
            <Actions>
              <PaymentButton
                href={Routes.Apartment.PAYMENT}
                onClick={() =>
                  ReactGA.event({
                    category: 'Billing',
                    action: 'Redirect to payment page',
                    label: 'Warning banner',
                  })
                }
              >
                <span>{msgWarning('gotopayment')} <ArrowIcon>&#10140;</ArrowIcon></span>
              </PaymentButton>
            </Actions>
          </Warning>
        ) }
        { !hasExpiringAgreement(user) && <Bulletins /> }
        <Content>{children}</Content>
        { !hasExpiringAgreement(user) && <HousingFooter history={history} /> }
        <Notifications notifications={notifications} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  // background-color: ${(props) => props.theme.colors.lightYellow};

  @media print {
    background: none;
  }
`;

const Warning = styled(WarningPanel)`
  background-color: ${(props) => props.theme.colors.strawberry};
  z-index: 100;
  position: relative;
  margin-top: 40px;

  @media print {
    display: none !important;
  }
  
  @media ${DeviceSize.desktopM} {
    margin-top: 0;
  }
`;

const ArrowIcon = styled.span`
  position: relative;
  margin-left: 10px;
  top: 1px;
`

const Actions = styled.p`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;

  @media ${DeviceSize.mobileL} {
    padding-top: 0;
    margin-top: 0;
  }
`;

const PaymentButton = styled(LinkButton)`
  min-width: 250px;
  font-size: 18px;
  background-color: ${(props) => props.theme.colors.coal};
  color: ${(props) => props.theme.colors.white};
  text-transform: none;

  @media ${DeviceSize.mobileL} {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
  }

  :hover {
    background-color: ${(props) => props.theme.colors.black};
    box-shadow: 4px 4px 4px ${(props) => props.theme.colors.shadow};
  }
`;

const Content = styled.div`
  min-height: 50vh;
  width: 100%;
  z-index: 1;
  position: relative;

  @media ${DeviceSize.desktopS} {
    margin-top: 20px;
  }
  
  @media print {
    margin-top: 0px;
  }
`;

export default PageContainer;
