import React, { useRef, Fragment } from 'react';
import styled from 'styled-components';
import { DeviceSize } from '../basic/DeviceSize';
import { H2 } from '../basic/Text';

type AccordionProps = {
  title: string;
  icon: string;
  scrollOnOpen?: boolean;
  color?: string;
  open: boolean;
}

const Accordion: React.FunctionComponent<AccordionProps> = ({
  title,
  icon,
  scrollOnOpen = false,
  color,
  open,
  children
})=> {
  const scrollToRef = useRef<null | HTMLDivElement>(null);

  const showContents = () => {
    if(!open && scrollToRef.current && scrollOnOpen) {
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  };

  return (
    <Fragment>
      <DropdownContainer onClick={showContents} ref={scrollToRef} color={color}>
        <H2>{title}</H2>
        <Icon className={`chevron ${open ? 'opened' : ''}`} src={icon} alt="Avausikoni"/>
      </DropdownContainer>
      {open &&
      <Content>
        {children}
      </Content>
      }
    </Fragment>
  )
};

const DropdownContainer = styled.div<{color?: string}>`
  background: ${props => props.color ? props.color : props.theme.colors.m2Black};
  border-radius: 40px;
  height: 60px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  display: none;
  
  :hover {
    cursor: pointer;
    background: ${ props => props.color ? '' : props.theme.colors.coal};
  }
  
  > H2 {
    color: ${ props => props.color ? props.theme.colors.m2Black : props.theme.colors.white};
    font-size: 18px;
  }
  
  @media ${DeviceSize.desktopS} {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
  }

  @media ${DeviceSize.mobileL} {
    height: auto;
    padding: 10px 24px;
  }
 
  @media print {
    display: none;
  }
`;

const Content = styled.div<{top?: string, bottom?: string}>`
  padding-top: ${props => props.top ? props.top : '10px'};
  padding-bottom: ${props => props.bottom ? props.bottom : '10px'};
  display: none;
  
  @media ${DeviceSize.desktopS} {
    display: block;
  }
`;

const Icon = styled.img`
  margin: auto 0;
  height: 12px;
  
  &.opened {
    transform: rotate(180deg);
  }
`;

export default Accordion;
