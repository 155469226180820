const APARTMENT_HOME = '/asuntoni';
const HOUSING_HOME = '/taloni';
const LIVING_HOME = '/asuminen';
const WORK_HOME = '/toita';
const M2_HOME = '/m2';

export const Routes = {
  Home: {
    HOME: '/',
    MESSAGE_SUCCESS: '/onnistui',
  },
  SIGN_IN: '/signin',
  FEEDBACK: '/feedback',
  APARTMENT_CHANGE_APPROVAL: '/vaihtoilmoitukset/:id/:secret',
  PROFILE: '/omat-tiedot',
  SEARCH: '/haku/:query',

  Apartment: {
    HOME: APARTMENT_HOME,
    BILLING: `${APARTMENT_HOME}/maksut`,
    BILLING_SUCCESS: `${APARTMENT_HOME}/maksut/onnistui`,
    BILLING_ERROR: `${APARTMENT_HOME}/maksut/virhe`,
    BILLING_DEBT_DETAILS: `${APARTMENT_HOME}/maksut/velkaerittely`,
    MESSAGES: `${APARTMENT_HOME}/vikailmoitukset`,
    PAYMENT: `${APARTMENT_HOME}/maksaminen`,
    CHANGE: `${APARTMENT_HOME}/vaihda`,
    SWAP: `${APARTMENT_HOME}/vaihda/asunnonvaihto`,
    NEW: `${APARTMENT_HOME}/vaihda/uusi-hakemus`,
    FORMS: `${APARTMENT_HOME}/lomakkeet`,
    BOOKINGS_CALENDAR: `${APARTMENT_HOME}/varaukseni`,
    WATER_METER: `${APARTMENT_HOME}/vesimittari`,
  },
  Housing: {
    HOME: HOUSING_HOME,
    NEWS: `${HOUSING_HOME}/tiedotteet`,
    SAFETY: `${HOUSING_HOME}/turvallisuus`,
    PARKING: `${HOUSING_HOME}/pysakointi`,
    LAUNDRY: `${HOUSING_HOME}/talopesula`,
    SAUNA: `${HOUSING_HOME}/sauna`,
    INTERNET: `${HOUSING_HOME}/netti`,
    STORAGE: `${HOUSING_HOME}/varastointi`,
    RENT_SUMMARY: `${HOUSING_HOME}/kooste-vuokrasta`,
    MANUALS: `${HOUSING_HOME}/ohjeet-ja-dokumentit`,
  },
  Living: {
    HOME: LIVING_HOME,
    BENEFITS: `${LIVING_HOME}/edut`,
    EVENTS: `${LIVING_HOME}/tapahtumat`,
    ACTIVITIES: `${LIVING_HOME}/asukastoiminta`,
  },
  // TODO: Fix HOME route logic
  WORK: `${WORK_HOME}/toita-asukkaille`,
  // TODO: Fix HOME route logic
  M2Homes: {
    HOME: M2_HOME,
    ABOUT: `${M2_HOME}/tietoa-meista`,
    SUSTAINABILITY: `${M2_HOME}/vastuullisuus`,
  },
};
