import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import WordPressPage from '../components/wordpress/wordpress';
import { useApp } from '../context/appContext';
import { getTenantModules, loadHousing, loadHousingCoop } from '../context/actions';
import HousingContact from '../components/housing/HousingContact';
import { DeviceSize } from '../components/basic/DeviceSize';
import { Routes } from '../constants/routes';
import { LinkButton } from '../components/button/Button';

const HousingLaundry = ({ history }: RouteComponentProps) => {
  const b = useMessageGetter('bookings.calendar');
  const t = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ housing, housingInfo, modules }, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
    loadHousingCoop(dispatch);
    if (!modules.modules) {
      getTenantModules(dispatch);
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const getLaundrySlug = (laundry: boolean | undefined, hasCalendar: boolean | undefined) => {
    // Catch undefined to wait until data is fetched
    if (laundry === undefined || hasCalendar === undefined) return;
    if (laundry && hasCalendar) return 'one4all-pesutupa';
    if (laundry) return 'pesutupa';
    return 'ei-pesutupa';
  }
  
  let slug = getLaundrySlug(housingInfo?.laundry, (modules.modules && modules.modules.length > 0));

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <Container>
          <WordPressPage slug={slug} />
          { modules.modules && modules.modules.length > 0 && housingInfo.laundry &&
            <ButtonContainer>
              <BookingsButton href={Routes.Apartment.BOOKINGS_CALENDAR}>
                <ButtonLabel>
                  <span>{b('navigate')}</span>
                </ButtonLabel>
              </BookingsButton>
            </ButtonContainer>
          }
        </Container>
      </PaddedContent>
      {housing && <HousingContact t={t} housingContacts={housing} c={c} />}
    </PageContainer>
  );
};

const Container = styled.div`
  
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
`;

const BookingsButton = styled(LinkButton)`
  display: inline-flex;
  margin: 0;
`;

const ButtonLabel = styled.div`
  display: flex;
  justify-content: center;

  + img {
    padding-left: 10px;
  }

  @media ${DeviceSize.mobileL} {
    font-size: 17px;
  }
`;

export default HousingLaundry;
