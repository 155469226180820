/* eslint-disable */
var en = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1], t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
  if (ord) return (n10 == 1 && n100 != 11) ? 'one'
      : (n10 == 2 && n100 != 12) ? 'two'
      : (n10 == 3 && n100 != 13) ? 'few'
      : 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var fi = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var number = function (value, name, offset) {
  if (!offset) return value;
  if (isNaN(value)) throw new Error("Can't apply offset:" + offset + ' to argument `' + name + '` with non-numerical value ' + JSON.stringify(value) + '.');
  return value - offset;
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

export default {
  en: {
    navigation: {
      home: {
        title: function(d) { return "Front page"; }
      },
      apartment: {
        title: function(d) { return "My apartment"; },
        agreement: function(d) { return "My lease agreement"; },
        billing: function(d) { return "My rent and payment information"; },
        change: function(d) { return "Apartment exchange"; },
        watermeter: function(d) { return "Information on water billing"; },
        termination: function(d) { return "Terminate rental agreement"; },
        bookingsCalendar: function(d) { return "My reservations"; },
        messages: function(d) { return "Fault reports"; },
        forms: function(d) { return "Forms"; }
      },
      housing: {
        title: function(d) { return "My building"; },
        myhousing: function(d) { return "Information"; },
        news: function(d) { return "News"; },
        safety: function(d) { return "Safety and evacuation"; },
        parking: function(d) { return "Resident parking"; },
        laundry: function(d) { return "Laundry room"; },
        sauna: function(d) { return "Sauna"; },
        ownhouse: function(d) { return "Own house"; },
        storage: function(d) { return "Storage"; },
        internet: function(d) { return "Internet connection"; },
        rentSummary: function(d) { return "Information on rent formation"; },
        bookingsCalendar: function(d) { return "My reservations"; },
        manuals: function(d) { return "User manuals and documents"; }
      },
      living: {
        title: function(d) { return "Living in M2-Homes"; },
        benefits: function(d) { return "Tenant benefits"; },
        events: function(d) { return "Tenant events"; },
        activities: function(d) { return "Community activities"; }
      },
      m2homes: {
        title: function(d) { return "M2-Homes"; },
        about: function(d) { return "About M2-Homes"; },
        sustainability: function(d) { return "Sustainability"; }
      },
      messages: {
        title: function(d) { return "Fault reports"; }
      },
      profileAlt: {
        title: function(d) { return "My information"; }
      },
      signout: {
        title: function(d) { return "Sign out"; }
      },
      profileMain: {
        title: function(d) { return "My information"; }
      },
      finnish: function(d) { return "Suomeksi"; },
      english: function(d) { return "In English"; }
    },
    faultformbulletin: {
      title: function(d) { return "There may still be some work to be done at home. If so, we apologise!"; },
      description: function(d) { return "A paper version of a Guarantee Check form has been delivered to your home. Instead of a paper form, you can use the electric form to report any faults and defects you may find in your home. The information will be redirected to the builder, who will ensure that any issues that need finishing are dealt with."; },
      description2: function(d) { return "Return the form even if there are no defects. The form must be completed within two weeks of the start of the lease."; },
      open: function(d) { return "Open the Guarantee Check form"; },
      close: function(d) { return "Close the Guarantee Check form"; }
    },
    moveinnotice: {
      description: function(d) { return "Your new home will be completed by " + d.startDate + ". The contents of these Home folders will be updated as we receive the necessary materials from the contractors. Our goal is to have all the content available to you latest on the move-in date."; }
    },
    wordpress: {
      errorpage: {
        title: function(d) { return "Page was not found!"; },
        post: function(d) { return "Please refresh the page or try again later!"; }
      }
    },
    search: {
      queryplaceholder: function(d) { return "What are you searching for?"; },
      title: function(d) { return "Search results"; },
      back: function(d) { return "< Back"; },
      resultsummary: function(d) { return "Your search " + d.query + " " + plural(d.resultCount, 0, en, { "0": "returned no results.", "1": "returned one result.", other: "returned " + number(d.resultCount, "resultCount") + " results." }); },
      apartment: {
        title: function(d) { return "My lease agreement"; },
        description: function(d) { return "Details of your lease agreement."; }
      },
      billing: {
        title: function(d) { return "My rent and payment information"; },
        description: function(d) { return "View your rent and payment information."; }
      },
      change: {
        title: function(d) { return "Apartment change"; },
        description: function(d) { return "You can apply for a new apartment from the M2-Kodit apartments becoming vacant if there is a justified need for a new apartment. Such needs may include, for example, changes in the size or the financial situation of the family or a health-related reason."; }
      },
      contact: {
        title: function(d) { return "Contact"; },
        description: function(d) { return "Send a message to our customer support through the form below. We will respond by email as soon as possible."; }
      },
      contactinformation: {
        title: function(d) { return "Contact information"; },
        description: function(d) { return "Information about your property's contact information."; }
      },
      fault: {
        title: function(d) { return "Fault report"; },
        description: function(d) { return "Report non-critical faults or problems with your apartment or your building's common spaces."; }
      },
      internet: {
        title: function(d) { return "Internet connection"; },
        description: function(d) { return "Information about your building's internet connection."; }
      },
      laundry: {
        title: function(d) { return "Laundry room"; },
        description: function(d) { return "Information about your building's common laundry room, its usage and booking."; }
      },
      manuals: {
        title: function(d) { return "User manuals and documents"; },
        description: function(d) { return "Information about your building's appliances and maintenance."; }
      },
      news: {
        title: function(d) { return "News"; },
        description: function(d) { return "News realted to your building"; }
      },
      parking: {
        title: function(d) { return "Resident parking"; },
        description: function(d) { return "Information about your building's resident parking spaces."; }
      },
      safety: {
        title: function(d) { return "Safety and evacuation"; },
        description: function(d) { return "Information about your buildings safety and evacuation plan."; }
      },
      sauna: {
        title: function(d) { return "Sauna"; },
        description: function(d) { return "Information about your building's common sauna and how to book it."; }
      },
      storage: {
        title: function(d) { return "Storage"; },
        description: function(d) { return "Information about your building's storage facilities."; }
      }
    },
    home: {
      title: function(d) { return "Welcome to OmaM2!"; },
      description: function(d) { return "You can manage your residence-related matters conveniently in the OmaM2 service."; },
      address: {
        title: function(d) { return "My address:"; }
      },
      contact: {
        title: function(d) { return "Contact us"; },
        intro: function(d) { return "Use the form below to send a message to our customer service. We will reply by e-mail as soon as possible."; },
        email: function(d) { return "E-mail address"; },
        phone: function(d) { return "Telephone number"; },
        subject: function(d) { return "Subject"; },
        header: function(d) { return "Header"; },
        desc: function(d) { return "Message"; },
        send: function(d) { return "Send"; }
      },
      residentpricelist: function(d) { return "Resident price list"; }
    },
    links: {
      fault: function(d) { return "Report a fault"; },
      agreement: function(d) { return "View your agreement"; },
      messages: function(d) { return "Contact us"; },
      payments: function(d) { return "Rent payment"; },
      terminate: function(d) { return "Terminate your agreement"; },
      exchange: function(d) { return "Change apartment"; }
    },
    linkshome: {
      parking: {
        title: function(d) { return "Info about parking"; },
        description: function(d) { return "Information about your building's resident parking spaces."; }
      },
      sauna: {
        title: function(d) { return "Info about sauna"; },
        description: function(d) { return "Information about your building's common sauna and how to book it."; }
      },
      laundry: {
        title: function(d) { return "Info about Laundry"; },
        description: function(d) { return "Information about your building's common laundry room, its usage and booking."; }
      },
      fault: {
        title: function(d) { return "Fault report"; },
        description: function(d) { return "Report non-critical faults or problems with your apartment or your building's common spaces."; }
      },
      disorder: {
        title: function(d) { return "Disturbances and disorderly conduct"; },
        description: function(d) { return "Report disturbances, such as excessive noise or smoking indoors"; }
      },
      forms: {
        title: function(d) { return "Forms"; },
        description: function(d) { return "Administrative forms"; }
      },
      change: {
        title: function(d) { return "Apartment change"; },
        description: function(d) { return "You can apply for a new apartment from the M2-Kodit apartments becoming vacant if there is a justified need for a new apartment. Such needs may include, for example, changes in the size or the financial situation of the family or a health-related reason."; },
        linktext: function(d) { return "Change apartments"; }
      },
      urgent: {
        title: function(d) { return "Report a fault"; },
        description: function(d) { return "Immediately call maintenance if you notice a critical fault, that should be fixed immediately, in your apartment or your buildings common spaces. A critical fault is for example a water leak that could cause severe damage."; },
        linktext: function(d) { return "Report an issue"; },
        contactbutton: function(d) { return "View contact information"; }
      },
      billing: {
        linktext: function(d) { return "Pay your rent"; }
      },
      contact: {
        title: function(d) { return "Contact"; },
        description: function(d) { return "Send a message to our customer support through the form below. We will respond by email as soon as possible. "; },
        linktext: function(d) { return "Contact us"; }
      }
    },
    warning: {
      debt: function(d) { return "There are overdue payments"; },
      conditional: function(d) { return "If you have made the payment, this notice is not valid."; },
      proceedings: function(d) { return "Your debt is under legal collection. Please contact the rent supervision services on +358 (0)9 7742 5513 Mon–Fri 9 a.m.–3 p.m."; },
      distraint: function(d) { return "The entirety or a part of your debt is being collected by enforcement authorities. Debts collected by enforcement authorities cannot be paid to the account of M2-Kodit. Please contact the rent supervision services for instructions on how to pay your debt. Rent supervision services’ phone number: +358 (0)9 7742 5513 Mon–Fri 9 a.m.–3 p.m."; },
      close: function(d) { return "Close"; },
      uuras: {
        text: function(d) { return "Additional income through the Uuras employment programme."; },
        linktext: function(d) { return "Read more."; }
      },
      gotopayment: function(d) { return "Make a payment"; }
    },
    apartment: {
      title: function(d) { return "My apartment information"; }
    },
    change: {
      title: function(d) { return "Planning to move?"; },
      description: function(d) { return "If your life circumstances change, you can request changing your apartment to another M2-Kodit apartment in two ways."; },
      alternative1: function(d) { return "1. Switch homes with another M2-Kodit customer"; },
      alt1description: function(d) { return "Existing M2-Kodit customers can agree to change apartments with each other. This option works well when you are e.g., looking for a smaller apartment and an other M2-Kodit customer is looking for a bigger apartment at the same time. Read more..."; },
      alternative2: function(d) { return "2. Requesting a new apartment by sending a whole new application to us"; },
      alt2description: function(d) { return "Visit our website https://m2kodit.fi/en/ and browse all the available/soon coming available apartments and send a whole new application to M2-Kodit. Read more..."; },
      apartmentchangelink: function(d) { return "https://hakemus.tampuuri.fi/m2kodit/"; },
      descriptiontitle2: function(d) { return "Apartment exchange"; },
      changeinstructionslink: function(d) { return "http://m2kodit.fi/asukkaalle/asunnon-vaihto/"; },
      descriptioncontact: function(d) { return "Browse the apartment exchange notices or submit your own notice. The apartment exchange arrangement involves the residents of two apartments swapping their apartments. If you find an apartment that suits you, request an apartment exchange by sending M2-Kodit a message on"; },
      descriptionmakechangelinktext: function(d) { return "kodit@ysaatio.fi"; },
      descriptionmakechangelinkhref: function(d) { return "kodit@ysaatio.fi"; },
      descriptionmakechangeend: function(d) { return "."; },
      descriptioninstructionslinktext: function(d) { return "Learn more about the apartment exchange arrangements."; },
      descriptiontitle3: function(d) { return "Requesting a new apartment through M2-Kodit"; },
      searchvacantapartments: function(d) { return "Browse the M2-Kodit apartments becoming vacant"; },
      searchvacantlink: function(d) { return "https://asunnot.m2kodit.fi"; },
      and: function(d) { return "and"; },
      fillapartmentform: function(d) { return "submit an apartment application."; },
      fillapartmentformlink: function(d) { return "https://hakemus.tampuuri.fi/m2kodit/?_ga=2.31850682.540683483.1587125277-1894718168.1581421105"; },
      description3content: function(d) { return "You can apply for a new apartment from the M2-Kodit apartments becoming vacant if there is a justified need for a new apartment. Such needs may include, for example, changes in the size or the financial situation of the family or a health-related reason."; },
      enlistedapartments: function(d) { return "Apartments available for exchange"; },
      instructions: {
        title: function(d) { return "Instructions for moving from one apartment to another"; }
      },
      instructions1: {
        text: function(d) { return "It is possible to exchange the apartments of two tenants. When you have found a suitable apartment, please contact the tenant who submitted the notice directly. "; },
        text2: function(d) { return "Arrange the time and date of visiting the apartments. If the arrangement suits both parties, contact M2-Kodit on "; },
        textlink: function(d) { return "kodit@ysaatio.fi"; },
        textend: function(d) { return "."; },
        text3: function(d) { return "M2-Kodit will handle the apartment exchange arrangements according to the instructions of the Housing Finance and Development Centre of Finland. The payment and tenancy information of your current lease agreement and your credit history will be checked during this process. Any payment default entries will have an effect on the amount of the security deposit of the new rental apartment. The exchange can be made if the prerequisites for the exchange are fulfilled. \nDo not terminate the lease agreement of your current apartment before the apartment exchange arrangement has been confirmed. M2-Kodit will not carry out separate moving inspections when apartments are exchanged."; }
      },
      instructions2: {
        title: function(d) { return "Submit an apartment exchange notice"; },
        text: function(d) { return "Make your apartment available for apartment exchange with an OmaM2 form. Your notice will be processed and published in the service if the apartment is eligible for exchange. Please note that when you make your apartment available for exchange, the details of your apartment, your contact details and the notice will be made public to all users of the OmaM2 service. \nYour notice will be displayed for six months or until you delete it. If you change apartments or stop using the service, the notice will be deleted automatically."; }
      },
      adsent: function(d) { return "Your notice has been submitted and is awaiting confirmation!"; },
      gotonewad: function(d) { return "Make an apartment exchange notice"; },
      allcities: function(d) { return "All cities"; },
      emptyadlist: function(d) { return "No apartment exchange notices"; },
      close: function(d) { return "Close"; },
      ad: {
        current: {
          title: function(d) { return "Tenant's description of their home"; }
        },
        desired: {
          title: function(d) { return "I am looking for this kind of home"; }
        },
        buildingtitle: function(d) { return "Details of the apartment"; },
        name: function(d) { return "Tenant’s name"; },
        phone: function(d) { return "Telephone number"; },
        email: function(d) { return "E-mail address"; },
        address: function(d) { return "Home address"; },
        description: function(d) { return "Describe the apartment you are looking for and when you would like to move. The text will be published with your notice."; },
        newtitle: function(d) { return "Make an apartment exchange notice"; },
        newdescstart: function(d) { return "When you make your apartment available for exchange, the details of your apartment, your contact details and the notice will be published in this service. "; },
        newdesclinktext: function(d) { return "Learn more about the apartment exchange arrangements"; },
        newdescend: function(d) { return "."; },
        publish: function(d) { return "Send"; },
        status: {
          published: function(d) { return "Published: " + d.published; },
          "new": function(d) { return "Awaiting confirmation"; },
          deleted: function(d) { return "Deleted"; }
        },
        icontext: function(d) { return "Apartment exchange notice"; },
        removebtn: function(d) { return "Delete"; },
        housinginfobtn: function(d) { return "View building presentation"; },
        rooms: function(d) { return "Apartment type"; },
        squares: function(d) { return "Floor area"; },
        lease: function(d) { return "Rent"; },
        water: function(d) { return "Monthly advance water tariff"; },
        buildingtype: function(d) { return "Building type"; },
        year: function(d) { return "Year of construction"; },
        apartments: function(d) { return "Number of apartments"; },
        floors: function(d) { return "Number of floors"; },
        elevator: function(d) { return "Lift"; },
        yes: function(d) { return "Yes"; },
        no: function(d) { return "No"; }
      },
      disabled: function(d) { return "You have a valid apartment exchange notice. If you want to make a new notice, please first delete the old one."; },
      swap: {
        title: function(d) { return "Alternative 1 – M2-Kodit customers switch homes with each other"; },
        description: function(d) { return "Every M2-Kodit customer can place an announcement of willingness to switch homes with another M2-Kodit customer. When you choose this option, do as follows:"; },
        step1: function(d) { return "Take a look at the homes shown below and see if there is a home that interests you. (If there is no announcements shown below, no customer wants to switch homes at the moment. You can check the situation again whenever you want.)"; },
        step2: function(d) { return "When you find a suitable home, contact the customer who has submitted the notice directly and agree about seeing the apartment. Getting to see the apartment is always agreed between customers."; },
        step3: function(d) { return "When you find a partner for switching homes, send a message about it to: kodit@ysaatio.fi"; },
        step4: function(d) { return "We will process the switching application according to the instructions of the Housing Finance and Development Centre of Finland. We will also check applicants' payment and tenancy information and credit history. Any payment default entries will affect on the amount of the security deposit of the new rental apartment."; },
        step5: function(d) { return "The switch can be done when the prerequisites are fulfilled."; },
        notice: function(d) { return "Note! Do not terminate the lease agreement of your current home before the switch arrangements are confirmed. M2-Kodit will not carry out moving inspections in situations where customers switch homes with each other."; },
        instructionlink: function(d) { return "Would you like to submit an announcement to switch apartments with another M2-Kodit customer? "; },
        link: function(d) { return "Click here for instructions."; },
        newtitle: function(d) { return "Submit an announcement to switch apartments with another M2-Kodit customer"; },
        newswapinfo1: function(d) { return "The information of your present home will be added to the announcement automatically. Informations are: apartment type, floor area, rent, water usage fee (also if there is a seperate water usage measuring in the apartment), building type, year of construction, number of apartments and number of floors."; },
        newswapinfo2: function(d) { return "Note that everything you write to your announcement will be published. Don't write anything you don't want other customer to see. Never write your social security number to the announcement. Details of your apartment and your contact information will be made public for all OmaM2 users."; },
        newswapinfo3: function(d) { return "Only announcements in which the switch between customers is possible will be published. Your announcement is valid for three (3) months. You can remove your announcement whenever you want. If you move away from your apartment, the announcement will be deleted automatically."; },
        currentapttitle: function(d) { return "Additional information of your current home"; },
        currentaptplaceholder: function(d) { return "Here you can arvertise your current apartment. Tell others why they would consider to move to your current home. Is there a balcony perhaps? A dog park nearby or something else? The text you write here will be published in your announcement."; },
        desiredapttitle: function(d) { return "What kind of an apartment you search in exchange?"; },
        desiredaptplaceholder: function(d) { return "Tell here what kind of a home you are searching, where and when would you like to move. The text you write here will be published in your announcement."; },
        termscheckbox: function(d) { return "I have understood that details of my current apartment and my contact information will be published in my announcement. Also the additional text I wrote above will be published for other OmaM2 users to see."; },
        send: function(d) { return "Send"; },
        backtotop: function(d) { return "Return to top"; }
      },
      newapplication: {
        title: function(d) { return "Alternative 2 - A whole new application"; },
        description: function(d) { return "When you want to leave a whole new application to M2-Kodit, do as follows:"; },
        step1: function(d) { return "Go to the address "; },
        step2: function(d) { return "Bowse the apartments. When you find an apartment or apartments that would suite your present life situation better that you current home, add the apartments to the application and follow the instructions on the website."; },
        step3: function(d) { return "We will process your application according to the instructions of the Housing Finance and Development Centre of Finland."; },
        step4: function(d) { return "We will also check applicant's payment and tenancy history. Possible payment default entries will affect on the amount ot the security deposit of the new rental apartment."; },
        notice: function(d) { return "Note that you can apply for a new apartment if there is a justified need for it. Such needs may include, for example, changes in the size or the financial situation of the family or in health-related reasons."; }
      }
    },
    watermeter: {
      title: function(d) { return "Information on water billing"; },
      noexist: {
        description: function(d) { return "Your apartment does not have it's own water meter."; }
      }
    },
    housing: {
      title: function(d) { return "My building"; },
      description: function(d) { return "Through the OmaM2 service you can conveniently manage your apartment."; },
      basicinfo: {
        buildingtype: function(d) { return "Building type"; },
        year: function(d) { return "Year of construction"; },
        apartmentcounts: function(d) { return "Number of apartments"; },
        floors: function(d) { return "Number of floors"; },
        elevator: function(d) { return "Lift"; }
      },
      housefeat: {
        title: function(d) { return "Your building is equipped with"; },
        laundryroom: function(d) { return "A laundry room"; },
        dryingroom: function(d) { return "A drying room"; },
        bikestorage: function(d) { return "A bicycle storage room"; },
        clubroom: function(d) { return "A club room"; },
        wasteroom: function(d) { return "A waste collection room"; },
        buildingsauna: function(d) { return "A shared sauna room"; },
        locker: function(d) { return "A dressing room"; },
        apartmentstorage: function(d) { return "Apartment-specific storage rooms"; },
        outdoorstorage: function(d) { return "An outdoor equipment storage room"; },
        babycarriage: function(d) { return "A baby carriage storage room"; },
        none: function(d) { return "No info"; },
        book: function(d) { return "Book"; }
      },
      contacts: {
        title: function(d) { return "Contact information"; },
        manager: function(d) { return "Need to contact the property manager?"; },
        descriptionLeft: function(d) { return "At M2-Kodit, our competent customer service primarily helps with questions related to property management. Please contact our customer service (information on the left). If your issue can only be resolved by the property manager, our customer service ensures that your message will reach the property manager."; },
        descriptionAbove: function(d) { return "At M2-Kodit, our competent customer service primarily helps with questions related to property management. Please contact our customer service (information above). If your issue can only be resolved by the property manager, our customer service ensures that your message will reach the property manager."; },
        maintenance: function(d) { return "Maintenance company"; },
        customerservice: function(d) { return "Customer service"; },
        customerservicename: function(d) { return "M2-Kodit"; },
        emergency: function(d) { return "On-call services"; },
        phonenumber: function(d) { return "tel."; }
      },
      briefings: {
        title: function(d) { return "Bulletins"; }
      },
      showall: {
        placeholder: function(d) { return "Show all"; }
      },
      documents: {
        title: function(d) { return "Documents"; }
      },
      instructions: {
        title: function(d) { return "Downloadable instructions"; }
      },
      emergencyplan: {
        title: function(d) { return "Property rescue plan"; },
        documentname: function(d) { return "Rescue plan"; }
      },
      noexist: {
        emergencyplan: function(d) { return "Rescue plan to be made available."; },
        briefings: function(d) { return "No bulletins."; },
        documents: function(d) { return "No documents."; },
        residentpricelist: function(d) { return "No resident price lists."; },
        instructions: function(d) { return "You can find the important contact information for dealing with everyday situations below. If you wish, you can also contact us via the contact form in the OmaM2-service found"; },
        instructionsLink: function(d) { return "here."; },
        laundry: function(d) { return "There is no laundry room available in your home building. "; }
      },
      yes: function(d) { return "Yes"; },
      no: function(d) { return "No"; },
      readmore: function(d) { return "Learn more"; },
      sauna: {
        title: function(d) { return "Sauna bookings"; }
      },
      safety: {
        title: function(d) { return "Safety and evacuation"; },
        download: function(d) { return "Evacuation plan, Safetum"; }
      },
      parking: {
        title: function(d) { return "Parking"; },
        header: function(d) { return "Resident Parking"; }
      },
      ownhouse: {
        title: function(d) { return "Own house"; }
      },
      news: {
        title: function(d) { return "News"; }
      },
      latestnews: {
        title: function(d) { return "Latest news"; }
      },
      laundry: {
        title: function(d) { return "Laundry room"; }
      },
      internet: {
        title: function(d) { return "Internet connection"; }
      },
      storage: {
        title: function(d) { return "Storage"; }
      },
      externalnews: {
        title: function(d) { return "News from m2kodit.fi"; }
      },
      rentSummary: {
        title: function(d) { return "Information on rent formation"; }
      },
      folders: {
        title: function(d) { return "Instructions and documents"; },
        description: function(d) { return "In the menus below, you will find important information and instructions regarding your home's appliances, upkeeping and maintenance"; },
        generic: function(d) { return "Unnamed folder"; },
        documents: function(d) { return "Housing folders content"; },
        ownhouse: function(d) { return "Information about Living in a M2-home"; },
        safety: function(d) { return "Safety"; },
        communication: function(d) { return "TV and data"; },
        appliances: function(d) { return "Household appliances"; },
        hvac: function(d) { return "Heating and ventilation"; },
        service: function(d) { return "Housekeeping and maintenance"; }
      }
    },
    bookings: {
      calendar: {
        title: function(d) { return "My reservations"; },
        pincode: function(d) { return "Your pin code:"; },
        userManual: function(d) { return "One4all Mobile user manual"; },
        noexist: function(d) { return "Reservation calendar not found!"; },
        navigate: function(d) { return "To my reservations"; }
      },
      list: {
        upcoming: function(d) { return "Upcoming reservations"; },
        noexist: function(d) { return "No upcoming reservations."; },
        link: function(d) { return "To my reservations."; },
        showall: function(d) { return "Show all"; }
      }
    },
    profile: {
      title: function(d) { return "Update your information"; },
      description: function(d) { return "Check that your contact details are up to date."; },
      contactinfoheader: function(d) { return "Contact details"; },
      phoneplaceholder: function(d) { return "Telephone number"; },
      phonerequired: function(d) { return "Enter telephone number"; },
      phoneinvalid: function(d) { return "Check telephone number"; },
      emailplaceholder: function(d) { return "E-mail address"; },
      emailrequired: function(d) { return "Enter e-mail address"; },
      emailinvalid: function(d) { return "Check e-mail address"; },
      save: function(d) { return "Save"; },
      savesuccess: function(d) { return "Thank you, your information has been saved!"; },
      newsletter: function(d) { return "Subscribe to the M2-Kodit newsletter!"; },
      newsletterSubtitle: function(d) { return "You will be given current information about M2-Kodit apartments, tenant activities and services available to tenants."; },
      newsletterBtn: function(d) { return "Subscribe"; },
      protectionOrder: function(d) { return "Order of non-disclosure"; },
      protectionOrderText: function(d) { return "You have been set under an order of non-disclosure."; }
    },
    footer: {
      housing: {
        defaultcontact: function(d) { return "Contact details"; },
        contact: function(d) { return "Your property’s contact information"; },
        manager: function(d) { return "Property manager"; },
        maintenance: function(d) { return "Maintenance company"; },
        customerservice: {
          title: function(d) { return "Customer service"; },
          name: function(d) { return "M2-Kodit"; },
          phone: function(d) { return "09 7742 5500"; },
          email: function(d) { return "m2.asiakaspalvelu@ysaatio.fi"; }
        },
        myhousing: function(d) { return "Information"; },
        news: function(d) { return "Bulletins"; },
        safety: function(d) { return "Safety and evacuation plan"; },
        parking: function(d) { return "Parking"; },
        laundry: function(d) { return "Laundry"; },
        sauna: function(d) { return "Sauna"; },
        internet: function(d) { return "Internet connection"; }
      },
      copyright: function(d) { return "Copyright © M2 Kodit 2023"; },
      dataprotection: function(d) { return "Data security"; },
      terms: function(d) { return "Terms of use and personal data protection"; },
      linkbutton: function(d) { return "See contact details"; },
      text: function(d) { return "M2-Kodit is a house rental company wholly owned by the Y Foundation, which manages more than ten thousand rental apartments throughout Finland. We offer rental homes that residents can afford. Our rents are determined according to ARA’s cost principle and are genuinely reasonable. We are not for profit."; },
      contact: {
        description: function(d) { return "Contact us from the link below. We will get back to you as soon as possible."; },
        button: function(d) { return "Contact us"; }
      },
      titles: {
        omam2: function(d) { return "OmaM2"; },
        apartment: function(d) { return "My apartment"; },
        housing: function(d) { return "My Building"; },
        fault: {
          reports: function(d) { return "Fault reports"; }
        },
        contact: function(d) { return "Contact"; }
      },
      apartment: {
        contract: function(d) { return "My lease agreement"; },
        billing: function(d) { return "My rent and payment information"; },
        maintenance: function(d) { return "User manuals and documents"; },
        apartmentchange: function(d) { return "Apartment exchange"; },
        watermeter: function(d) { return "Information on water billing"; },
        bookingsCalendar: function(d) { return "My reservations"; }
      }
    },
    signin: {
      title: function(d) { return "Welcome to the OmaM2 service!"; },
      description: function(d) { return "OmaM2 is a self-service for tenants. "; },
      step1: {
        description: function(d) { return "You can manage your residence-related matters conveniently in the service. In the OmaM2 service, you can manage"; },
        servicedescriptions: function(d) { return "Your current payment situation Information on your property \nTopical bulletins of your property \nYour ongoing fault notifications \nApartment exchange notifications \nYou can also contact M2-Kodit through the service."; },
        authdescription: function(d) { return "You can sign into the service once your lease agreement has been signed. \nStart by entering your phone number. After you have signed in, you will be sent a single-use code to sign in as a text message. When you sign in to the service for the first time, you need to use your"; },
        authoptions: function(d) { return "Online banking credentials or \nMobile ID for authentication purposes."; },
        placeholder: function(d) { return "Enter your phone number"; },
        label: function(d) { return "Phone number"; },
        error: function(d) { return "Check your phonenumber"; },
        forbidden: function(d) { return "User not found. Please contact the M2-Kodit customer service on +358 (0)9 7742 5500 or m2.asiakaspalvelu@ysaatio.fi."; },
        "continue": function(d) { return "Continue"; }
      },
      step2: {
        description: function(d) { return "Enter the single-use code sent to you as an SMS to sign in to the service."; },
        placeholder: function(d) { return "Enter code"; },
        label: function(d) { return "Single-use code"; },
        error: function(d) { return "Check the single-use sign in code"; },
        back: function(d) { return "Re-enter your phone number"; },
        doSignIn: function(d) { return "Sign in"; },
        acceptservice: function(d) { return "I accept"; },
        termsofuse: function(d) { return "the terms of use of the service."; }
      },
      step3: {
        description: function(d) { return "Verify your identity with your online banking credentials or your Mobile ID."; },
        error: function(d) { return "There was an error with the authentication. Please try again."; },
        forbidden: function(d) { return "User not found. Please contact the M2-Kodit customer service on +358 (0)9 7742 5500 or m2.asiakaspalvelu@ysaatio.fi."; },
        "continue": function(d) { return "Continue to authentication"; }
      },
      step4: {
        title: function(d) { return "Update your contact details"; },
        description: function(d) { return "Check that your contact details are up to date."; },
        phoneplaceholder: function(d) { return "Telephone number"; },
        phonerequired: function(d) { return "Enter telephone number"; },
        phoneinvalid: function(d) { return "Check telephone number"; },
        emailplaceholder: function(d) { return "E-mail address"; },
        noemail: function(d) { return "I have no e-mail address."; },
        emailrequired: function(d) { return "Enter e-mail address"; },
        emailinvalid: function(d) { return "Check e-mail address"; },
        save: function(d) { return "Save"; }
      },
      redirectToM2: function(d) { return "< Back to the m2kodit.fi front page"; },
      cancel: function(d) { return "Cancel"; }
    },
    feedback: {
      step1: {
        title: function(d) { return "You have signed out of OmaM2."; },
        question: function(d) { return "Did you find OmaM2 helpful?"; },
        yes: function(d) { return "Yes"; },
        no: function(d) { return "No"; },
        placeholder: function(d) { return "Please give us feedback so we can improve our service. Your feedback will be kept anonymous."; },
        send: function(d) { return "Send"; }
      },
      step2: {
        title: function(d) { return "Thank you for your feedback!"; },
        login: function(d) { return "Login"; }
      },
      redirectToM2: function(d) { return "< Back to the m2kodit.fi front page"; }
    },
    messages: {
      "new": {
        title: function(d) { return "Contact us"; },
        desc: function(d) { return "Use the form below to send a message to our customer service."; },
        choose: function(d) { return "Choose the topic"; },
        housing: {
          "": function(d) { return "if your issue is regarding problematic situations with housing (eg. housing allowance or other benefits, managing finances, maintaining neighbour relations, keeping your home clean)"; },
          debtassistance: function(d) { return "Advice in debt issues"; },
          benefitapplication: function(d) { return "Help in applying benefits (e.g., general housing allowence)"; },
          tooexpensive: function(d) { return "Too expensive apartment"; },
          neighbourdispute: function(d) { return "Help in resolving a neighbor dispute"; },
          evictionassistance: function(d) { return "Advice in an eviction situation"; },
          dailyerrandsassistance: function(d) { return "Advice in handling challenges in life"; },
          mentalhealthandsubstanceabuse: function(d) { return "Advice in mental health or substance abuse issues"; }
        },
        billing: {
          "": function(d) { return "if your issue is related to paying rent"; },
          latepayment: function(d) { return "Small delay in rent payment"; },
          paymentissues: function(d) { return "Difficulties in paying the rent"; },
          debtrepayment: function(d) { return "Settling existing debt / Negotiating a payment plan"; }
        },
        customerservice: {
          "": function(d) { return "if your issue is regarding other issues"; },
          apartmentapplications: function(d) { return "Application for an apartment"; },
          sauna_laundry_parking: function(d) { return "Sauna-, laundry room- or parking space reservations"; },
          contracttermination: function(d) { return "Termination of lease/moving out"; },
          propertymanagement: function(d) { return "Issues concerning property management"; },
          faultreports: function(d) { return "Request for maintenance / Report a fault"; },
          other: function(d) { return "Other matters"; }
        },
        faultreportstart: function(d) { return "Fault reports"; },
        faultreport: function(d) { return "are used to report issues or problems regarding your apartment or your building and its common spaces. Faults reports are intended for non-urgent repair and maintenance requests. In urgent situations call your building's"; },
        faultreportlink: function(d) { return "maintenance company."; },
        replysoon: function(d) { return "We will reply by e-mail as soon as possible."; },
        form: {
          email: function(d) { return "E-mail address"; },
          emailrequired: function(d) { return "Enter e-mail address"; },
          emailinvalid: function(d) { return "Check e-mail address"; },
          phone: function(d) { return "Telephone number"; },
          phonerequired: function(d) { return "Enter telephone number"; },
          phoneinvalid: function(d) { return "Check telephone number"; },
          name: function(d) { return "Name"; },
          category: function(d) { return "Select topic *"; },
          subcategory: function(d) { return "Select subtopic *"; },
          subject: function(d) { return "Header"; },
          subjectrequired: function(d) { return "Enter header"; },
          content: function(d) { return "Message"; },
          contentrequired: function(d) { return "Enter message"; },
          button: function(d) { return "Send"; },
          messagesent: function(d) { return "Your message has been sent!"; },
          messagefailure: function(d) { return "Error sending message!"; },
          categories: {
            "471": function(d) { return "Customer service"; },
            "472": function(d) { return "Fault report concerning the apartment"; },
            "473": function(d) { return "Payment of rent"; },
            "474": function(d) { return "Document requests"; },
            "859": function(d) { return "Housing counselling"; },
            problemhouse: function(d) { return "Fault report concerning the building"; }
          }
        },
        copymessage: function(d) { return "An e-mail address was not found in your information. You can add an e-mail from the "; },
        profile: function(d) { return "My information page"; },
        instruction: {
          latepayment: function(d) { return "If you want to discuss a possibility to pay your rent in a longer term, choose 'Settling existing debt / Negotiating a payment plan' if you want to inform a small delay (maximum 3 days) in rent payment, continue filling in the form."; },
          sauna_laundry_parking: function(d) { return "In sauna, laundry room and parking space reservations please contact your own maintenance copmany. "; },
          faultreports: function(d) { return "In case of a water-, smoke- or fire damages, or if your refrigerator/freezer doesn't work, call immediately to your maintenance company. "; },
          faultreportsother: function(d) { return "In other maintenance/fault situations, fill out the "; },
          linktomaintenance: function(d) { return "Click here to see the contact information."; },
          linktofaultreport: function(d) { return "electric fault form."; },
          propertymanagement: function(d) { return "Your message will be directed to our customer service, where questions concerning property management are primarily dealt with. If your issue can only be resolved by the property manager, our customer service ensures that your message will reach the property manager.  "; }
        }
      },
      title: function(d) { return "Fault reports"; },
      maindescription: function(d) { return "View the status of the fault reports of your apartment or building."; },
      description: function(d) { return "Fault reports for your apartment, received through the online form"; },
      disclaimer1: function(d) { return "Fault reports made by phone or email will not be shown in OmaM2. "; },
      deschousing: function(d) { return "Fault reports for your building, received through the online form "; },
      disclaimer2: function(d) { return "Fault reports made by phone or email will not be shown in OmaM2. "; },
      disclaimer3: function(d) { return "Please note that the list includes every fault report concerning the common areas in your building."; },
      emptylist: function(d) { return "No fault reports."; },
      subject: function(d) { return "Subject"; },
      messageStatuses: {
        "0": function(d) { return "Received"; },
        "1": function(d) { return "Pending"; },
        "2": function(d) { return "Completed"; },
        Sent: function(d) { return "Received"; },
        InProgress: function(d) { return "Pending"; },
        Ready: function(d) { return "Completed"; }
      },
      showmore: function(d) { return "Show all"; },
      newmessage: function(d) { return "Report a problem"; }
    },
    message: {
      success: {
        success: function(d) { return "Message was sent successfully!"; },
        description: function(d) { return "You will be directed back to the frontpage shorty. You can return directly by using the Frontpage button below."; },
        button: function(d) { return "Frontpage"; }
      }
    },
    billing: {
      title: function(d) { return "Rent and payment information"; },
      description: function(d) { return "View your rent and payment information."; },
      description2: function(d) { return "Your payment will appear in OmaM2 and in M2-Kodit rent control within three (3) banking days. Please note that holidays are not calculated as banking days."; },
      print: {
        description: function(d) { return "Your rent invoice and the payment details can be found by clicking or tapping the button below."; },
        title: function(d) { return "Print your rent invoice"; },
        parkingLot: function(d) { return "Print your parking space invoice"; },
        page: function(d) { return "Print"; }
      },
      filter: {
        startdate: function(d) { return "Start report from month"; },
        enddate: function(d) { return "End report at month"; },
        applyrange: function(d) { return "Show the time range"; },
        showall: function(d) { return "Show all"; },
        showbilled: function(d) { return "Show invoices"; },
        showpaid: function(d) { return "Show payments"; }
      },
      gotopayment: function(d) { return "Make a payment"; },
      payment: {
        title: function(d) { return "Make a payment"; },
        description: function(d) { return "Your payment will appear in OmaM2 and in M2-Kodit rent control within three (3) banking days."; },
        description2: function(d) { return "You can pay your debt in full or in installments. <b>NOTE! When paying through OmaM2, always start a new payment transaction below.</b> Start a new payment transaction, even if the transaction concerns a previous payment installment. <b>Do not use the payment information of an old payment paid in the OmaM2 service.</b>"; },
        errorhelptext: function(d) { return "There is a problem with the transaction. Please use your online bank or a bill to make the payment."; },
        subtitle1: function(d) { return "1. Give the payment amount"; },
        description1: function(d) { return "You are due"; },
        tobepaid: function(d) { return "Amount to be paid"; },
        amounttoolow: function(d) { return "Minimum payment amount is 1 €"; },
        subtitle2: function(d) { return "2. Choose your payment method"; },
        paymentinfo: function(d) { return "Payment details"; },
        paymentreceiver: function(d) { return "Receiver"; },
        paymentreceiver2: function(d) { return "Kiinteistö Oy M2-Kodit"; },
        referencenumber: function(d) { return "Reference number"; },
        sum: function(d) { return "Amount to be paid"; },
        gotopayment: function(d) { return "Make a payment"; },
        showbilling: function(d) { return "Show debt details"; },
        paytrailinfo: function(d) { return "We use Paytrail as our payment processor."; },
        success: function(d) { return "Your payment has been registered and will be visible in the OmaM2 service within three (3) business days."; },
        failure: function(d) { return "The transaction was terminated."; },
        "continue": function(d) { return "Continue"; },
        recent: function(d) { return "You have paid " + d.amount + " € on " + d.time + ". Please note that your payment will be visible in OmaM2 once the bank transfer has been completed in the M2-Kodit rent control (at least three (3) banking days). Holidays and weekends are not counted as bank days, which means that it may take several days for the payment to appear."; }
      },
      current: {
        period: function(d) { return "Current payment period"; },
        advance: function(d) { return "Excess amount paid in advance"; },
        remaining: function(d) { return "Payments currently overdue"; },
        uptodate: function(d) { return "Your payments are up to date"; }
      },
      history: {
        period: function(d) { return "Payment period"; },
        billed: function(d) { return "Invoiced"; },
        paid: function(d) { return "Payments"; },
        duedate: function(d) { return "Due date"; },
        paiddate: function(d) { return "Payment date"; },
        sum: function(d) { return "Total"; },
        advance: function(d) { return "excess amount paid remaining"; },
        remaining: function(d) { return "overdue payments remaining"; },
        uptodate: function(d) { return "your payments are up to date"; },
        prevAdvance: function(d) { return "Excess amount paid in previous payment periods"; },
        prevRemaining: function(d) { return "Overdue payments in previous payment periods"; }
      },
      itemized: function(d) { return "Show debt report"; },
      months: {
        "0": function(d) { return "January"; },
        "1": function(d) { return "February"; },
        "2": function(d) { return "March"; },
        "3": function(d) { return "April"; },
        "4": function(d) { return "May"; },
        "5": function(d) { return "June"; },
        "6": function(d) { return "July"; },
        "7": function(d) { return "August"; },
        "8": function(d) { return "September"; },
        "9": function(d) { return "October"; },
        "10": function(d) { return "November"; },
        "11": function(d) { return "December"; }
      }
    },
    agreement: {
      title: function(d) { return "Details of your lease agreement"; },
      istemporary: function(d) { return "Fixed term"; },
      ongoing: function(d) { return "Valid until further notice"; },
      month: function(d) { return "Monthly"; },
      personmonth: function(d) { return "per person per month"; },
      permonth: function(d) { return "per month"; },
      day: function(d) { return "day"; },
      last: function(d) { return "last"; },
      apartment: {
        title: function(d) { return "Apartment information"; },
        type: function(d) { return "Apartment type"; },
        sqfoot: function(d) { return "Floor area"; },
        houseowner: function(d) { return "Lessor"; }
      },
      tenants: {
        title: function(d) { return "Residents"; },
        title2: function(d) { return "Tenant information"; },
        contract: function(d) { return "Tenant / Tenant's:"; },
        other: function(d) { return "Other residents:"; }
      },
      print: function(d) { return "Print the information"; },
      download: function(d) { return "Download the lease agreement terms"; },
      terminate: function(d) { return "Terminate your lease"; },
      lease: {
        title: function(d) { return "Rent and water tariff"; },
        title2: function(d) { return "Rent and water tariff"; },
        price: function(d) { return "Rent"; },
        waterPrePayment: function(d) { return "Advance water tariff"; },
        waterPerPerson: function(d) { return "Water tariff"; },
        waterPerConsumption: function(d) { return "Water tariff according to water consumption"; },
        duedate: function(d) { return "Due date"; }
      },
      period: {
        title: function(d) { return "Rent period"; },
        type: function(d) { return "Lease"; },
        startdate: function(d) { return "Lease valid from"; },
        enddate: function(d) { return "Lease valid until"; },
        paiddeposit: function(d) { return "Paid security deposit"; }
      },
      deposit: {
        title: function(d) { return "Security deposit payment"; },
        paymenttype: {
          "218": function(d) { return "Financial security deposit"; },
          "223": function(d) { return "Security deposit voucher"; },
          "245": function(d) { return "Security deposit voucher"; },
          "260": function(d) { return "Exchanger's deposit"; },
          "318": function(d) { return "Key deposit fee"; }
        },
        sum: function(d) { return "Sum"; },
        moneydeposit: function(d) { return "Financial security deposit"; },
        paidcontract: function(d) { return "Security deposit voucher"; },
        othertype: function(d) { return "Other type of security deposit"; },
        keydeposit: function(d) { return "Key deposit fee"; },
        refunded: function(d) { return "Returned"; },
        refundedsum: function(d) { return "returned sum"; },
        info: function(d) { return "If you have payed rent deposit, it will be returned to you within approximately a month after the lease agreement has ended. On condition that no claims have been found during the inspection of the apartment or in the rental payments and that all keys have been returned as instructed."; }
      }
    },
    modal: {
      redirect: function(d) { return "The service will be opened in a new tab."; },
      "continue": function(d) { return "Continue"; },
      cancel: function(d) { return "Cancel"; }
    },
    onboarding: {
      welcome: function(d) { return "Welcome"; },
      info1: function(d) { return "The OmaM2 service is an online service open to all residents of M2-Kodit.\n \nLearn more by clicking or tapping the arrows or numbers."; },
      uptodate: function(d) { return "Information up to date"; },
      info2: function(d) { return "Update your contact details, view the information of your property or contact M2-Kodit."; },
      rental: function(d) { return "My tenancy information"; },
      info3: function(d) { return "The rent payment page contains a summary of your rent payments. Here, you can also print your rent invoice."; },
      developing: function(d) { return "OmaM2 is being developed"; },
      info4: function(d) { return "We are developing the OmaM2 service continuously. New features will be added. Please provide us feedback when you sign out."; }
    },
    debt: {
      title: function(d) { return "Debt report"; },
      description: function(d) { return "Your debt report includes all unpaid invoices. Payments are always allocated to the unpaid invoice with the earliest date of issue. You can suggest a payment plan to M2-Kodit."; },
      paytype: function(d) { return "Unpaid payments"; },
      rent: function(d) { return "Rent"; },
      collectioncharge: function(d) { return "Collection costs"; },
      penaltyinterest: function(d) { return "Late payment interest"; },
      saunapayment: function(d) { return "Sauna room fee"; },
      debtamount: function(d) { return "Debt"; },
      duedate: function(d) { return "Due date"; },
      sum: function(d) { return "Total"; },
      print: function(d) { return "Print invoice"; },
      contact: {
        title: function(d) { return "Suggest a payment plan"; },
        description: function(d) { return "If you are unable to pay your entire debt at once, you can discuss a payment plan by calling our customer service."; },
        description2: function(d) { return "Phone: 09 7742 5500, open Monday to Friday from 9–15"; },
        paysuggestion: function(d) { return "Payment plan suggestion"; },
        placeholder: function(d) { return "Enter the precise sums and dates for repayment and the rent due during the payment plan."; },
        subject: function(d) { return "Message subject"; },
        email: function(d) { return "E-mail address"; },
        phonenumber: function(d) { return "Telephone number"; },
        name: function(d) { return "Name"; },
        send: function(d) { return "Send"; },
        copymessage: function(d) { return "If you have entered an e-mail address, a copy of the message you sent will be sent to this address. You can update your contact details in the My information page."; }
      }
    },
    m2: {
      about: {
        title: function(d) { return "About M2-Kodit"; }
      },
      sustainability: {
        title: function(d) { return "Sustainability"; }
      }
    },
    living: {
      home: {
        title: function(d) { return "Living in M2-Kodit"; }
      },
      benefits: {
        title: function(d) { return "Tenant benefits"; }
      },
      events: {
        title: function(d) { return "Tenant events"; },
        upcoming: function(d) { return "Upcoming events"; },
        noupcoming: function(d) { return "No upcoming events"; }
      },
      activities: {
        title: function(d) { return "Community activities"; }
      }
    },
    forms: {
      title: function(d) { return "Forms to simplify your business"; },
      pdf: {
        powerofattorney: function(d) { return "Power of attorney to represent the tenant (PDF)"; },
        keytransferagreement: function(d) { return "Key handover agreement (PDF)"; },
        personaldataconsent: function(d) { return "Tenant's consent to disclosure of personal data (PDF)"; },
        estateinstructions: function(d) { return "Instructions for the estate (PDF)"; }
      }
    }
  },
  fi: {
    navigation: {
      home: {
        title: function(d) { return "Etusivu "; }
      },
      apartment: {
        title: function(d) { return "Oma asumiseni "; },
        agreement: function(d) { return "Vuokrasopimukseni"; },
        billing: function(d) { return "Vuokra- ja maksutietoni"; },
        change: function(d) { return "Asunnon­vaihto"; },
        watermeter: function(d) { return "Veden kulutus ja laskutus"; },
        termination: function(d) { return "Vuokrasopimuksen irtisanominen"; },
        bookingsCalendar: function(d) { return "Varaukseni"; },
        messages: function(d) { return "Vikailmoitukseni"; },
        forms: function(d) { return "Lomakkeita"; }
      },
      housing: {
        title: function(d) { return "Taloni"; },
        myhousing: function(d) { return "Taloni tiedot"; },
        news: function(d) { return "Tiedotteet"; },
        safety: function(d) { return "Turvallisuus ja pelastautuminen"; },
        parking: function(d) { return "Pysäköinti"; },
        laundry: function(d) { return "Talopesula"; },
        sauna: function(d) { return "Saunavuorot"; },
        ownhouse: function(d) { return "Oma talo"; },
        storage: function(d) { return "Varastointi ja säilytys"; },
        internet: function(d) { return "Nettiyhteys"; },
        rentSummary: function(d) { return "Vuokran muodostuminen"; },
        bookingsCalendar: function(d) { return "Varaukseni"; },
        manuals: function(d) { return "Ohjeet ja dokumentit"; }
      },
      living: {
        title: function(d) { return "Asuminen M2-Kodeilla"; },
        benefits: function(d) { return "Asukasedut"; },
        events: function(d) { return "Asukastapahtumat"; },
        activities: function(d) { return "Asukastoiminta"; }
      },
      work: {
        title: function(d) { return "Töitä asukkaille"; }
      },
      m2homes: {
        title: function(d) { return "M2-Kodit"; },
        about: function(d) { return "Tietoa M2-Kodeista"; },
        sustainability: function(d) { return "Vastuullisuus M2-Kodeilla"; }
      },
      messages: {
        title: function(d) { return "Vikailmoitukseni"; }
      },
      profileAlt: {
        title: function(d) { return "Omat tiedot"; }
      },
      signout: {
        title: function(d) { return "Kirjaudu ulos"; }
      },
      profileMain: {
        title: function(d) { return "Omat tiedot"; }
      },
      finnish: function(d) { return "Suomeksi"; },
      english: function(d) { return "In English"; }
    },
    faultformbulletin: {
      title: function(d) { return "Kotonasi saattaa vielä olla jotain viimeistelemättä. Jos näin on, pahoittelemme!"; },
      description: function(d) { return "Kotiisi on toimitettu paperinen Takuutarkastuskysely. Paperisen lomakkeen sijaan, voit ilmoittaa asunnossa mahdollisesti havaitsemistasi vioista ja puutteista alle avautuvalla sähköisellä lomakkeella. Tiedot ohjautuvat rakentajalle, joka huolehtii, että viimeistelyä vaativat asiat tulevat hoidetuiksi."; },
      description2: function(d) { return "Palauta lomake, vaikka puutteita ei olisikaan. Lomake on täytettävissä kaksi viikkoa vuokrasopimuksen voimaanastumispäivästä."; },
      open: function(d) { return "Avaa Takuutarkastuskysely"; },
      close: function(d) { return "Sulje Takuutarkastuskysely"; }
    },
    moveinnotice: {
      description: function(d) { return "Uusi kotitalosi valmistuu " + d.startDate + " mennessä. Täydennämme Kodin kansioiden sisältöä sitä mukaa kun saamme urakoitsijoilta tarvittavia materiaaleja. Tavoitteemme on, että kaikki sisältö on käytettävissäsi viimeistään muuttopäivänä."; }
    },
    wordpress: {
      errorpage: {
        title: function(d) { return "Sivua ei löydetty!"; },
        post: function(d) { return "Ole hyvä ja lataa sivu uudelleen tai yritä myöhemmin!"; }
      }
    },
    search: {
      queryplaceholder: function(d) { return "Hae OmaM2:sta"; },
      title: function(d) { return "Hakutulokset"; },
      back: function(d) { return "< Takaisin"; },
      resultsummary: function(d) { return "Haulla " + d.query + " " + plural(d.resultCount, 0, fi, { "0": "ei löytynyt yhtään osumaa.", "1": "löytyi yksi osuma.", other: "löytyi " + number(d.resultCount, "resultCount") + " osumaa." }); },
      apartment: {
        title: function(d) { return "Vuokrasopimukseni"; },
        description: function(d) { return "Vuokrasopimuksesi tiedot"; }
      },
      billing: {
        title: function(d) { return "Vuokra- ja maksutietoni"; },
        description: function(d) { return "Tarkastele vuokra- ja maksutietojasi."; }
      },
      change: {
        title: function(d) { return "Asunnon­vaihto"; },
        description: function(d) { return "Voit hakea vaihtoasuntoa vapautuvista M2-Kotien asunnoista, kun asunnonvaihdolle on perusteltu tarve. Tällaisia voivat olla esimerkiksi perhekoon tai taloudellisen tilanteen muutos tai terveydellinen syy."; }
      },
      contact: {
        title: function(d) { return "Yhteyden­otto"; },
        description: function(d) { return "Lähetä viesti asiakaspalveluumme alla olevalla lomakkeella. Vastaamme sinulle sähköpostitse mahdollisimman pian."; }
      },
      contactinformation: {
        title: function(d) { return "Yhteystiedot"; },
        description: function(d) { return "Tietoa talosi yhteystiedoista."; }
      },
      fault: {
        title: function(d) { return "Vikailmoitus"; },
        description: function(d) { return "Ilmoita asuntoasi tai talon yhteisiä tiloja koskevasta kiireettömästä viasta."; }
      },
      internet: {
        title: function(d) { return "Netti"; },
        description: function(d) { return "Tietoa taloyhtiön netistä."; }
      },
      laundry: {
        title: function(d) { return "Talopesula"; },
        description: function(d) { return "Tietoa taloyhtiön pesutuvasta, varauksista ja käytöstä."; }
      },
      manuals: {
        title: function(d) { return "Ohjeet ja dokumentit"; },
        description: function(d) { return "Selaa asunnon ohjeita ja dokumentteja."; }
      },
      news: {
        title: function(d) { return "Tiedotteet"; },
        description: function(d) { return "Selaa taloyhtiösi tiedotteita."; }
      },
      parking: {
        title: function(d) { return "Asukaspysäköinti"; },
        description: function(d) { return "Tietoa taloyhtiösi asukaspysäköinnistä."; }
      },
      safety: {
        title: function(d) { return "Turvallisuus ja pelastautuminen"; },
        description: function(d) { return "Tietoa taloyhtiösi turvallisuudesta ja pelastautumisesta."; }
      },
      sauna: {
        title: function(d) { return "Saunavuorot"; },
        description: function(d) { return "Tietoa taloyhtiösi saunasta, saunavuoroista ja varauksista."; }
      },
      storage: {
        title: function(d) { return "Varastointi"; },
        description: function(d) { return "Tietoa taloyhtiösi varastoinnista."; }
      }
    },
    home: {
      title: function(d) { return "Tervetuloa OmaM2-palveluun!"; },
      description: function(d) { return "OmaM2-palvelussa voit kätevästi hoitaa asumiseesi liittyviä asioita."; },
      address: {
        title: function(d) { return "Oma osoitteeni:"; }
      },
      contact: {
        title: function(d) { return "Ota yhteyttä"; },
        intro: function(d) { return "Lähetä viesti asiakaspalveluumme alla olevalla lomakkeella. Vastaamme sinulle sähköpostitse mahdollisimman pian."; },
        email: function(d) { return "Sähköpostiosoite"; },
        phone: function(d) { return "Puhelinnumero"; },
        subject: function(d) { return "Aihe"; },
        header: function(d) { return "Otsikko"; },
        desc: function(d) { return "Viesti"; },
        send: function(d) { return "Lähetä"; }
      },
      residentpricelist: function(d) { return "Asukaslaskuhinnasto"; }
    },
    links: {
      fault: function(d) { return "Ilmoita viasta"; },
      agreement: function(d) { return "Tarkastele sopimustasi"; },
      messages: function(d) { return "Ota yhteyttä"; },
      payments: function(d) { return "Vuokranmaksu"; },
      terminate: function(d) { return "Irtisano sopimuksesi"; },
      exchange: function(d) { return "Vaihda asuntoa"; }
    },
    linkshome: {
      parking: {
        title: function(d) { return "Tietoa pysäköinnistä"; },
        description: function(d) { return "Tietoa taloyhtiösi asukaspysäköinnistä"; }
      },
      sauna: {
        title: function(d) { return "Tietoa saunavuoroista"; },
        description: function(d) { return "Tietoa taloyhtiösi saunasta, saunavuoroista ja varauksista"; }
      },
      laundry: {
        title: function(d) { return "Tietoa talopesulasta"; },
        description: function(d) { return "Tietoa taloyhtiön talopesulasta, varauksista ja käytöstä"; }
      },
      fault: {
        title: function(d) { return "Vikailmoitus"; },
        description: function(d) { return "Ilmoita asuntoasi tai talon yhteisiä tiloja koskevasta kiireettömästä viasta."; }
      },
      disorder: {
        title: function(d) { return "Häiriöt ja tiedotteet"; },
        description: function(d) { return "Metelöinti, lemmikkien aiheuttamat häiriöt, tupakointi"; }
      },
      forms: {
        title: function(d) { return "Lomakkeet"; },
        description: function(d) { return "Erilaisia lomakkeita kuten esimerkiksi valtakirja tai avaintenluovutussopimus"; }
      },
      change: {
        title: function(d) { return "Asunnon­vaihto"; },
        description: function(d) { return "Voit hakea vaihtoasuntoa vapautuvista M2-Kotien asunnoista, kun asunnonvaihdolle on perusteltu tarve. Tällaisia voivat olla esimerkiksi perhekoon tai taloudellisen tilanteen muutos tai terveydellinen syy. "; },
        linktext: function(d) { return "Vaihda asuntoa"; }
      },
      billing: {
        linktext: function(d) { return "Maksa vuokra"; }
      },
      urgent: {
        title: function(d) { return "Ilmoita viasta"; },
        description: function(d) { return "Soita heti huoltoyhtiöösi, jos huomaat talossasi tai asunnossasi kiireellisesti korjattavan vian. Heti korjattavia vikoja ovat esimerkiksi vesivuoto tai muut viat, jotka vaarantavat asumista."; },
        linktext: function(d) { return "Ilmoita viasta"; },
        contactbutton: function(d) { return "Katso huolto­yhtiösi yhteys­tiedot"; }
      },
      contact: {
        title: function(d) { return "Yhteyden­otto"; },
        description: function(d) { return "Lähetä viesti asiakaspalveluumme alla olevalla lomakkeella. Vastaamme sinulle sähköpostitse mahdollisimman pian."; },
        linktext: function(d) { return "Ota yhteyttä"; }
      }
    },
    warning: {
      debt: function(d) { return "Sinulla on erääntynyttä maksettavaa"; },
      conditional: function(d) { return "Jos olet jo suorittanut maksusi, tämä huomautus on aiheeton."; },
      proceedings: function(d) { return "Velka on siirtynyt oikeudelliseen perintään. Otathan heti yhteyttä vuokravalvontaan puh. 09 7742 5513, puhelinaika ma–pe klo 9–15."; },
      distraint: function(d) { return "Koko velka tai osa velasta on perinnässä ulosottovirastossa. Ulosottovirastossa perinnässä olevaa velkaa ei voi maksaa M2-Kotien tilille. Otathan heti yhteyttä vuokravalvontaan, niin saat ohjeet velan maksamiselle. Vuokravalvonta puh. 09 7742 5513, puhelinaika ma–pe klo 9–15."; },
      close: function(d) { return "Sulje"; },
      uuras: {
        text: function(d) { return "Lisätienestiä Uuras-työllistymisohjelman kautta."; },
        linktext: function(d) { return "Lue lisää."; }
      },
      gotopayment: function(d) { return "Siirry maksamaan"; }
    },
    apartment: {
      title: function(d) { return "Asuntoni tiedot"; }
    },
    change: {
      title: function(d) { return "Asunnonvaihto"; },
      description: function(d) { return "Jos elämäntilanteesi muuttuu, voit hakea asunnon vaihtoa toiseen M2-Kotien asuntoon kahdella tapaa"; },
      alternative1: function(d) { return "1. Asukkaiden tekemän keskinäisen vaihdon kautta"; },
      alt1description: function(d) { return "Nykyiset M2-Kotien asukkaat voivat sopia vaihtavansa nykyisiä koteja keskenään. Vaihtoehto on toimiva silloin kun tarvitset esim. itse pienemmän kodin ja toinen M2-Kotien asukas puolestaan suuremman kodin. Lue lisää..."; },
      alternative2: function(d) { return "2. Jättämällä kokonaan uuden asuntohakemuksen M2-Kodeille"; },
      alt2description: function(d) { return "Hae uutta kotia kaikkien vapaiden tai vapautumassa olevien M2-Kotien joukosta. Lue lisää..."; },
      apartmentchangelink: function(d) { return "https://hakemus.tampuuri.fi/m2kodit/"; },
      descriptiontitle2: function(d) { return "Keskinäinen asunnonvaihto"; },
      changeinstructionslink: function(d) { return "http://m2kodit.fi/asukkaalle/asunnon-vaihto/"; },
      descriptioncontact: function(d) { return "Selaa asunnonvaihtoilmoituksia tai jätä oma ilmoituksesi asunnonvaihtotoiveesta. Keskinäisessä asunnonvaihdossa asukkaat etsivät sopivan vaihtoasunnon itse. Jos sopiva vaihtopari löytyy, pyydä vaihtoa lähettämällä M2-Kodeille viesti osoitteeseen"; },
      descriptionmakechangelinktext: function(d) { return "kodit@ysaatio.fi"; },
      descriptionmakechangelinkhref: function(d) { return "kodit@ysaatio.fi"; },
      descriptionmakechangeend: function(d) { return "."; },
      descriptioninstructionslinktext: function(d) { return "Katso ohjeet keskinäiseen asunnon vaihtoon."; },
      descriptiontitle3: function(d) { return "Asunnon vaihto M2-Kotien kautta"; },
      searchvacantapartments: function(d) { return "Selaa vaihtoasuntoja vapautuvien M2-Kotien asuntojen joukosta"; },
      searchvacantlink: function(d) { return "https://asunnot.m2kodit.fi"; },
      and: function(d) { return "ja"; },
      fillapartmentform: function(d) { return "täytä asuntohakemus."; },
      fillapartmentformlink: function(d) { return "https://hakemus.tampuuri.fi/m2kodit/?_ga=2.31850682.540683483.1587125277-1894718168.1581421105"; },
      description3content: function(d) { return "Voit hakea vaihtoasuntoa vapautuvista M2-Kotien asunnoista, kun asunnonvaihdolle on perusteltu tarve. Tällaisia voivat olla esimerkiksi perhekoon tai taloudellisen tilanteen muutos tai terveydellinen syy."; },
      enlistedapartments: function(d) { return "Vaihtoon ilmoitetut asunnot"; },
      instructions: {
        title: function(d) { return "Ohje asunnonvaihtajalle"; }
      },
      instructions1: {
        text: function(d) { return "Asuntojen vaihtaminen keskenään on mahdollista. Kun olet löytänyt sopivan vaihtoasunnon, ota suoraan yhteyttä ilmoituksen jättäneeseen asukkaaseen. "; },
        text2: function(d) { return "Sovi asuntonäyttö suoraan vaihtokumppanin kanssa. Jos sopiva vaihtokumppani löytyy, olkaa yhteydessä asiasta M2-Kodeille osoitteeseen"; },
        textlink: function(d) { return "kodit@ysaatio.fi"; },
        textend: function(d) { return "."; },
        text3: function(d) { return "M2-Kodit käsittelee keskinäisen asunnonvaihdon ARAn ohjeiden mukaisesti. Samalla katsotaan nykyisen vuokrasuhteen maksu- ja asumishistoria sekä tarkistetaan luottotiedot. Mahdolliset luottotietomerkinnät vaikuttavat uuden vuokra-asunnon vakuuden määrään. Vaihto voidaan suorittaa, kun vaihdon edellytykset täyttyvät. \nIrtisano nykyisen asuntosi vuokrasopimus vasta, kun halutun vaihtoasunnon saaminen on vahvistettu. Keskinäisissä vaihdoissa M2-Kodit ei tee erillisiä muuttotarkastuksia."; }
      },
      instructions2: {
        title: function(d) { return "Vaihtoilmoituksen tekeminen"; },
        text: function(d) { return "Ilmoita asuntosi vaihtoon OmaM2-palvelun lomakkeella. Ilmoitus käsitellään ja julkaistaan palvelussa, jos vaihto on mahdollinen. Huomio, että ilmoittaessasi asuntosi vaihtoon, asuntosi tiedot, omat yhteystietosi ja ilmoitusteksti julkaistaan kaikkien OmaM2-palvelun käyttäjien nähtäväksi. \nIlmoituksesi on näkyvissä kuusi kuukautta tai kunnes poistat sen. Jos vaihdat asuntoa tai lopetat palvelun käytön, ilmoitus poistuu automaattisesti."; }
      },
      adsent: function(d) { return "Ilmoituksesi on lähetetty tarkistettavaksi!"; },
      gotonewad: function(d) { return "Tee asunnonvaihtoilmoitus"; },
      allcities: function(d) { return "Kaikki kaupungit"; },
      emptyadlist: function(d) { return "Ei asunnonvaihtoilmoituksia"; },
      close: function(d) { return "Sulje"; },
      ad: {
        current: {
          title: function(d) { return "Asukkaan kertomaa vaihtoon ilmoittamastaan kodista"; }
        },
        desired: {
          title: function(d) { return "Tällaista kotia etsin vaihdossa"; }
        },
        buildingtitle: function(d) { return "Vaihdettavan asunnon tiedot"; },
        name: function(d) { return "Ilmoittajan nimi"; },
        phone: function(d) { return "Puhelinnumero"; },
        email: function(d) { return "Sähköposti"; },
        address: function(d) { return "Kotiosoite"; },
        description: function(d) { return "Kerro millaista asuntoa etsit ja milloin haluaisit muuttaa. Ilmoitusteksti julkaistaan vaihtoilmoituksesi yhteydessä."; },
        newtitle: function(d) { return "Tee asunnonvaihtoilmoitus"; },
        newdescstart: function(d) { return "Ilmoittaessasi asuntosi vaihtoon, asuntosi tiedot, yhteystietosi ja ilmoitusteksti julkaistaan tässä palvelussa. "; },
        newdesclinktext: function(d) { return "Katso ohjeet asunnon vaihtoon"; },
        newdescend: function(d) { return "."; },
        publish: function(d) { return "Lähetä"; },
        status: {
          published: function(d) { return "Julkaistu: " + d.published; },
          "new": function(d) { return "Odottaa hyväksyntää"; },
          deleted: function(d) { return "Poistettu"; }
        },
        icontext: function(d) { return "Vaihtoilmoitus"; },
        removebtn: function(d) { return "Poista"; },
        housinginfobtn: function(d) { return "Katso talon esittely"; },
        rooms: function(d) { return "Huoneistotyyppi"; },
        squares: function(d) { return "Pinta-ala"; },
        lease: function(d) { return "Vuokra"; },
        water: function(d) { return "Vesiennakko"; },
        buildingtype: function(d) { return "Rakennustyyppi"; },
        year: function(d) { return "Valmistumisvuosi"; },
        apartments: function(d) { return "Asuntoja"; },
        floors: function(d) { return "Asuinkerroksia"; },
        elevator: function(d) { return "Hissi"; },
        yes: function(d) { return "Kyllä"; },
        no: function(d) { return "Ei"; }
      },
      disabled: function(d) { return "Sinulla on voimassa oleva vaihtoilmoitus. Jos haluat luoda uuden ilmoituksen, poista ensin vanha."; },
      swap: {
        title: function(d) { return "Vaihtoehto 1 – M2-Kodin asukkaiden keskinäinen asunnonvaihto"; },
        description: function(d) { return "Jokainen M2-Kotien asukas voi ilmoittaa halukkuudesta vaihtaa kotia keskenään toisen M2-Kodin asukkaan kanssa. Kun valitset tämän vaihtoehdon, toimi näin:"; },
        step1: function(d) { return "Tutustu alla näkyviin asunnonvaihtajaehdokkaiden asuntoihin ja katso löytyisikö niistä sinua kiinnostava koti (Jos alla ei näy asuntoja, ei tällä hetkellä kukaan asukas ole ilmoittanut kotiaan keskinäiseen asunnonvaihtoon. Voit käydä tarkistamassa ilmoitustilanne uudestaan aina kun haluat.)"; },
        step2: function(d) { return "Kun löydät alta sopivan kodin, ole yhteydessä suoraa asukkaaseen, joka ilmoituksen on jättänyt ja sovi hänen kanssaan tutustumisesta asuntoon. Asuntoon tutustuminen sovitaan aina asukkaiden kesken."; },
        step3: function(d) { return "Kun löydät kumppanin asunnonvaihtoon, lähettäkää viestiä vaihtohalukkuudesta osoitteeseen: kodit@ysaatio.fi"; },
        step4: function(d) { return "Tarkastamme hakijoiden luottotiedot, asumis- ja maksuhistorian ja käsittelemme asukkaiden keskinäisen asunnonvaihdon ARAn ohjeiden mukaisesti. Mahdolliset luottotietomerkinnät vaikuttavat uuden vuokra-asunnon vakuuden määrään. "; },
        step5: function(d) { return "Mikäli ehdot täyttyvät, voidaan keskinäinen asunnonvaihto toteuttaa."; },
        notice: function(d) { return "Huom! Älä irtisano nykyistä asuntoasi ennen kuin saat keskinäinen asunnonvaihto on vahvistettu M2-Kotien puolelta. M2-Kodit ei tee muuttotarkastuksia kun muutto toteutuu asukkaiden keskinäisen asunnonvaihdon kautta."; },
        instructionlink: function(d) { return "Haluatko ilmoittaa oman nykyisen kotisi asukkaiden keskinäiseen asunnonvaihtoon? "; },
        link: function(d) { return "Klikkaa tästä ohjeisiin."; },
        newtitle: function(d) { return "Tee asunnonvaihtoilmoitus"; },
        newswapinfo1: function(d) { return "Nykyisen M2-kotisi tiedot tulevat ilmoitukseen automaattisesti. Tiedot ovat: huoneistotyyppi, pinta-ala, vuokra, vesiennakko (myös tieto, jos huoneistossa on oma vedenkulutuksen mittaus), rakennustyyppi, valmistumisvuosi, asuntojen lukumäärä ja asuinkerrosten määrä."; },
        newswapinfo2: function(d) { return "Huomioi, että kaikki ilmoitukseen kirjoittamasi tieto julkaistaan. Älä siis kirjoita mitään sellaista, mitä et halua muiden asukkaiden näkevän. Älä esim. koskaan kirjoita henkilötunnustasi ilmoitukseen. Asuntoosi liittyvät tiedot ja yhteystietosi julkaistaan osana asunnonvaihtoilmoitusta."; },
        newswapinfo3: function(d) { return "Vain ilmoitukset, joissa keskinäinen vaihto on asukastietojen mukaan mahdollista, julkaistaan. Ilmoituksesi on voimassa kolme (3) kuukautta, tai kunnes poistat sen. Jos muutat pois, asunnonvaihtoilmoituksesi poistuu automaattisesti. "; },
        currentapttitle: function(d) { return "Lisätietoja vaihtoon ilmoittamastasi kodista."; },
        currentaptplaceholder: function(d) { return "Tässä voit mainostaa kotiasi. Kerro miksi vaihtaja innostuisi muuttamaan nykyiseen kotiisi. Kuuluuko siihen esim. parveke, löytyykö läheltä koirapuisto, vai ehkä jotain muuta? Teksti julkaistaan asunnonvaihtoilmoituksessasi."; },
        desiredapttitle: function(d) { return "Millaista kotia etsit vaihdossa?"; },
        desiredaptplaceholder: function(d) { return "Kirjoita tähän millaista kotia etsit, mihin ja milloin haluaisit muuttaa. Ilmoitusteksti julkaistaan asunnonvaihtoilmoituksessasi, asunto- ja talotietojen lisäksi."; },
        termscheckbox: function(d) { return "Olen ymmärtänyt, että nykyisen asuntoni tiedot ja omat kontaktitietoni julkaistaan osana asunnonvaihtoilmoitusta. Myös yllä oleviin tekstikenttiin kirjoittamani tieto julkaistaan ilmoituksessa kaikkien OmaM2-palvelun käyttäjien näkyviin. "; },
        send: function(d) { return "Lähetä"; },
        backtotop: function(d) { return "Palaa sivun alkuun"; }
      },
      newapplication: {
        title: function(d) { return "Vaihtoehto 2 - Kokonaan uusi asuntohakemus M2-Kodeille"; },
        description: function(d) { return "Kun haluat hakea uutta asuntoa M2-Kotien kaikkien vapaiden tai vapautumassa olevien kotien joukosta, toimi näin:"; },
        step1: function(d) { return "Mene osoitteeseen "; },
        step2: function(d) { return "Kun löydät kodin/kodit, jotka sopisivat paremmin nykyiseen elämäntilanteeseesi, lisää ne hakemukselle ja lähetä hakemus sivuston ohjeiden mukaisesti. "; },
        step3: function(d) { return "Käsittelemme hakemuksesi muiden hakemusten joukossa, ARA-ohjeiden mukaisesti. "; },
        step4: function(d) { return "Mahdolliset luottotietomerkinnät vaikuttavat uuden vuokra-asunnon vakuuden määrään."; },
        notice: function(d) { return "Huomioi, että voit hakea uutta kotia vain, jos se on perusteltua, esim. perheen koko tai taloudellinen tilanne on muuttunut. Muutokset terveydentilassa voivat myös toimia perusteena uudelle kodille."; }
      }
    },
    watermeter: {
      title: function(d) { return "Veden kulutus ja laskutus"; },
      noexist: {
        description: function(d) { return "Asunnossa ei ole vesimittaria."; }
      }
    },
    housing: {
      title: function(d) { return "Taloni tiedot"; },
      description: function(d) { return "OmaM2-palvelussa voit kätevästi hoitaa asumiseesi liittyviä asioita."; },
      basicinfo: {
        buildingtype: function(d) { return "Rakennustyyppi"; },
        year: function(d) { return "Valmistumisvuosi"; },
        apartmentcounts: function(d) { return "Asuntoja"; },
        floors: function(d) { return "Asuinkerroksia"; },
        elevator: function(d) { return "Hissi"; }
      },
      housefeat: {
        title: function(d) { return "Talostasi löytyy"; },
        laundryroom: function(d) { return "Talopesula"; },
        dryingroom: function(d) { return "Kuivaushuone"; },
        bikestorage: function(d) { return "Pyörähuone"; },
        clubroom: function(d) { return "Yhteistilat"; },
        wasteroom: function(d) { return "Jätehuone"; },
        buildingsauna: function(d) { return "Talosauna"; },
        locker: function(d) { return "Pukuhuone"; },
        apartmentstorage: function(d) { return "Huoneistokohtaiset irtainvarastot"; },
        outdoorstorage: function(d) { return "Ulkoiluvälinevarasto"; },
        babycarriage: function(d) { return "Lastenvaunuvarasto"; },
        none: function(d) { return "Ei tietoa"; },
        book: function(d) { return "Varaa"; }
      },
      contacts: {
        title: function(d) { return "Yhteystiedot"; },
        manager: function(d) { return "Asiaa isännöitsijälle?"; },
        descriptionLeft: function(d) { return "M2-Kodeilla isännöintiin liittyvissä kysymyksissä auttaa ensisijaisesti osaava asiakaspalvelumme. Ole siis yhteydessä asiakaspalveluun, tiedot vasemmalla. Jos asiasi on sellainen, että vain isännöitsijä voi auttaa, varmistaa asiakaspalvelumme, että viestisi välittyy kotitalosi isännöitsijälle jatkotoimenpiteitä varten."; },
        descriptionAbove: function(d) { return "M2-Kodeilla isännöintiin liittyvissä kysymyksissä auttaa ensisijaisesti osaava asiakaspalvelumme. Ole siis yhteydessä asiakaspalveluun, tiedot yläpuolella. Jos asiasi on sellainen, että vain isännöitsijä voi auttaa, varmistaa asiakaspalvelumme, että viestisi välittyy kotitalosi isännöitsijälle jatkotoimenpiteitä varten."; },
        maintenance: function(d) { return "Huoltoyhtiö"; },
        customerservice: function(d) { return "Asiakaspalvelu"; },
        customerservicename: function(d) { return "M2-Kodit"; },
        emergency: function(d) { return "Päivystys"; },
        phonenumber: function(d) { return "puh."; }
      },
      briefings: {
        title: function(d) { return "Tiedotteet"; }
      },
      showall: {
        placeholder: function(d) { return "Näytä kaikki"; }
      },
      documents: {
        title: function(d) { return "Dokumentit"; }
      },
      instructions: {
        title: function(d) { return "Ladattavat ohjeet"; }
      },
      emergencyplan: {
        title: function(d) { return "Talon pelastussuunnitelma"; },
        documentname: function(d) { return "Pelastussuunnitelma"; }
      },
      noexist: {
        emergencyplan: function(d) { return "Pelastussuunnitelma tulossa."; },
        briefings: function(d) { return "Ei tiedotteita."; },
        documents: function(d) { return "Ei dokumentteja."; },
        residentpricelist: function(d) { return "Ei asukaslaskuhinnastoja."; },
        instructions: function(d) { return "Alla tärkeimmät yhteystiedot arjen tilanteiden hoitamiseen kanssamme. Voit halutessasi lähettää meille yhteydenottopyynnön myös täältä OmaM2-palvelun kautta. Klikkaa lomakkeeseen"; },
        instructionsLink: function(d) { return "tästä."; },
        laundry: function(d) { return "Kotitalossasi ei ole asukkaiden käyttöön varattua talopesulaa. "; }
      },
      yes: function(d) { return "Kyllä"; },
      no: function(d) { return "Ei"; },
      readmore: function(d) { return "Lue lisää"; },
      sauna: {
        title: function(d) { return "Saunavuorot"; }
      },
      safety: {
        title: function(d) { return "Turvallisuus ja pelastautuminen"; },
        download: function(d) { return "Pelastussuunnitelma, Safetum"; }
      },
      parking: {
        header: function(d) { return "Asukaspysäköinti"; }
      },
      ownhouse: {
        title: function(d) { return "Oma talo"; }
      },
      news: {
        title: function(d) { return "Tiedotteet"; }
      },
      latestnews: {
        title: function(d) { return "Talokohtaiset tiedotteet "; }
      },
      externalnews: {
        title: function(d) { return "m2kodit.fi-uutiset"; }
      },
      laundry: {
        title: function(d) { return "Talopesula"; }
      },
      internet: {
        title: function(d) { return "Nettiyhteys"; }
      },
      storage: {
        title: function(d) { return "Varastointi ja säilytys"; }
      },
      rentSummary: {
        title: function(d) { return "Vuokran muodostuminen"; }
      },
      folders: {
        title: function(d) { return "Ohjeet ja dokumentit"; },
        description: function(d) { return "Alta näkyvistä valikoista löydät tärkeää tietoa ja ohjeita kotisi laitteisiin, ylläpitoon ja huoltoon liittyen."; },
        generic: function(d) { return "Nimetön kansio"; },
        documents: function(d) { return "Kodin tiedot kansion sisältö"; },
        ownhouse: function(d) { return "Tietoa asumisesta"; },
        safety: function(d) { return "Turvallisuus"; },
        communication: function(d) { return "TV- ja tietoliikenne"; },
        appliances: function(d) { return "Kodinkoneet"; },
        hvac: function(d) { return "Lämmitys ja ilmanvaihto"; },
        service: function(d) { return "Kodinhoito ja -huolto"; }
      }
    },
    bookings: {
      calendar: {
        title: function(d) { return "Varaukseni"; },
        pincode: function(d) { return "Pin-koodisi:"; },
        userManual: function(d) { return "One4all Mobile käyttöohje"; },
        noexist: function(d) { return "Varauskalenteria ei löydetty!"; },
        navigate: function(d) { return "Varauskalenteriin"; }
      },
      list: {
        upcoming: function(d) { return "Tulevat varaukseni"; },
        noexist: function(d) { return "Sinulla ei ole voimassa olevia varauksia."; },
        link: function(d) { return "Siirry varauskalenteriin."; },
        showall: function(d) { return "Näytä kaikki"; }
      }
    },
    profile: {
      title: function(d) { return "Päivitä omat tietosi"; },
      description: function(d) { return "Tarkista, että yhteystietosi ovat ajan tasalla."; },
      contactinfoheader: function(d) { return "Yhteystiedot"; },
      phoneplaceholder: function(d) { return "Puhelinnumero"; },
      phonerequired: function(d) { return "Anna puhelinnumero"; },
      phoneinvalid: function(d) { return "Tarkista puhelinnumero"; },
      emailplaceholder: function(d) { return "Sähköpostiosoite"; },
      emailrequired: function(d) { return "Anna sähköpostiosoite"; },
      emailinvalid: function(d) { return "Tarkista sähköpostiosoite"; },
      save: function(d) { return "Tallenna"; },
      savesuccess: function(d) { return "Kiitos, tiedot on tallennettu!"; },
      newsletter: function(d) { return "Tilaa M2-Kotien uutiskirje!"; },
      newsletterSubtitle: function(d) { return "Saat ajankohtaista tietoa M2-Kotien asunnoista, asukkaan palveluista ja asukastoiminnasta."; },
      newsletterBtn: function(d) { return "Tilaa uutiskirje"; },
      protectionOrder: function(d) { return "Turvakielto"; },
      protectionOrderText: function(d) { return "Sinulle on asetettu turvakielto."; }
    },
    footer: {
      housing: {
        defaultcontact: function(d) { return "Yhteystiedot"; },
        contact: function(d) { return "Talosi yhteystiedot"; },
        manager: function(d) { return "Isännöitsijä"; },
        maintenance: function(d) { return "Huoltoyhtiö"; },
        customerservice: {
          title: function(d) { return "Asiakaspalvelu"; },
          name: function(d) { return "M2-Kodit"; },
          phone: function(d) { return "09 7742 5500"; },
          email: function(d) { return "m2.asiakaspalvelu@ysaatio.fi"; }
        },
        myhousing: function(d) { return "Tiedot"; },
        news: function(d) { return "Ajankohtaista "; },
        safety: function(d) { return "Turvallisuus ja pelastautuminen"; },
        parking: function(d) { return "Pysäköinti"; },
        laundry: function(d) { return "Talopesula"; },
        sauna: function(d) { return "Saunavuorot"; },
        internet: function(d) { return "Nettiyhteys"; }
      },
      copyright: function(d) { return "Copyright © M2-Kodit 2023"; },
      dataprotection: function(d) { return "Tietosuoja"; },
      terms: function(d) { return "Käyttöehdot ja henkilötietosuoja"; },
      linkbutton: function(d) { return "Katso yhteystiedot"; },
      text: function(d) { return "OmaM2 on asukkaiden palvelu, jossa voi hoitaa asumiseen liittyviä asioita kellonajasta riippumatta; maksaa vuokran, tehdä vikailmoituksen, lähettää viestiä M2-asiakaspalveluun, lukea lisää sauna- tai talopesulavuorojen varauskäytännöistä, tarkastaa huoltoyhtiön tai asiakaspalvelun yhteystiedot."; },
      contact: {
        description: function(d) { return "Lähetä viesti asiakaspalveluumme alla olevalla lomakkeella. Vastaamme sinulle sähköpostitse mahdollisimman pian."; },
        button: function(d) { return "Ota yhteyttä "; }
      },
      titles: {
        omam2: function(d) { return "OmaM2"; },
        apartment: function(d) { return "Oma asumiseni"; },
        housing: function(d) { return "Taloni"; },
        fault: {
          reports: function(d) { return "Vikailmoitukseni"; }
        },
        contact: function(d) { return "Yhteydenotto"; }
      },
      apartment: {
        contract: function(d) { return "Vuokrasopimukseni"; },
        billing: function(d) { return "Vuokra- ja maksutietoni"; },
        maintenance: function(d) { return "Ohjeet ja dokumentit"; },
        apartmentchange: function(d) { return "Asunnonvaihto"; },
        watermeter: function(d) { return "Veden kulutus ja laskutus"; },
        bookingsCalendar: function(d) { return "Varaukseni"; }
      }
    },
    signin: {
      title: function(d) { return "Tervetuloa OmaM2-palveluun!"; },
      description: function(d) { return "OmaM2 on asukkaiden oma palvelukanava."; },
      step1: {
        description: function(d) { return "Täällä voit kätevästi "; },
        servicedescriptions: function(d) { return "katsoa vuokratietojasi ja maksaa vuokrasi \nkatsoa käsittelyssä olevia vikailmoituksiasi \ntutustua vaihtopörssissä ilmoitettuihin vaihtoasuntoihin \nkatsoa talosi tietoja ja tiedotteita \nottaa M2-Koteihin yhteyttä."; },
        authdescription: function(d) { return "Pääset kirjautumaan palveluun, kun sopimuksesi on allekirjoitettu. \nAloita kirjoittamalla alle puhelinnumerosi. Saat tekstiviestillä kertakäyttöisen kirjautumiskoodin. Jos kirjaudut ensimmäistä kertaa, tunnistaudu palveluun joko"; },
        authoptions: function(d) { return "verkkopankkitunnuksilla tai \nmobiilivarmenteella. "; },
        placeholder: function(d) { return "Anna puhelinnumerosi"; },
        label: function(d) { return "Puhelinnumerosi"; },
        error: function(d) { return "Tarkista puhelinnumero"; },
        forbidden: function(d) { return "Käyttäjää ei löytynyt. Ota tarvittaessa yhteys M2-Kotien asiakaspalveluun puh. 09 7742 5500 tai m2.asiakaspalvelu@ysaatio.fi."; },
        "continue": function(d) { return "Jatka"; }
      },
      step2: {
        description: function(d) { return "Kirjoita tekstiviestinä saamasi kertakäyttöinen kirjautumiskoodi, niin pääset kirjautumaan asukassivuillesi."; },
        placeholder: function(d) { return "Syötä koodi"; },
        label: function(d) { return "Kirjautumiskoodi"; },
        error: function(d) { return "Tarkista kirjautumiskoodi"; },
        back: function(d) { return "Anna puhelinnumero uudestaan"; },
        doSignIn: function(d) { return "Kirjaudu"; },
        acceptservice: function(d) { return "Hyväksyn palvelun"; },
        termsofuse: function(d) { return "käyttöehdot."; }
      },
      step3: {
        description: function(d) { return "Seuraavaksi vahvistamme henkilöllisyytesi pankkitunnuksilla tai mobiilivarmenteella."; },
        error: function(d) { return "Tunnistautumisessa tapahtui virhe, ole hyvä ja yritä uudelleen."; },
        forbidden: function(d) { return "Käyttäjää ei löytynyt. Ota tarvittaessa yhteys M2-Kotien asiakaspalveluun puh. 09 7742 5500 tai m2.asiakaspalvelu@ysaatio.fi."; },
        "continue": function(d) { return "Siirry tunnistautumaan"; }
      },
      step4: {
        title: function(d) { return "Päivitä yhteystietosi"; },
        description: function(d) { return "Tarkista, että yhteystietosi ovat ajan tasalla."; },
        phoneplaceholder: function(d) { return "Puhelinnumero"; },
        phonerequired: function(d) { return "Anna puhelinnumero"; },
        phoneinvalid: function(d) { return "Tarkista puhelinnumero"; },
        emailplaceholder: function(d) { return "Sähköpostiosoite"; },
        noemail: function(d) { return "Minulla ei ole sähköpostia."; },
        emailrequired: function(d) { return "Anna sähköpostiosoite"; },
        emailinvalid: function(d) { return "Tarkista sähköpostiosoite"; },
        save: function(d) { return "Tallenna"; }
      },
      redirectToM2: function(d) { return "< Takaisin m2kodit.fi-etusivulle"; },
      cancel: function(d) { return "Peruuta"; }
    },
    feedback: {
      step1: {
        title: function(d) { return "Kirjauduit ulos OmaM2 -palvelusta."; },
        question: function(d) { return "Saitko hoidettua asiat, joita varten kirjauduit palveluun?"; },
        yes: function(d) { return "Kyllä"; },
        no: function(d) { return "Ei"; },
        placeholder: function(d) { return "Anna meille palautetta palvelun kehittämiseksi. Palautteet käsitellään anonyymisti."; },
        send: function(d) { return "Lähetä"; }
      },
      step2: {
        title: function(d) { return "Kiitos palautteestasi!"; },
        login: function(d) { return "Kirjaudu sisään"; }
      },
      redirectToM2: function(d) { return "< Takaisin m2kodit.fi-etusivulle"; }
    },
    messages: {
      "new": {
        title: function(d) { return "Ota yhteyttä"; },
        desc: function(d) { return "Lähetä meille viesti alla olevalla lomakkeella."; },
        choose: function(d) { return "Valitse aiheeksi"; },
        housing: {
          "": function(d) { return "jos asiasi koskee asumisen ongelmatilanteita (mm. asumistuen ja etuuksien hakeminen, oman talouden hallinta, naapurisopu, kodin puhtaanapito)"; },
          debtassistance: function(d) { return "Apua velkaantumistilanteessa"; },
          benefitapplication: function(d) { return "Etuuksien hakeminen (esim. asumistuki)"; },
          tooexpensive: function(d) { return "Liian kallis asunto"; },
          neighbourdispute: function(d) { return "Apua naapurikiistan ratkaisemiseen"; },
          evictionassistance: function(d) { return "Apua häätötilanteen selvittämiseen"; },
          dailyerrandsassistance: function(d) { return "Neuvoa arjen haasteellisuuteen"; },
          mentalhealthandsubstanceabuse: function(d) { return "Neuvontaa mielenterveys- tai päihdeasioissa"; }
        },
        billing: {
          "": function(d) { return "jos asiasi koskee vuokranmaksuun liittyviä asioita"; },
          latepayment: function(d) { return "Vuokranmaksun viivästyminen"; },
          paymentissues: function(d) { return "Vuokranmaksuvaikeudet"; },
          debtrepayment: function(d) { return "Vuokravelasta sopiminen"; }
        },
        customerservice: {
          "": function(d) { return "mikäli asiasi koskee muita asioita"; },
          apartmentapplications: function(d) { return "Asuntohakemus"; },
          sauna_laundry_parking: function(d) { return "Sauna-, talopesula- tai autopaikkavaraukset"; },
          contracttermination: function(d) { return "Asunnon irtisanominen/poismuutto"; },
          propertymanagement: function(d) { return "Isännöintiin liittyvät asiat "; },
          faultreports: function(d) { return "Vikailmoitus"; },
          other: function(d) { return "Muut asiat"; }
        },
        faultreportstart: function(d) { return "Vikailmoituksella"; },
        faultreport: function(d) { return "voit ilmoittaa huoneistoasi tai talosi yhteis- sekä yleistiloja koskevan vian tai haitan. Vikailmoitus on tarkoitettu kiireettömiin korjaus- ja huoltotoimenpiteisiin. Kiireellisissä tapauksissa soita"; },
        faultreportlink: function(d) { return "talosi kiinteistöhuoltoon."; },
        replysoon: function(d) { return "Vastaamme yhteydenottopyyntöösi sähköpostitse mahdollisimman pian."; },
        form: {
          email: function(d) { return "Sähköposti"; },
          emailrequired: function(d) { return "Anna sähköpostiosoite"; },
          emailinvalid: function(d) { return "Tarkista sähköpostiosoite"; },
          phone: function(d) { return "Puhelinnumero"; },
          phonerequired: function(d) { return "Anna puhelinnumero"; },
          phoneinvalid: function(d) { return "Tarkista puhelinnumero"; },
          name: function(d) { return "Nimi"; },
          category: function(d) { return "Valitse aihe *"; },
          subcategory: function(d) { return "Valitse ala-aihe *"; },
          subject: function(d) { return "Otsikko"; },
          subjectrequired: function(d) { return "Anna otsikko"; },
          content: function(d) { return "Viesti"; },
          contentrequired: function(d) { return "Kirjoita viestisi"; },
          button: function(d) { return "Lähetä"; },
          messagesent: function(d) { return "Viestisi on lähetetty!"; },
          messagefailure: function(d) { return "Virhe viestin lähetyksessä!"; },
          categories: {
            "471": function(d) { return "Asiakaspalvelu"; },
            "472": function(d) { return "Vikailmoitus asunnosta"; },
            "473": function(d) { return "Vuokranmaksu"; },
            "474": function(d) { return "Asiakirjatilaukset"; },
            "859": function(d) { return "Asumisneuvonta"; },
            problemhouse: function(d) { return "Vikailmoitus talosta"; }
          }
        },
        copymessage: function(d) { return "Yhteystiedoistasi ei löytynyt sähköpostiosoitetta. Voit käydä lisäämässä sen "; },
        profile: function(d) { return "Omat tiedot -osiossa"; },
        instruction: {
          latepayment: function(d) { return "Jos haluat keskustella mahdollisuudesta maksaa vuokrasummaa pidemmällä aikavälillä, valitse 'Vuokravelasta sopiminen'. Jos kyseessä on vuokranmaksun viivästyminen muutamalla päivällä (enintään 3 päivää), jatka lomakkeen täyttämistä."; },
          sauna_laundry_parking: function(d) { return "Sauna-, talopesula- ja autopaikkavarauksissa auttaa oma huoltoyhtiösi. "; },
          faultreports: function(d) { return "Vesi-, savu- tai palovahingoissa sekä toimimattoman kylmälaitteen osalta, soita välittömästi huoltoyhtiöösi. "; },
          faultreportsother: function(d) { return "Muissa vika-/huoltopyyntöasioissa, täytä "; },
          linktomaintenance: function(d) { return "Klikkaa huoltoyhtiösi yhteystietoihin."; },
          linktofaultreport: function(d) { return "sähköinen vikailmoituslomake."; },
          propertymanagement: function(d) { return "Viestisi ohjataan asiakaspalveluumme, joka ensisijaisesti auttaa isännöintiin liittyvissä kysymyksissä. Jos viestisi aihe on sellainen, että vain isännöitsijä voi auttaa, varmistaa asiakaspalvelu, että se tavoittaa kotitalosi isännöitsijän."; }
        }
      },
      title: function(d) { return "Vikailmoitukseni"; },
      maindescription: function(d) { return "Seuraa oman asuntosi ja talosi vikailmoituksia"; },
      description: function(d) { return "Asunnostasi sähköisella lomakkeella tehdyt vikailmoitukset"; },
      disclaimer1: function(d) { return "Jos olet tehnyt vikailmoituksia puhelimitse tai sähköpostilla, ne eivät näy OmaM2-palvelussa"; },
      deschousing: function(d) { return "Talostasi sähköisella lomakkeella tehdyt vikailmoitukset"; },
      disclaimer2: function(d) { return "Jos Talostasi on tehty vikailmoituksia puhelimitse tai sähköpostilla, ne eivät näy OmaM2-palvelussa"; },
      disclaimer3: function(d) { return "Huomaathan, että listassa näkyvät kaikki talosi yhteistiloja koskevat vikailmoitukset."; },
      emptylist: function(d) { return "Ei vikailmoituksia"; },
      subject: function(d) { return "Aihe"; },
      messageStatuses: {
        "0": function(d) { return "Vastaanotettu"; },
        "1": function(d) { return "Käsittelyssä"; },
        "2": function(d) { return "Valmis"; },
        Sent: function(d) { return "Vastaanotettu"; },
        InProgress: function(d) { return "Käsittelyssä"; },
        Ready: function(d) { return "Valmis"; }
      },
      showmore: function(d) { return "Näytä kaikki"; },
      newmessage: function(d) { return "Ilmoita viasta"; }
    },
    message: {
      success: {
        success: function(d) { return "Viesti lähetetty onnistuneesti!"; },
        description: function(d) { return "Sinut ohjataan takaisin etusivulle hetken kuluttua. Voit myös palata takaisin käyttämällä Etusivu painiketta."; },
        button: function(d) { return "Etusivu"; }
      }
    },
    billing: {
      title: function(d) { return "Vuokra- ja maksutietoni"; },
      description: function(d) { return "Tarkastele vuokra- ja maksutietojasi."; },
      description2: function(d) { return "Suorituksesi näkyy OmaM2-palvelussa ja M2-Kotien vuokravalvonnassa kolmen (3) pankkipäivän kuluessa. Huomioithan, että pyhiä ja arkipyhiä ei lasketa pankkipäiviksi."; },
      print: {
        description: function(d) { return "Vuokralaskusi maksutietoineen löydät alla olevasta painikkeesta."; },
        title: function(d) { return "Tulosta vuokralaskusi"; },
        parkingLot: function(d) { return "Tulosta autopaikkalaskusi"; },
        page: function(d) { return "Tulosta"; }
      },
      filter: {
        startdate: function(d) { return "Aloita tarkastelu kuukaudesta"; },
        enddate: function(d) { return "Lopeta tarkastelu kuukauteen"; },
        applyrange: function(d) { return "Näytä aikaväli"; },
        showall: function(d) { return "Näytä kaikki"; },
        showbilled: function(d) { return "Näytä laskut"; },
        showpaid: function(d) { return "Näytä suoritukset"; }
      },
      gotopayment: function(d) { return "Siirry maksamaan"; },
      payment: {
        title: function(d) { return "Maksaminen"; },
        description: function(d) { return "Suorituksesi näkyy OmaM2-palvelussa ja M2-Kotien vuokravalvonnassa kolmen (3) pankkipäivän kuluessa."; },
        description2: function(d) { return "Voit maksaa joko koko maksun tai osan siitä. <b>HUOM! Aloita OmaM2-palvelussa maksaessasi aina uusi maksutapahtuma alta.</b> Aloita uusi maksutapahtuma, vaikka suoritus koskisikin aiempaa maksuerää. <b>Älä käytä OmaM2-palvelussa maksetun vanhan suorituksen maksutietoja.</b> "; },
        errorhelptext: function(d) { return "Maksaminen ei valitettavasti onnistu. Käytä verkkopankkiasi tai laskua maksamiseen."; },
        subtitle1: function(d) { return "1. Anna maksettava summa"; },
        description1: function(d) { return "Sinulla on erääntynyttä maksettavaa"; },
        tobepaid: function(d) { return "Maksetaan"; },
        amounttoolow: function(d) { return "Maksettavan summan pitää olla vähintään 1 €"; },
        subtitle2: function(d) { return "2. Valitse maksutapa"; },
        paymentinfo: function(d) { return "Maksun tiedot"; },
        paymentreceiver: function(d) { return "Maksun saaja"; },
        paymentreceiver2: function(d) { return "Kiinteistö Oy M2-Kodit"; },
        referencenumber: function(d) { return "Viitenumero"; },
        sum: function(d) { return "Maksettava summa"; },
        gotopayment: function(d) { return "Siirry maksamaan"; },
        showbilling: function(d) { return "Näytä velkaerittely"; },
        paytrailinfo: function(d) { return "Maksupalvelumme on Paytrail."; },
        success: function(d) { return "Maksusi on rekisteröity ja näkyy OmaM2-palvelussa kolmen (3) pankkipäivän kuluessa."; },
        failure: function(d) { return "Maksutapahtuma on keskeytynyt."; },
        "continue": function(d) { return "Jatka"; },
        proceed: function(d) { return "[TRANSLATE ME] Proceed"; },
        recent: function(d) { return "Olet " + d.time + " maksanut " + d.amount + " €. Huomioithan, että suorituksesi näkyy OmaM2:ssa vasta kun pankkisuoritus on kirjautunut M2-Kotien vuokravalvontaan (vähintään kolme (3) pankkipäivää). Pyhiä ja arkipyhiä tai viikonloppuja ei lasketa pankkipäiviksi, jolloin suorituksen näkymiseen voi mennä useampi päivä. "; }
      },
      current: {
        period: function(d) { return "Kuluva maksukausi"; },
        advance: function(d) { return "Tällä hetkellä olet maksanut liikaa / ennakkoa"; },
        remaining: function(d) { return "Tällä hetkellä sinulla on erääntynyttä maksettavaa"; },
        uptodate: function(d) { return "Tällä hetkellä maksusi ovat ajan tasalla"; }
      },
      history: {
        period: function(d) { return "Maksukausi"; },
        billed: function(d) { return "Laskutettu"; },
        paid: function(d) { return "Suoritukset"; },
        duedate: function(d) { return "Eräpäivä"; },
        paiddate: function(d) { return "Maksupäivä"; },
        sum: function(d) { return "Yhteensä"; },
        advance: function(d) { return "jälkeen olit maksanut liikaa / ennakkoa"; },
        remaining: function(d) { return "jälkeen sinulla oli erääntynyttä maksettavaa"; },
        uptodate: function(d) { return "jälkeen maksusi olivat ajan tasalla"; },
        prevAdvance: function(d) { return "Edellisillä maksukausilla maksettu liikaa / ennakkoa"; },
        prevRemaining: function(d) { return "Edellisillä maksukausilla maksamatta jäänyt"; }
      },
      itemized: function(d) { return "Näytä velkaerittely"; },
      months: {
        "0": function(d) { return "Tammikuu"; },
        "1": function(d) { return "Helmikuu"; },
        "2": function(d) { return "Maaliskuu"; },
        "3": function(d) { return "Huhtikuu"; },
        "4": function(d) { return "Toukokuu"; },
        "5": function(d) { return "Kesäkuu"; },
        "6": function(d) { return "Heinäkuu"; },
        "7": function(d) { return "Elokuu"; },
        "8": function(d) { return "Syyskuu"; },
        "9": function(d) { return "Lokakuu"; },
        "10": function(d) { return "Marraskuu"; },
        "11": function(d) { return "Joulukuu"; }
      }
    },
    agreement: {
      title: function(d) { return "Vuokrasopimukseni"; },
      subtitle: function(d) { return "Vuokrasopimuksesi tiedot"; },
      istemporary: function(d) { return "Määräaikainen"; },
      ongoing: function(d) { return "Toistaiseksi voimassa"; },
      month: function(d) { return "kuukauden"; },
      personmonth: function(d) { return "/ hlö / kk"; },
      permonth: function(d) { return "/ kk"; },
      day: function(d) { return "päivä"; },
      last: function(d) { return "viimeinen"; },
      apartment: {
        title: function(d) { return "Huoneiston tiedot"; },
        address: function(d) { return "Osoite"; },
        type: function(d) { return "Huoneistotyyppi"; },
        sqfoot: function(d) { return "Pinta-ala"; },
        houseowner: function(d) { return "Vuokranantaja"; }
      },
      tenants: {
        title: function(d) { return "Asukkaat"; },
        title2: function(d) { return "Vuokralaisen tiedot"; },
        contract: function(d) { return "Vuokralainen / Vuokralaiset"; },
        other: function(d) { return "Muut asukkaat"; }
      },
      print: function(d) { return "Tulosta tiedot"; },
      download: function(d) { return "Lataa sopimusehdot"; },
      terminate: function(d) { return "Irtisano sopimus"; },
      lease: {
        title: function(d) { return "Vuokra ja vesimaksu"; },
        title2: function(d) { return "Vuokra ja vesimaksu"; },
        price: function(d) { return "Vuokra"; },
        waterPrePayment: function(d) { return "Vesimaksuennakko"; },
        waterPerPerson: function(d) { return "Vesimaksu"; },
        waterPerConsumption: function(d) { return "Vesimaksu kulutuksen mukaan"; },
        duedate: function(d) { return "Eräpäivä"; }
      },
      period: {
        title: function(d) { return "Vuokra-aika"; },
        type: function(d) { return "Sopimus"; },
        startdate: function(d) { return "Sopimus alkaa"; },
        enddate: function(d) { return "Sopimus päättyy"; },
        paiddeposit: function(d) { return "Maksettu vuokravakuus"; }
      },
      deposit: {
        title: function(d) { return "Vakuusmaksu"; },
        paymenttype: {
          "218": function(d) { return "Rahavakuus"; },
          "223": function(d) { return "Maksusitoumus"; },
          "245": function(d) { return "Muu vakuus"; },
          "260": function(d) { return "Vaihtajan vakuus"; },
          "318": function(d) { return "Avainpantti"; }
        },
        sum: function(d) { return "Summa"; },
        moneydeposit: function(d) { return "Rahavakuus"; },
        paidcontract: function(d) { return "Maksusitoumus"; },
        othertype: function(d) { return "Muu vakuus"; },
        keydeposit: function(d) { return "Avainpantti"; },
        refunded: function(d) { return "Palautettu"; },
        refundedsum: function(d) { return "palautettu summa"; },
        info: function(d) { return "Mahdollinen vakuus palautetaan noin kuukauden sisällä vuokrasuhteen päättymisestä, jos asunnon tarkastuksessa eikä vuokrasaatavissa ole ilmennyt saatavia ja kaikki avaimet on luovutettu ohjeistuksen mukaisesti."; }
      }
    },
    modal: {
      redirect: function(d) { return "Palveluun siirtyminen avaa uuden välilehden."; },
      "continue": function(d) { return "Jatka"; },
      cancel: function(d) { return "Peruuta"; }
    },
    onboarding: {
      welcome: function(d) { return "Tervetuloa"; },
      info1: function(d) { return "OmaM2 on M2-Kotien asukkaiden asiointisivusto.\n \nLue lisää klikkaamalla nuolia tai numeroita."; },
      uptodate: function(d) { return "Tiedot ajan tasalla"; },
      info2: function(d) { return "Päivitä yhteystietosi, katso talosi tietoja tai ota yhteyttä M2-Koteihin."; },
      rental: function(d) { return "Omat vuokratiedot"; },
      info3: function(d) { return "Vuokranmaksu -osiossa näet vuokranmaksutilanteesi yhdellä silmäyksellä. Voit myös tulostaa vuokralaskusi."; },
      developing: function(d) { return "OmaM2 kehittyy"; },
      info4: function(d) { return "Kehitämme OmaM2-palvelua jatkuvasti. Tulossa on uusia ominaisuuksia. Anna meille palautetta palvelusta uloskirjautumisen yhteydessä."; }
    },
    debt: {
      title: function(d) { return "Velkaerittely"; },
      description: function(d) { return "Velkaerittelystä näet maksamattomat laskut. Maksusuoritukset kohdistuvat aina vanhimpaan maksamattomaan laskuun. Voit myös tehdä maksusuunnitelmaehdotuksen M2-Kodeille."; },
      paytype: function(d) { return "Maksettavaa"; },
      rent: function(d) { return "Vuokra"; },
      collectioncharge: function(d) { return "Perintäkulu"; },
      penaltyinterest: function(d) { return "Viivästyskorko"; },
      saunapayment: function(d) { return "Saunamaksu"; },
      debtamount: function(d) { return "Velka"; },
      duedate: function(d) { return "Eräpäivä"; },
      sum: function(d) { return "Yhteensä"; },
      print: function(d) { return "Tulosta lasku"; },
      contact: {
        title: function(d) { return "Ehdota maksusuunnitelmaa"; },
        description: function(d) { return "Mikäli et pysty maksamaan koko velkaerää kerralla, voit neuvotella maksusuunnitelmasta soittamalla asiakaspalveluumme."; },
        description2: function(d) { return "Puhelin: 09 7742 5500, auki maanantaista perjantaihin klo 9–15"; },
        paysuggestion: function(d) { return "Maksusuunnitelmaehdotus"; },
        placeholder: function(d) { return "Ilmoita tarkat summat ja maksupäivät velan lyhennyksille ja maksusuunnitelman aikana erääntyville vuokrille."; },
        subject: function(d) { return "Viestin aihe"; },
        email: function(d) { return "Sähköpostiosoite"; },
        phonenumber: function(d) { return "Puhelinnumero"; },
        name: function(d) { return "Nimi"; },
        send: function(d) { return "Lähetä"; },
        copymessage: function(d) { return "Jos olet antanut sähköpostiosoitteesi, saat lähettämästäsi viestistä kopion sähköpostiisi. Yhteystietosi voit päivittää omat tiedot -osiossa."; }
      }
    },
    work: {
      title: function(d) { return "Töitä asukkaille"; },
      more: function(d) { return "Lue lisää"; },
      work: function(d) { return "Haluatko töitä?"; }
    },
    m2: {
      about: {
        title: function(d) { return "Tietoa M2-Kodeista"; }
      },
      sustainability: {
        title: function(d) { return "M2-Kotien vastuullisuus"; }
      }
    },
    living: {
      home: {
        title: function(d) { return "Asuminen M2-Kodeilla"; }
      },
      benefits: {
        title: function(d) { return "Asukasedut"; }
      },
      events: {
        title: function(d) { return "Asukastapahtumat"; },
        upcoming: function(d) { return "Tulevat tapahtumat"; },
        noupcoming: function(d) { return "Ei tulevia tapahtumia"; }
      },
      activities: {
        title: function(d) { return "Asukastoiminta"; }
      }
    },
    forms: {
      title: function(d) { return "Lomakkeita asiontisi helpottamiseksi"; },
      pdf: {
        powerofattorney: function(d) { return "Valtakirja vuokralaisen edustamiseen (PDF)"; },
        keytransferagreement: function(d) { return "Avaintenluovutussopimus (PDF)"; },
        personaldataconsent: function(d) { return "Vuokralaisen suostumus henkilötietojen luovuttamiseen (PDF)"; },
        estateinstructions: function(d) { return "Ohjeita kuolinpesälle (PDF)"; }
      }
    }
  }
}