// React
import React, { Fragment, useEffect } from 'react';
import { Router, Route, Switch, RouteComponentProps } from 'react-router-dom';
import ReactGA from 'react-ga';

// Pages
import ApartmentChangePage from './pages/ApartmentChangePage';
import ApartmentNewApplicationPage from './pages/ApartmentNewApplicationPage';
import ApartmentSwapPage from './pages/ApartmentSwapPage';
import BillingPage from './pages/BillingPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import SignInPage from './pages/SignInPage';
import FeedbackPage from './pages/FeedbackPage';
import MessagesPage from './pages/MessagesPage';
import SearchPage from './pages/SearchPage';
import AgreementPage from './pages/AgreementPage';
import ApartmentChangeApprovalPage from './pages/ApartmentChangeApprovalPage';
import PaymentPage from './pages/PaymentPage';
import DebtPage from './pages/DebtPage';
import ApartmentFormsPage from './pages/ApartmentFormsPage';

// Pages: Housing
import HousingPage from './pages/HousingPage';
import HousingSauna from './pages/HousingSauna';
import HousingLaundry from './pages/HousingLaundry';
import HousingNews from './pages/HousingNews';
import HousingParking from './pages/HousingParking';
import HousingSafety from './pages/HousingSafety';
import HousingStorage from './pages/HousingStorage';
import HousingInternet from './pages/HousingInternet';
import ApartmentWaterMeter from './pages/ApartmentWaterMeter';
import HousingRentSummary from './pages/HousingRentSummary';
import HousingBookingsCalendar from './pages/HousingBookingsCalendar';
import HousingManualsPage from './pages/HousingManualsPage';

// Pages: Living
import LivingHome from './pages/living/LivingHome';
import LivingBenefits from './pages/living/LivingBenefits';
import LivingEvents from './pages/living/LivingEvents';
import LivingActivities from './pages/living/LivingActivities';

// Pages: Work
import WorkPage from './pages/work/WorkPage';

// Pages: M2
import M2About from './pages/m2/M2About';
import M2Sustainability from './pages/m2/M2Sustainability';

// Components
import Loader from './components/basic/Loader';
import LanguageQueryParamHandler from './components/localization/LanguageQueryParamHandler';

// Context
import { Dispatcher, logout } from './context/actions';

import { Routes } from './constants/routes';
import { useApp } from './context/appContext';
import * as history from 'history';
import AuthenticatedRoute from './AuthenticatedRoute';

const customHistory = history.createBrowserHistory();

export const mainNavi = [
  { name: 'home', to: Routes.Home.HOME },
  { name: 'apartment',
    to: Routes.Apartment.HOME,
    children: [
      { name: 'agreement', to: Routes.Apartment.HOME },
      { name: 'billing', to: Routes.Apartment.BILLING },
      { name: 'bookingsCalendar', to: Routes.Apartment.BOOKINGS_CALENDAR },
      { name: 'messages', to: Routes.Apartment.MESSAGES },
      { name: 'watermeter', to: Routes.Apartment.WATER_METER},
      { name: 'forms', to: Routes.Apartment.FORMS },
      { name: 'change', to: Routes.Apartment.CHANGE },
      // { name: 'termination', to: Routes.Apartment.HOME } TODO: Fix on Desktop
    ],
  },
  { name: 'housing', to: Routes.Housing.HOME,
    children: [
      { name: 'myhousing', to: Routes.Housing.HOME },
      { name: 'internet', to: Routes.Housing.INTERNET },
      { name: 'sauna', to: Routes.Housing.SAUNA },
      { name: 'laundry', to: Routes.Housing.LAUNDRY },
      { name: 'parking', to: Routes.Housing.PARKING },
      { name: 'storage', to: Routes.Housing.STORAGE },
      { name: 'manuals', to: Routes.Housing.MANUALS },
      { name: 'safety', to: Routes.Housing.SAFETY },
      { name: 'rentSummary', to: Routes.Housing.RENT_SUMMARY },
    ]
  },
  { name: 'living', to: Routes.Living.HOME,
    children: [
      { name: 'title', to: Routes.Living.HOME},
      { name: 'benefits', to: Routes.Living.BENEFITS },
      { name: 'events', to: Routes.Living.EVENTS },
      { name: 'activities', to: Routes.Living.ACTIVITIES},
    ]
  },
  { name: 'work', to: Routes.WORK, excludeLanguage: 'en' },
  { name: 'm2homes', to: Routes.M2Homes.HOME,
    children: [
      { name: 'about', to: Routes.M2Homes.HOME },
      { name: 'sustainability', to: Routes.M2Homes.SUSTAINABILITY },
    ]
  },
  { name: 'profileAlt', to: Routes.PROFILE, mobileOnly: true },
];

export const mobileNavi = [
  { name: 'home', to: Routes.Home.HOME },
  { name: 'apartment',
    to: Routes.Apartment.HOME,
    children: [
      { name: 'agreement', to: Routes.Apartment.HOME },
      { name: 'billing', to: Routes.Apartment.BILLING },
      { name: 'bookingsCalendar', to: Routes.Apartment.BOOKINGS_CALENDAR },
      { name: 'messages', to: Routes.Apartment.MESSAGES },
      { name: 'watermeter', to: Routes.Apartment.WATER_METER},
      { name: 'forms', to: Routes.Apartment.FORMS },
      { name: 'change', to: Routes.Apartment.CHANGE },
      { name: 'termination', to: Routes.Apartment.HOME } // TODO: Scroll to anchor for termination button or something
    ],
  },
  { name: 'housing', to: Routes.Housing.HOME,
    children: [
      { name: 'myhousing', to: Routes.Housing.HOME },
      { name: 'internet', to: Routes.Housing.INTERNET },
      { name: 'sauna', to: Routes.Housing.SAUNA },
      { name: 'laundry', to: Routes.Housing.LAUNDRY },
      { name: 'parking', to: Routes.Housing.PARKING },
      { name: 'storage', to: Routes.Housing.STORAGE },
      { name: 'manuals', to: Routes.Housing.MANUALS },
      { name: 'safety', to: Routes.Housing.SAFETY },
      { name: 'rentSummary', to: Routes.Housing.RENT_SUMMARY },
    ]
  },
  { name: 'living', to: Routes.Living.HOME,
    children: [
      { name: 'benefits', to: Routes.Living.BENEFITS },
      { name: 'events', to: Routes.Living.EVENTS },
      { name: 'activities', to: Routes.Living.ACTIVITIES},
      { name: 'title', to: Routes.Living.HOME},
    ]
  },
  { name: 'work', to: Routes.WORK, excludeLanguage: 'en' },
  { name: 'm2homes', to: Routes.M2Homes.HOME,
    children: [
      { name: 'about', to: Routes.M2Homes.HOME },
      { name: 'sustainability', to: Routes.M2Homes.SUSTAINABILITY },
    ]
  },
  { name: 'profileAlt', to: Routes.PROFILE, mobileOnly: true },
];

function logoutOnInactivity(dispatcher: Dispatcher) {
  // Only use on production
  // TODO: Turning this back on for now, I'm suspecting something's acting up here
  // if(process.env.REACT_APP_ENV !== 'production') {
  //   return
  // };

  const now = Date.now();
  const lastActivity = Number.parseInt(window.localStorage.getItem('lastActivity') || now.toString());
  window.localStorage.setItem('lastActivity', now.toString());
  if (lastActivity < now - 15 * 60 * 1000) {
    logout(dispatcher);

    // will clear all the data from the previous session about the user
    window.localStorage.clear();
    window.localStorage.setItem('lastActivity', now.toString());
    return true;
  }
  return false;
}

export const ApartmentSubRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Fragment>
      <AuthenticatedRoute exact path={Routes.Apartment.HOME} component={AgreementPage} />
      <AuthenticatedRoute exact path={Routes.Apartment.BILLING} component={BillingPage} />
      <AuthenticatedRoute path={Routes.Apartment.BILLING_SUCCESS} component={BillingPage} />
      <AuthenticatedRoute path={Routes.Apartment.BILLING_ERROR} component={BillingPage} />
      <AuthenticatedRoute path={Routes.Apartment.BILLING_DEBT_DETAILS} component={DebtPage} />
      <AuthenticatedRoute path={Routes.Apartment.PAYMENT} component={PaymentPage} />
      <AuthenticatedRoute path={Routes.Apartment.MESSAGES} component={MessagesPage} />
      <AuthenticatedRoute exact path={Routes.Apartment.CHANGE} component={ApartmentChangePage} />
      <AuthenticatedRoute path={Routes.Apartment.SWAP} component={ApartmentSwapPage} />
      <AuthenticatedRoute path={Routes.Apartment.NEW} component={ApartmentNewApplicationPage} />
      <AuthenticatedRoute path={Routes.Apartment.FORMS} component={ApartmentFormsPage} />
      <AuthenticatedRoute path={Routes.Apartment.WATER_METER} component={ApartmentWaterMeter} />
      <AuthenticatedRoute path={Routes.Apartment.BOOKINGS_CALENDAR} component={HousingBookingsCalendar} />
      {/* <AuthenticatedRoute component={AgreementPage} >
        <Redirect to={Routes.Apartment.HOME}/>
      </AuthenticatedRoute> */}
    </Fragment>
  );
};

export const HousingSubRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Fragment>
      <AuthenticatedRoute exact path={Routes.Housing.HOME} component={HousingPage} />
      <AuthenticatedRoute path={Routes.Housing.SAUNA} component={HousingSauna} />
      <AuthenticatedRoute path={Routes.Housing.INTERNET} component={HousingInternet} />
      <AuthenticatedRoute path={Routes.Housing.NEWS} component={HousingNews} />
      <AuthenticatedRoute path={Routes.Housing.SAFETY} component={HousingSafety} />
      <AuthenticatedRoute path={Routes.Housing.PARKING} component={HousingParking} />
      <AuthenticatedRoute path={Routes.Housing.LAUNDRY} component={HousingLaundry} />
      <AuthenticatedRoute path={Routes.Housing.STORAGE} component={HousingStorage} />
      <AuthenticatedRoute path={Routes.Housing.MANUALS} component={HousingManualsPage} />
      <AuthenticatedRoute path={Routes.Housing.RENT_SUMMARY} component={HousingRentSummary} />
      {/* <AuthenticatedRoute component={HousingPage}>
        <Redirect to={Routes.Housing.HOME}/>
      </AuthenticatedRoute> */}
    </Fragment>
  );
};

export const LivingSubRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Fragment>
      <AuthenticatedRoute exact path={Routes.Living.HOME} component={LivingHome} />
      <AuthenticatedRoute path={Routes.Living.BENEFITS} component={LivingBenefits} />
      <AuthenticatedRoute path={Routes.Living.EVENTS} component={LivingEvents} />
      <AuthenticatedRoute path={Routes.Living.ACTIVITIES} component={LivingActivities} />
      {/* <AuthenticatedRoute component={LivingHome} >
        <Redirect to={Routes.Living.HOME}/>
      </AuthenticatedRoute> */}
    </Fragment>
  );
};

export const M2SubRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Fragment>
      <AuthenticatedRoute exact path={Routes.M2Homes.HOME} component={M2About} />
      <AuthenticatedRoute path={Routes.M2Homes.ABOUT} component={M2About} />
      <AuthenticatedRoute path={Routes.M2Homes.SUSTAINABILITY} component={M2Sustainability} />
      {/* <AuthenticatedRoute component={M2About} >
        <Redirect to={Routes.M2Homes.HOME}/>
      </AuthenticatedRoute> */}
    </Fragment>
  );
};

const RoutingRules = () => {
  const [state, dispatch] = useApp();

  useEffect(() => {
    return customHistory.listen((location) => ReactGA.pageview(location.pathname));
  }, []);

  useEffect(() => {
    logoutOnInactivity(dispatch);
  });

  if (!state.user.isLoaded) return <Loader />;

  return (
    <Router history={customHistory}>
      <LanguageQueryParamHandler>
        <Switch>
          <Route path={Routes.SIGN_IN} component={SignInPage} />
          <Route path={Routes.FEEDBACK} component={FeedbackPage} />
          <Route path={Routes.APARTMENT_CHANGE_APPROVAL} component={ApartmentChangeApprovalPage} />

          <AuthenticatedRoute path={Routes.PROFILE} component={ProfilePage} />
          <AuthenticatedRoute path={Routes.SEARCH} component={SearchPage} />

          {/* Apartment sub routes (/asuntoni/*) */}
          <AuthenticatedRoute path={Routes.Apartment.HOME} component={ApartmentSubRoutes} />

          {/* Housing sub routes (/taloni/*) */}
          <AuthenticatedRoute path={Routes.Housing.HOME} component={HousingSubRoutes} />

          {/* Living sub routes (/asuminen/*) */}
          <AuthenticatedRoute path={Routes.Living.HOME} component={LivingSubRoutes} />
          
          <AuthenticatedRoute path={Routes.WORK} component={WorkPage} />
          
          {/* M2 sub routes (/m2/*) */}
          <AuthenticatedRoute path={Routes.M2Homes.HOME} component={M2SubRoutes} />

          <AuthenticatedRoute exact path={Routes.Home.HOME} component={HomePage} />
          {/* <AuthenticatedRoute component={HomePage}>
            <Redirect to={Routes.Home.HOME}/>
          </AuthenticatedRoute> */}
        </Switch>
      </LanguageQueryParamHandler>
    </Router>
  );
};

export default RoutingRules;
