/**
 * AWS Gateway config for local, dev, staging and production environments (based on REACT_APP_ENV)
 */
const AWSApiConfig = {
  local: {
    apiGateway: {
      endpoints: [{
        name: 'apigateway',
        endpoint: 'http://localhost:4000',
        region: 'eu-central-1',
      }],
    }
  },
  dev: {
    apiGateway: {
      endpoints: [{
        name: 'apigateway',
        endpoint: 'https://jm79919vyd.execute-api.eu-central-1.amazonaws.com/dev',
        region: 'eu-central-1',
      }],
    }
  },
  staging: {
    apiGateway: {
      endpoints: [{
        name: 'apigateway',
        endpoint: 'https://usbfm9oni5.execute-api.eu-central-1.amazonaws.com/staging',
        region: 'eu-central-1',
      }],
    }
  },
  production: {
    apiGateway: {
      endpoints: [{
        name: 'apigateway',
        endpoint: 'https://oma-api.m2kodit.fi',
        region: 'eu-central-1',
      }],
    }
  },
}

export default AWSApiConfig;
