/* define device sizes here and use them in media queries*/
const size = {
  mobileS: '375px',
  mobileM: '410px',
  mobileL: '670px',
  tabletM: '770px',
  tabletL: '850px',
  desktopS: '900px',
  desktopM: '1280px'
};

export const DeviceSize = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tabletM: `(max-width: ${size.tabletM})`,
  tabletL: `(max-width: ${size.tabletL})`,
  desktopS: `(max-width: ${size.desktopS})`,
  desktopM: `(max-width: ${size.desktopM})`
};
