import React, { FunctionComponent, useEffect, Fragment } from 'react';
import { useApp } from '../../context/appContext';
import { useMessageGetter } from 'react-message-context';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../../theme';
import {H2, H3, SmallText, baseFontStyle, BasicText } from '../basic/Text';
import { blackLogo } from '../../assets';
import { History } from 'history';
import PaddedContent from '../housing/PaddedContent';
import { DeviceSize } from '../basic/DeviceSize';
import { loadHousing } from '../../context/actions';
import { isSignupComplete } from '../../utils';
import { NavLink } from 'react-router-dom';
import { Button } from '../button/Button';
import ReactGA from 'react-ga';

const HousingFooter: FunctionComponent<{ history: History }> = ({ history }) => {
  const t = useMessageGetter('footer');
  const [{ user, housing, housingInfo }, dispatch] = useApp();
  //const housing = state.housing;
  useEffect(() => {
    if (!housing && isSignupComplete(user)) {
      loadHousing(dispatch);
    }
  }, [dispatch, housing, user]);

  const smallText = (text?: string) => {
    return text && <SmallText>{text}</SmallText>;
  };

  const redirectToContacts = () => {
    window.location.href = 'https://m2kodit.fi/yhteystiedot/';
  };

  const LinkIcon = () => {
    return <Fragment> &#10095;</Fragment>
  }

  const FooterNavLink: FunctionComponent<{ to: string, children?: JSX.Element[] }> = ({to, children}) => {
    const scrollTop = () => {
      const element = document.getElementById('homelogo')
      element && element.scrollIntoView(true)
    }
    return <NavLink to={to} onClick={() => scrollTop()}>{children}</NavLink>
  }

  return (
    <ThemeProvider theme={theme('default')}>
      <Footer role="contentinfo">
        <FooterRow>
          {housing && (
            <FooterContainer>
              <FooterContainerColumn>
                <TitleH2>{t('titles.apartment')}</TitleH2>
                <FooterList>
                  <li><FooterNavLink to={'/asuntoni'}>{t('apartment.contract')}</FooterNavLink><LinkIcon/></li>
                  <li><FooterNavLink to={'/asuntoni/maksut'}>{t('apartment.billing')}</FooterNavLink><LinkIcon/></li>
                  <li>
                    <FooterNavLink to={'/taloni/varaukseni'}>{t('apartment.bookingsCalendar')}</FooterNavLink>
                    <LinkIcon/>
                  </li>
                  <li>
                    <FooterNavLink to={'/asuntoni/vikailmoitukset'}>{t('titles.fault.reports')}</FooterNavLink>
                    <LinkIcon/>
                  </li>
                </FooterList>
              </FooterContainerColumn>
              <FooterContainerColumn width={2}>
                <TitleH2>{t('titles.housing')}</TitleH2>
                <FooterList columns={2}>
                  <li><FooterNavLink to={'/taloni'}>{t('housing.myhousing')}</FooterNavLink><LinkIcon/></li>
                  <li><FooterNavLink to={'/#news'}>{t('housing.news')}</FooterNavLink><LinkIcon/></li>
                  <li><FooterNavLink to={'/taloni/turvallisuus'}>{t('housing.safety')}</FooterNavLink><LinkIcon/></li>
                  <li><FooterNavLink to={'/taloni/sauna'}>{t('housing.sauna')}</FooterNavLink><LinkIcon/></li>
                  <li><FooterNavLink to={'/taloni/netti'}>{t('housing.internet')}</FooterNavLink><LinkIcon/></li>
                  <li><FooterNavLink to={'/taloni/pysakointi'}>{t('housing.parking')}</FooterNavLink><LinkIcon/></li>
                  <li><FooterNavLink to={'/taloni/talopesula'}>{t('housing.laundry')}</FooterNavLink><LinkIcon/></li>
                  { housingInfo.folders && (
                    <li>
                      <FooterNavLink to={'/taloni/ohjeet-ja-dokumentit'}>{t('apartment.maintenance')}</FooterNavLink>
                      <LinkIcon/>
                    </li>
                  )}
                </FooterList>
              </FooterContainerColumn>
              <FooterContainerColumn>
                <LinkH2>
                  <a href='https://m2kodit.fi/' target='_blank' rel="noreferrer">
                    m2kodit.fi <LinkIcon />
                  </a>
                </LinkH2>
              </FooterContainerColumn>
            </FooterContainer>
          )}
          {!housing && (
            <FooterElement>
              <TitleH2>{t('housing.defaultcontact')}</TitleH2>
              <MainContact>
                <PaddedH3>{t('housing.customerservice.title')}</PaddedH3>
                {smallText(t('housing.customerservice.name'))}
                {smallText(t('housing.customerservice.phone'))}
                {smallText(t('housing.customerservice.email'))}
                <ContactButton onClick={redirectToContacts}>{t('linkbutton')}</ContactButton>
              </MainContact>
            </FooterElement>
          )}
          <FooterElement>
            <Logo src={blackLogo} alt="M2 logo" aria-label="M2-Kodit logo" />
          </FooterElement>
          <FooterDescriptionContainer>
            <SmallText>
              {t('text')}
            </SmallText>
          </FooterDescriptionContainer>
        </FooterRow>
        <CopyrightRow>
          <FooterElement>
            <CopyrightText>{t('copyright')}</CopyrightText>
          </FooterElement>
          <FooterPolicyLinks>
            <FooterElement>
              <FooterLink
                href="https://m2kodit.fi/tietosuoja/"
                onClick={() =>
                  ReactGA.event({
                    category: 'Quick links',
                    action: 'Click external link',
                    label: 'https://m2kodit.fi/tietosuoja/',
                  })
                }
              >
                {`${t('dataprotection')}`} <LinkIcon />
              </FooterLink>
            </FooterElement>
            <FooterElement>
              <FooterLink
                href="https://m2kodit.fi/kayttoehdot-ja-henkilotietosuoja/"
                onClick={() =>
                  ReactGA.event({
                    category: 'Quick links',
                    action: 'Click external link',
                    label: 'https://m2kodit.fi/kayttoehdot-ja-henkilotietosuoja/',
                  })
                }
              >
                {`${t('terms')}`} <LinkIcon />
              </FooterLink>
            </FooterElement>
          </FooterPolicyLinks>
        </CopyrightRow>
      </Footer>
    </ThemeProvider>
  );
};

const PaddedH3 = styled(H3)`
  padding-bottom: 3px;
`;

const LinkH2 = styled(H2)`
  font-size: 24px;
  a {
    color: ${(props) => props.theme.colors.m2Black};
  }
`;

const TitleH2 = styled(H2)`
  font-size: 24px;
`

const FooterDescriptionContainer = styled.div`
  width: 66.6667%;
  
  @media ${DeviceSize.desktopS} {
    margin-top: 10px;
    width: 85%;
  }
`;

const Logo = styled.img`
  width: 150px;
  @media ${DeviceSize.mobileL} {
    width: 125px;
    margin-top: 16px;
  }

  @media print {
    display: none;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  text-align: left;
  flex-direction: row;
  width: 100%;
  
  a:hover {
    text-decoration: underline;
  }
  
  @media ${DeviceSize.mobileL} {
    flex-direction: column;
    padding: 0 10px;
  }

  @media print {
    display: none;
  }
`;

const FooterContainerColumn = styled.div<{width?: number}>`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  
  // TODO: This is a little janky. What if we want 5 columns? Not enough time to fix now...
  width: ${props => (props.width || 1) * 25}%; 

  @media ${DeviceSize.desktopM} {
    flex: 1;
    width: 100%;
  }

  @media print {
    display: none;
  }
`;

const FooterList = styled.ul<{columns?: number}>`
  columns: ${props => props.columns || 1};
  font-weight: 400;
  padding: 0;
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  list-style: none;

  > li {
    padding: 2px 0px;
    margin-bottom: 12px;

    > a {
      color: ${props => props.theme.colors.m2Black};
    }
  }

  @media ${DeviceSize.desktopM} {
    max-height: initial;
  }

  @media ${DeviceSize.mobileL} {
    > li {
      margin-bottom: 4px;
    }
  }
`;

const MainContact = styled.div`
  margin-top: 22px;

  @media print {
    position: absolute;
    top: 0;
    left: 0;

    h3 {
      display: none;
    }

    p {
      color: ${(props) => props.theme.colors.coal};
      font-size: 10px;
      letter-spacing: 0.5px;
    }

    p:first-of-type {
      ${baseFontStyle};
      color: ${(props) => props.theme.colors.coal};
      margin: 0;
      font-size: 24px;
      letter-spacing: 1.2px;
    }
  }
`;

const CopyrightText = styled(BasicText)`
  margin: 0;
  font-size: 14px;
`;

const FooterPolicyLinks = styled.div`
  display: flex;

  @media ${DeviceSize.mobileL} {
    flex-direction: column;
    text-align: left;
  }
`;

const Footer = styled.footer`
  position: relative;
  margin-top: 20px;
  width: 100%;
  min-height: 452px;

  @media print {
    display: none;
  }
`;

const FooterRow = styled(PaddedContent)`
  border-radius: 24px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${(props) => props.theme.colors.mint};

  @media ${DeviceSize.desktopS} {
    margin: 0;
    border-radius: unset;
    padding: 2em 10px;
  }
`;

const CopyrightRow = styled(PaddedContent)`
  display: flex;
  flex-flow: wrap;
  padding: 0;

  @media print {
    display: none;
  }

  @media ${DeviceSize.mobileL} {
    padding: 24px 10px;
    text-align: left;
    flex-direction: column-reverse;
  }
`;

const FooterElement = styled.div`
  margin: 24px 16px;

  @media ${DeviceSize.mobileL} {
    width: 100%;
    margin: 2px 0;
  }
`;

const FooterLink = styled.a`
  font-weight: bold;
  color: ${(props) => props.theme.colors.m2Black};
  
  :hover {
    text-decoration: underline;
  }
`;

const ContactButton = styled(Button)`
  margin-top: 15px;
  
  :hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.coal};
  }

  @media print {
    display: none;
  }
`;

export default HousingFooter;
