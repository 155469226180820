import React, { Fragment,  useState } from 'react';
import styled, { css } from 'styled-components';
import { DeviceSize } from '../basic/DeviceSize';
import { downloadDocument } from '../../apis';
import RedirectModal from '../basic/RedirectModal';
import { activeElements, removeAttributes, setAttributes } from '../../utils';
import Loader from '../basic/Loader';
import { useMessageGetter } from 'react-message-context';
import dayjs from 'dayjs';
import ReactGA from 'react-ga';

type DocumentItemProps = {
  type: string;
  icon: string;
  item: any; // Type?
  display?: string; // What's this for again...?
  prefix?: string; // Appears before document name. TODO: Refactor this whole thing, I don't like this.
  className?: string;
};

// eslint-disable-next-line max-len
export const DocumentItem: React.FunctionComponent<DocumentItemProps> = ({ type, icon, item, display, prefix = '', className }) => {
  const [downloading, setDownloading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState('');

  const modalText = useMessageGetter('modal');
  
  // For downloadable documents
  const downloadFile = async () => {
    setDownloading(true);

    // To be sure, format document date with dayjs to ISO string to avoid slashes or other characters
    // that would cause problems with routes
    // TODO: If download returns error response, we load indefinitely
    const documentDate = dayjs(item.date).toISOString();
    await downloadDocument(item.fileId, documentDate).then((response) => {
      // const binArray = new Uint8Array(response.data.data);
      // const blob = new Blob([binArray], { type: response.contenttype });
      // const url = URL.createObjectURL(blob);
      ReactGA.event({
        category: 'Housing',
        action: 'Click external link',
        label: `https://oma-api.m2kodit.fi/documents/${item.fileId}/${documentDate}`,
      });
      setTimeout(() => {
        window.open(response.url, '_top');
      })
      setDownloading(false);
    });
  };

  // For external links
  const backgroundElements = document.querySelectorAll(activeElements);

  const cancelRedirection = () => {
    document.body.style.overflow = 'auto';
    setShowModal(false);

    // deactivating inputs, buttons in the background etc. when modal is shown
    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'false' });
    });

    ReactGA.event({
      category: 'Housing',
      action: 'Cancel redirection',
      label: item.url,
    });
  };

  const continueToPage = () => {
    document.body.style.overflow = 'auto';

    window.open(modalUrl, '_blank');
    setShowModal(false);

    // activating inputs, buttons in the background etc. when modal is hidden
    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'false' });
    });

    ReactGA.event({
      category: 'Housing',
      action: `Redirect to ${type} page`,
      label: item.url,
    });
  };

  const openRedirectModal = () => {
    document.body.style.overflow = 'hidden';

    // deactivating inputs, buttons in the background etc. when modal is shown
    backgroundElements.forEach((element) => {
      setAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
    });

    setShowModal(true);
    setModalUrl(item.url);
  };

  // Components for different document types
  const prefixedItemTitle = `${prefix}${item.title}`
  
  // TODO: These are all rather verbose and not very DRY, perhaps refactor sometime if it gets too messy
  // TODO: Typings are inconsistent between document types
  const BriefingItem = () => ( 
    <DocumentRowFile display={display} onClick={downloadFile} role="button" className={className} loading={downloading}>
      <DocumentTitle>
        <DocumentDate aria-label={`Tiedosto luotu: ${dayjs(item.date).format('DD.MM.YYYY')}`}>
          {dayjs(item.date).format('DD.MM.YYYY')}
        </DocumentDate> 
        <DocumentName aria-label={`Tiedoston nimi: ${item.title}`}>
          { prefixedItemTitle }
          { downloading && <DownloadingLoader /> }
        </DocumentName>
      </DocumentTitle>
      { icon  && <ArrowIcon src={icon}/> }
    </DocumentRowFile> 
  )

  const ManualItem = () => (
    <DocumentRow display={display} onClick={downloadFile} role="button" className={className} loading={downloading}>
      <DocumentTitle>
        <DocumentName aria-label={`Tiedoston nimi: ${item.title}`}>
          { prefixedItemTitle }
          { downloading && <DownloadingLoader /> }
        </DocumentName>
      </DocumentTitle>
    </DocumentRow>
  )

  const ExternalItem = () => ( 
    <DocumentRowFile 
      display={display} 
      onClick={openRedirectModal} 
      role="button" 
      className={className} 
      loading={downloading}
    >
      <DocumentTitle>
        <DocumentDate aria-label={`Julkaistu: ${dayjs(item.date).format('DD.MM.YYYY')}`}>
          { dayjs(item.date).format('DD.MM.YYYY') }
        </DocumentDate>
        <DocumentName aria-label={`Otsikko: ${item.title}`}>
          { prefixedItemTitle }
          { downloading && <DownloadingLoader /> }
        </DocumentName>
      </DocumentTitle>
      { icon && <ArrowIcon src={icon}/> }
    </DocumentRowFile>  
  )

  const GenericItem = () => (
    <GenericDocumentRow onClick={downloadFile} role="button" className={className} loading={downloading}>
      <DocumentTitle>
        <DocumentName aria-label={`Tiedoston nimi: ${item.title}`} color={'m2White'}>
          { prefixedItemTitle }
          { downloading && <DownloadingLoader /> }
        </DocumentName>
      </DocumentTitle>
      { icon && <ArrowIcon src={icon}/> }
    </GenericDocumentRow>
  )

  // TODO: Create an enum for all the different document types
  const ItemComponent = () => {
    switch(type) {
      case 'briefings':
        return <BriefingItem />
      case 'external':
        return <ExternalItem />
      case 'manuals':
        return <ManualItem />
      default:
        return <GenericItem />
    }
  }

  return (
    <Fragment>
      { showModal && (
        <RedirectModal 
          cancelRedirect={cancelRedirection} 
          continueRedirect={continueToPage}
          modalShown={showModal}
        >
          { modalText('redirect') }
        </RedirectModal>
      )}

      <ItemComponent />
    </Fragment>
  );
};


// TODO: Refactor all document types to use the same base styledcomponent, and then style others based on that one.
const loadingStyle = css`
  /* Modify the style while loading here */
      opacity: 75%;
`;

const DocumentRowFile = styled.div<{ display?: string, loading?: boolean }>`
  display: ${(props) => (props.display ? props.display : 'none')};
  flex-direction: row;
  align-items: center;
  padding: 10px 36px;
  margin: 5px 0;
  border-radius: 60px;
  background: ${props => props.theme.colors.m2White};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media ${DeviceSize.desktopM} {
    width: 100%;
    min-width: 100%;
  }

  ${props => props.loading && `
    ${loadingStyle};
  `}
`;

const GenericDocumentRow = styled.div<{ display?: string, loading?: boolean }>`
  background: ${props => props.theme.colors.m2Black};
  padding: 10px 36px;
  
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  border-radius: 60px;
  align-items: center;

  width: fit-content;

  cursor: pointer;
  
  :hover {
    background-color: ${props => props.theme.colors.coal};
  }
  
  ${props => props.loading && `
    ${loadingStyle};
  `}
`;

const DocumentRow = styled.div<{ display?: string, loading?: boolean }>`
  display: ${(props) => (props.display ? props.display : 'none')};
  flex-direction: row;
  align-items: center;
  padding: 2px 20px;
  margin: 2px 0;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media ${DeviceSize.desktopM} {
    width: 100%;
    min-width: 100%;
  }

  @media ${DeviceSize.mobileL} {
    margin: 4px 0;
  }
  
  ${props => props.loading && `
    ${loadingStyle};
  `}
`;

const DocumentTitle = styled.div<{ color?: string }>`
  margin-bottom: 0;
  width: 100%;
  
  @media ${DeviceSize.desktopS} {
    > span {
      margin-right: 10px;
    }
  }
`;

const DocumentName = styled.p<{color?: string}>`
  font-family: ${props => props.theme.text.primaryFont};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: initial;
  line-height: 1.43;
  margin: 0;
  position: relative;

  color: ${props => props.color ? props.theme.colors[props.color] : 'black'};

  @media ${DeviceSize.desktopS} {
    word-break: break-word;
  }
`;

const DocumentDate = styled.div`
  color: ${(props) => props.theme.colors.m2Black};
  font-size: 16px;
  font-weight: 400;

  @media ${DeviceSize.desktopS} {
    display: block;
  }
`;

const DownloadingLoader = styled(Loader)`
  position: absolute !important;
  display: inline-block !important;
  right: 0;
  top: 2px;
`;

const ArrowIcon = styled.img`
  height: 22px;
  align-self: center;
  margin-left: 20px;
`;
