import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { DeviceSize } from '../basic/DeviceSize';

const Notifications: FunctionComponent<{ notifications: string[] }> = ({ notifications }) => {
  return (
    <Container>
      {notifications.map((msg, i) => (
        <Notification key={i} length={notifications.length}>{msg}</Notification>
      ))}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1000;
  display: flex;
  width: 100%;
  background: ${props => props.theme.colors.camel};
  transition: height 0.5s;
`;

const Notification = styled.p<{length?: number}>`
  padding: 20px;
  margin: auto;
  font-size: 18px;
  height: ${props => props.length ? `${props.length * 75}px` : '75px'};
  
  @media ${DeviceSize.mobileL} {
    height: 20%;
  }
`;

export default Notifications;
