import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import WordPressPage from '../components/wordpress/wordpress';
import { useApp } from '../context/appContext';
import { loadHousing } from '../context/actions';
import HousingContact from '../components/housing/HousingContact';

const HousingRentSummary = ({ history }: RouteComponentProps) => {

  const t = useMessageGetter('housing');
  const c = useMessageGetter('footer.housing.customerservice');
  const [{ housing }, dispatch] = useApp();

  useEffect(() => {
    loadHousing(dispatch);
  }, [dispatch]);

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <WordPressPage slug='rent-summary' />
      </PaddedContent>
      { housing && <HousingContact t={t} housingContacts={housing} c={c} /> }
    </PageContainer>
  );
};


export default HousingRentSummary;
