import React, { FunctionComponent, useState, Fragment, useEffect } from 'react';
import { DeviceSize } from '../basic/DeviceSize';
import { mobileNavi } from '../../AppRouter';
import styled from 'styled-components';
import MainMenuItem from './MainMenuItem';
import { NavLink } from 'react-router-dom';
import { useMessageGetter } from 'react-message-context';
import { ColumnContainer } from '../basic/Flex';
import Logout from '../logout/Logout';
import { MainNavi } from '../basic/Text';
import { blackLogo, hamburgerIcon, closeMenuIcon } from '../../assets';
import { setAttributes, removeAttributes, changeLanguage, hasExpiringAgreement } from '../../utils';
import { useApp } from '../../context/appContext';

const SideMenu: FunctionComponent = () => {
  const t = useMessageGetter('navigation');
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<string | null>(null);
  const tag = document.body.querySelector('.content');
  const [state] = useApp();
  const disabledItemsOnExpired = ['housing', 'work', 'messages'];
  const disabledSubItemsOnExpired = ['change', 'manuals', 'watermeter'];

  const activeTabElements =
    '#mainnavi, #subMenu, #chevronmenu, .subitem, .sidemenu > div > .logoutButton, #temporarycross, #localizations';
  const activeSidemenu = document.body.querySelectorAll(activeTabElements);
  const debts = state.debts;

  useEffect(() => {
    document.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        setIsOpen(false);
      }
    });
  });

  useEffect(() => {
    if (tag) {
      if (isOpen) {
        tag.setAttribute('style', 'position: fixed; width: 100%');

        activeSidemenu.forEach((element) => {
          removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'false' });
        });
      } else {
        tag.removeAttribute('style');

        activeSidemenu.forEach((element) => {
          setAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
        });
      }
    }
  }, [isOpen, tag, activeSidemenu]);

  useEffect(() => {
    if (isOpen) {
      const activeElements = document.querySelectorAll(
        activeTabElements
      ) as unknown as HTMLCollectionOf<HTMLInputElement>;
      const first = activeElements[0];
      const last = activeElements[activeElements.length - 1];

      first.focus();

      document.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.keyCode !== 9 || e.key !== 'Tab') return;

        if (e.keyCode === 9) {
          if (e.shiftKey) {
            if (document.activeElement === first) {
              last.focus();
              e.preventDefault();
            }
          } else {
            if (document.activeElement === last) {
              first.focus();
              e.preventDefault();
            }
          }
        }
      });
    }
  }, [isOpen]);
  
  // Client's request, hide water meter heading if apartment does not have a meter
  const filterLink = (routeName: string) => {
    return !(routeName === 'watermeter' && state.apartment.info.waterMeter !== 'Huoneistokohtainen vesimittari');
  };
  
  const filterReservations = (routeName: string) => {
    return !(routeName === 'bookingsCalendar' && !state?.modules.modules?.length);
  };

  // Filter out change and manuals from expiring agreements
  const filterLinkExpired = (routeName: string) => {
    return !(disabledSubItemsOnExpired.some((item) => item === routeName) && hasExpiringAgreement(state.user));
  };

  const handleMenuItemClick = (item: string | null, closeMenu: boolean = false) => {
    // TODO: Add animation for opening/closing menu items
    // TODO: Leave submenu open when clicking submenu link 
    
    if(currentItem === item) { // Close submenu if it is clicked while open
      setCurrentItem(null); 
    } else {
      setCurrentItem(item);
    }

    if(closeMenu) {
      setIsOpen(false);
    }
  };

  return (
    <Mobile id="sideMenu">
      {isOpen ? (
        <CloseButton aria-label="Close the menu" onClick={() => setIsOpen(false)} src={closeMenuIcon} />
      ) : (
        <OpenButton aria-label="Open the menu" onClick={() => setIsOpen(true)} src={hamburgerIcon}/>
      )}

      <Overlay onClick={() => setIsOpen(false)} style={{ width: isOpen ? '100%' : '0%' }} warning={debts.hideWarning} />
      <MenuContainer
        style={{ width: isOpen ? '100%' : '0%' }}
        className="sidemenu"
        aria-haspopup="true"
        warning={debts.hideWarning}
      >
        {isOpen && (
          <CloseIcon>
            <CloseButton aria-label="Close the menu" onClick={() => setIsOpen(false)} src={closeMenuIcon} />
          </CloseIcon>
        )}
        <MenuInnerContainer>
          <MobileLogoIcon src={blackLogo} />
          <MenuItems role="navigation" aria-label="Sidemenu navigation">
            { mobileNavi.map((naviItem, i) => {
              if (window.sessionStorage.currentLanguage === 'en' && naviItem.name === 'work') {
                return null
              }
              if (hasExpiringAgreement(state.user) && disabledItemsOnExpired.some((item) => item === naviItem.name)) {
                return null
              }

              return (
                <Fragment key={naviItem.name}>
                  {naviItem.children ? (
                    <NestedMenuItem
                      name={naviItem.name}
                      children={
                        naviItem.children
                          .filter((subItem) => filterLink(subItem.name))
                          .filter((subItem) => filterReservations(subItem.name))
                          .filter((subItem) => filterLinkExpired(subItem.name))
                      }
                      to={naviItem.to}
                      clickSubMenuItem={() => handleMenuItemClick(currentItem, true)}
                      clickMenuItem={() => handleMenuItemClick(naviItem.name)}
                      isOpen={currentItem === naviItem.name}
                    />
                  ) : (
                    <TabItem
                      key={naviItem.name}
                      activeClassName="active"
                      id="mainnavi"
                      exact
                      to={naviItem.to}
                      role="menuitem"
                      onClick={() => handleMenuItemClick(naviItem.name)}
                    >
                      <TitleContainer>
                        <Nav>
                          <MainNaviText>{t(naviItem.name + '.title')}</MainNaviText>
                        </Nav>
                      </TitleContainer>
                    </TabItem>
                  )}
                </Fragment>
              )
            })
            }
            <Localizations id="localizations">
              <Language
                onClick={() => changeLanguage('fi')}
                active={sessionStorage.getItem('currentLanguage') === 'fi'}
              >
                {t('finnish')}
              </Language>
              <Language>{' | '}</Language>
              <Language
                onClick={() => changeLanguage('en')}
                active={sessionStorage.getItem('currentLanguage') === 'en'}
              >
                {t('english')}
              </Language>
            </Localizations>
            <Logout>
              <LogoutText>{t('signout.title')}</LogoutText>
            </Logout>
          </MenuItems>
        </MenuInnerContainer>
      </MenuContainer>
    </Mobile>
  );
};

const MobileLogoIcon = styled.img`
  max-width: 130px;
  margin-bottom: 10px;
`;

const Mobile = styled.div`
  display: none;
  visibility: hidden;
  @media ${DeviceSize.desktopM} {
    display: flex;
    visibility: visible;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.theme.layout.headerHeight}px;
    order: 2;
    width: 50px;
    z-index: 2;
  }
  @media print {
    display: none;
  }
`;

const Overlay = styled.div<{ warning?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background: rgba(5, 38, 44, 0.7);
  overflow: hidden;
  z-index: 1000;
`;

const MenuContainer = styled.div<{ warning?: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: ${(props) => props.theme.colors.lemon};
  color: white;
  transition: 0.3s;
  z-index: 1001;
  overflow-y: scroll;
`;

const MenuInnerContainer = styled.div`
  padding: 20px 20px 40px;
`;

const CloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 64px;
  padding-right: 20px;
`;

const MenuItems = styled.div`
  display: flex;
  margin: 16px 0;
  flex-flow: column;
  flex-grow: 1;
  font-family: ${(props) => props.theme.text.primaryFont};
  
  > div:last-child {
    margin-top: 30px;
  }

  > a,
  > div {
    display: flex;
  }

  > a.active,
  > div.active {
  }

  > a.subitem {
  }

  @media ${DeviceSize.desktopM} {
    > a,
    > div {
    }
  }
`;

const TitleContainer = styled(ColumnContainer)`
  width: 100%;
`;

const Nav = styled.div`
  flex: 1 90%;
`;

const MainNaviText = styled(MainNavi)`
  font-family: ${(props) => props.theme.text.primaryFont};
  font-size: 14pt;
  font-weight: 700;
  margin-bottom: 14px;
`;

// TODO: Remove unused
const TabItem = styled(NavLink)`
`;

const NestedMenuItem = styled(MainMenuItem)`
  cursor: pointer;
`;

const OpenButton = styled.img`
  position: relative;
  height: 32px;
  width: 32px;
`;

const CloseButton = styled.img`
  position: relative;
  height: 32px;
  width: 32px;

  :before,
  :after {
    position: absolute;
    left: 15px;
    top: 5px;
    content: ' ';
    height: 25px;
    width: 8px;
    background-color: ${(props) => props.theme.colors.mint};
  }
`;

const Localizations = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${(props) => props.theme.colors.white};
  margin-top: 10px;
`;

const Language = styled(MainNavi)<{ active?: boolean }>`
  margin: 0;
  padding: 10px 10px 10px 0;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }

  font-weight: ${(props) => props.active ? 'bold' : 'normal'};
`;

const LogoutText = styled.span`
  font-family: ${(props) => props.theme.text.primaryFont};
  font-size: 14pt;
  font-weight: 700;
  margin-bottom: 14px;
`

export default SideMenu;
