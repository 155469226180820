/**
 * Format phone number removing all characters except for digits, and transform it into international format
 */
export const sanitizePhone = phoneNumber => {
  if (!phoneNumber) {
    throw new Error('Empty phone number');
  }
  let onlyNumbers = phoneNumber.replace(/[^0-9]/g, '');
  if (onlyNumbers.length < 7 || onlyNumbers.length > 13) {
    throw new Error('Invalid phone number');
  }

  if (onlyNumbers.charAt(0) === '0') {
    return `+358${onlyNumbers.substring(1)}`;
  } else {
    return `+${onlyNumbers}`;
  }
};

export const messageCategories = [471, 473, 859];

export const depositCategories = [218, 223, 245, 260, 318];

export const removeCountryCode = phoneNumber => {
  if (!phoneNumber) {
    throw new Error('Empty phone number');
  }

  if (phoneNumber.length < 7 || phoneNumber.length > 14) {
    throw new Error('Invalid phone number');
  }

  if (phoneNumber.includes('+358')) {
    return phoneNumber.replace('+358', '0');
  } else if (phoneNumber.includes('+')){
    return phoneNumber.replace('+', '');
  } else {
    return phoneNumber;
  }
};

export const paymentTypesIds = [331, 332, 235, 231, 240, 272, 292, 220, 290, 262, 236, 263, 312, 313, 294, 328, 329, 287, 239, 306, 303, 269, 289, 307, 334, 335, 344, 350];
