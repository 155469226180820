// React
import React, { useEffect, useState } from 'react';
import { useApp } from '../../context/appContext';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components';

// Components
import PaddedContent from '../housing/PaddedContent';
import { BasicParagraph, H2 } from '../basic/Text';
import Loader from '../basic/Loader';

import { formatDate, formatTime } from '../../utils';
import { getTenantBookings, LOAD_STATES } from '../../context/actions';
import { arrowForward } from '../../assets';
import { DeviceSize } from '../basic/DeviceSize';
import { RowContainer } from '../basic/Flex';
import { Link } from 'react-router-dom';
import { Routes } from '../../constants/routes';

const BookingsList: React.FunctionComponent = () => {
  const t = useMessageGetter('bookings.list');
  const [state, dispatch] = useApp();
  const { bookings, loadState } = state.bookings;
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    getTenantBookings(dispatch);
  }, [dispatch]);

  const showAllBookings = () => {
    setShowAll(true);
  };

  return (
    <Container>
      <H2>{t('upcoming')}</H2>
      {loadState !== LOAD_STATES.BUSY ?
        <BookingListContainer>
          {bookings && bookings.length > 0 ? (
            /* Display a maximum of three bookings by default */
            (showAll ? bookings : bookings.slice(0, 3)).map((booking) => (
              <Link to={Routes.Apartment.BOOKINGS_CALENDAR} key={booking.uuid}>
                <BookingItemContainer>
                  <TextContainer>
                    <DateText>
                      {`${formatDate(booking.begins)}`}
                    </DateText>
                    <TitleText>
                      {`${booking.calendarName} ${formatTime(booking.begins)}`}
                    </TitleText>
                  </TextContainer>
                  <Icon src={arrowForward} />
                </BookingItemContainer>
              </Link>
            ))
          )
            :
            <div>
              <NoBookingsText>
                {t('noexist')}{' '}
                <InlineLink to={Routes.Apartment.BOOKINGS_CALENDAR}>{t('link')}</InlineLink>
              </NoBookingsText>
            </div>
          }
          {!showAll && bookings && bookings.length > 3 &&
            <ButtonContainer>
              <ShowAllButton onClick={showAllBookings}>
                {t('showall')}
              </ShowAllButton>
            </ButtonContainer>
          }
        </BookingListContainer> : <Loader/>}
    </Container>
  );
};

const Container = styled(PaddedContent)`
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
`;

const NoBookingsText = styled(BasicParagraph)`
  margin: 5px 0;
`;

const InlineLink = styled(Link)`
    color: inherit;
    font-family: inherit;
    text-decoration: underline;
    line-height: 1.5;
    letter-spacing: 0.8px;
`;

const BookingListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;

  @media ${DeviceSize.desktopM} {
    flex-direction: column;
  }
`;

const ButtonContainer = styled(RowContainer)`
  flex-direction: row;
  padding: 24px;

  @media ${DeviceSize.desktopM} {
    justify-content: end;
    width: unset !important;
    max-width: unset !important;
  }
`;

const ShowAllButton = styled(H2)`
  font-size: 20px;
  text-align: right;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media ${DeviceSize.desktopS} {
    font-size: 16px;
  }
`;

const Icon = styled.img`
  margin-left: auto;
  height: 24px;
`;

const BookingItemContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.m2White};
  align-items: center;
  justify-content: space-between;
  padding: 10px 36px;
  margin: 5px;
  border-radius: 60px;
  width: 100%;
  max-width: 100%;

  @media ${DeviceSize.desktopM} {
    width: 100%;
    max-width: 100%;
  }
`;

const TextContainer = styled.div`
  width: 100%;
`;

const DateText = styled(BasicParagraph)`
  margin: 0;
  padding: 0;
`;

const TitleText = styled(BasicParagraph)`
  font-size: 20px;
  font-family: ${(props) => props.theme.text.secondaryFont};
  margin: 0;
  padding: 0;

  @media ${DeviceSize.desktopS} {
    font-size: 16px;
  }
`;

export default BookingsList;
