import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/background_logo.svg';
import ColorIcon from '../basic/ColorIcon';
import {DeviceSize} from '../basic/DeviceSize';

// Big ole M2 logo used in the 2023-Q2 UI update. 

interface BackgroundLogoProps {
  color?: string
}

const BackgroundLogo = ({color}: BackgroundLogoProps) => (
  <BackgroundLogoContainer>
    <ColorIcon IconComponent={Logo} color={color} width={800} height={800} />
  </BackgroundLogoContainer>
);

const BackgroundLogoContainer = styled.div`
  position: absolute;
  max-width: 50vw;
  left: -150px;
  top: -40px;
  
  @media ${DeviceSize.desktopM} {
    display: block;
    position: absolute;
    top: 10px;
    left: -25vw; 
    max-width: 125vw;  // fill page width
    overflow: hidden;
  }

  @media print {
    display: none;
  }
`;

export default BackgroundLogo;
