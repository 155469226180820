import { Routes } from './routes';

export const searchPages = [
  {
    name: 'apartment',
    keywords: 'vuokrasopimus, sopimus, vuokrasopimukseni, vakuusmaksu, vakuus, vuokravakuus irtisanominen,' +
      'sopimuksen irtisanominen, irtisanomisilmoitus, muutto, poismuutto, contract, lease agreement, agreement,' +
      'deposit, paid deposit, refund, terminate contract, end, moving, moving away',
    route: Routes.Apartment.HOME
  },
  {
    name: 'billing',
    keywords: 'vuokranmaksu, vuokran maksu, vuokra, vuokran maksaminen, laskuni, velkaerittely,' +
      'maksusuunnitelma, vuokraerittely, vesimaksu, paying rent, payment, rent, renting, invoice,' +
      'bill, payments, water bill',
    route: Routes.Apartment.BILLING
  },
  {
    name: 'change',
    keywords: 'asunnonvaihto, asunnon vaihto, asunnon vaihtopörssi, vaihtopörssi, asuntohakemus, hakemus, ' +
      'asunnon vaihtaminen, aprtment change, exchange, vacant apartments, available, apartment market, applicant form',
    route: Routes.Apartment.CHANGE
  },
  {
    name: 'contact',
    keywords: 'asiakaspalvelu, aspa, asumisneuvonta, yhteydenotto, viesti, viestin lähetys, contact,' +
      'customer service, m2-kodit, message, sending message',
    route: Routes.Home.HOME + '#newmessage'
  },
  {
    name: 'contactinformation',
    keywords: 'huolto, huoltoyhtiö, isännöitsijä, yhteystiedot, contact information, maintenance company,' +
      'property manager, maintenance',
    route: Routes.Home.HOME + '#maintenance'
  },
  {
    name: 'fault',
    keywords: 'vikailmoitus, fault report, fault, issue, building',
    route: Routes.Apartment.MESSAGES
  },
  {
    name: 'internet',
    keywords: 'netti, nettiyhteys, internet, internet connection',
    route: Routes.Housing.INTERNET
  },
  {
    name: 'laundry',
    keywords: 'pesutupa, talopesula, pyykkitupa, kuivaushuone, taloni,' +
      'laundry room, laudry, dryer, drying room, building',
    route: Routes.Housing.LAUNDRY
  },
  {
    name: 'manuals',
    keywords: 'kodin kansio, kodinkansio, asukaskansio, asuminen, ohjeet' +
      'folders, home folder, appliances and maintenance, appliance, manual, instruction',
    route: Routes.Housing.MANUALS
  },
  {
    name: 'news',
    keywords: 'tiedotteet, taloni, News, bulletins, building',
    route: Routes.Housing.NEWS},
  {
    name: 'parking',
    keywords: 'auto, autot, autopaikka, autopaikat, pysäköinti, asukaspysäköinti, vieraspysäköinti,' +
      'parkkipaikka, vieraspaikka, autopaikan varaus, autopaikan varaaminen, sähköauton lataaminen, taloni',
    route: Routes.Housing.PARKING
  },
  {
    name: 'safety',
    keywords: 'turvallisuus, pelastautuminen, pelastautumissuunnitelma, pelastussuunnitelma, turvallisuusohje, taloni',
    route: Routes.Housing.SAFETY
  },
  {
    name: 'sauna',
    keywords: 'sauna, saunat, saunavuoro, saunavuorot, saunavaraus, talosauna, taloni',
    route: Routes.Housing.SAUNA
  },
  {
    name: 'storage',
    keywords: 'hinnasto, asukaslaskutushinnasto, järjestyssäännöt, käyttöohje, varasto, taloni',
    route: Routes.Housing.STORAGE
  },
];
